import React, { useState } from 'react'

import BOOKMARKED_ICON from '../../../assets/icons/Bookmarked.svg'
import BOOKMARK_ICON from '../../../assets/icons/Bookmark.svg'
import type { PreviewResource, Resource } from '../../../types/Resources'
import type { Course, PreviewCourse } from '../../../types/Courses'
import {
  useBookmark,
  useUnBookmark,
} from '../../../mutations/resources/Bookmark'
import { useToastContext } from '../../../contexts/ToastContext'
import { useQueryClient } from '@tanstack/react-query'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'
import { useAuth } from '../../../contexts/AuthProvider'

const BookmarkButton: React.FC<{
  resource: Resource | Course | PreviewCourse | PreviewResource
  btnClasses?: string
  iconClasses?: string
}> = ({ resource, btnClasses, iconClasses }) => {
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const user = useAuth()
  const { mutate: callBookmark } = useBookmark()
  const { mutate: callUnBookmark } = useUnBookmark()

  const [isBookmarked, setIsBookmarked] = useState<boolean>(
    resource?.isBookmarked
  )

  const handleBookmark = async () => {
    const currentBookmarkStatus = isBookmarked
    const toastMessage = currentBookmarkStatus
      ? 'Bookmark removed!'
      : 'Bookmark added!'

    const onSuccess = () => {
      addToast('success', toastMessage, { durationMs: 1500 })
      setIsBookmarked(!currentBookmarkStatus)

      trackMixPanel({
        eventName: MIXPANEL_EVENT.RESOURCE_BOOKMARKED,
        properties: {
          id: resource?.id,
          name: resource?.title,
          accountID: user.user.data.id,
        },
      })

      const queries = queryClient
        .getQueryCache()
        .findAll()
        .filter((query) => {
          const queryKey = query.queryKey[0]
          return (
            queryKey === 'getHighlightedResources' ||
            // multimedia
            queryKey === 'getBookmarkedResources' ||
            queryKey === 'getResourceForPreview' ||
            queryKey === 'getResources' ||
            // courses
            queryKey === 'getBookmarkedCourses' ||
            queryKey === 'getCourseForPreview' ||
            queryKey === 'getCourses'
          )
        })

      queries.forEach((q) => {
        const cachedData = queryClient.getQueryData<any>(q.queryKey)

        if (cachedData) {
          // update cache for preview resource
          if (
            q.queryKey[0] === 'getResourceForPreview' ||
            q.queryKey[0] === 'getCourseForPreview'
          )
            queryClient.setQueryData(q.queryKey, {
              ...cachedData,
              isBookmarked: !currentBookmarkStatus,
            })
          // update cache for resources or bookmarks
          else {
            // bookmarked resource and need to add it to 'My bookmarks'
            if (
              (q.queryKey[0] === 'getBookmarkedResources' ||
                q.queryKey[0] === 'getBookmarkedCourses') &&
              !currentBookmarkStatus
            )
              queryClient.setQueryData(q.queryKey, {
                ...cachedData,
                resources: [
                  ...cachedData.resources,
                  { ...resource, isBookmarked: true },
                ],
              })
            // my resources page grid update
            else
              queryClient.setQueryData(q.queryKey, {
                ...cachedData,
                resources: cachedData.resources.map((item) => {
                  if (item.id === resource.id) {
                    return {
                      ...item,
                      isBookmarked: !currentBookmarkStatus,
                    }
                  }
                  return item
                }),
              })
          }
        }
      })
    }

    if (!currentBookmarkStatus)
      callBookmark({ resourceId: resource.id }, { onSuccess })
    else callUnBookmark({ resourceId: resource.id }, { onSuccess })
  }

  return (
    <button onClick={handleBookmark} className={`flex ${btnClasses}`}>
      {isBookmarked ? (
        <img
          src={BOOKMARKED_ICON}
          className={iconClasses}
          alt="bookmarked-icon"
        />
      ) : (
        <img src={BOOKMARK_ICON} className={iconClasses} alt="bookmark-icon" />
      )}
    </button>
  )
}

export default BookmarkButton
