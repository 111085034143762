import { useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import AppLogo from '../AppLogo'
import QuestionsButton from '../QuestionsButton'

interface OnboardingScreenProps {
  withOpacity?: boolean
}

const OnboardingScreen: React.FC<OnboardingScreenProps> = ({
  withOpacity = true,
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    if (divRef.current) divRef.current.scrollTo({ top: 0 })
  }, [pathname])

  return (
    <div
      ref={divRef}
      className="background-image-onboarding relative h-screen overflow-y-auto p-8 font-grotesque xs:px-0 xs:py-10"
    >
      {/* Logo */}
      <AppLogo classes="absolute top-10 left-10 h-14 xs:hidden" />

      {/* Questions Button */}
      <QuestionsButton />

      {/* Content */}
      <div
        className={`flex min-h-full flex-col items-center bg-white px-4 py-6 text-text-primary sm:pb-16 sm:pt-38 [&>*]:flex [&>*]:w-full [&>*]:flex-col [&>*]:gap-6 [&>*]:sm:gap-10 ${
          withOpacity ? 'bg-opacity-90' : ''
        }`}
      >
        <Outlet />
      </div>
    </div>
  )
}

export default OnboardingScreen
