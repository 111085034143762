import { yupResolver } from '@hookform/resolvers/yup'
import type { KeyboardEvent } from 'react'
import React, { Fragment, useState } from 'react'
import { useController, useForm, FormProvider } from 'react-hook-form'
import * as Yup from 'yup'
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  EyeIcon,
  RefreshIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/solid'
import { Combobox, Listbox, Transition } from '@headlessui/react'

import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
} from '../../constants/classConstants'
import type {
  Insurance,
  InsuranceRelationship,
  SelectInsuranceModel,
} from '../../types/Insurance'
import Modal from '../../components/Modals/ImageModal'
import PHOTO_PLACEHOLDER from '../../assets/images/photoPlaceholder.svg'
import {
  genderOptions,
  insurancePatientRelationships,
  states,
} from '../../constants/values'
import { DEFAULT_DATE_REGEX } from '../../constants/regex'
import type { State } from '../../types/State'
import { useGetInsurances } from '../../queries/dashboard/Insurances'
import { isValidDefaultDate, toBase64 } from '../../helpers/generic'
import { useDebounce } from '../../hooks/useDebounce'
import DatePicker from '../../components/DatePicker'

const formSchema = (fullSchema: boolean) => {
  let schema = Yup.object().shape({
    id: Yup.string(),
    insurance: Yup.object().shape({
      name: Yup.string().required('Insurance is required.').trim(),
    }),
    otherInsuranceName: Yup.string().when('insurance.other', {
      is: true,
      then: Yup.string().required('Insurance name is required.').trim(),
    }),
    firstName: Yup.string().required('First name is required.').trim(),
    lastName: Yup.string().required('Last name is required.').trim(),
    birthDate: Yup.string()
      .required('Birthdate is required.')
      .matches(
        DEFAULT_DATE_REGEX,
        'Birthdate must be in valid MM/DD/YYYY format.'
      )
      .test('valid-date', 'Birthdate is not valid.', (enteredDate: string) =>
        isValidDefaultDate(enteredDate)
      )
      .test(
        'test-birth-date-future',
        'Birthdate cannot be in the future.',
        (enteredDate: string) => {
          const date = new Date(enteredDate)
          const now = new Date()
          return date <= now
        }
      )
      .trim(),
    gender: Yup.string().required('Gender is required.').trim(),
    relationship: Yup.mixed().required('Relationship is required.'),
    memberId: Yup.string().required('Member ID is required.').trim(),
    groupId: Yup.string().required('Group ID is required.').trim(),
    frontInsurance: Yup.string().nullable(),
    backInsurance: Yup.string().nullable(),
  })

  if (fullSchema) {
    schema = schema.shape({
      apartmentUnit: Yup.string(),
      streetAddress: Yup.string()
        .required('Street address is required.')
        .trim(),
      city: Yup.string().required('City is required.').trim(),
      state: Yup.string().required('State is required.').trim(),
      zip: Yup.string().required('Zip is required.').trim(),
    })
  }

  return schema
}

const InsuranceForm: React.FC<{
  formButtons: React.ReactNode
  onSubmit?: (data: Insurance) => void
  defaultValues?: Insurance | Partial<Insurance>
  split?: boolean
  preSplit?: boolean
  editableInsurance?: boolean
  classes?: string
  buttonsOutsideForm?: boolean
}> = ({
  defaultValues,
  formButtons,
  onSubmit,
  split = false,
  preSplit = false,
  editableInsurance = true,
  classes = '',
  buttonsOutsideForm = false,
}) => {
  const [query, setQuery] = useState<string>('')
  const [blockSearch, setBlockSearch] = useState<boolean>(false)
  const debouncedSearchQuery = useDebounce(query, 600)
  const [openFront, setOpenFront] = useState<boolean>(false)
  const [openBack, setOpenBack] = useState<boolean>(false)
  const {
    data: insurances,
    isFetching,
    isLoading,
  } = useGetInsurances({
    name: debouncedSearchQuery,
    blockSearch: blockSearch,
  })

  const classNames = (...classesStr: string[]) =>
    classesStr.filter(Boolean).join(' ')

  const methods = useForm<Insurance>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(formSchema(!split)),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods

  const { field: fieldInsurance } = useController({
    control: control,
    name: 'insurance',
    defaultValue: null,
  })

  const { field: fieldGender } = useController({
    control: control,
    name: 'gender',
    defaultValue: '',
  })

  const { field: fieldRelationship } = useController({
    control: control,
    name: 'relationship',
    defaultValue: null,
  })

  const { field: fieldFrontInsurance } = useController({
    control: control,
    name: 'frontInsurance',
    defaultValue: '',
  })

  const { field: fieldBackInsurance } = useController({
    control: control,
    name: 'backInsurance',
    defaultValue: '',
  })

  const { field: fieldState } = useController({
    control: control,
    name: 'state',
    defaultValue: '',
  })

  const resetFormInsuranceChange = (insurance: SelectInsuranceModel) => {
    Object.keys(defaultValues).forEach((field: any) => {
      if (field === 'insurance') setValue('insurance', insurance)
      else if (field === 'gender') setValue('gender', '')
      else if (field === 'relationship') setValue('relationship', null)
      else if (field === 'frontInsurance') setValue('frontInsurance', '')
      else if (field === 'backInsurance') setValue('backInsurance', '')
      else if (field === 'state') setValue('state', '')
      else if (field === 'birthDate') setValue('birthDate', '')
      else if (field === 'otherInsuranceName')
        setValue('otherInsuranceName', '')
      else if (field === 'id') setValue('id', defaultValues?.id || '')
      else setValue(field, undefined)
    })
  }

  return (
    <>
      <form
        className={`flex max-w-sm flex-col justify-center gap-4 text-text-primary ${classes}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!preSplit && (
          <>
            {/* Insurance */}
            {editableInsurance ? (
              <div className="flex flex-col justify-center">
                <Combobox
                  as="div"
                  {...fieldInsurance}
                  onChange={(e) => {
                    if (defaultValues) resetFormInsuranceChange(e)
                    else fieldInsurance.onChange(e)
                  }}
                >
                  <Combobox.Label className="block text-base font-semibold xs:text-sm">
                    Insurance
                  </Combobox.Label>
                  <div className="relative mt-1">
                    <Combobox.Input
                      className={`${
                        errors.insurance ? comboBoxErrorClass : comboBoxClass
                      } capitalize`}
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(i: SelectInsuranceModel) =>
                        i?.name?.toLowerCase()
                      }
                      placeholder="e.g. Blue Cross Blue Shield"
                      autoComplete="off"
                      onFocus={() => setBlockSearch(false)}
                    />
                    <Combobox.Button
                      className={`${
                        !Boolean(insurances?.length) ? 'cursor-default' : ''
                      } absolute inset-y-0 right-3 flex items-center rounded-r-md px-2 focus:outline-none`}
                    >
                      {isFetching ? (
                        <RefreshIcon
                          className="loader h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      ) : (
                        Boolean(insurances?.length) && (
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        )
                      )}
                    </Combobox.Button>

                    {!isFetching && !isLoading && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {(insurances
                          ? [
                              ...insurances,
                              { name: 'Other', id: 'other', other: true },
                            ]
                          : []
                        ).map((insurance) => (
                          <Combobox.Option
                            key={insurance.id}
                            value={insurance}
                            onClick={() => setBlockSearch(true)}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                          >
                            <span className="block truncate capitalize">
                              {insurance?.name?.toLowerCase()}
                            </span>
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
                {errors.insurance && (
                  <p className="mt-2 text-sm text-status-error">
                    {errors.insurance?.name?.message}
                  </p>
                )}
              </div>
            ) : (
              <div className="flex flex-col">
                <h3 className="text-lg font-semibold capitalize xs:text-base">
                  {fieldInsurance?.value?.name?.toLowerCase()}
                </h3>
                {fieldInsurance?.value?.insuranceType && (
                  <p className="text-xs font-normal sm:text-sm">
                    {fieldInsurance.value.insuranceType}
                  </p>
                )}
              </div>
            )}

            <div className="w-full border-t-2 border-components-fillBorders" />

            {/* Insurance name - Other */}
            {fieldInsurance?.value?.other && (
              <div>
                <label
                  htmlFor="otherInsuranceName"
                  className="block text-base font-semibold xs:text-sm"
                >
                  Insurance
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    className={`${
                      errors.otherInsuranceName
                        ? inputErrorClass
                        : inputValidClass
                    }`}
                    placeholder="Insurance"
                    {...register('otherInsuranceName')}
                  />
                  {errors.otherInsuranceName && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                <p className="mt-2 text-sm text-status-error">
                  {errors.otherInsuranceName?.message}
                </p>
              </div>
            )}

            {/* First name */}
            <div>
              <label
                htmlFor="firstName"
                className="block text-base font-semibold xs:text-sm"
              >
                Policy holder's first name
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  className={`${
                    errors.firstName ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="First name"
                  {...register('firstName')}
                />
                {errors.firstName && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.firstName?.message && (
                <p className="mt-2 text-sm text-status-error">
                  {errors.firstName?.message}
                </p>
              )}
            </div>

            {/* Last name */}
            <div>
              <label
                htmlFor="lastName"
                className="block text-base font-semibold xs:text-sm"
              >
                Policy holder's last name
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  placeholder="Last name"
                  className={`${
                    errors.lastName ? inputErrorClass : inputValidClass
                  }`}
                  {...register('lastName')}
                />
                {errors.lastName && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.lastName?.message && (
                <p className="mt-2 text-sm text-status-error">
                  {errors.lastName?.message}
                </p>
              )}
            </div>

            {/* Birthdate */}
            <div>
              <label
                htmlFor="birthDate"
                className="block text-base font-semibold xs:text-sm"
              >
                Policy holder's date of birth
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <DatePicker
                  setValue={setValue}
                  control={control}
                  fieldKey="birthDate"
                  error={errors.birthDate}
                />
              </div>
            </div>

            {/* Gender */}
            <div>
              <Listbox {...fieldGender}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-base font-semibold xs:text-sm">
                      Policy holder's sex at birth
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = genderOptions.find((g: string) =>
                            g.toLowerCase().startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('gender', match, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={`${
                          errors.gender ? comboBoxErrorClass : comboBoxClass
                        }`}
                      >
                        <span
                          className={`${
                            Boolean(fieldGender?.value)
                              ? ''
                              : 'text-text-placeholder'
                          } block truncate`}
                        >
                          {fieldGender?.value || 'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {genderOptions.map((gender: string) => (
                            <Listbox.Option
                              key={gender}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={gender}
                            >
                              <span className={'block truncate'}>{gender}</span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              {errors.gender && (
                <p className="mt-2 text-sm text-status-error">
                  {errors.gender.message}
                </p>
              )}
            </div>

            {/* Relationship */}
            <div>
              <Listbox {...fieldRelationship}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-base font-semibold xs:text-sm">
                      Patient's relationship to policy holder
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = insurancePatientRelationships.find(
                            (r: InsuranceRelationship) =>
                              r.displayName
                                .toLowerCase()
                                .startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('relationship', match, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={`${
                          errors.relationship
                            ? comboBoxErrorClass
                            : comboBoxClass
                        }`}
                      >
                        <span
                          className={`block truncate ${
                            Boolean(fieldRelationship?.value?.displayName)
                              ? ''
                              : 'text-text-placeholder'
                          } `}
                        >
                          {fieldRelationship?.value?.displayName || 'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {React.Children.toArray(
                            insurancePatientRelationships.map(
                              (relationship: InsuranceRelationship) => (
                                <Listbox.Option
                                  className={({ active }) =>
                                    classNames(
                                      'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                      active && 'bg-components-fillBorders'
                                    )
                                  }
                                  value={relationship}
                                >
                                  <span className={'block truncate'}>
                                    {relationship.displayName}
                                  </span>
                                </Listbox.Option>
                              )
                            )
                          )}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              {errors.relationship && (
                <p className="mt-2 text-sm text-status-error">
                  {errors.relationship.message}
                </p>
              )}
            </div>

            {/* Member ID & Group ID */}
            <div className="flex flex-col gap-4 2xl:flex-row">
              {/* Member */}
              <div className="w-full">
                <label
                  htmlFor="memberId"
                  className="block text-base font-semibold xs:text-sm"
                >
                  Member/Subscriber ID
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    className={`${
                      errors.memberId ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="ID"
                    {...register('memberId')}
                  />
                  {errors.memberId && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.memberId?.message && (
                  <p className="mt-2 text-sm text-status-error">
                    {errors.memberId?.message}
                  </p>
                )}
              </div>

              {/* Group */}
              <div className="w-full">
                <label
                  htmlFor="groupId"
                  className="block text-base font-semibold xs:text-sm"
                >
                  Group ID
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    className={`${
                      errors.groupId ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="ID"
                    {...register('groupId')}
                  />
                  {errors.groupId && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.groupId?.message && (
                  <p className="mt-2 text-sm text-status-error">
                    {errors.groupId?.message}
                  </p>
                )}
              </div>
            </div>

            {/* Insurance card */}
            <div>
              <p className="block text-base font-semibold xs:text-sm">
                Insurance card
              </p>
              <p className="block text-sm font-normal">
                Please upload photos of the insurance card.
              </p>

              {/* Front/back card */}
              <div className="mt-6 flex gap-8">
                {/* Front */}
                <div>
                  <p className="text-sm font-semibold">Front</p>
                  <div>
                    {!fieldFrontInsurance?.value ? (
                      <label htmlFor="file-input" className="cursor-pointer">
                        <img
                          src={PHOTO_PLACEHOLDER}
                          alt="front"
                          className="h-32 object-contain"
                        />
                      </label>
                    ) : (
                      <div className="group relative">
                        {/* On hover show options */}
                        <div className="absolute z-10 h-full w-full items-center rounded-md group-hover:flex sm:hidden sm:justify-evenly sm:bg-background-overlay xs:flex xs:justify-end">
                          <TrashIcon
                            className="w-12 cursor-pointer text-text-label xs:w-5"
                            onClick={() => setValue('frontInsurance', null)}
                          />

                          <EyeIcon
                            className="w-12 cursor-pointer text-text-label xs:ml-4 xs:w-5"
                            onClick={() => setOpenFront(true)}
                          />
                        </div>

                        {/* Preview */}
                        <img
                          src={PHOTO_PLACEHOLDER}
                          alt="front"
                          className="h-32 object-contain"
                        />
                        <img
                          src={fieldFrontInsurance?.value}
                          className="absolute top-1/2 h-28 -translate-y-1/2 transform rounded-md object-cover sm:left-1/2 sm:-translate-x-1/2 xs:left-2"
                          alt="insurance"
                        />

                        {/* Modal for big image preview */}
                        <Modal open={openFront} setOpen={setOpenFront}>
                          <div className="flex flex-col items-center">
                            <p className="mb-2 text-center text-lg font-semibold text-white">
                              Insurance Card - Front
                            </p>
                            <div className="relative">
                              <div className="absolute right-0 -top-16">
                                <XIcon
                                  className="w-6 cursor-pointer text-white"
                                  onClick={() => setOpenFront(false)}
                                />
                              </div>
                              <img
                                src={fieldFrontInsurance?.value}
                                className="mb-2 h-full max-w-3xl rounded-md xs:max-w-full"
                                alt="insurance"
                              />
                            </div>

                            <TrashIcon
                              className="w-6 cursor-pointer text-center text-text-label"
                              onClick={() => {
                                setOpenFront(false)
                                setValue('frontInsurance', null)
                              }}
                            />
                          </div>
                        </Modal>
                      </div>
                    )}

                    <input
                      type="file"
                      id="file-input"
                      className="hidden"
                      accept="image/png, image/jpeg"
                      {...fieldFrontInsurance}
                      onChange={async (e) => {
                        setValue(
                          'frontInsurance',
                          await toBase64(e.target.files[0])
                        )
                      }}
                      value=""
                    />
                    {errors.frontInsurance?.message && (
                      <p className="mt-2 text-sm text-status-error">
                        {errors.frontInsurance?.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Back */}
                <div>
                  <p className="text-sm font-semibold">Back</p>
                  <div>
                    {!fieldBackInsurance?.value ? (
                      <label
                        htmlFor="file-input-back"
                        className="cursor-pointer"
                      >
                        <img
                          src={PHOTO_PLACEHOLDER}
                          alt="back"
                          className="h-32 object-contain"
                        />
                      </label>
                    ) : (
                      <div className="group relative">
                        {/* On hover show options */}
                        <div className="absolute z-10 h-full w-full items-center rounded-md group-hover:flex sm:hidden sm:justify-evenly sm:bg-background-overlay xs:flex xs:justify-end">
                          <TrashIcon
                            className="w-12 cursor-pointer text-text-label xs:w-5"
                            onClick={() => setValue('backInsurance', null)}
                          />

                          <EyeIcon
                            className="w-12 cursor-pointer text-text-label xs:ml-4 xs:w-5"
                            onClick={() => setOpenBack(true)}
                          />
                        </div>

                        {/* Preview */}
                        <img
                          src={PHOTO_PLACEHOLDER}
                          alt="back"
                          className="h-32 object-contain"
                        />
                        <img
                          src={fieldBackInsurance?.value}
                          className="absolute top-1/2 h-28 -translate-y-1/2 transform rounded-md object-cover sm:left-1/2 sm:-translate-x-1/2 xs:left-2"
                          alt="insurance"
                        />

                        {/* Modal for big image preview */}
                        <Modal open={openBack} setOpen={setOpenBack}>
                          <div className="flex h-full flex-col items-center">
                            <p className="mb-2 text-center text-lg font-semibold text-white">
                              Insurance Card - Back
                            </p>
                            <div className="relative">
                              <div className="absolute right-0 -top-16">
                                <XIcon
                                  className="w-6 cursor-pointer text-white"
                                  onClick={() => setOpenBack(false)}
                                />
                              </div>
                              <img
                                src={fieldBackInsurance?.value}
                                className="mb-2 h-full max-w-3xl rounded-md xs:max-w-full"
                                alt="insurance"
                              />
                            </div>
                            <TrashIcon
                              className="w-6 cursor-pointer text-center text-text-label"
                              onClick={() => {
                                setOpenBack(false)
                                setValue('backInsurance', null)
                              }}
                            />
                          </div>
                        </Modal>
                      </div>
                    )}

                    <input
                      type="file"
                      id="file-input-back"
                      className="hidden"
                      accept="image/png, image/jpeg"
                      {...fieldBackInsurance}
                      onChange={async (e) => {
                        setValue(
                          'backInsurance',
                          await toBase64(e.target.files[0])
                        )
                      }}
                      value=""
                    />
                    {errors.backInsurance?.message && (
                      <p className="mt-2 text-sm text-status-error">
                        {errors.backInsurance?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {!split && (
          <>
            {/* Policy Holder's Physical Address */}
            {!preSplit && (
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-center text-base font-semibold text-text-secondary xs:text-sm">
                    Policy Holder's Physical Address
                  </span>
                </div>
              </div>
            )}

            {/* Street address */}
            <div>
              <label
                htmlFor="streetAddress"
                className="block text-base font-semibold xs:text-sm"
              >
                Street address
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  className={`${
                    errors.streetAddress ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="Street address"
                  {...register('streetAddress')}
                />
                {errors.streetAddress && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.streetAddress?.message && (
                <p className="mt-2 text-sm text-status-error">
                  {errors.streetAddress?.message}
                </p>
              )}
            </div>

            {/* Apt unit & City */}
            <div className="flex gap-4">
              {/* Apt/Unit */}
              <div className="w-full">
                <label
                  htmlFor="apartmentUnit"
                  className="block text-base font-semibold xs:text-sm"
                >
                  Apt/Unit{' '}
                  <span className="text-xs font-light">(optional)</span>
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    className={`${inputValidClass}`}
                    placeholder="Apt or Unit number"
                    {...register('apartmentUnit')}
                  />
                </div>
              </div>

              {/* City */}
              <div className="flex w-full flex-col justify-between">
                <label
                  htmlFor="city"
                  className="block text-base font-semibold xs:text-sm"
                >
                  City
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    className={`${
                      errors.city ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="City name"
                    {...register('city')}
                  />
                  {errors.city && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.city?.message && (
                  <p className="mt-2 text-left text-sm text-status-error">
                    {errors.city?.message}
                  </p>
                )}
              </div>
            </div>

            {/* State & Zip code */}
            <div className="flex gap-4">
              {/* State */}
              <div className="w-full">
                <Listbox {...fieldState}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-base font-semibold xs:text-sm">
                        State
                      </Listbox.Label>
                      <div className="relative mt-1">
                        <Listbox.Button
                          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                            const match = states.find((s: State) =>
                              s?.name
                                ?.toLowerCase()
                                .startsWith(e.key.toLowerCase())
                            )
                            if (match) {
                              setValue('state', match.abbrev, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                              })
                            }
                          }}
                          className={`${
                            errors.state ? comboBoxErrorClass : comboBoxClass
                          }`}
                        >
                          <span
                            className={`${
                              fieldState?.value ? '' : 'text-text-placeholder'
                            } block truncate`}
                          >
                            {states?.find(
                              (s: State) =>
                                s.name === fieldState?.value ||
                                s.abbrev === fieldState?.value
                            )?.name || 'Select'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {states.map((state: State) => (
                              <Listbox.Option
                                key={state.abbrev}
                                className={({ active }) =>
                                  classNames(
                                    'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                    active && 'bg-components-fillBorders'
                                  )
                                }
                                value={state.abbrev}
                              >
                                {({ selected }) => (
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {state?.name}
                                  </span>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {errors.state && (
                  <p className="mt-2 text-sm text-status-error">
                    {errors.state.message}
                  </p>
                )}
              </div>

              {/* Zip */}
              <div className="w-full">
                <label
                  htmlFor="zip"
                  className="block text-base font-semibold xs:text-sm"
                >
                  Zip code
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    className={`${
                      errors.zip ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="Zip code"
                    {...register('zip')}
                  />
                  {errors.zip && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.zip?.message && (
                  <p className="mt-2 text-sm text-status-error">
                    {errors.zip?.message}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        {!buttonsOutsideForm && (
          <FormProvider {...methods}>{formButtons}</FormProvider>
        )}
      </form>
      {buttonsOutsideForm && (
        <FormProvider {...methods}>{formButtons}</FormProvider>
      )}
    </>
  )
}

export default InsuranceForm
