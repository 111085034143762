import React from 'react'
import { useNavigate, useParams } from 'react-router'

import PatientPacket from '../../components/PatientPacket'
import { useAuth } from '../../contexts/AuthProvider'

const PermissionPatientPacket: React.FC = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { patientId } = useParams()

  const patient = user.roster.find((p) => p.id === patientId)

  const handleNavigateNext = async () =>
    navigate(`/create-permission/${patient.id}/consent`)

  return (
    <PatientPacket
      patient={patient}
      onSuccessPostPatientPacket={handleNavigateNext}
    />
  )
}

export default PermissionPatientPacket
