// import { usePatient } from '../contexts/PatientProvider'
// import { useGetInsuranceEligibility } from '../queries/insurance/InsuranceEligibility'
// import type { ServiceLine } from '../types/ServiceLine'
// import useCurrentServiceLine from './useCurrentServiceLine'
import useHasUsedAllFreeSessions from './useHasUsedAllFreeSessions'

// INSURANCE REVERTED

// has no more remaining free sessions
// and
// does not have insurance or insurance is being verified
const useIsSelfPay = () => {
  // const { patient } = usePatient()
  // const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const usedAllFreeSessions = useHasUsedAllFreeSessions()

  // const shouldCheckInsuranceEligibility = Boolean(
  //   patient.id &&
  //     patient.insurance?.id &&
  //     !patient.unassociatedInsurance &&
  //     currentServiceLine?.serviceType
  // )

  // const { data: insuranceEligibilityCheck } = useGetInsuranceEligibility({
  //   patientId: patient.id,
  //   insuranceId: patient.insurance?.id,
  //   serviceType: currentServiceLine.serviceType,
  //   enabled: shouldCheckInsuranceEligibility,
  // })

  // used up all free sessions and does not have a verified insurance
  return usedAllFreeSessions
  //  &&
  // (!patient.insurance ||
  //   patient.unassociatedInsurance ||
  //   (patient.insurance?.id &&
  //     insuranceEligibilityCheck &&
  //     !insuranceEligibilityCheck.insuranceEligibile))
}

export default useIsSelfPay
