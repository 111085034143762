import type { Course } from './Courses'

export type ResourceFormat = {
  displayName: string
  id: string
  icon: string
  activityName: string
}

export type ResourceStatus = {
  id: string
  name: string
  orderId: number
}

export type ResourceTopic = {
  id?: string
  name: string
  key: string
  section?: string
  other?: boolean
}

export interface Resource {
  id: string
  imageSrc: string
  title: string
  description: string
  format: ResourceFormat
  duration: number
  isBookmarked?: boolean
  type: string
  previewText: string
}

export const FOR_WHO_RESOURCE = {
  FOR_YOU: 'For You',
  FOR_CAREGIVERS: 'For Parents/Caregivers',
  FOR_PATIENT: 'For',
} as const

export type ResourceSection = {
  totalElements: number
  totalPages: number
  resources: Partial<Resource[]> | Partial<Course[]> | any
}

export type PreviewResource = {
  id: string
  format: ResourceFormat
  title: string
  description: string
  duration: number
  topicKeys: string[]
  contentUrl: string
  imageSrc: string
  isBookmarked: boolean
  type: string
}
