import React from 'react'
import { useNavigate } from 'react-router-dom'

import CenteredModal from '../CenteredModal'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { usePatient } from '../../contexts/PatientProvider'
import type { ServiceLine } from '../../types/ServiceLine'
import { enumeratedElementsFromArray } from '../../helpers/generic'

const BookNewAreaOfFocusSession: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  newServiceLines: ServiceLine[]
}> = ({ open, setOpen, newServiceLines }) => {
  const navigate = useNavigate()
  const { patient } = usePatient()

  const bookNow = () => {
    navigate('/select-service-line')
    setOpen(false)
  }

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        <p className="text-lg font-semibold">
          Would you like to book a{' '}
          {enumeratedElementsFromArray(
            newServiceLines.map((sl: ServiceLine) => sl.type)
          )}{' '}
          therapy session{' '}
          {patient?.relationship?.key === 'myself'
            ? ''
            : `for ${patient.firstName}`}{' '}
          now?
        </p>
        <div className="flex justify-end gap-4">
          <button
            className={tertiaryButtonClass}
            onClick={() => setOpen(false)}
          >
            No, book later
          </button>
          <button className={primaryButtonClass} onClick={bookNow}>
            Yes, book now
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default BookNewAreaOfFocusSession
