import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

type GetReceiptParams = {
  paymentId: string
}

const getReceipt = async (paymentId: string): Promise<string> => {
  const token: string = await auth.currentUser.getIdToken()
  const data: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL
    }v1/payments/${paymentId}/receipt`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  if (!data.url) throw new Error('Payment receipt not found.')

  return data.url
}

export const useGetReceipt = () =>
  useMutation((params: GetReceiptParams) => getReceipt(params.paymentId))
