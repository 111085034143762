import { ChevronRightIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { primaryButtonClass, panelClass } from '../../constants/classConstants'
import AVATAR from '../../assets/avatars/User.svg'
import AVATAR_CHILD from '../../assets/avatars/Child1.svg'
import { usePatient } from '../../contexts/PatientProvider'
import { useAuth } from '../../contexts/AuthProvider'
import { ONBOARDING_STEP, type Patient } from '../../types/Patient'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.careFor },
}

const CareForScreen: React.FC = () => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { user } = useAuth()
  const clientInfo = user.data.clientData
  const navigate = useNavigate()
  const { setPatient, patient } = usePatient()
  const [forSelf, setForSelf] = useState<boolean>(
    searchParams.has('forSelf')
      ? searchParams.get('forSelf') === 'true'
      : patient && patient?.relationship
      ? patient?.relationship?.key === 'myself'
      : null
  )

  const navigateToNextScreen = () => {
    trackMixPanel(MIXPANEL_DATA)

    if (clientInfo?.clientType === 'EDUCATION')
      navigate(`/onboarding/eligibility${location.search}`, {
        state: location.state,
      })
    else
      navigate(`/onboarding/build-profile${location.search}`, {
        state: location.state,
      })
  }

  const next = () => {
    setPatient({
      onboardingStep: ONBOARDING_STEP.buildProfile,
      relationship: forSelf ? { name: 'Myself', key: 'myself' } : null,
    })
    navigateToNextScreen()
  }

  // check if there's already a patient as 'myself' and redirect if that is the case
  useEffect(() => {
    if (!user?.roster?.length) return

    if (user.roster.some((p: Patient) => p?.relationship?.key === 'myself')) {
      setPatient({
        onboardingStep: ONBOARDING_STEP.buildProfile,
        relationship: null,
      })
      navigateToNextScreen()
    }
  }, [user, patient])

  return (
    <div className="max-w-lg">
      {/* Title */}
      <p className="text-center text-2xl font-semibold text-text-primary xs:text-base">
        Who is the care for?
      </p>

      {/* Choose care */}
      <div className="flex flex-col justify-center gap-4">
        <button
          className={`${
            forSelf === true
              ? 'border-2 border-cta-active bg-components-paleBlue'
              : ''
          } ${panelClass}`}
          onClick={() => setForSelf(true)}
        >
          <img src={AVATAR} alt="self" className="h-18 w-18 xs:h-14 xs:w-14" />
          <p className="self-center text-left text-base font-semibold text-text-primary">
            Myself
          </p>
        </button>
        <button
          className={`${
            forSelf === false
              ? 'border-2 border-cta-active bg-components-paleBlue'
              : ''
          } ${panelClass}`}
          onClick={() => setForSelf(false)}
        >
          <img
            src={AVATAR_CHILD}
            alt="child"
            className="h-18 w-18 xs:h-14 xs:w-14"
          />
          <p className="self-center text-left text-base font-semibold text-text-primary">
            My Child or Someone Else
          </p>
        </button>
      </div>

      {/* Next */}
      <button
        type="submit"
        disabled={forSelf === null}
        className={`${primaryButtonClass} w-full`}
        onClick={next}
      >
        Next
        <ChevronRightIcon className="h-5 w-5 text-white" />
      </button>

      <SkipAndComeBackLater
        mixPanelData={{
          ...MIXPANEL_DATA,
          properties: {
            ...MIXPANEL_DATA.properties,
            skipped: true,
          },
        }}
      />
    </div>
  )
}

export default CareForScreen
