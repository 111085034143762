import React from 'react'
import { useNavigate } from 'react-router'
import { ExclamationIcon } from '@heroicons/react/outline'

import { tertiaryButtonClass } from '../../../constants/classConstants'
import { EXTERNAL_LINKS } from '../../../constants/values'

const DuressScreen: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className="max-w-xl items-center justify-center">
      <div className="flex flex-col gap-6">
        <div className="self-center rounded-full bg-status-error bg-opacity-20 p-4">
          <ExclamationIcon className="h-10 w-10 text-status-error" />
        </div>
        <h1 className="text-center text-2xl font-semibold xs:text-lg">
          If you, your child, or someone you are caring for is in crisis, seek
          immediate help:
        </h1>
        <div className="flex self-center">
          <p className="whitespace-pre-wrap text-left text-base xs:text-sm">
            • Text 741741 for a crisis text line{'\n'}• Call 911{'\n'}• Go to
            your local emergency department
          </p>
        </div>
        <p className="text-center text-base xs:text-sm">
          Additional resources can be found{' '}
          <a
            href={EXTERNAL_LINKS.RESOURCES}
            className="text-cta-default underline"
            target="_blank"
          >
            here
          </a>
          .
        </p>
        <button
          type="button"
          onClick={() => navigate('/dashboard', { replace: true })}
          className={tertiaryButtonClass}
        >
          Ok
        </button>
      </div>
    </div>
  )
}

export default DuressScreen
