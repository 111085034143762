import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { CHANGE_PROVIDER_SURVEY_ID } from '../../constants/values'

interface SkipSurveyChangeProviderProps {
  patientId: string
  providerId: string
}

const skipSurveyChangeProvider = async ({
  patientId,
  providerId,
}: SkipSurveyChangeProviderProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
    }/v1/survey/${CHANGE_PROVIDER_SURVEY_ID}/sendAnswers`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([
        {
          questionNo: 999,
          answerInt: 999,
          answerText: 'SKIP',
        },
        {
          questionNo: 3,
          answerInt: 0,
          answerText: patientId,
        },
        {
          questionNo: 4,
          answerInt: 0,
          answerText: providerId,
        },
      ]),
    }
  )

  if (!result.ok) throw new Error('Something went wrong.')
}

export const useSkipSurveyChangeProvider = () =>
  useMutation((params: SkipSurveyChangeProviderProps) =>
    skipSurveyChangeProvider(params)
  )
