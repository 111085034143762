import React from 'react'

import CenteredModal from '../CenteredModal'
import { tertiaryButtonClass } from '../../constants/classConstants'
import type { Therapist } from '../../types/Therapist'
import { LICENSE_CREDENTIAL } from '../../constants/values'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import type { ServiceLine } from '../../types/ServiceLine'

import SPEAK_LANGUAGE from '../../assets/icons/speakLanguage.svg'
import THERAPY from '../../assets/icons/therapy.svg'
import type { SelectedTherapist } from '../../screens/contact/ContactScreen'
import { PatientPill } from '../../screens/contact/ContactScreen'
import type { Patient } from '../../types/Patient'

const ViewTherapistInfoModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedTherapist: SelectedTherapist
}> = ({ open, setOpen, selectedTherapist }) => {
  const therapist: Therapist = selectedTherapist?.therapist
  const patients: Patient[] = selectedTherapist?.patients

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-4 text-text-primary">
        <p className="text-lg font-semibold">Therapist Information</p>

        {/* Profile picture & name */}
        <div className="flex flex-row items-center gap-6 sm:gap-16">
          <img
            className="h-14 w-14 rounded-full object-cover sm:h-30 sm:w-30 sm:border-4 sm:border-components-paleBlue"
            src={therapist?.src}
            alt="Therapist"
          />
          <div className="flex flex-col items-start gap-0 sm:gap-2">
            <h1 className="text-left text-sm font-semibold sm:text-2xl">
              {therapist?.preferredName}
            </h1>
            <h2 className="text-label text-left text-xs font-semibold sm:text-base">
              {therapist?.licenseCredential ||
                LICENSE_CREDENTIAL[
                  SERVICE_LINES_ARRAY.find(
                    (line: ServiceLine) =>
                      line.displayName === therapist?.serviceLine
                  )?.serviceType
                ]}
            </h2>
          </div>
        </div>

        {/* Type & language */}
        <div className="flex flex-col items-start gap-1">
          <div className="flex flex-row gap-1">
            <img src={THERAPY} alt="Support" className="h-5 w-5" />

            <p className="text-sm font-normal capitalize">
              {therapist?.serviceLine}
            </p>
          </div>
          <div className="flex flex-row gap-1">
            <img src={SPEAK_LANGUAGE} alt="language" className="h-5 w-5" />

            <p className="text-left text-sm font-normal">
              {React.Children.toArray(
                therapist?.languages.map((language, i) => (
                  <span>
                    {language}
                    {i < therapist?.languages?.length - 1 && ', '}
                  </span>
                ))
              )}
            </p>
          </div>
        </div>

        {/* Intro */}
        <p className="text-base font-semibold">Intro</p>
        <p className="text-base">{therapist?.description}</p>

        {/* Contact */}
        <p className="text-base font-semibold">Contact</p>
        <a
          href={`mailto:${therapist?.email}`}
          className="text-base text-cta-default underline"
        >
          {therapist?.email}
        </a>

        {/* For who */}
        <p className="text-base font-semibold">For Whom</p>
        <div className="flex flex-wrap gap-2">
          {React.Children.toArray(
            patients?.map((patient) => <PatientPill p={patient} />)
          )}
        </div>

        {/* Buttons */}
        <div className="flex justify-end">
          <button
            className={tertiaryButtonClass}
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default ViewTherapistInfoModal
