import React from 'react'
import { Outlet } from 'react-router-dom'

import QuestionsButton from '../QuestionsButton'
import CrisisMessaging from '../CrisisMessaging'
import { EXTERNAL_LINKS } from '../../constants/values'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const InitialColorfulScreen: React.FC<{
  showWarning?: boolean
  showAccessToResources?: boolean
  bannerBg?: string
}> = ({
  showWarning,
  showAccessToResources,
  bannerBg = 'background-image-signin',
}) => {
  const handleAccessResources = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.DOT_ACADEMY_LINK,
      properties: null,
    })
    window.open(EXTERNAL_LINKS.DOT_ACADEMY, '_blank')
  }

  return (
    <div className={`${bannerBg} h-screen font-grotesque sm:overflow-y-auto`}>
      <div className="flex flex-col items-center gap-5 py-6 sm:min-h-screen sm:justify-center sm:overflow-visible xs:h-full xs:overflow-y-auto">
        <div className="relative z-10 w-full bg-white sm:max-w-lg sm:rounded-2xl sm:px-8 sm:shadow xs:px-4">
          {/* Questions Button */}
          <QuestionsButton showOnMobile />

          {/* Content */}
          <div className="my-10">
            <Outlet />
          </div>
        </div>

        {/* Warning */}
        {showWarning && <CrisisMessaging showShadow />}

        {/* Access to resources */}
        {showAccessToResources && (
          <div className="flex w-full items-center justify-center rounded-2xl bg-text-primary p-4 text-base shadow-lg sm:max-w-lg xs:rounded-none">
            <p className="text-center font-normal text-secondaryAccents-lightOrange">
              <span>Teacher or a faculty member?</span>
              <button
                onClick={handleAccessResources}
                className="ml-2 text-white underline"
              >
                Access our resources here
              </button>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default InitialColorfulScreen
