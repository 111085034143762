import { ChevronLeftIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
} from '../../constants/classConstants'
import { PASWORD_VALIDATION_REGEX } from '../../constants/regex'
import AppLogo from '../../components/AppLogo'

type ResetPasswordForm = {
  newPassword: string
  confirmPassword: string
}

const formSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter a new password.')
    .matches(
      PASWORD_VALIDATION_REGEX,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
    )
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Please make sure they match.')
    .trim(),
})

const ResetPasswordScreen: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = async (data: ResetPasswordForm) => data

  return (
    <div className="flex flex-col text-text-primary">
      {/* Back link */}
      <div className="absolute flex items-start sm:top-6 sm:left-4 sm:w-2/3 xs:top-4 xs:left-2 xs:w-full">
        <Link to="/sign-in" className="mb-6 flex items-center text-base">
          <ChevronLeftIcon className="h-5 w-5" />
          <span className="font-semibold">Back</span>
        </Link>
      </div>

      {/* Logo */}
      <AppLogo classes="flex self-center mt-4" logoWithText />
      <p className="mt-8 text-center text-2xl font-semibold xs:text-base">
        Reset Password
      </p>
      <p className="text-center text-base xs:text-sm">
        Please set your new password.
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
        {/* New password */}
        <div>
          <label
            htmlFor="newPassword"
            className="block text-base font-semibold xs:text-sm"
          >
            New password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={errors.newPassword ? inputErrorClass : inputValidClass}
              placeholder="New password"
              {...register('newPassword')}
            />
            {errors.newPassword && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.newPassword?.message}
          </p>
        </div>

        {/* Confirm password */}
        <div className="mt-4">
          <label
            htmlFor="confirmPassword"
            className="block text-base font-semibold xs:text-sm"
          >
            Confirm new password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={
                errors.confirmPassword ? inputErrorClass : inputValidClass
              }
              placeholder="Confirm password"
              {...register('confirmPassword')}
            />
            {errors.confirmPassword && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.confirmPassword?.message}
          </p>
        </div>

        {/* Submit */}
        <button type="submit" className={`${primaryButtonClass} mt-6 w-full`}>
          Submit
        </button>
      </form>
    </div>
  )
}

export default ResetPasswordScreen
