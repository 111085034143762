import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface StartCourseProps {
  courseId: string
  patientId: string
}

const startCourse = async ({ courseId, patientId }: StartCourseProps) => {
  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL}v1/courses/start`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        courseId,
        patientId,
      }),
    }
  )
}

export const useStartCourse = () =>
  useMutation((params: StartCourseProps) => startCourse(params))
