import React from 'react'
import CenteredModal from '../../../components/CenteredModal'
import { ExclamationIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router'

import {
  deleteButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'

const QuitTaskModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ open, setOpen }) => {
  const navigate = useNavigate()

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        <div className="flex flex-row gap-4">
          <div className="self-start rounded-full bg-status-error bg-opacity-20 p-2.5">
            <ExclamationIcon className="h-5 w-5 text-status-error" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">Quit this task?</p>
            <p>
              Are you sure you would like to quit this task? Your progress will
              not be saved.
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button
            className={deleteButtonClass}
            onClick={() => navigate('/dashboard')}
          >
            Yes, quit
          </button>
          <button
            className={tertiaryButtonClass}
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default QuitTaskModal
