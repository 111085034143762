import { useMutation } from '@tanstack/react-query'

interface CreateNewUserProps {
  token: string
  firstName: string
  lastName: string
  phoneNumber: string
  clientId: string
}

const createNewUser = async ({
  token,
  firstName,
  lastName,
  phoneNumber,
  clientId,
}: CreateNewUserProps): Promise<string> => {
  const result = await fetch(`${import.meta.env.VITE_DOTCOM_BFF_URL}user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      firstName,
      lastName,
      phoneNumber,
      clientId,
    }),
  }).then((response) => response.json())

  return result.dctUserId
}

export const useCreateNewUser = () =>
  useMutation((params: CreateNewUserProps) => createNewUser(params))
