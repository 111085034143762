import React from 'react'

const ProgressBar: React.FC<{ value: number }> = ({ value }) => {
  return (
    <div className="flex h-2 w-full rounded-3.5xl bg-components-disabled">
      <div
        className="h-2 rounded-3.5xl bg-cta-default"
        style={{ width: `${value}%` }}
      />
    </div>
  )
}

export default ProgressBar
