import { ExclamationCircleIcon } from '@heroicons/react/solid'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { signInWithEmailAndPassword } from 'firebase/auth'

import {
  inputValidClass,
  primaryButtonClass,
} from '../../constants/classConstants'
import { EMAIL_REGEX } from '../../constants/regex'
import AppLogo from '../../components/AppLogo'
import { auth } from '../../config/firebase'
import { useAuth } from '../../contexts/AuthProvider'
import { genericErrors } from '../../constants/values'

type SignInForm = {
  email: string
  password: string
}

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email.')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.')
    .trim(),
  password: Yup.string().required('Please enter your password.').trim(),
})

const SignIn: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { authError, setAuthError, setInitialized } = useAuth()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>({
    defaultValues: { email: searchParams.get('email') || '' },
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = async (data: SignInForm) => {
    try {
      setInitialized(false)
      const { user } = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )
      // check if user has email verified
      if (!user.emailVerified) {
        setAuthError('auth/email-unverified')
        navigate('/confirmation/success')
        setInitialized(true)
      }
    } catch (e) {
      setInitialized(true)
      if (e.code) setAuthError(e.code)
      else setAuthError('auth/something-wrong')
    }
  }

  return (
    <div className="flex flex-col text-text-primary">
      {/* Header */}
      <AppLogo classes="flex self-center" logoWithText />

      <p className="mt-4 rounded-md bg-secondaryAccents-lightPink p-2 text-center text-base xs:text-sm">
        🥳 DotCom Therapy is now{' '}
        <span className="font-semibold">Huddle Up</span>!
      </p>

      <p className="mt-8 text-center text-2xl font-semibold xs:mt-4 xs:text-base">
        Sign In
      </p>

      {/* Form */}
      <form className="mb-6 space-y-6 pt-2" onSubmit={handleSubmit(onSubmit)}>
        {/* Email */}
        <div>
          <label
            htmlFor="email"
            className="block text-base font-semibold xs:text-sm"
          >
            Email
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={inputValidClass}
              placeholder="Email address"
              {...register('email')}
              onChange={(e) => {
                if (authError) {
                  setAuthError(null)
                }
                register('email').onChange(e)
              }}
            />
          </div>
          {Boolean(errors.email) && (
            <p className="mt-1 text-sm text-status-error">
              {errors.email.message}
            </p>
          )}
        </div>

        {/* Password */}
        <div>
          <label
            htmlFor="password"
            className="block text-base font-semibold xs:text-sm"
          >
            Password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={inputValidClass}
              placeholder="Password"
              {...register('password')}
              onChange={(e) => {
                if (authError) {
                  setAuthError(null)
                }
                register('password').onChange(e)
              }}
            />
          </div>
          {Boolean(errors.password) && (
            <p className="mt-1 text-sm text-status-error">
              {errors.password.message}
            </p>
          )}
        </div>

        {/* Error */}
        {Boolean(authError) && (
          <div className="flex items-center gap-1">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <p className="text-sm text-status-error">
              {genericErrors[authError] || authError}
            </p>
          </div>
        )}

        {/* Forgot */}
        <p className="mt-6 text-center">
          <Link
            to="/password/forgot"
            className="text-sm font-semibold underline"
          >
            Forgot password
          </Link>
        </p>

        {/* Submit */}
        <button type="submit" className={`${primaryButtonClass} mt-6 w-full`}>
          Sign in
        </button>

        {/* New to dot */}
        <p className="mt-6 text-center text-base font-semibold xs:text-sm">
          New to Huddle Up?{' '}
          <button
            onClick={() => navigate('/sign-up/verify')}
            className="text-cta-default underline"
          >
            Sign Up
          </button>
        </p>
      </form>
    </div>
  )
}

export default SignIn
