import type { KeyboardEvent } from 'react'
import { flushSync } from 'react-dom'
import React, { useRef, useState, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import cloneDeep from 'lodash.clonedeep'

import type { ProviderBookingLocationState } from '../../types/Booking'
import useIsSelfPay from '../../hooks/useIsSelfPay'
import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { CARD_SAVING_ERROR_TEXT, states } from '../../constants/values'
import type { State } from '../../types/State'
import type { CreditCardRefType } from '../../components/CreditCardInput'
import CreditCardInput from '../../components/CreditCardInput'
import { usePatient } from '../../contexts/PatientProvider'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import { useAuth } from '../../contexts/AuthProvider'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import { useToastContext } from '../../contexts/ToastContext'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { ServiceLine } from '../../types/ServiceLine'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { useGetPrices } from '../../queries/payments/GetPrices'

// INSURANCE REVERTED

const formSchema = Yup.object().shape({
  billingStreetAddress: Yup.string()
    .required('Street address is required.')
    .trim(),
  billingApartmentUnit: Yup.string().trim(),
  billingCity: Yup.string().required('City is required.').trim(),
  billingState: Yup.string().required('State is required.').trim(),
  billingZip: Yup.string().required('Zip is required.').trim(),
})

interface ConfirmBillingInformationForm {
  billingStreetAddress: string
  billingApartmentUnit: string
  billingCity: string
  billingState: string
  billingZip: string
}

const ConfirmBillingInformation: React.FC = () => {
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState<boolean>(false)
  const [isCreditCardComplete, setIsCreditCardComplete] =
    useState<boolean>(false)
  const [isCreditCardSubmitReady, setIsCreditCardSubmitReady] =
    useState<boolean>(false)
  const [isCreditCardError, setIsCreditCardError] = useState<boolean>(false)

  const creditCardRef: { current: CreditCardRefType } = useRef()

  const { user, setUser } = useAuth()
  const { patient, setPatient } = usePatient()
  const navigate = useNavigate()
  const location = useLocation()
  const isSelfPay = useIsSelfPay()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const addToast = useToastContext()
  const { mutateAsync: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const { data: prices, isLoading: isLoadingPrices } = useGetPrices()

  const locationState = location.state as ProviderBookingLocationState

  const {
    setValue,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<ConfirmBillingInformationForm>({
    mode: 'all',
    resolver: yupResolver(formSchema),
    defaultValues: {
      billingStreetAddress: '',
      billingApartmentUnit: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
    },
  })

  const isLoading = isLoadingCreditCard || isLoadingUpdateProfile
  const isDisabledSubmit =
    !isCreditCardComplete ||
    !isValid ||
    isSubmitting ||
    isLoading ||
    !isCreditCardSubmitReady

  const onSubmit = async (data: ConfirmBillingInformationForm) => {
    if (isDisabledSubmit) return

    const paymentMethod = await creditCardRef.current.submitCreditCard()

    const newUser = cloneDeep(user)
    const newPatient = cloneDeep(patient)

    const patientIdx = newUser.roster.findIndex(
      (p: Patient) => p.id === patient.id
    )

    const conditionIdx = newUser.roster[patientIdx].conditions.findIndex(
      (c: Condition) => !c.isIep
    )

    const carePlanIdx = newUser.roster[patientIdx].conditions[
      conditionIdx
    ]?.carePlans.findIndex(
      (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
    )

    newPatient.conditions[conditionIdx].carePlans[
      carePlanIdx
    ].billingInformationConfirmed = true
    newPatient.billingStreetAddress = data.billingStreetAddress
    newPatient.billingApartmentUnit = data.billingApartmentUnit
    newPatient.billingCity = data.billingCity
    newPatient.billingState = data.billingState
    newPatient.billingZip = data.billingZip

    newUser.roster[patientIdx] = newPatient
    newUser.paymentMethod = paymentMethod

    await callUpdateProfile(
      {
        patient: newPatient,
        user,
      },
      {
        onSuccess: async () => {
          flushSync(() => {
            setUser(newUser)
            setPatient(newPatient)
            navigate('../confirmation', {
              state: locationState,
            })
          })
        },
        onError: () => addToast('error', 'Something went wrong 🫤'),
      }
    )
  }

  const isLoadingForm = isSubmitting || isLoading || !isCreditCardSubmitReady

  const classNames = (...classesStr: string[]) =>
    classesStr.filter(Boolean).join(' ')

  return (
    <div className="max-w-lg text-center">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold sm:text-2xl sm:font-medium">
          Let's confirm your billing information
        </h2>
        {/* {!isSelfPay && (
          <p className="text-sm font-normal !leading-5 sm:text-base">
            <>
              This is just in case your insurance does not cover the session,
              <br />
              for no-shows or late modification/cancellations.
            </>
          </p>
        )} */}
        {isSelfPay && (
          <p className="text-sm font-normal sm:text-base">
            <span className="font-semibold">Session total:</span> $
            {isLoadingPrices ? '-' : locationState?.cost || prices[30]}
          </p>
        )}
      </div>
      <div className="flex flex-col items-center gap-6 sm:gap-8">
        <p className="text-sm font-semibold text-text-secondary sm:text-base">
          Credit/Debit Card
        </p>
        <div className="mx-auto flex w-full max-w-md flex-col gap-2">
          <CreditCardInput
            hidePostalCode={true}
            ref={creditCardRef}
            setIsComplete={setIsCreditCardComplete}
            setIsLoading={setIsLoadingCreditCard}
            setIsSubmitReady={setIsCreditCardSubmitReady}
            setIsError={setIsCreditCardError}
          />
          {isCreditCardError && (
            <p className="text-sm text-status-error">
              {CARD_SAVING_ERROR_TEXT}
            </p>
          )}
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <p className="text-sm font-semibold text-text-secondary sm:text-base">
              Billing Address
            </p>
            {/* {!isSelfPay && (
              <p className="text-xs font-normal sm:text-sm">
                We'll bill this address if there is any balance not covered by
                insurance
              </p>
            )} */}
          </div>
          <div className="mx-auto grid w-full max-w-md grid-cols-2 gap-4 text-left">
            {/* Street address */}
            <Controller
              control={control}
              name="billingStreetAddress"
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 flex flex-col gap-1">
                  <label
                    htmlFor="billingStreetAddress"
                    className="text-sm font-semibold sm:text-base"
                  >
                    Street address
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={`${
                        error ? inputErrorClass : inputValidClass
                      } font-normal`}
                      placeholder="Street address"
                      {...field}
                    />
                    {Boolean(error) && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-status-error"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {Boolean(error) && (
                    <p className="text-sm text-status-error">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* Apt unit */}
            <Controller
              control={control}
              name="billingApartmentUnit"
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 flex flex-col gap-1">
                  <label
                    htmlFor="billingApartmentUnit"
                    className="text-sm font-semibold sm:text-base"
                  >
                    Apt/Unit (optional)
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={`${
                        error ? inputErrorClass : inputValidClass
                      } font-normal`}
                      placeholder="Apt or Unit number"
                      {...field}
                    />
                    {Boolean(error) && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-status-error"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {Boolean(error) && (
                    <p className="text-sm text-status-error">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* City */}
            <Controller
              control={control}
              name="billingCity"
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 flex flex-col gap-1">
                  <label
                    htmlFor="billingCity"
                    className="text-sm font-semibold sm:text-base"
                  >
                    City
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={`${
                        error ? inputErrorClass : inputValidClass
                      } font-normal`}
                      placeholder="City name"
                      {...field}
                    />
                    {Boolean(error) && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-status-error"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {Boolean(error) && (
                    <p className="text-sm text-status-error">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* State */}
            <Controller
              control={control}
              name="billingState"
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                  <Listbox {...field}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-base font-semibold xs:text-sm">
                          State
                        </Listbox.Label>
                        <div className={`relative ${error ? 'h-full' : ''}`}>
                          <Listbox.Button
                            onKeyDown={(
                              e: KeyboardEvent<HTMLButtonElement>
                            ) => {
                              const match = states.find((s: State) =>
                                s?.name
                                  .toLowerCase()
                                  .startsWith(e.key.toLowerCase())
                              )
                              if (match) {
                                setValue('billingState', match?.name, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                })
                              }
                            }}
                            className={`${error ? 'h-full' : ''} ${
                              error ? comboBoxErrorClass : comboBoxClass
                            }`}
                          >
                            <span
                              className={`${
                                field?.value ? '' : 'text-text-placeholder'
                              } block truncate`}
                            >
                              {field?.value || 'Select'}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {states.map((state: State) => (
                                <Listbox.Option
                                  key={state.abbrev}
                                  className={({ active }) =>
                                    classNames(
                                      'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                      active && 'bg-components-fillBorders'
                                    )
                                  }
                                  value={state?.name}
                                >
                                  {({ selected }) => (
                                    <span
                                      className={classNames(
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'block truncate'
                                      )}
                                    >
                                      {state?.name}
                                    </span>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  {Boolean(error) && (
                    <p className="text-sm text-status-error">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* Zip Code */}
            <Controller
              control={control}
              name="billingZip"
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                  <label
                    htmlFor="billingZip"
                    className="text-sm font-semibold sm:text-base"
                  >
                    Zip code
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={`${
                        error ? inputErrorClass : inputValidClass
                      } font-normal`}
                      placeholder="Zip code"
                      {...field}
                    />
                    {Boolean(error) && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-status-error"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {Boolean(error) && (
                    <p className="text-sm text-status-error">{error.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 sm:mt-6 sm:gap-12">
        <div className="flex gap-2 sm:gap-4">
          <button
            type="button"
            disabled={isLoadingForm}
            className={`w-1/2 ${tertiaryButtonClass}`}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </button>
          <button
            type="button"
            className={`w-1/2 ${primaryButtonClass}`}
            disabled={isDisabledSubmit}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoadingForm ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                Loading
              </>
            ) : (
              <>
                Next
                <ChevronRightIcon className="h-5 w-5" />
              </>
            )}
          </button>
        </div>
        {!isLoadingForm && <SkipAndComeBackLater />}
      </div>
    </div>
  )
}

export default ConfirmBillingInformation
