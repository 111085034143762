import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface AttachPaymentMethodProps {
  customerId: string
  stripePaymentMethodId: string
}

const attachPaymentMethod = async ({
  customerId,
  stripePaymentMethodId,
}: AttachPaymentMethodProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL}v1/payment-methods/attach`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        customerId,
        stripePaymentMethodId,
      }),
    }
  )

  if (!result.ok)
    throw new Error('Something went wrong while attaching payment method.')
}

export const useAttachPaymentMethod = () =>
  useMutation((params: AttachPaymentMethodProps) => attachPaymentMethod(params))
