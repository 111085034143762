interface TherapistAvatarWrapperProps {
  reversed?: boolean
  children: React.ReactNode
}

const TherapistAvatarWrapper: React.FC<TherapistAvatarWrapperProps> = ({
  reversed = false,
  children,
}) => {
  return (
    <div
      className={`flex items-center justify-center ${
        reversed ? 'flex-row-reverse' : 'flex-row'
      }`}
    >
      {children}
    </div>
  )
}

export default TherapistAvatarWrapper
