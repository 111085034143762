import { Navigate, useLocation } from 'react-router-dom'

import { usePatient } from '../../contexts/PatientProvider'

const RequirePatient = ({ children }) => {
  const location = useLocation()
  const { patient } = usePatient()

  if (!patient)
    return <Navigate to="/dashboard" replace state={{ from: location }} />

  return children
}

export default RequirePatient
