import React, { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { RefreshIcon } from '@heroicons/react/solid'

import INSURANCE from '../../assets/images/Insurance.svg'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useDeleteInsurance } from '../../mutations/dashboard/DeleteInsurance'
import { useToastContext } from '../../contexts/ToastContext'
import { usePatient } from '../../contexts/PatientProvider'
import type { CarePlan, Condition } from '../../types/Patient'
import useGoToBookingWithSelfPay from './hooks/useGoToBookingWithSelfPay'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { ServiceLine } from '../../types/ServiceLine'
import { useCheckProviderInInsuranceNetwork } from '../../queries/booking/ProviderInsuranceNetwork'
import { states } from '../../constants/values'
import { useGetProviders } from '../../queries/booking/Providers'
import GlobalLoader from '../../components/GlobalLoader'
import type { Therapist } from '../../types/Therapist'
import type { State } from '../../types/State'

const InsuranceInfoConfirm: React.FC = () => {
  const [noLongerInsurance, setNoLongerInsurance] = useState(false)
  const { patient } = usePatient()
  const patientCarePlans: CarePlan[] = patient?.conditions?.find(
    (c: Condition) => !c.isIep
  )?.carePlans
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const addToast = useToastContext()
  const [goToBookingWithSelfPay] = useGoToBookingWithSelfPay()
  const {
    mutate: callDeleteInsurance,
    isLoading: isLoadingIntermediaryDeleteInsurance,
  } = useDeleteInsurance()

  const forSelf: boolean = patient?.relationship?.key === 'myself'

  const { data: therapists, isLoading: isLoadingTherapists } = useGetProviders({
    patient,
    serviceType: currentServiceLine.serviceType,
    language: patient.preferredLanguage,
    licenseJurisdiction: states?.find(
      (state: State) => state?.name === patient?.state
    )?.abbrev,
    withFirstAvailability: false,
    ignoreTakingNewPrivatePatients: true,
  })

  const currentProvider = (therapists || []).find(
    (t: Therapist) =>
      t.id ===
      patientCarePlans.find(
        (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
      ).providerId
  )

  const isProviderInsuranceNetworkEnabled = Boolean(
    !patient.unassociatedInsurance &&
      patient.insurance?.id &&
      currentProvider?.id
  )

  const {
    data: providerInsuranceNetwork,
    isLoading: isLoadingProviderInsuranceCheck,
  } = useCheckProviderInInsuranceNetwork({
    patient,
    serviceType: currentServiceLine.serviceType,
    language: patient.preferredLanguage,
    licenseJurisdiction: states.find(
      (state: State) => state?.name === patient?.state
    )?.abbrev,
    providerId: currentProvider?.id,
    insuranceId: patient.insurance?.id,
    enabled: isProviderInsuranceNetworkEnabled,
  })

  const handleNoLongerHaveInsurance = async () => {
    if (patient.insurance?.id) {
      callDeleteInsurance(
        {
          insuranceId: patient.insurance.id,
          patientId: patient.id,
        },
        {
          onError: () => addToast('error', 'Something went wrong.'),
          onSuccess: async () => setNoLongerInsurance(true),
        }
      )
    } else setNoLongerInsurance(true)
  }

  useEffect(() => {
    if (!noLongerInsurance || !patient || !currentServiceLine) return

    const carePlanIdx = patientCarePlans.findIndex(
      (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
    )

    if (
      noLongerInsurance &&
      !patient.insurance &&
      patientCarePlans[carePlanIdx].bookingNonSponsoredInformationConfirmed
    ) {
      goToBookingWithSelfPay()
    }
  }, [patient, currentServiceLine, noLongerInsurance])

  if (!patient?.insurance) return <Navigate to="/dashboard" />

  if (
    isLoadingTherapists ||
    (isProviderInsuranceNetworkEnabled && isLoadingProviderInsuranceCheck)
  )
    return <GlobalLoader />

  return (
    <div className="max-w-3xl !gap-6 text-center">
      <h1 className="text-lg font-semibold sm:text-2xl">
        Is this still {forSelf ? 'your' : 'the'} correct insurance information
        {!forSelf && (
          <>
            {' '}
            for <span className="text-cta-default">{patient.firstName}</span>
          </>
        )}
        ?
      </h1>
      <div className="mx-auto flex w-full max-w-lg flex-col gap-6">
        <img src={INSURANCE} alt="insurance" className="mx-auto h-20 w-20" />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col border-b-2 border-components-fillBorders pb-4">
            <h3 className="text-base font-semibold sm:text-lg">
              {patient?.insurance?.insurance?.name}
            </h3>
            {Boolean(providerInsuranceNetwork) && (
              <p className="text-xs font-normal sm:text-sm">
                {providerInsuranceNetwork.currentProviderInNetwork
                  ? 'In-Network Insurance'
                  : 'Out-of-Network Insurance'}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 text-left">
            <Col
              label={"Policy holder's name"}
              content={`${patient.insurance.firstName} ${patient.insurance.lastName}`}
              colSpan={2}
            />
            <Col
              label={"Policy holder's date of birth"}
              content={patient.insurance.birthDate}
              colSpan={1}
            />
            <Col
              label={"Policy holder's sex at birth"}
              content={patient.insurance.gender}
              colSpan={1}
            />
            <Col
              label={'Member/Subscriber ID'}
              content={patient.insurance.memberId}
              colSpan={1}
            />
            <Col
              label={'Group ID'}
              content={patient.insurance.groupId}
              colSpan={1}
            />
            <Col
              label={"Patient's relationship to policy holder"}
              content={patient.insurance.relationship.displayName}
              colSpan={2}
            />
          </div>
        </div>
        <div className="flex flex-col gap-6 sm:gap-12">
          <div className="grid grid-cols-2 gap-2 sm:gap-4">
            <Link to="../edit" className={tertiaryButtonClass}>
              No, edit
            </Link>
            <Link
              to="../policy-holder-address/confirm"
              className={primaryButtonClass}
            >
              Yes, it's correct
            </Link>
          </div>
          <button
            disabled={isLoadingIntermediaryDeleteInsurance}
            onClick={() => handleNoLongerHaveInsurance()}
            className={`${
              isLoadingIntermediaryDeleteInsurance ? '' : 'underline'
            } flex flex-row items-center justify-center gap-1 self-center text-sm font-medium text-text-secondary sm:text-base`}
          >
            {isLoadingIntermediaryDeleteInsurance ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                Loading
              </>
            ) : (
              'I no longer have insurance'
            )}
          </button>
        </div>
        <Link
          to="/dashboard"
          className="text-sm font-medium text-text-secondary underline sm:text-base"
        >
          Cancel
        </Link>
      </div>
    </div>
  )
}

const Col: React.FC<{ label: string; content: string; colSpan: number }> = ({
  label,
  content,
  colSpan,
}) => (
  <div className={`col-span-${colSpan} flex flex-col`}>
    <p className="text-sm font-semibold sm:text-base">{label}</p>
    <p className="py-3.5 px-4 text-xs font-normal sm:text-sm">{content}</p>
  </div>
)

export default InsuranceInfoConfirm
