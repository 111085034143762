import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { TELE_APPROPRIATENESS_SURVEY_ID } from '../../mutations/outcomes/PostTeleAppropriateness'

const getTeleForm = async (personId: string): Promise<boolean> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const result = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
      }/v1/survey/${TELE_APPROPRIATENESS_SURVEY_ID}/getLatest?personId=${personId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return Boolean(result)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const useGetTeleForm = (params: { personId: string }) =>
  useQuery(['getTeleForm'], async () => getTeleForm(params.personId), {
    cacheTime: 0,
    staleTime: 0,
  })
