import type { Ref } from 'react'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { flushSync } from 'react-dom'
import cloneDeep from 'lodash.clonedeep'

import { useAuth } from '../contexts/AuthProvider'
import { useGetCustomer } from '../queries/payments/GetCustomer'
import { useAttachPaymentMethod } from '../mutations/payments/AttachPaymentMethod'
import type { PaymentMethod } from '../types/User'
import { getPaymentMethod } from '../helpers/externalCalls'
import { removeTaskForUpdateCardInfo } from '../helpers/tasks'

export interface CreditCardRefType {
  submitCreditCard: () => Promise<PaymentMethod>
}

interface CreditCardInputProps {
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setIsSubmitReady: React.Dispatch<React.SetStateAction<boolean>>
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
  ref: Ref<CreditCardRefType>
  onFinish?: () => void
  classes?: string
  hidePostalCode?: boolean
}

const CreditCardInput: React.FC<CreditCardInputProps> = forwardRef(
  (
    {
      classes,
      setIsComplete,
      setIsLoading,
      setIsSubmitReady,
      setIsError,
      onFinish = () => {},
      hidePostalCode = false,
    },
    ref
  ) => {
    const {
      data: stripeCustomer,
      isError: isErrorStripeCustomer,
      isLoading: isLoadingStripeCustomer,
    } = useGetCustomer()
    const { user, setUser } = useAuth()
    const elements = useElements()
    const stripeHook = useStripe()
    const { mutateAsync: mutateAsyncAttachPaymentMethod } =
      useAttachPaymentMethod()

    const isSubmitDisabled =
      !stripeCustomer || isErrorStripeCustomer || isLoadingStripeCustomer
    useEffect(() => {
      setIsSubmitReady(!isSubmitDisabled)
    }, [isSubmitDisabled])

    // reset state when component is unmounted
    useEffect(
      () => () => {
        setIsComplete(false)
        setIsLoading(false)
        setIsSubmitReady(false)
        setIsError(false)
      },
      []
    )

    const onSubmit = async (): Promise<PaymentMethod> => {
      if (isSubmitDisabled) return

      const cardElement = elements.getElement(CardElement)

      // create paymentMethod
      const { paymentMethod } = await stripeHook.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${user.data.firstName} ${user.data.lastName}`,
          email: user.data.email,
        },
      })

      // attach paymentMethod to customer
      await mutateAsyncAttachPaymentMethod({
        customerId: stripeCustomer.id,
        stripePaymentMethodId: paymentMethod.id,
      })

      const newPaymentMethod = await getPaymentMethod()

      // update user data payment method
      flushSync(() => {
        const newUser = cloneDeep(user)
        newUser.paymentMethod = newPaymentMethod
        setUser(newUser)
        onFinish()
      })

      // remove task
      removeTaskForUpdateCardInfo(user.data.id)

      return newPaymentMethod
    }

    useImperativeHandle(
      ref,
      () => ({
        async submitCreditCard(): Promise<PaymentMethod> {
          if (!isSubmitDisabled) {
            try {
              setIsLoading(true)
              return await onSubmit()
            } catch (error) {
              setIsError(true)
              throw new Error(error)
            } finally {
              setIsLoading(false)
            }
          }
        },
      }),
      [isSubmitDisabled, onSubmit]
    )

    // TODO on small screens, the input overflows horizontally.
    // * Improvement: use CardNumberElement, CardExpiryElement and CardCvcElement instead of CardElement alone.
    // https://stripe.com/docs/payments/payment-card-element-comparison
    return (
      <CardElement
        options={{
          hidePostalCode,
          style: {
            base: {
              fontSize: '16px',
              '::placeholder': {
                color: '#C0C4CC',
              },
            },
          },
        }}
        className={`StripeElement ${classes}`}
        onChange={(e) => {
          setIsComplete(e.complete)
        }}
      />
    )
  }
)

export default CreditCardInput
