import React, { useEffect, useState } from 'react'
import { InformationCircleIcon, RefreshIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import CenteredModal from '../CenteredModal'
import { useToastContext } from '../../contexts/ToastContext'
import Tooltip from '../Tooltip'
import { useUpdateUserPerson } from '../../mutations/user/UpdateUserPerson'
import { useAuth } from '../../contexts/AuthProvider'

interface EditCommuncationProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  successMessage?: string
  customOnCancel?: () => void
}

interface CommunicationForm {
  bookingTextMessage: boolean
  marketingEmail: boolean
  marketingTextMessage: boolean
}

export const EditCommunicationPreference: React.FC<EditCommuncationProps> = ({
  open,
  setOpen,
}) => {
  const { user, setUser } = useAuth()
  const addToast = useToastContext()

  const { mutateAsync: callUpdateUserPerson, isLoading } = useUpdateUserPerson()

  const { handleSubmit, setValue } = useForm<CommunicationForm>({
    mode: 'all',
    defaultValues: {
      bookingTextMessage: user?.data?.bookingUpdatesText,
      marketingEmail: user?.data?.marketingOptInEmail,
      marketingTextMessage: user?.data?.marketingOptInText,
    },
  })

  const onSave = async (data: CommunicationForm) => {
    const newUser = {
      ...user,
      data: {
        ...user.data,
        marketingOptInEmail: data.marketingEmail,
        marketingOptInText: data.marketingTextMessage,
        bookingUpdatesText: data.bookingTextMessage,
      },
    }

    setUser(newUser)

    await callUpdateUserPerson({
      user: newUser,
    })

    setOpen(false)
    addToast('success', 'Communication preference saved!')
  }

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-4 text-text-primary">
        {/* Header */}
        <p className="text-lg font-semibold">Edit Communication Preference</p>

        <CommunicationPreferenceComponent
          setValueBookingText={(val: boolean) =>
            setValue('bookingTextMessage', val)
          }
          setValueMarktingEmail={(val: boolean) =>
            setValue('marketingEmail', val)
          }
          setValueMarketingTextMessage={(val: boolean) =>
            setValue('marketingTextMessage', val)
          }
        />

        {/* Buttons */}
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-4">
            <button
              type="button"
              className={tertiaryButtonClass}
              onClick={() => setOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              className={primaryButtonClass}
              onClick={handleSubmit(onSave)}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-white" /> Loading
                </>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </div>
    </CenteredModal>
  )
}

export const CommunicationPreferenceComponent = ({
  disabled = false,
  setValueBookingText = null,
  setValueMarktingEmail = null,
  setValueMarketingTextMessage = null,
}) => {
  const { user } = useAuth()

  return (
    <div className="grid-col-2 grid w-full gap-2">
      <p className="col-span-2 text-base">Booking Updates and Information</p>
      <CheckBoxElement label="Email" withToolTip disabled checked />
      <CheckBoxElement
        label="Text Message"
        disabled={disabled}
        setValue={setValueBookingText}
        checked={user?.data?.bookingUpdatesText}
      />
      <p className="col-span-2 text-base">Marketing Communication</p>
      <CheckBoxElement
        label="Email"
        disabled={disabled}
        setValue={setValueMarktingEmail}
        checked={user?.data?.marketingOptInEmail}
      />
      <CheckBoxElement
        label="Text Message"
        disabled={disabled}
        setValue={setValueMarketingTextMessage}
        checked={user?.data?.marketingOptInText}
      />
    </div>
  )
}

const CheckBoxElement = ({
  label,
  disabled = false,
  withToolTip = false,
  setValue = null,
  checked = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked)
    setValue(e.target.checked)
  }

  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        disabled={disabled}
        checked={isChecked}
        className={`h-4 w-4 rounded ${
          disabled ? 'border-gray-300 text-text-label' : 'text-cta-default'
        }`}
        onChange={handleChange}
      />
      <p className="text-sm text-text-label">{label}</p>
      {withToolTip && (
        <Tooltip
          content="You cannot opt-out of this communication."
          position="right"
        >
          <InformationCircleIcon className="h-4 w-4 text-text-label" />
        </Tooltip>
      )}
    </div>
  )
}
