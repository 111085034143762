import React, { useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import type { Course } from '../../../types/Courses'
import ImageLoader from '../components/ImageLoader'
import BookmarkButton from '../components/BookmarkButton'
import COURSES_ICON from '../../../assets/icons/CoursesVideoIcon.svg'
import CoursePatientBadge from './CoursePatientBadge'

const OneCourse: React.FC<{
  course: Course
  showBadge?: boolean
  showProgressDetails?: boolean
  patientId?: string
  onClick?: (...args: any[]) => any
}> = ({
  course,
  showBadge = false,
  showProgressDetails = false,
  patientId = null,
  onClick = () => {},
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paragraphRef = useRef(null)

  const handleGoToCourse = () => {
    onClick(course)
    navigate(
      `/view-course/${course.id}?${new URLSearchParams(
        searchParams
      ).toString()}#Courses`,
      {
        state: {
          fromPathname: location.pathname,
          recommendForPatientId: patientId,
        },
      }
    )
  }

  const currentCourseProgress = course?.courseProgresses?.find((cp) =>
    patientId ? cp?.patient?.id === patientId : cp.patient === null
  )

  return (
    <div className="relative flex overflow-hidden rounded-2xl border border-components-fields bg-white shadow-subtle">
      <button onClick={handleGoToCourse} className="w-1/2 flex-shrink-0">
        {showBadge && (
          <CoursePatientBadge progresses={course?.courseProgresses} />
        )}
        <ImageLoader src={course.imageSrc} horizontal={false} />
      </button>

      <div className="flex w-1/2 flex-col gap-4 px-4 pt-4 pb-6 text-base">
        {/* Title, bookmark, no of videos */}
        <div className="flex flex-col gap-2">
          <div className="flex justify-between gap-1">
            <p
              ref={paragraphRef}
              className="h-12 w-11/12 font-semibold line-clamp-2 xs:h-auto xs:text-sm"
            >
              {course.title}
            </p>
            <BookmarkButton
              resource={course}
              iconClasses="h-5 w-5"
              btnClasses="self-start"
            />
          </div>
          <div className="flex items-center gap-1">
            <img src={COURSES_ICON} alt="Courses icon" />
            <p className="text-sm text-text-secondary xs:text-xs">
              {course.numberOfVideos} Module{course.numberOfVideos > 1 && 's'}
            </p>
          </div>
        </div>

        {/* Status */}
        {showProgressDetails &&
          currentCourseProgress &&
          (currentCourseProgress.status.id === 'in_progress' ? (
            <div className="flex flex-wrap items-center gap-2">
              <div className="flex w-full max-w-[40%] rounded-full bg-components-fillBorders">
                <div
                  className="h-2.5 rounded-full bg-secondaryAccents-lightGreen"
                  style={{
                    width: `${currentCourseProgress.completePercentage}%`,
                  }}
                />
              </div>
              <p className="whitespace-nowrap text-sm font-semibold">
                {currentCourseProgress.completePercentage}% Complete
              </p>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <div
                className={`${
                  currentCourseProgress.status.id === 'completed'
                    ? 'bg-status-success text-white'
                    : 'bg-components-fillBorders text-text-primary'
                } rounded-3xl py-2 px-3 text-sm font-semibold xs:text-xs`}
              >
                <p className="text-xs font-semibold">
                  {currentCourseProgress.status.name}{' '}
                  {currentCourseProgress?.completedAt}
                </p>
              </div>
            </div>
          ))}

        {/* Description */}
        <p className="break-words text-sm font-normal line-clamp-5 md:text-base xs:hidden">
          {course.previewText}
        </p>

        {/* Teacher info */}
        <div className="min-w absolute left-0 bottom-7 hidden max-w-[calc(50%-16px)] flex-col gap-1 rounded-r rounded-br bg-beige bg-opacity-90 p-2 sm:flex">
          <p className="text-sm font-semibold line-clamp-1">
            {course.teacherName}
          </p>
          <p className="text-xs line-clamp-2">{course.teacherCredentials}</p>
        </div>
      </div>
    </div>
  )
}

export default OneCourse
