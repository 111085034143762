import { useParams } from 'react-router-dom'

import { SERVICE_LINES_ARRAY } from '../constants/serviceLine'
import type { ServiceLine } from '../types/ServiceLine'

const useCurrentServiceLine = () => {
  const { serviceLine } = useParams()
  const currentServiceLine: ServiceLine = SERVICE_LINES_ARRAY.find(
    (line: ServiceLine) => line.url === serviceLine
  )

  return currentServiceLine
}

export default useCurrentServiceLine
