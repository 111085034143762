import { useNavigate } from 'react-router-dom'

import { usePatient } from '../../../contexts/PatientProvider'
import type { ServiceLine } from '../../../types/ServiceLine'
import useCurrentServiceLine from '../../../hooks/useCurrentServiceLine'
import type { CarePlan, Condition } from '../../../types/Patient'

const useGoToBookingWithSelfPay = () => {
  const { patient } = usePatient()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const navigate = useNavigate()
  const patientCarePlans: CarePlan[] = patient?.conditions?.find(
    (c: Condition) => !c.isIep
  )?.carePlans

  const goToBookingWithSelfPay = () => {
    const providerId = patientCarePlans.find(
      (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
    ).providerId

    if (providerId)
      navigate(`/booking/${currentServiceLine.url}/providers/${providerId}`)
    else navigate(`/booking/${currentServiceLine.url}/providers`)
  }

  return [goToBookingWithSelfPay]
}

export default useGoToBookingWithSelfPay
