import type { User } from 'firebase/auth'
import React from 'react'
import { Link, Navigate } from 'react-router-dom'

import EMAIL from '../../assets/images/Email.svg'
import { auth } from '../../config/firebase'

const ConfirmationEmail: React.FC = () => {
  const currentUser: User = auth.currentUser

  if (!currentUser) return <Navigate to="/sign-in" />

  return (
    <div className="flex flex-col items-center gap-8 text-text-primary">
      <p className="text-center text-2xl font-semibold">
        We sent you an email!
      </p>

      <img src={EMAIL} alt="email" className="h-36 xs:h-24 xs:w-24" />

      <p className="text-center text-2xl">
        Please check your inbox or spam folder to verify your email
      </p>

      {Boolean(currentUser.email?.length) && (
        <Link
          className="text-sm text-text-secondary underline"
          to="/confirmation/resend"
        >
          I didn't receive my email
        </Link>
      )}
    </div>
  )
}

export default ConfirmationEmail
