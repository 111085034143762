const getVanityUrl = () => {
  const host = window.location.hostname

  if (host.includes('localhost'))
    return host.replace('localhost', 'huddleupcare.com')

  return host
}

export default getVanityUrl
