import React, { useState, useContext, createContext, useMemo } from 'react'

export interface ResourceGridContextModel {
  mapGridToPage: Record<string, number>
  setMapGridToPage: React.Dispatch<React.SetStateAction<Record<string, number>>>
}

export const ResourceGridContext = createContext<ResourceGridContextModel>(
  {} as ResourceGridContextModel
)

export const useResourceGridContext = (): ResourceGridContextModel =>
  useContext(ResourceGridContext)

export const ResourceGridProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [mapGridToPage, setMapGridToPage] = useState<Record<string, number>>({})
  const contextValue = useMemo(
    () => ({ mapGridToPage, setMapGridToPage }),
    [mapGridToPage, setMapGridToPage]
  )

  return (
    <ResourceGridContext.Provider value={contextValue}>
      {children}
    </ResourceGridContext.Provider>
  )
}
