import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface GetResourceProgressProps {
  resourceId: string
}

export const useGetResourceProgressId = (
  params: GetResourceProgressProps,
  deps = params
) =>
  useQuery(['getResourceProgressId', deps], async () =>
    getResourceProgressId(params)
  )

const getResourceProgressId = async ({
  resourceId,
}: GetResourceProgressProps): Promise<string> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL}v1/progress`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          resourceId,
        }),
      }
    ).then((response) => response.json())

    return response.id
  } catch (error) {
    return null
  }
}
