import { useEffect, useState } from 'react'
import { format, parse, lastDayOfMonth } from 'date-fns'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useAuth } from '../../contexts/AuthProvider'
import {
  generateUpdateCreditCardTask,
  getCardBrandText,
} from '../../helpers/utils'
import CenteredModal from '../CenteredModal'
import EditCreditCardModal from './EditCreditCardModal'
import {
  isLessThanOneDayRemainingBeforeExpiration,
  terminateCardExpiringFlag,
} from '../../helpers/payments'
import type { Task } from '../../types/Dashboard'
import { addTaskForUpdateCardInfo } from '../../helpers/tasks'
import { useToastContext } from '../../contexts/ToastContext'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const CardExpiringModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ open, setOpen }) => {
  const { user, setUser } = useAuth()
  const addToast = useToastContext()
  const paymentMethod = user.paymentMethod

  const [isOpenEditCreditCardModal, setIsOpenEditCreditCardModal] =
    useState<boolean>(false)

  const oneDayRemaining = isLessThanOneDayRemainingBeforeExpiration(
    paymentMethod.cardExpirationMonth,
    paymentMethod.cardExpirationYear
  )

  const expirationDateMyyyy = `${paymentMethod.cardExpirationMonth}/${paymentMethod.cardExpirationYear}`

  const handleDoThisTaskLater = () => {
    const userTasksFromLocalStorage = JSON.parse(
      localStorage.getItem('DOTCOM_USER_TASKS')
    )

    // if task already created
    if (userTasksFromLocalStorage?.[user.data.id]?.['UPDATE_CREDIT_CARD']) {
      return
    }

    const task: Task = generateUpdateCreditCardTask(paymentMethod)

    addTaskForUpdateCardInfo(task, user.data.id)
    addToast('success', 'Update card information was added to “My Tasks”')
    // make sure MyTasks are rerendered
    setUser({ ...user })
  }

  const skip = () => {
    terminateCardExpiringFlag(user.data.id)
    handleDoThisTaskLater()
  }

  useEffect(() => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.MODAL_VIEWED,
      properties: {
        name: 'Modal',
        type: 'Credit Card Expiring',
      },
    })
  }, [])

  return (
    <>
      <CenteredModal setOpen={setOpen} open={open} noClose>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <h3 className="text-lg font-semibold">
              Welcome back, {user.data.firstName}!
            </h3>
            <p className="text-sm font-normal sm:text-base">
              {`This is a friendly reminder that your ${getCardBrandText(
                paymentMethod.cardBrand
              )} ending in ${paymentMethod.cardLastDigits} expires on ${format(
                lastDayOfMonth(
                  parse(expirationDateMyyyy, 'M/yyyy', new Date())
                ),
                'M/d/yy'
              )}.`}
              <br />
              <br />
              {oneDayRemaining
                ? `Please update your card information at least 15 minutes prior to your upcoming session.`
                : `Please update your card information to continue booking
              sessions beyond ${format(
                lastDayOfMonth(
                  parse(expirationDateMyyyy, 'M/yyyy', new Date())
                ),
                'MMMM dd, yyyy'
              )}.`}
              <br />
              <br />
              {oneDayRemaining ? (
                <>
                  If your card details are not updated by the expiration date,
                  any sessions scheduled on and after this date will be
                  automatically cancelled.
                </>
              ) : (
                <>
                  Any sessions scheduled on and after this date will be
                  automatically cancelled if your card details are not updated
                  by the expiration date.
                </>
              )}
            </p>
          </div>
          <div className="flex flex-col-reverse gap-2 sm:flex-row sm:justify-end sm:gap-4">
            <button
              type="button"
              onClick={() => {
                skip()
                setOpen(false)
              }}
              className={`${tertiaryButtonClass}`}
            >
              Do this task later
            </button>
            <button
              type="button"
              onClick={() => {
                setOpen(false)
                setIsOpenEditCreditCardModal(true)
              }}
              className={`${primaryButtonClass}`}
            >
              Update now
            </button>
          </div>
        </div>
      </CenteredModal>
      <EditCreditCardModal
        open={isOpenEditCreditCardModal}
        setOpen={setIsOpenEditCreditCardModal}
        successMessage="Your card information was updated successfully!"
        customOnCancel={skip}
      />
    </>
  )
}

export default CardExpiringModal
