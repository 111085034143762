import React from 'react'

import CourseGridLoader from './CourseGridLoader'

const CourseScreenLoader: React.FC = () => {
  return (
    <div className="flex w-full max-w-5xl flex-col gap-10 sm:items-center sm:py-20 xs:gap-6">
      {/* Back button */}
      <div className="mb-4 flex w-full items-start xs:mb-0">
        <div className="flex h-7 w-20 animate-pulse rounded-md bg-components-fields" />
      </div>

      {/* Content */}
      <div className="flex w-full flex-col gap-10 rounded-2xl bg-white p-8 shadow-subtle xs:gap-6 xs:p-4">
        {/* Header */}
        <div className="flex flex-col gap-2">
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
        </div>

        {/* Modules */}
        <div className="grid grid-cols-3 gap-6 rounded-2xl border-4 border-components-paleBlue px-6 py-8 xs:grid-cols-1">
          {React.Children.toArray(
            [...Array(6)].map(() => (
              <div className="flex flex-col gap-2 rounded-lg border border-components-fields">
                <div className="h-56 animate-pulse rounded-md rounded-t-lg bg-components-fields object-cover" />
                <div className="flex flex-col gap-1 px-2 pb-4">
                  <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
                  <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Divider */}
      <div className="w-full border-t border-text-secondary" />

      {/* Recommended */}
      <div className="flex w-full flex-col gap-10 xs:pb-10">
        <p className="text-2xl font-semibold xs:text-center xs:text-xl">
          Recommended
        </p>
        <CourseGridLoader loadingNumber={2} />
      </div>
    </div>
  )
}

export default CourseScreenLoader
