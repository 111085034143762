import type { Location as ReactRouterLocation } from 'react-router-dom'

import trackMixPanel, { MIXPANEL_EVENT } from '../hooks/useMixPanel'
import type { User } from '../types/User'
import { getSourceFromLocation } from './utils'

const handleClickSupportEmail = (
  user: User,
  location: Location | ReactRouterLocation
) => {
  trackMixPanel({
    eventName: MIXPANEL_EVENT.SUPPORT_EMAIL,
    properties: {
      accountId: user.data.id,
      source: getSourceFromLocation(location),
    },
  })
}

export default handleClickSupportEmail
