import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import type { ServiceLine } from '../../types/ServiceLine'

interface GetClientServiceLinesProps {
  clientId: string
  enabled?: boolean
  clientType: string
}

export const useGetClientServiceLines = (
  { enabled = true, ...params }: GetClientServiceLinesProps,
  deps = params
) =>
  useQuery(
    ['getClientServiceLines', deps],
    async () => getClientServiceLines(params),
    { enabled }
  )

const getClientServiceLines = async ({
  clientId,
  clientType,
}): Promise<ServiceLine[]> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const availableServiceLineIds: number[] = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_CLIENTS_URL
      }v1/products/service-lines?clientId=${clientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return availableServiceLineIds
      .map((serviceId: number) =>
        SERVICE_LINES_ARRAY.find(
          (sl: ServiceLine) => sl.serviceId === serviceId
        )
      )
      .filter((sl: ServiceLine | undefined) => Boolean(sl))
      .filter((sl: ServiceLine) => {
        if (clientType === 'EDUCATION') return sl.serviceId === 4
        return true
      })
      .sort((a: ServiceLine, b: ServiceLine) => a.orderId - b.orderId)
  } catch (error) {
    return Promise.reject(error)
  }
}
