import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Link } from 'react-router-dom'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import handleClickSupportEmail from '../../helpers/supportClick'
import { useAuth } from '../../contexts/AuthProvider'

const InsuranceVerifyScreen: React.FC = () => {
  const { user } = useAuth()

  return (
    <div className="items-center">
      {/* Texts */}
      <p className="text-center text-2xl font-semibold text-text-primary xs:text-lg">
        We are getting your insurance verified!
      </p>
      <p className="mt-6 whitespace-pre-wrap text-center text-base font-medium text-text-primary xs:text-sm xs:font-normal">
        We are currently verifying your coverage and will reach out with details
        and next steps!{'\n'}
        If you have any questions, please send them to{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium text-cta-default underline"
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>

      {/* Back/Next buttons */}
      <div className="mt-6 flex w-full items-center gap-2 px-10 sm:gap-4 xs:px-0">
        <Link to="/insurance/choose" className={`w-1/2 ${tertiaryButtonClass}`}>
          <ChevronLeftIcon className="h-5 w-5" />
          Use another insurance
        </Link>
        <Link to="/dashboard" className={`w-1/2 ${primaryButtonClass}`}>
          Go to my dashboard
          <ChevronRightIcon className="h-5 w-5 text-white" />
        </Link>
      </div>
    </div>
  )
}

export default InsuranceVerifyScreen
