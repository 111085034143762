import { Navigate, useLocation } from 'react-router-dom'

import useIsPatientPayCOS from '../../hooks/useIsPatientPayCOS'

const RequirePatientPayCOS = ({ children }) => {
  const location = useLocation()
  const isPatientPayCOS = useIsPatientPayCOS()

  if (isPatientPayCOS) return children

  return <Navigate to="/dashboard" replace state={{ from: location }} />
}

export default RequirePatientPayCOS
