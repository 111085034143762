import { useLocation, Navigate } from 'react-router-dom'

import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { ServiceLine } from '../../types/ServiceLine'

const RequireServiceLine = ({ children }) => {
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const location = useLocation()

  if (!currentServiceLine) {
    return <Navigate to="/404" replace state={{ from: location }} />
  }

  return children
}

export default RequireServiceLine
