import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import type { Resource } from '../../../types/Resources'
import { getSourceFromLocation } from '../../../helpers/utils'
import ImageLoader from './ImageLoader'
import BookmarkButton from './BookmarkButton'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'
import { useAuth } from '../../../contexts/AuthProvider'

const OneResource: React.FC<{
  resource: Resource
  onClick?: (...args: any[]) => any
  patientId?: string
}> = ({ resource, onClick = () => {}, patientId }) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const paragraphRef = useRef(null)
  const user = useAuth()

  // handle hover on resource title with text more than 2 lines
  useEffect(() => {
    const paragraphElement = paragraphRef.current

    const handleHover = () => {
      if (paragraphElement.offsetHeight < paragraphElement.scrollHeight) {
        paragraphElement.classList.remove('h-12', 'line-clamp-2')
        paragraphElement.classList.add('h-full', 'line-clamp-none')
      }
    }

    const handleMouseLeave = () => {
      paragraphElement.classList.add('h-12', 'line-clamp-2')
      paragraphElement.classList.remove('h-full', 'line-clamp-none')
    }

    paragraphElement.addEventListener('mouseenter', handleHover)
    paragraphElement.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      paragraphElement.removeEventListener('mouseenter', handleHover)
      paragraphElement.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  const handleGoToResource = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.RESOURCE_VIEWED,
      properties: {
        source: getSourceFromLocation(location),
        id: resource.id,
        accountId: user.user.data.id,
        name: resource.title,
      },
    })
    onClick(resource)
    navigate(
      `/view-resource/${resource.id}?${new URLSearchParams(
        searchParams
      ).toString()}`,
      {
        state: {
          fromPathname: location.pathname,
          recommendForPatientId: patientId,
        },
      }
    )
  }

  return (
    <div className="flex flex-col overflow-hidden rounded-2xl border border-components-fields bg-white shadow-subtle">
      <button onClick={handleGoToResource}>
        <ImageLoader src={resource.imageSrc} />
      </button>
      <div className="flex flex-1 flex-col gap-1 px-4 pt-4 pb-6 text-base text-text-primary">
        <div className="flex justify-between gap-1">
          <p
            ref={paragraphRef}
            className="h-12 w-11/12 font-semibold line-clamp-2 xs:h-auto xs:text-sm"
          >
            {resource.title}
          </p>
          <BookmarkButton
            resource={resource}
            iconClasses="h-5 w-5"
            btnClasses="self-start"
          />
        </div>
        <div className="flex items-center gap-2 text-sm text-text-secondary xs:text-xs">
          <img src={resource?.format?.icon} alt="icon" className="h-3.5" />
          {resource.duration} min {resource?.format?.activityName}
        </div>
        {Boolean(resource?.previewText) && (
          <p className="text-sm font-normal line-clamp-3 md:text-base">
            {resource.previewText}
          </p>
        )}
      </div>
    </div>
  )
}

export default OneResource
