import { useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { DEFAULT_DATE_REGEX } from '../../constants/regex'
import DatePicker from '../../components/DatePicker'
import { isValidDefaultDate } from '../../helpers/generic'
import { useAuth } from '../../contexts/AuthProvider'
import { useGetConsentForm } from '../../queries/permissions/GetConsentForm'
import { usePostConsentForm } from '../../mutations/permissions/PostConsentForm'
import { useToastContext } from '../../contexts/ToastContext'
import { useSendSeparateLoginInvite } from '../../mutations/permissions/SendSeparateLoginInvite'
import { usePermissionSettingsContext } from '../../contexts/PermissionSettingsContext'
import { useUpdateSeparateLoginPatient } from '../../mutations/permissions/UpdateSeparateLoginPatient'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { getSourceFromLocation } from '../../helpers/utils'

interface ConsentFormData {
  electronicSignature: string
  signedDate: string
}

const patientPacketFormSchema = Yup.object().shape({
  electronicSignature: Yup.string()
    .required('Electronic Signature is required.')
    .trim(),
  signedDate: Yup.string()
    .required('Signed Date is required.')
    .matches(
      DEFAULT_DATE_REGEX,
      'Signed Date must be in valid MM/DD/YYYY format.'
    )
    .test('valid-date', 'Signed Date is not valid.', (enteredDate: string) =>
      isValidDefaultDate(enteredDate)
    )
    .trim(),
})

const PermissionConsentForm: React.FC = (): JSX.Element => {
  const searchParams = new URLSearchParams(window.location.search)
  const ableToBookAndCancelParam = searchParams.get('ableToBookAndCancel')
  const ableToBookAndCancel = ableToBookAndCancelParam === 'true'

  const addToast = useToastContext()
  const { setUser, user } = useAuth()
  const { patientId } = useParams()
  const patient = user.roster.find((p) => p.id === patientId)
  const navigate = useNavigate()
  const [pdfLoaded, setPdfLoaded] = useState<boolean>(false)
  const {
    email: emailFromContext,
    ableToBookAndCancel: ableToBookAndCancelContext,
  } = usePermissionSettingsContext()
  const email = emailFromContext || patient?.email

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors, isValid, isDirty },
  } = useForm<ConsentFormData>({
    mode: 'all',
    defaultValues: {
      electronicSignature: '',
      signedDate: format(new Date(), 'MM/dd/yyyy'),
    },
    resolver: yupResolver(patientPacketFormSchema),
  })

  const {
    data: consentForm,
    isLoading: isLoadingGetConsentForm,
    isError: isErrorConsentForm,
  } = useGetConsentForm({
    patientId: patient.id,
  })

  const { isLoading: isLoadingPostConsentForm, mutate: callPostConsentForm } =
    usePostConsentForm()
  const { mutate: callSendInvite, isLoading: isLoadingSendingInvite } =
    useSendSeparateLoginInvite()
  const {
    mutate: callUpdateSeparateLoginProps,
    isLoading: isLoadingUpdatePatient,
  } = useUpdateSeparateLoginPatient()

  const handleNavigateNext = async (data?: ConsentFormData) =>
    callPostConsentForm(
      {
        name: data.electronicSignature,
        signatureDate: data.signedDate,
        version: consentForm.version,
        patient,
      },
      {
        onSettled: (consentFormSuccess: boolean) => {
          if (consentFormSuccess) {
            const newUser = cloneDeep(user)
            const patientIdx = newUser?.roster?.findIndex(
              (p) => p.id === patient.id
            )
            newUser.roster[patientIdx].invitedForSeparateLogin = true
            newUser.roster[patientIdx].email = email
            newUser.roster[patientIdx].separateLoginInvitedAt =
              new Date().getTime()
            newUser.roster[patientIdx].ableToBookAndCancel =
              ableToBookAndCancelContext

            // update separate login props only
            if (ableToBookAndCancelParam) {
              callUpdateSeparateLoginProps(
                { patient, ableToBookAndCancel },
                {
                  onSuccess: () => {
                    newUser.roster[patientIdx].ableToBookAndCancel =
                      ableToBookAndCancel
                    newUser.roster[patientIdx].invitedForSeparateLogin = false

                    trackMixPanel({
                      eventName: MIXPANEL_EVENT.ACCOUNT_UPDATED,
                      properties: {
                        category: 'Booking Permission',
                        accountId: user.data.id,
                        source: getSourceFromLocation(location),
                        value: ableToBookAndCancel,
                      },
                    })

                    flushSync(() => setUser(newUser))
                    navigate('/permission-settings', {
                      state: {
                        managePermissionChanged: true,
                      },
                    })
                  },
                  onError: () =>
                    addToast('error', 'Something went wrong, please retry.'),
                }
              )
            } else {
              callSendInvite(
                { personId: patient.personId, email },
                {
                  onSuccess: () => {
                    flushSync(() => setUser(newUser))
                    navigate('/permission-settings', {
                      state: {
                        consentFormSuccessful: true,
                      },
                    })
                  },
                  onError: () =>
                    addToast('error', 'Something went wrong, please retry.'),
                }
              )
            }
          } else {
            addToast('error', 'Something went wrong, please retry.')
          }
        },
      }
    )

  const isLoadingSubmit =
    isLoadingPostConsentForm || isLoadingSendingInvite || isLoadingUpdatePatient

  if (!email?.length && !ableToBookAndCancelParam)
    return <Navigate to="/dashboard" />

  return (
    <div className="max-w-5xl items-center text-center">
      <div className="flex w-full max-w-2xl flex-col items-center gap-4 sm:gap-6">
        <h1 className="text-center text-2xl font-medium xs:text-lg">
          {ableToBookAndCancelParam ? 'Manage' : 'Create'} Separate Login for{' '}
          {patient.firstName}
        </h1>
        <p className="text-sm font-normal sm:text-base">
          Please review and confirm the following to{' '}
          {ableToBookAndCancelParam ? 'manage' : 'create a'} separate account
          for {patient.firstName}.
        </p>
      </div>
      <div
        className={`mx-auto h-full max-h-[50vh] overflow-x-hidden sm:max-h-[90vh] ${
          pdfLoaded
            ? 'overflow-y-auto rounded-lg border border-components-fields'
            : 'overflow-y-hidden'
        }`}
      >
        {isLoadingGetConsentForm ? (
          <div className="flex flex-row items-center justify-center gap-2 text-text-primary">
            <p>Loading the consent form</p>
          </div>
        ) : !isErrorConsentForm ? (
          <Document
            file={consentForm.url}
            className="space-y-0 p-0 sm:space-y-2 sm:p-4 lg:p-6"
            onLoadSuccess={() => setPdfLoaded(true)}
          >
            {[...Array(3)].map((_, index) => (
              <Page
                key={index + 1}
                pageNumber={index + 1}
                className={`-mx-6 border-b shadow-none last:border-b-0 sm:mx-auto sm:border-b-0 sm:shadow-subtle [&>*]:!h-full [&>*]:!w-full`}
              />
            ))}
          </Document>
        ) : (
          <p className="text-status-error">
            Something went wrong while fetching the consent form.
          </p>
        )}
      </div>
      {consentForm?.signed && <p>Already signed ✅</p>}
      <div className="grid w-full max-w-sm grid-cols-2 gap-4">
        <div className="col-span-2 flex flex-col items-start gap-1">
          <label
            htmlFor="electronicSignature"
            className="text-sm font-semibold sm:text-base"
          >
            Electronic Signature
          </label>
          <div className="relative self-stretch">
            <input
              type="text"
              className={`${
                errors.electronicSignature ? inputErrorClass : inputValidClass
              } font-normal`}
              placeholder="Enter"
              {...register('electronicSignature')}
              disabled={
                isLoadingGetConsentForm ||
                isErrorConsentForm ||
                consentForm?.signed
              }
            />
            {Boolean(errors.electronicSignature) && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-status-error"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {Boolean(errors.electronicSignature) ? (
            <p className="text-sm text-status-error">
              {errors.electronicSignature?.message}
            </p>
          ) : (
            <p className="text-sm">{`e.g. ${user.data.firstName} ${user.data.lastName}`}</p>
          )}
        </div>
        <div className="col-span-2 flex flex-col items-start gap-1">
          <label
            htmlFor="signedDate"
            className="text-sm font-semibold sm:text-base"
          >
            Signed Date
          </label>
          <div className="relative self-stretch">
            <DatePicker
              setValue={setValue}
              control={control}
              fieldKey="signedDate"
              isDisabled={
                isLoadingGetConsentForm ||
                isErrorConsentForm ||
                consentForm?.signed
              }
              disableFutureDays
            />
            {Boolean(errors.signedDate) && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-status-error"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {Boolean(errors.signedDate) && (
            <p className="text-sm text-status-error">
              {errors.signedDate?.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full max-w-lg flex-col items-center gap-4 pt-8 sm:gap-12 sm:pt-0">
        <div className="flex w-full flex-row gap-2 sm:gap-4">
          <button
            onClick={() => navigate(-1)}
            className={`${tertiaryButtonClass} w-full`}
            disabled={isLoadingSubmit}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            <p>Back</p>
          </button>
          <button
            onClick={() =>
              consentForm?.signed
                ? handleNavigateNext()
                : handleSubmit(handleNavigateNext)()
            }
            disabled={!isDirty || !isValid || isLoadingSubmit}
            className={`${primaryButtonClass} w-full`}
          >
            {isLoadingSubmit ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                <p>Loading</p>
              </>
            ) : (
              <>
                <p>Submit</p>
                <ChevronRightIcon className="h-5 w-5" />
              </>
            )}
          </button>
        </div>
        {!isLoadingSubmit && <SkipAndComeBackLater />}
      </div>
    </div>
  )
}

export default PermissionConsentForm
