import React from 'react'

import ResourcesGridDisplay from './ResourceGridDisplay'
import { ResourcePaginator } from './ResourcesGrid'
import type { ResourceSection } from '../../../types/Resources'

const FilteredResourcesGrid: React.FC<{
  resourceSection: ResourceSection
  isLoadingResourceSection: boolean
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}> = ({
  resourceSection,
  isLoadingResourceSection,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <>
      {/* Resources grid */}
      <ResourcesGridDisplay resources={resourceSection?.resources} />

      {/* Paginator */}
      <ResourcePaginator
        pageMapId="filteredResources"
        currentPage={currentPage}
        resourceSection={resourceSection}
        setCurrentPage={setCurrentPage}
        isLoadingResourceSection={isLoadingResourceSection}
      />
    </>
  )
}

export default FilteredResourcesGrid
