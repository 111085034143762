import React, { useRef, useState } from 'react'
import { RefreshIcon } from '@heroicons/react/solid'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import CenteredModal from '../CenteredModal'
import { useToastContext } from '../../contexts/ToastContext'
import type { CreditCardRefType } from '../CreditCardInput'
import CreditCardInput from '../CreditCardInput'
import { CARD_SAVING_ERROR_TEXT } from '../../constants/values'
import { useAuth } from '../../contexts/AuthProvider'
import { clearCardExpiringFlag } from '../../helpers/payments'

interface EditCreditCardProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  successMessage?: string
  customOnCancel?: () => void
}

const EditCreditCardModal: React.FC<EditCreditCardProps> = ({
  open,
  setOpen,
  successMessage = 'Your card information was updated successfully!',
  customOnCancel = () => {},
}) => {
  const addToast = useToastContext()
  const { user } = useAuth()

  const [isCreditCardSubmitReady, setIsCreditCardSubmitReady] =
    useState<boolean>(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState<boolean>(false)
  const [isCardElementFilled, setIsCardElementFilled] = useState<boolean>(false)
  const [isCreditCardError, setIsCreditCardError] = useState<boolean>(false)

  const creditCardRef: { current: CreditCardRefType } = useRef()

  const isLoading = isLoadingCreditCard || !isCreditCardSubmitReady
  const isDisabledSave = isLoading || !isCardElementFilled

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-4 text-text-primary">
        {/* Header */}
        <p className="text-lg font-semibold">Edit Credit/Debit Card</p>
        <p className="text-base">Please enter the new card information.</p>

        <CreditCardInput
          ref={creditCardRef}
          onFinish={() => {
            setOpen(false)
            addToast('success', successMessage)
            clearCardExpiringFlag(user.data.id)
          }}
          setIsComplete={setIsCardElementFilled}
          setIsLoading={setIsLoadingCreditCard}
          setIsSubmitReady={setIsCreditCardSubmitReady}
          setIsError={setIsCreditCardError}
        />

        {isCreditCardError && (
          <p className="text-sm text-status-error">{CARD_SAVING_ERROR_TEXT}</p>
        )}

        {/* Buttons */}
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-4">
            <button
              disabled={isLoading}
              type="button"
              className={tertiaryButtonClass}
              onClick={() => {
                customOnCancel?.()
                setOpen(false)
              }}
            >
              Cancel
            </button>
            <button
              className={primaryButtonClass}
              onClick={() => creditCardRef.current.submitCreditCard()}
              disabled={isDisabledSave}
            >
              {isLoading ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-white" /> Loading
                </>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </div>
    </CenteredModal>
  )
}

export default EditCreditCardModal
