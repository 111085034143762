import React from 'react'

import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'
import { useAuth } from '../../../contexts/AuthProvider'

const ApplePodcastPlayer: React.FC<{ podcastUrl: string; resourceItem }> = ({
  podcastUrl,
  resourceItem,
}) => {
  const user = useAuth()

  const handlePlay = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.RESOURCE_PLAYED,
      properties: {
        id: resourceItem?.id,
        name: resourceItem?.title,
        accountID: user.user.data.id,
      },
    })
  }

  return (
    <iframe
      className="apple-podcast-iframe"
      title="Apple Podcast"
      allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
      width="100%"
      height="175px"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      src={podcastUrl.replace('https://', 'https://embed.')}
      onPlaying={handlePlay}
    />
  )
}

export default ApplePodcastPlayer
