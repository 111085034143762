import {
  parse,
  startOfMonth,
  lastDayOfMonth,
  isBefore,
  addMonths,
  differenceInHours,
  differenceInMonths,
  differenceInWeeks,
  addSeconds,
} from 'date-fns'
import cloneDeep from 'lodash.clonedeep'

export const getFirstDayOfNextMonthFromExpirationMonth = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  return startOfMonth(
    addMonths(
      parse(
        `${cardExpirationMonth}/${cardExpirationYear}`,
        'M/yyyy',
        new Date()
      ),
      1
    )
  )
}

export const getLastDayOfExpirationMonth = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  return lastDayOfMonth(
    parse(`${cardExpirationMonth}/${cardExpirationYear}`, 'M/yyyy', new Date())
  )
}

export const isCreditCardExpired = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  const firstDayOfNextMonthFromExpirationMonth =
    getFirstDayOfNextMonthFromExpirationMonth(
      cardExpirationMonth,
      cardExpirationYear
    )

  const now = new Date()

  return !isBefore(now, firstDayOfNextMonthFromExpirationMonth)
}

export const willCreditCardExpireBeforeSession = (
  sessionDate: Date,
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  const firstDayOfNextMonthFromExpirationMonth =
    getFirstDayOfNextMonthFromExpirationMonth(
      cardExpirationMonth,
      cardExpirationYear
    )

  return !isBefore(sessionDate, firstDayOfNextMonthFromExpirationMonth)
}

export const isLessThanOneDayRemainingBeforeExpiration = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  const firstDayOfNextMonthFromExpirationMonth =
    getFirstDayOfNextMonthFromExpirationMonth(
      cardExpirationMonth,
      cardExpirationYear
    )

  return (
    differenceInHours(
      firstDayOfNextMonthFromExpirationMonth,
      addSeconds(new Date(), 1)
    ) <= 24
  )
}

export const isLessThanOneWeekRemainingBeforeExpiration = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  const firstDayOfNextMonthFromExpirationMonth =
    getFirstDayOfNextMonthFromExpirationMonth(
      cardExpirationMonth,
      cardExpirationYear
    )

  return (
    differenceInWeeks(
      firstDayOfNextMonthFromExpirationMonth,
      addSeconds(new Date(), 1)
    ) <= 0
  )
}

export const isLessThanOneMonthRemainingBeforeExpiration = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  const firstDayOfNextMonthFromExpirationMonth =
    getFirstDayOfNextMonthFromExpirationMonth(
      cardExpirationMonth,
      cardExpirationYear
    )

  return (
    differenceInMonths(
      firstDayOfNextMonthFromExpirationMonth,
      addSeconds(new Date(), 1)
    ) <= 0
  )
}

export const isLessThanTwoMonthsRemainingBeforeExpiration = (
  cardExpirationMonth: number,
  cardExpirationYear: number
) => {
  const firstDayOfNextMonthFromExpirationMonth =
    getFirstDayOfNextMonthFromExpirationMonth(
      cardExpirationMonth,
      cardExpirationYear
    )

  return (
    differenceInMonths(
      firstDayOfNextMonthFromExpirationMonth,
      addSeconds(new Date(), 1)
    ) <= 1
  )
}

export const enableCardExpiringFlag = (
  userId: string,
  type: '2M' | '1M' | '1W' | '1D' | '0D'
) => {
  const dotcomCardExpiring = JSON.parse(
    localStorage.getItem('DOTCOM_CARD_EXPIRING')
  )

  // 2months, 1month, 1week, 1day
  const typeObj = { '2M': 0, '1M': 0, '1W': 0, '1D': 0, '0D': 0 }
  typeObj[type] = 1

  if (!dotcomCardExpiring) {
    localStorage.setItem(
      'DOTCOM_CARD_EXPIRING',
      JSON.stringify({ [userId]: typeObj })
    )
  } else if (!dotcomCardExpiring?.[userId]) {
    localStorage.setItem(
      'DOTCOM_CARD_EXPIRING',
      JSON.stringify({ ...dotcomCardExpiring, [userId]: typeObj })
    )
  } else {
    localStorage.setItem(
      'DOTCOM_CARD_EXPIRING',
      JSON.stringify({
        ...dotcomCardExpiring,
        [userId]: { ...dotcomCardExpiring[userId], [type]: 1 },
      })
    )
  }
}

export const terminateCardExpiringFlag = (userId: string) => {
  const dotcomCardExpiring = JSON.parse(
    localStorage.getItem('DOTCOM_CARD_EXPIRING')
  )

  const newValues = Object.entries(dotcomCardExpiring[userId]).reduce(
    (acc, [key, value]) => {
      if (value === 1) {
        acc[key] = 2
      } else {
        acc[key] = value
      }
      return acc
    },
    {}
  )

  localStorage.setItem(
    'DOTCOM_CARD_EXPIRING',
    JSON.stringify({
      ...dotcomCardExpiring,
      [userId]: newValues,
    })
  )
}

export const clearCardExpiringFlag = (userId: string) => {
  const dotcomCardExpiring = JSON.parse(
    localStorage.getItem('DOTCOM_CARD_EXPIRING')
  )

  if (dotcomCardExpiring) {
    const clone = cloneDeep(dotcomCardExpiring)
    delete clone[userId]
    localStorage.setItem('DOTCOM_CARD_EXPIRING', JSON.stringify(clone))
  }
}
