import React, { useState, useContext, createContext } from 'react'
import type { Patient } from '../types/Patient'

export interface PatientContextModel {
  patient: Patient | Partial<Patient>
  setPatient: React.Dispatch<React.SetStateAction<Patient | Partial<Patient>>>
}

export const PatientContext = createContext<PatientContextModel>(
  {} as PatientContextModel
)

export const usePatient = (): PatientContextModel => useContext(PatientContext)

export const PatientProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [patient, setPatient] = useState<Partial<Patient>>(null)

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>
      {children}
    </PatientContext.Provider>
  )
}
