import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface DownloadDocumentProps {
  id: string
}

const downloadDocument = async ({ id }: DownloadDocumentProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const documentDownloadResult: {
    url: string
  } = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_STORAGE_URL
    }/v1/documents/${id}/download`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return documentDownloadResult?.url
}

export const useDownloadDocument = () =>
  useMutation((params: DownloadDocumentProps) => downloadDocument(params))
