import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { Patient } from '../../types/Patient'

interface UpdateSeparateLoginPatientProps {
  patient: Partial<Patient>
  ableToBookAndCancel: boolean
}

const updateSeparateLoginPatient = async ({
  patient,
  ableToBookAndCancel,
}: UpdateSeparateLoginPatientProps): Promise<boolean> => {
  try {
    const token = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL}v1/persons/${
        patient.personId
      }`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          firstName: patient.firstName,
          middleName: '',
          lastName: patient.lastName,
          userId: patient?.userId,
          ableToBookAndCancel,
        }),
      }
    )

    if (!response.ok) throw new Error()

    return true
  } catch (error) {
    throw new Error("Couldn't mark user as verified")
  }
}

export const useUpdateSeparateLoginPatient = () =>
  useMutation((params: UpdateSeparateLoginPatientProps) =>
    updateSeparateLoginPatient(params)
  )
