import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import {
  convertFrontendDateToBackend,
  dataURLtoFile,
} from '../../helpers/generic'
import type { Insurance } from '../../types/Insurance'

interface InsuranceMemberShipProps {
  patientId: string
  insurance: Insurance
}

const createInsuranceMembership = async ({
  patientId,
  insurance,
}: InsuranceMemberShipProps): Promise<string> => {
  const formData = new FormData()
  formData.append('run_eligibility_job', '1')
  formData.append('insurance_membership[payer_id]', insurance.insurance.id)
  formData.append('insurance_membership[member_id]', insurance.memberId)
  formData.append('insurance_membership[group_number]', insurance.groupId)
  formData.append(
    'insurance_membership[relationship_to_insured]',
    insurance.relationship?.key
  )
  formData.append(
    'insurance_membership[insured_first_name]',
    insurance.firstName
  )
  formData.append('insurance_membership[insured_last_name]', insurance.lastName)
  formData.append(
    'insurance_membership[insured_date_of_birth]',
    convertFrontendDateToBackend(insurance.birthDate)
  )
  formData.append(
    'insurance_membership[insured_gender_at_birth]',
    insurance.gender.toLowerCase()
  )
  formData.append(
    'insurance_membership[insured_address_line_1]',
    insurance.streetAddress
  )
  formData.append(
    'insurance_membership[insured_address_line_2]',
    insurance.apartmentUnit
  )
  formData.append('insurance_membership[insured_city]', insurance.city)
  formData.append('insurance_membership[insured_country]', 'US')
  formData.append('insurance_membership[insured_state]', insurance.state)
  formData.append('insurance_membership[insured_zip_code]', insurance.zip)
  formData.append('insurance_membership[insured_phone_number]', '')
  formData.append('insurance_membership[coordination_of_benefits]', 'primary')

  // convert image if it was uploaded, if not do nothing
  try {
    const cardFrontPhoto: File = dataURLtoFile(
      insurance.frontInsurance,
      'frontInsurance.png'
    )
    formData.append('insurance_membership[card_front]', cardFrontPhoto)
  } catch (error) {}

  try {
    const cardBackPhoto: File = dataURLtoFile(
      insurance.backInsurance,
      'backInsurance.png'
    )
    formData.append('insurance_membership[card_back]', cardBackPhoto)
  } catch (error) {}

  const token: string = await auth.currentUser.getIdToken()
  const result: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/patients/${patientId}/insurance_memberships`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  ).then((response) => response.json())

  if (!result?.id) throw new Error(JSON.stringify(result.error))

  return result.id
}

const createUnassociatedInsuranceMembership = async ({
  patientId,
  insurance,
}: InsuranceMemberShipProps): Promise<string> => {
  const formData = new FormData()
  formData.append('run_eligibility_job', '1')
  formData.append(
    'unassociated_insurance_membership[payer_id]',
    insurance.insurance.id
  )
  formData.append(
    'unassociated_insurance_membership[member_id]',
    insurance.memberId
  )
  formData.append(
    'unassociated_insurance_membership[group_number]',
    insurance.groupId
  )
  formData.append(
    'unassociated_insurance_membership[relationship_to_insured]',
    insurance.relationship?.key
  )
  formData.append(
    'unassociated_insurance_membership[insured_first_name]',
    insurance.firstName
  )
  formData.append(
    'unassociated_insurance_membership[insured_last_name]',
    insurance.lastName
  )
  formData.append(
    'unassociated_insurance_membership[insured_date_of_birth]',
    convertFrontendDateToBackend(insurance.birthDate)
  )
  formData.append(
    'unassociated_insurance_membership[insured_gender_at_birth]',
    insurance.gender.toLowerCase()
  )
  formData.append(
    'unassociated_insurance_membership[insured_address_line_1]',
    insurance.streetAddress
  )
  formData.append(
    'unassociated_insurance_membership[insured_address_line_2]',
    insurance.apartmentUnit
  )
  formData.append(
    'unassociated_insurance_membership[insured_city]',
    insurance.city
  )
  formData.append('unassociated_insurance_membership[insured_country]', 'US')
  formData.append(
    'unassociated_insurance_membership[insured_state]',
    insurance.state
  )
  formData.append(
    'unassociated_insurance_membership[insured_zip_code]',
    insurance.zip
  )
  formData.append('unassociated_insurance_membership[insured_phone_number]', '')
  formData.append(
    'unassociated_insurance_membership[coordination_of_benefits]',
    'primary'
  )
  formData.append(
    'unassociated_insurance_membership[payer_name_entered_by_patient]',
    insurance.otherInsuranceName
  )

  // convert image if it was uploaded, if not do nothing
  try {
    const cardFrontPhoto: File = dataURLtoFile(
      insurance.frontInsurance,
      'frontInsurance.png'
    )
    formData.append(
      'unassociated_insurance_membership[card_front]',
      cardFrontPhoto
    )
  } catch (error) {}

  try {
    const cardBackPhoto: File = dataURLtoFile(
      insurance.backInsurance,
      'backInsurance.png'
    )
    formData.append(
      'unassociated_insurance_membership[card_back]',
      cardBackPhoto
    )
  } catch (error) {}

  const token: string = await auth.currentUser.getIdToken()
  const result: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/patients/${patientId}/unassociated_insurance_memberships`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  ).then((response) => response.json())

  if (!result?.id) throw new Error(JSON.stringify(result.error))

  return result.id
}

export const useCreateInsuranceMembership = () =>
  useMutation((params: InsuranceMemberShipProps) =>
    createInsuranceMembership(params)
  )

export const useCreateUnassociatedMembership = () =>
  useMutation((params: InsuranceMemberShipProps) =>
    createUnassociatedInsuranceMembership(params)
  )
