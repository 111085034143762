import React from 'react'
import Lottie from 'lottie-react'

import DOT_ANIMATION from '../assets/lotties/DotLoader.json'
import LOGO_TEXT from '../assets/images/LogoText.svg'

const GlobalLoader: React.FC = () => {
  return (
    <div className="fixed top-0 left-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white">
      <div className="h-72 w-72 xs:h-52 xs:w-52">
        <Lottie animationData={DOT_ANIMATION} loop={true} />
      </div>
      <img className="w-46 object-cover xs:w-32" src={LOGO_TEXT} alt="logo" />
    </div>
  )
}

export default GlobalLoader
