const toCamel = (str: string) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isObject = function (obj: any) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

export const toCamelCase = function (obj: any) {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = toCamelCase(obj[k])
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return toCamelCase(i)
    })
  }

  return obj
}

export const toSnake = (key: string) =>
  key.replace(/([A-Z])/g, '_$1').toLowerCase()

export const toSnakeCase = function (obj: any) {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach((k) => {
      n[toSnake(k)] = toSnakeCase(obj[k])
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return toSnakeCase(i)
    })
  }

  return obj
}
