import { useQuery } from '@tanstack/react-query'

import type { User } from '../../types/User'
import { auth } from '../../config/firebase'
import { toCourse } from '../../helpers/transformers'
import type { ResourceSection } from '../../types/Resources'

type GetCoursesParams = {
  user: User
  patientId: string
  forCaregiver?: boolean
  paginationToken: number
  pageNumber?: number
  pageSize?: number
  disabled?: boolean
}

export const useGetCourses = (params: GetCoursesParams, deps = params) =>
  useQuery(['getCourses', deps], async () => getCourses(params), {
    enabled: !params?.disabled,
  })

const getCourses = async ({
  user,
  forCaregiver,
  patientId,
  pageNumber = 0,
  paginationToken,
  pageSize = 4,
}: GetCoursesParams): Promise<ResourceSection> => {
  const token: string = await auth.currentUser.getIdToken()

  try {
    if (forCaregiver) {
      const response: any = await fetch(
        `${
          import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
        }v2/display/courses/caregivers?page=${pageNumber}&pageSize=${pageSize}&paginationToken=${paginationToken}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => response.json())

      return {
        resources: response.courses.map((c: any) =>
          toCourse({
            ...c,
            courseProgresses: c.courseProgresses.map((cp: any) => ({
              ...cp,
              patient: user.roster.find((p) => p.id === cp.patientId),
            })),
          })
        ),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      }
    } else {
      const response: any = await fetch(
        `${
          import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
        }v2/display/courses/patients/${patientId}?page=${pageNumber}&pageSize=${pageSize}&paginationToken=${paginationToken}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => response.json())

      return {
        resources: response.courses.map((c: any) =>
          toCourse({
            ...c,
            courseProgresses: c.courseProgresses.map((cp: any) => ({
              ...cp,
              patient: user.roster.find((p) => p.id === cp.patientId),
            })),
          })
        ),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      }
    }
  } catch (error) {
    return null
  }
}
