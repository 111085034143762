import React from 'react'

const CourseGridLoader: React.FC<{
  loadingNumber?: number
  noPadding?: boolean
}> = ({ loadingNumber, noPadding }) => {
  return (
    <div
      className={`${
        noPadding ? '' : 'sm:px-6'
      } grid w-full grid-cols-1 gap-6 lg:grid-cols-2 xs:gap-4`}
    >
      {React.Children.toArray(
        [...Array(loadingNumber)].map(() => (
          <div className="relative flex overflow-hidden rounded-2xl border border-components-fields bg-white shadow-subtle">
            <div className="h-72 w-1/2 animate-pulse rounded rounded-tr-none rounded-br-none bg-components-fields xs:h-36 xs:w-36" />
            <div className="flex flex-1 flex-col gap-4 px-4 pt-4 pb-6 text-base">
              <p className="h-5 w-full animate-pulse rounded bg-components-fields font-semibold" />
              <p className="h-5 w-full animate-pulse rounded bg-components-fields font-semibold" />
              <p className="h-5 w-2/3 animate-pulse rounded bg-components-fields font-semibold" />
              <p className="h-5 w-2/3 animate-pulse rounded bg-components-fields font-semibold" />
              <p className="h-5 w-1/3 animate-pulse rounded bg-components-fields font-semibold" />
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default CourseGridLoader
