import React from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { EXTERNAL_LINKS } from '../constants/values'

const CrisisMessaging: React.FC<{ noIcon?: boolean; showShadow?: boolean }> = ({
  noIcon = false,
  showShadow = false,
}) => {
  return (
    <div
      className={`${showShadow ? 'shadow-lg' : ''} ${
        noIcon
          ? 'rounded-lg bg-components-disabledFill p-2'
          : 'rounded-2xl border bg-components-fillBorders p-4 xs:rounded-none'
      } flex items-start justify-center sm:max-w-lg`}
    >
      {!noIcon && <ExclamationIcon className="w-32 text-text-primary" />}
      <p
        className={`${
          noIcon ? '' : 'ml-4'
        } text-justify text-sm font-normal text-text-primary`}
      >
        If you, your child, or someone you are caring for is in crisis, seek
        immediate help. Text 741741 for a crisis text line, call 911, or go to
        your local emergency department. Additional resources can be found{' '}
        <a
          href={EXTERNAL_LINKS.RESOURCES}
          className="text-cta-default underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

export default CrisisMessaging
