import { useMutation } from '@tanstack/react-query'

import type { ResourceTopic } from '../../types/Resources'
import { auth } from '../../config/firebase'

interface DeleteTopicsProps {
  topics: ResourceTopic[]
}

const deleteTopics = async ({ topics }: DeleteTopicsProps) => {
  if (!topics?.length) return

  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL}v1/preferences?${topics
      .map((topic: ResourceTopic) => `userPreferenceIds=${topic.id}`)
      .join('&')}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const useDeleteTopics = () =>
  useMutation((params: DeleteTopicsProps) => deleteTopics(params))
