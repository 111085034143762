import React, { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { ExclamationCircleIcon, RefreshIcon } from '@heroicons/react/solid'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { useRegister } from '../../../contexts/RegisterContext'
import type { CreateLoginForm } from '../../../types/Register'
import { EMAIL_REGEX, VALID_PHONE_NUMBER_REGEX } from '../../../constants/regex'
import { auth } from '../../../config/firebase'
import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
} from '../../../constants/classConstants'
import { formatPhoneNumber } from '../../../helpers/generic'
import SUCCESS_GLOBE from '../../../assets/images/SuccessGlobe.svg'
import { useGetClientInfo } from '../../../queries/onboarding/GetClientInfo'
import { useClaimPerson } from '../../../mutations/eligibility/ClaimPerson'
import { useGrantAccessPerson } from '../../../mutations/eligibility/GrantAccessPerson'
import { useSendEmailVerification } from '../../../mutations/emails/SendEmailVerification'
import { useToastContext } from '../../../contexts/ToastContext'
import { useCreateNewUser } from '../../../mutations/user/CreateNewUser'
import { EXTERNAL_LINKS } from '../../../constants/values'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter valid first name').trim(),
  lastName: Yup.string().required('Please enter valid last name').trim(),
  phoneNumber: Yup.string().matches(VALID_PHONE_NUMBER_REGEX, {
    name: 'validPhoneNumber',
    message: 'Please complete the phone number validly.',
    excludeEmptyString: true,
  }),
  preferredName: Yup.string(),
  email: Yup.string()
    .required('Email is required')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.')
    .trim(),
  password: Yup.string()
    .required('Please enter a password.')
    .min(8, 'Please enter a password, must have 8 characters.')
    .trim(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Please make sure they match.'
  ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Please check this box to accept our Terms & Conditions'
  ),
})

const CreateLogin: React.FC = () => {
  const addToast = useToastContext()
  const { userInfo } = useRegister()
  const { forSelf, useWorkEmail } = useRegister()
  const navigate = useNavigate()
  const [otherError, setOtherError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate: callClaimPerson } = useClaimPerson()
  const { mutate: callGrantAccessPerson } = useGrantAccessPerson()
  const { mutate: callSendEmailVerification } = useSendEmailVerification()
  const { data: clientInfo } = useGetClientInfo()
  const { mutateAsync: callCreateNewUser } = useCreateNewUser()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<CreateLoginForm>({
    defaultValues:
      clientInfo?.clientType === 'EMPLOYER' || forSelf
        ? { ...userInfo, email: useWorkEmail ? userInfo.email : '' }
        : null,
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = async (data: CreateLoginForm) => {
    setLoading(true)
    setOtherError(false)

    const onError = () => {
      setOtherError(true)
      setLoading(false)
    }

    try {
      // register with firebase
      const { user } = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )

      // get firebase token
      const initialToken: string = await user.getIdToken()

      // create new user in ruby, new person in java and add claims
      await callCreateNewUser({
        token: initialToken,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        clientId: clientInfo?.clientId,
      })

      // get updated token with custom claims
      const token: string = await auth.currentUser.getIdToken(true)

      // define params for persons service
      const claimOrAccessParams = {
        bearerToken: token,
        clientId: clientInfo?.clientId,
        dateOfBirth: userInfo?.birthDate,
        externalId: userInfo?.email || userInfo?.externalId,
      }

      // call to send email verification after successful eligibility claim/grant
      const onSuccess = (patientId: string) =>
        callSendEmailVerification(
          {
            queryParams: {
              ...userInfo,
              forSelf,
              patientId,
            },
            clientId: clientInfo?.clientId,
          },
          {
            onSuccess: () => navigate('/confirmation/success'),
            onError: () => {
              addToast(
                'error',
                'Something went wrong. Please try again later or contact support.'
              )
              setLoading(false)
            },
          }
        )

      // track account created event
      trackMixPanel({
        eventName: MIXPANEL_EVENT.ACCOUNT_CREATED,
        properties: {
          type: userInfo?.isIep ? 'Both' : 'Gen Ed',
        },
      })

      // claim person for the account holder if myself (myself as GenEd or Employer)
      if (forSelf) callClaimPerson(claimOrAccessParams, { onError, onSuccess })
      // grant access for a person if Gen Ed and not forSelf
      else
        callGrantAccessPerson(claimOrAccessParams, {
          onError,
          onSuccess,
        })
    } catch (e) {
      onError()
    }
  }

  if (
    clientInfo?.clientType === 'EMPLOYER' &&
    (!userInfo?.firstName || !userInfo?.lastName)
  )
    return <Navigate to="/sign-in" />

  return (
    <form
      onSubmit={handleSubmit((data: CreateLoginForm) => onSubmit(data))}
      className="max-w-md"
    >
      <p className="flex flex-wrap justify-center whitespace-pre text-base font-semibold sm:text-2xl">
        Your eligibility was verified, Yay!
      </p>

      <img
        src={SUCCESS_GLOBE}
        alt="success confetti"
        className="h-40 w-40 self-center sm:h-48 sm:w-48"
      />

      <div className="flex flex-col gap-2">
        <p className="text-center text-lg font-semibold xs:text-base">
          Let's create a login to make it official!
        </p>
        {clientInfo?.clientType === 'EDUCATION' && (
          <p className="text-center text-base xs:text-sm">
            Please enter your information below
          </p>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        {/* First name */}
        <div
          className={`xs:col-span-2 ${
            clientInfo?.clientType === 'EDUCATION' ? 'flex flex-col' : 'hidden'
          }`}
        >
          <label
            htmlFor="firstName"
            className="block text-base font-semibold xs:text-sm"
          >
            Your first name
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.firstName ? inputErrorClass : inputValidClass}
              placeholder="First"
              disabled={forSelf}
              defaultValue={forSelf ? userInfo?.firstName : null}
              {...register('firstName')}
            />
            {errors.firstName && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.firstName?.message}
          </p>
        </div>

        {/* Last name */}
        <div
          className={`xs:col-span-2 ${
            clientInfo?.clientType === 'EDUCATION' ? 'flex flex-col' : 'hidden'
          }`}
        >
          <label
            htmlFor="lastName"
            className="block text-base font-semibold xs:text-sm"
          >
            Your last name
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.lastName ? inputErrorClass : inputValidClass}
              placeholder="Last"
              disabled={forSelf}
              defaultValue={forSelf ? userInfo?.lastName : null}
              {...register('lastName')}
            />
            {errors.lastName && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.lastName?.message}
          </p>
        </div>

        {/* Email */}
        <div className="col-span-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="email"
              className="block text-base font-semibold xs:text-sm"
            >
              Login email
            </label>
            <a
              className="text-base font-medium underline"
              href="https://www.google.com/intl/en-US/gmail/about/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Don't have an email?
            </a>
          </div>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.email ? inputErrorClass : inputValidClass}
              placeholder="Email address"
              defaultValue={userInfo?.email}
              {...register('email')}
            />
            {errors.email && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.email?.message}
          </p>
        </div>

        {/* Phone Number */}
        <Controller
          control={control}
          name="phoneNumber"
          defaultValue=""
          rules={{ maxLength: 14 }}
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1">
              <label
                htmlFor="phoneNumber"
                className="text-sm font-semibold sm:text-base"
              >
                Phone number (optional)
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error && error.type !== 'validPhoneNumber'
                      ? inputErrorClass
                      : inputValidClass
                  } font-normal`}
                  placeholder="(000) 000-0000"
                  {...field}
                  onChange={(e) => {
                    field.onChange(formatPhoneNumber(e.target.value))
                  }}
                  maxLength={14}
                />
                {Boolean(error && error.type !== 'validPhoneNumber') && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p
                  className={`text-sm ${
                    error && error.type !== 'validPhoneNumber'
                      ? 'text-status-error'
                      : ''
                  }`}
                >
                  {error.message}
                </p>
              )}
            </div>
          )}
        />

        {/*  Password */}
        <div className="xs:col-span-2">
          <label
            htmlFor="password"
            className="block text-base font-semibold xs:text-sm"
          >
            Password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={errors.password ? inputErrorClass : inputValidClass}
              placeholder="Password"
              {...register('password')}
            />
            {errors.password && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.password?.message}
          </p>
        </div>

        {/* Confirm Password */}
        <div className="xs:col-span-2">
          <label
            htmlFor="confirm-password"
            className="block text-base font-semibold xs:text-sm"
          >
            Confirm Password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={
                errors.confirmPassword ? inputErrorClass : inputValidClass
              }
              placeholder="Confirm password"
              {...register('confirmPassword')}
            />
            {errors.confirmPassword && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.confirmPassword?.message}
          </p>
        </div>

        {/* Terms */}
        <div className="col-span-2">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                aria-describedby="terms-and-conditions-checkbox"
                type="checkbox"
                {...register('acceptTerms')}
                className="h-4 w-4 rounded border-gray-300 text-cta-default focus:ring-cta-disabled"
              />
            </div>
            <div className="ml-3 text-sm">
              <span id="terms-and-conditions" className="text-base xs:text-sm">
                I agree to Huddle Up's{' '}
                <a
                  href={EXTERNAL_LINKS.TERMS_CONDITIONS}
                  target={'_blank'}
                  className="text-cta-default underline"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </span>
              <p className="text-sm text-status-error">
                {errors.acceptTerms?.message}
              </p>
            </div>
          </div>
        </div>

        {otherError && (
          <div className="col-span-2 flex flex-col items-center justify-center text-center text-status-error">
            <p className="text-sm">Hmm, something went wrong.</p>
            <p className="whitespace-pre-wrap text-sm">
              Please contact{' '}
              <a
                href="mailto:support@huddleupcare.com"
                className="font-medium  underline xs:text-sm"
              >
                support@huddleupcare.com
              </a>{' '}
              for more information.
            </p>
          </div>
        )}
      </div>

      {/* Button */}
      <button
        type="submit"
        className={`${primaryButtonClass} w-full`}
        disabled={Boolean(Object.keys(errors)?.length) || loading || !isValid}
      >
        {loading ? (
          <>
            <RefreshIcon
              className="loader h-5 w-5 text-white"
              aria-hidden="true"
            />
            Loading
          </>
        ) : (
          'Create Account'
        )}
      </button>

      {/* Already have an account */}
      {!loading && (
        <Link to={'/sign-in'} className="self-center text-center underline">
          I already have an account
        </Link>
      )}
    </form>
  )
}

export default CreateLogin
