import { ChevronLeftIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import type {
  Resource,
  ResourceFormat,
  ResourceSection,
  ResourceStatus,
  ResourceTopic,
} from '../../types/Resources'
import ResourceFilters from './components/ResourceFilters'
import { useDebounce } from '../../hooks/useDebounce'
import { useGetBookmarkedResources } from '../../queries/resources/GetBookmarkedResources'
import ResourceGridLoader from './components/ResourceGridLoader'
import ResourcesGridDisplay from './components/ResourceGridDisplay'
import { useGetBookmarkedCourses } from '../../queries/courses/GetBookmarkedCourses'
import CourseGridLoader from './courses/CourseGridLoader'
import CoursesGridDisplay from './courses/CourseGridDisplay'
import TabNavigation from '../../components/TabNavigation'
import type { Course } from '../../types/Courses'
import { useAuth } from '../../contexts/AuthProvider'

const MyBookmarks: React.FC = () => {
  const { user } = useAuth()
  const [activeTab, setActiveTab] = useState<string>('Multimedia')
  const [resettingFilters, setResettingFilters] = useState<boolean>(false)
  const [mainSearchValue, setMainSearchValue] = useState<string>('')
  const [selectedTopics, setSelectedTopics] = useState<ResourceTopic[]>([])
  const [selectedFormats, setSelectedFormats] = useState<ResourceFormat[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<ResourceStatus[]>([])
  const debouncedMainSearchValue = useDebounce(mainSearchValue, 600)
  const [filteredResources, setFilteredResources] =
    useState<ResourceSection>(null)

  const { data: resourceSection, isLoading: isLoadingResources } =
    useGetBookmarkedResources({
      pageNumber: 0,
      pageSize: 12,
      isEnabled: activeTab === 'Multimedia',
      searchFilters: {
        searchString: debouncedMainSearchValue,
        resourceTypes: selectedFormats.map((format) => format.id),
        topics: selectedTopics.map((topic) => topic.key),
      },
    })

  const { data: courseSection, isLoading: isLoadingCourses } =
    useGetBookmarkedCourses({
      user,
      pageNumber: 0,
      pageSize: 12,
      isEnabled: activeTab === 'Courses',
      searchFilters: {
        searchString: debouncedMainSearchValue,
        userCourseStatuses: selectedStatuses.map((status) =>
          status.id.toUpperCase()
        ),
        topics: selectedTopics.map((topic) => topic.key),
      },
    })

  return (
    <div className="mb-auto flex w-full max-w-4.5xl flex-col items-start gap-8 pb-4 pt-8 sm:px-4 xs:pt-4">
      <Link
        to={`/resources#${activeTab}`}
        className="flex items-center text-base text-cta-default underline xs:text-sm"
      >
        <ChevronLeftIcon className="h-5 w-5" />
        Back to My Resources
      </Link>
      <p className="w-full border-b-4 border-components-fields text-2xl font-semibold xs:text-lg">
        My Bookmarks
      </p>

      <TabNavigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={['Multimedia', 'Courses']}
      />

      <ResourceFilters
        debouncedMainSearchValue={debouncedMainSearchValue}
        mainSearchValue={mainSearchValue}
        setMainSearchValue={setMainSearchValue}
        selectedFormats={selectedFormats}
        setSelectedFormats={setSelectedFormats}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        resettingFilters={resettingFilters}
        setResettingFilters={setResettingFilters}
        setFilteredResources={setFilteredResources}
        filteredResources={filteredResources}
        isLoadingFilteredResources={isLoadingResources}
        showFormat={activeTab === 'Multimedia'}
        showStatus={activeTab === 'Courses'}
      />
      {activeTab === 'Multimedia' ? (
        <>
          {isLoadingResources ? (
            <ResourceGridLoader loadingNumber={12} noPadding />
          ) : (
            <ResourcesGridDisplay
              resources={resourceSection?.resources.filter(
                (r: Resource) => r.isBookmarked && r.type === 'MULTIMEDIA'
              )}
              noPadding
            />
          )}
        </>
      ) : (
        <>
          {isLoadingCourses ? (
            <CourseGridLoader loadingNumber={12} noPadding />
          ) : (
            <CoursesGridDisplay
              showBadge
              courses={courseSection?.resources.filter(
                (c: Course) => c.isBookmarked && c.type === 'COURSE'
              )}
              noPadding
            />
          )}
        </>
      )}
    </div>
  )
}

export default MyBookmarks
