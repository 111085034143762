import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import INSURANCE_VERIFY from '../../assets/images/Insurance.svg'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { formatPossessiveNoun } from '../../helpers/generic'
import { usePatient } from '../../contexts/PatientProvider'
import useGoToBookingWithSelfPay from './hooks/useGoToBookingWithSelfPay'
import { useAuth } from '../../contexts/AuthProvider'
import handleClickSupportEmail from '../../helpers/supportClick'

const InsuranceInfoPendingVerification: React.FC = () => {
  const navigate = useNavigate()
  const { user, setUser } = useAuth()
  const { patient } = usePatient()
  const forSelf: boolean = patient?.relationship?.key === 'myself'

  const [goToBookingWithSelfPay] = useGoToBookingWithSelfPay()

  const onClickGoToDashboard = () => {
    const newUser = cloneDeep(user)
    const patientIdx = newUser.roster.findIndex((p) => p.id === patient.id)
    newUser.roster[patientIdx] = patient

    flushSync(() => {
      setUser(newUser)
      navigate('/dashboard')
    })
  }

  return (
    <div className="max-w-2xl text-center">
      <div className="flex flex-col gap-6">
        <h2 className="text-lg font-semibold sm:text-2xl">
          We are verifying{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : formatPossessiveNoun(patient.firstName)}
          </span>{' '}
          insurance coverage!
        </h2>
        <img
          src={INSURANCE_VERIFY}
          alt="insurance"
          className="mx-auto xs:h-24 xs:w-24"
        />
        <p className="text-sm font-medium sm:text-base">
          {patient?.insurance?.insurance?.insuranceType !== 'Medicaid' ? (
            <>
              {/* Regular */}
              For the best rates, we recommend waiting to book until your
              insurance is verified. If you book without insurance verification,
              you may be responsible for the full cost.
              <br />
              <br />
              Don't worry, we'll let you know as soon as your insurance is
              verified and adjust the prices accordingly!
            </>
          ) : (
            <>
              {/* Meicaid */}
              We'll let you know as soon as your insurance is verified!
            </>
          )}
          <br />
          <br />
          Our goal is to offer affordable and accessible care, so if you have
          any questions, please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="text-cta-default underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>

      {patient?.insurance?.insurance?.insuranceType !== 'Medicaid' ? (
        <>
          {/* Regular */}
          <div className="mx-auto flex w-full max-w-lg flex-col gap-6 sm:gap-12">
            <button
              type="button"
              onClick={() => goToBookingWithSelfPay()}
              className={primaryButtonClass}
            >
              Got it, continue booking
            </button>
            <Link
              to="/dashboard"
              className="text-sm font-medium text-text-secondary underline sm:text-base"
            >
              Book the session later
            </Link>
          </div>
        </>
      ) : (
        <>
          {/* Medicaid */}
          <button
            className={`${tertiaryButtonClass} mx-auto w-full max-w-lg`}
            onClick={onClickGoToDashboard}
          >
            Go back to my dashboard
          </button>
        </>
      )}
    </div>
  )
}

export default InsuranceInfoPendingVerification
