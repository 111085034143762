import { signOut } from 'firebase/auth'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { auth } from '../../config/firebase'
import { useAuth } from '../../contexts/AuthProvider'
import GlobalLoader from '../GlobalLoader'
import type { Patient } from '../../types/Patient'
import { useToastContext } from '../../contexts/ToastContext'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { initialized, user, setUser } = useAuth()
  const addToast = useToastContext()

  useEffect(() => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.PAGE_VIEW,
      properties: { url: window.location.href },
    })
  }, [])

  useEffect(() => {
    if (!user?.roster) return

    if (user.roster.some((p: Patient) => p?.birthDate === null)) {
      setUser({
        ...user,
        roster: user.roster.filter((p: Patient) => p?.birthDate !== null),
      })
      addToast(
        'error',
        'We are sorry, we encountered an error with your roster.'
      )
    }
  }, [user?.roster])

  if (!initialized) return <GlobalLoader />

  if (!user) {
    signOut(auth)
    return (
      <Navigate
        to={`/sign-in?redirectTo=${encodeURIComponent(
          `${window.location.pathname}${window.location.search}`
        )}`}
        replace
      />
    )
  }

  return <>{children}</>
}

export default RequireAuth
