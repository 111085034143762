import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { toCourse } from '../../helpers/transformers'
import type {
  ResourceSection,
  ResourceStatus,
  ResourceTopic,
} from '../../types/Resources'
import type { User } from '../../types/User'

export interface GetCoursesBookmarkProps {
  user: User
  pageNumber?: number
  pageSize?: number
  isEnabled?: boolean
  searchFilters?:
    | {
        searchString: string
        topics: ResourceTopic[]
        userCourseStatuses: ResourceStatus[]
      }
    | {}
}

export const useGetBookmarkedCourses = (
  params: GetCoursesBookmarkProps,
  deps = params
) =>
  useQuery(
    ['getBookmarkedCourses', deps],
    async () => getBookmarkedCourses(params),
    {
      enabled: params.isEnabled,
    }
  )

const getBookmarkedCourses = async ({
  user,
  pageNumber = 0,
  pageSize = 9,
  searchFilters = {},
}: GetCoursesBookmarkProps): Promise<ResourceSection> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/courses/bookmarks?page=${pageNumber}&pageSize=${pageSize}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...searchFilters }),
      }
    ).then((response) => response.json())

    return {
      resources: response.courses.map((rawCourse: any) =>
        toCourse({
          ...rawCourse,
          courseProgresses: rawCourse.courseProgresses.map((cp: any) => ({
            ...cp,
            patient: user.roster.find((p) => p.id === cp.patientId),
          })),
        })
      ),
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }
  } catch (error) {
    return null
  }
}
