import React from 'react'

const PickUpLoader: React.FC = () => {
  return (
    <div className="flex h-44 w-full flex-col gap-8 rounded-2xl border bg-cta-default p-8 shadow-lg sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-4.5xl xs:h-36 xs:gap-4 xs:rounded-none xs:border-0 xs:p-4 xs:text-lg">
      <div className="h-6 w-2/3 animate-pulse rounded-full bg-cta-disabled" />
      <div className="flex flex-col gap-2">
        <div className="h-5 w-full animate-pulse rounded-full bg-cta-disabled" />
        <div className="h-5 w-full animate-pulse rounded-full bg-cta-disabled" />
      </div>
    </div>
  )
}

export default PickUpLoader
