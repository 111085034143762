import React from 'react'
import { useAuth } from '../../../contexts/AuthProvider'
import {
  enumeratedElementsFromArray,
  formatPossessiveNoun,
} from '../../../helpers/generic'
import type { Patient } from '../../../types/Patient'
import DashboardElement from '../DashboardElement'
import handleClickSupportEmail from '../../../helpers/supportClick'

const VerifyingInsurance: React.FC = () => {
  const { user } = useAuth()

  const patientsWithUnverifiedInsurance = user?.roster?.filter(
    (p: Patient) =>
      !p.isIepOnly &&
      p?.insurance &&
      !p?.insurance?.verified &&
      // either insurance needs to be verified according to claimToPatient or user is in COS
      (user.products.some((p) => p.claimToPatient) ||
        p?.conditions
          ?.find((c) => !c.isIep)
          ?.carePlans?.some(
            (cp) =>
              cp.remainingSessions === 0 ||
              cp.bookingNonSponsoredInformationConfirmed
          ))
  )

  if (!patientsWithUnverifiedInsurance?.length) return

  return (
    <DashboardElement
      noGap
      title={
        <span>
          We are verifying{' '}
          {enumeratedElementsFromArray(
            patientsWithUnverifiedInsurance.map((p: Patient) =>
              p?.relationship?.key === 'myself'
                ? 'your'
                : formatPossessiveNoun(p.firstName)
            )
          )}{' '}
          insurance coverage
        </span>
      }
    >
      <div>
        <p className="text-base xs:text-sm">
          We are currently verifying your coverage and will reach out with
          details and next steps!
        </p>
        <p className="text-base xs:text-sm">
          If you have any questions, please send them to{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline "
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>
    </DashboardElement>
  )
}

export default VerifyingInsurance
