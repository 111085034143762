import React, { useState, useContext, createContext, useMemo } from 'react'

export interface PermissionSettingsContextModel {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  ableToBookAndCancel: boolean
  setAbleToBookAndCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const PermissionSettingsContext =
  createContext<PermissionSettingsContextModel>(
    {} as PermissionSettingsContextModel
  )

export const usePermissionSettingsContext =
  (): PermissionSettingsContextModel => useContext(PermissionSettingsContext)

export const PermissionSettingsProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [email, setEmail] = useState<string>(null)
  const [ableToBookAndCancel, setAbleToBookAndCancel] = useState<boolean>(false)

  const contextValue = useMemo(
    () => ({ email, setEmail, ableToBookAndCancel, setAbleToBookAndCancel }),
    [email, setEmail, ableToBookAndCancel, setAbleToBookAndCancel]
  )

  return (
    <PermissionSettingsContext.Provider value={contextValue}>
      {children}
    </PermissionSettingsContext.Provider>
  )
}
