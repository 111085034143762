import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type {
  ResourceFormat,
  ResourceSection,
  ResourceTopic,
} from '../../types/Resources'
import { toResource } from '../../helpers/transformers'

interface GetBookmarkedResourcesProps {
  pageNumber?: number
  pageSize?: number
  isEnabled?: boolean
  searchFilters?:
    | {
        searchString: string
        topics: ResourceTopic[]
        resourceTypes: ResourceFormat[]
      }
    | {}
}

const getBookmarkedResources = async ({
  pageNumber = 0,
  pageSize = 9,
  searchFilters = {},
}: GetBookmarkedResourcesProps): Promise<ResourceSection> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/multimedia/bookmarks?page=${pageNumber}&pageSize=${pageSize}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...searchFilters }),
      }
    ).then((response) => response.json())

    return {
      resources: response.resources.map((rawResource: any) =>
        toResource(rawResource)
      ),
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }
  } catch (error) {
    return null
  }
}

export const useGetBookmarkedResources = (
  params: GetBookmarkedResourcesProps,
  deps = params
) =>
  useQuery(
    ['getBookmarkedResources', deps],
    async () => getBookmarkedResources(params),
    {
      enabled: params.isEnabled,
    }
  )
