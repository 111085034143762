import React from 'react'

import type { Step } from '../types/Step'

const Stepper: React.FC<{ steps: Step[] }> = ({ steps }) => {
  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps?.length - 1 ? 'pr-8' : '',
              'relative'
            )}
          >
            {step.status === 'complete' ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-cta-default" />
                </div>
                <span className="relative flex h-8 w-8 items-center justify-center rounded-full bg-cta-default text-base font-semibold text-white">
                  {step.name}
                </span>
              </>
            ) : step.status === 'current' ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-cta-default bg-white text-base font-semibold text-cta-default"
                  aria-current="step"
                >
                  {step.name}
                </span>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span className="group relative flex h-8 w-8 items-center justify-center rounded-full bg-components-disabled text-base font-semibold text-text-label">
                  <span>{step.name}</span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Stepper

export const StepperLoader: React.FC<{ steps: Step[] }> = ({ steps }) => {
  return (
    <div className="mx-auto flex animate-pulse flex-row items-center">
      {React.Children.toArray(
        new Array(steps?.length)
          .fill(<div className="h-8 w-8 rounded-full bg-components-fields" />)
          .flatMap((x) => [
            x,
            <div className="h-0.5 w-8 bg-components-fields" />,
          ])
          .slice(0, -1)
      )}
    </div>
  )
}
