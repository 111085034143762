import React, { useState } from 'react'

import { primaryButtonClass } from '../../constants/classConstants'
import { usePatient } from '../../contexts/PatientProvider'
import type { ServiceLine } from '../../types/ServiceLine'
import CenteredModal from '../CenteredModal'

interface AddAreasOfFocusModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onFinish: (newServiceLines: ServiceLine[]) => void
  onCancel: () => void
  options: ServiceLine[]
}

const AddAreasOfFocusModal: React.FC<AddAreasOfFocusModalProps> = ({
  open,
  setOpen,
  onFinish,
  onCancel,
  options,
}) => {
  const { patient } = usePatient()

  const [serviceLinesSelected, setServiceLinesSelected] = useState<
    ServiceLine[]
  >([])

  const toggleServiceLine = (serviceLine: ServiceLine) => {
    if (!serviceLinesSelected.includes(serviceLine))
      setServiceLinesSelected((current) => [...current, serviceLine])
    else
      setServiceLinesSelected((current) =>
        current.filter(
          (line: ServiceLine) => line.displayName !== serviceLine.displayName
        )
      )
  }

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-8 text-text-primary">
        <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-4">
          {options?.length === 0 ? (
            <p>No other areas of focus available to add in {patient?.state}</p>
          ) : (
            options.map((serviceLine: ServiceLine) => (
              <button
                key={serviceLine.serviceType}
                onClick={() => toggleServiceLine(serviceLine)}
                className={`${
                  serviceLinesSelected.includes(serviceLine)
                    ? 'border border-cta-default bg-components-paleBlue text-cta-default'
                    : 'border border-components-fillBorders bg-components-fillBorders text-text-primary'
                } rounded-xl p-3 text-sm font-semibold sm:rounded-2xl sm:p-4 sm:text-base`}
              >
                {serviceLine.displayName}
              </button>
            ))
          )}
        </div>
        <div className="flex flex-col gap-4">
          <button
            onClick={() => onFinish(serviceLinesSelected)}
            type="button"
            disabled={serviceLinesSelected?.length === 0}
            className={`${primaryButtonClass}`}
          >
            Add
          </button>
          <button
            onClick={() => {
              setServiceLinesSelected([])
              onCancel()
            }}
            type="button"
            className="text-sm font-medium text-black underline sm:text-base"
          >
            Cancel
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default AddAreasOfFocusModal
