import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface GetPatientPacketProps {
  patientId: string
}

interface PatientPacketResultData {
  url: string
  version: number
  signed: boolean
  patientRelationship: string | null
  signDate: string | null
  signedName: string | null
  error?: string
}

export const useGetPatientPacket = (params: GetPatientPacketProps) =>
  useQuery(['getPatientPacket', params.patientId], async () =>
    getPatientPacket(params)
  )

export const getPatientPacket = async ({
  patientId,
}: GetPatientPacketProps): Promise<PatientPacketResultData> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const result = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PATIENT_PACKET_URL
      }/v2/patient-packet/${patientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return result
  } catch (error) {
    return Promise.reject(error)
  }
}
