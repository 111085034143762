import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { DisplayDocument } from '../../screens/view-patient/tabs/DocumentsTab'
import { toDocument } from '../../helpers/transformers'

interface GetDocumentsProps {
  patientId: string
}

export const useGetDocuments = (params: GetDocumentsProps, deps = params) =>
  useQuery(['getDocuments', deps.patientId], async () =>
    getDocuments(params.patientId)
  )

export interface Document {
  createdAt: number
  createdBy: string
  deletable: boolean
  documentType: string
  fileId: string
  id: string
  modifiedAt: number
  modifiedBy: string
  ownerId: string
  selfUploaded: boolean
  tags: { key: string; value: string }[]
}

const getDocuments = async (patientId: string): Promise<DisplayDocument[]> => {
  const token: string = await auth.currentUser.getIdToken()

  try {
    const documents: Document[] = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_STORAGE_URL
      }/v1/documents/dot/patients/${patientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return documents.map((document) => toDocument(document))
  } catch (error) {
    return Promise.reject(error)
  }
}
