import type { KeyboardEvent } from 'react'
import React, { useEffect, useState, Fragment } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Link, useLocation } from 'react-router-dom'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import cloneDeep from 'lodash.clonedeep'

import type { Insurance } from '../../types/Insurance'
import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { states } from '../../constants/values'
import type { State } from '../../types/State'
import { useUpdateInsurance } from '../../mutations/dashboard/UpdateInsurance'
import { useAuth } from '../../contexts/AuthProvider'
import { useToastContext } from '../../contexts/ToastContext'
import type { Patient } from '../../types/Patient'
import { usePatient } from '../../contexts/PatientProvider'
import useNavigateInsuranceComplete from './hooks/useNavigateInsuranceComplete'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'

const formSchema = Yup.object().shape({
  apartmentUnit: Yup.string(),
  streetAddress: Yup.string().required('Street address is required.').trim(),
  city: Yup.string().required('City is required.').trim(),
  state: Yup.string().required('State is required.').trim(),
  zip: Yup.string().required('Zip code is required.').trim(),
})

const InsuranceInfoPolicyHolderAddressEdit: React.FC = () => {
  const [settled, setSettled] = useState(false)

  const { patient, setPatient } = usePatient()
  const { user, setUser } = useAuth()
  const location = useLocation()
  const addToast = useToastContext()

  const [navigateAccordinglyToFlow, isLoadingNavigateAccordinglyToFlow] =
    useNavigateInsuranceComplete()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<Partial<Insurance>>({
    defaultValues: location.state?.insurance,
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const { mutate: callUpdateInsurance, isLoading: isLoadingUpdateInsurance } =
    useUpdateInsurance()

  const onSubmit = (newInsurance: Insurance) => {
    callUpdateInsurance(
      { patientId: patient.id, insurance: newInsurance },
      {
        onError: () => addToast('error', 'Something went wrong.'),
        onSuccess: () => {
          const patientIdx = user.roster.findIndex(
            (p: Patient) => p.id === patient.id
          )
          const newUser = cloneDeep(user)
          newUser.roster[patientIdx].insurance = newInsurance
          setUser(newUser)
          setPatient({ ...patient, insurance: newInsurance })

          setSettled(true)
        },
      }
    )
  }

  useEffect(() => {
    if (settled && !isLoadingNavigateAccordinglyToFlow)
      navigateAccordinglyToFlow({
        unassociatedInsurance: false,
      })
  }, [settled, isLoadingNavigateAccordinglyToFlow])

  const classNames = (...classesStr: string[]) =>
    classesStr.filter(Boolean).join(' ')

  return (
    <div className="max-w-xl text-center">
      <h2 className="text-lg font-semibold sm:text-2xl">
        What is the policy holder's physical address?
      </h2>
      <div className="mx-auto grid w-full max-w-sm grid-cols-2 gap-4 text-left">
        {/* Street address */}
        <Controller
          control={control}
          name="streetAddress"
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1">
              <label
                htmlFor="streetAddress"
                className="text-sm font-semibold sm:text-base"
              >
                Street address
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Street address"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        {/* Apt unit */}
        <Controller
          control={control}
          name="apartmentUnit"
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
              <label
                htmlFor="apartmentUnit"
                className="text-sm font-semibold sm:text-base"
              >
                Apt/Unit (optional)
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Apt or Unit number"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        {/* City */}
        <Controller
          control={control}
          name="city"
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
              <label
                htmlFor="city"
                className="text-sm font-semibold sm:text-base"
              >
                City
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="City name"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        {/* State */}
        <Controller
          control={control}
          name="state"
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
              <Listbox {...field}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-base font-semibold xs:text-sm">
                      State
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = states.find((s: State) =>
                            s?.name
                              ?.toLowerCase()
                              .startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('state', match?.name, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={`${
                          error ? comboBoxErrorClass : comboBoxClass
                        }`}
                      >
                        <span
                          className={`${
                            field?.value ? '' : 'text-text-placeholder'
                          } block truncate`}
                        >
                          {field?.value || 'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {states?.map((state: State) => (
                            <Listbox.Option
                              key={state.abbrev}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={state?.name}
                            >
                              {({ selected }) => (
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {state?.name}
                                </span>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        {/* Zip Code */}
        <Controller
          control={control}
          name="zip"
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
              <label
                htmlFor="zip"
                className="text-sm font-semibold sm:text-base"
              >
                Zip code
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Zip code"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />
      </div>
      <div className="mx-auto flex w-full max-w-lg flex-col gap-6 sm:gap-12">
        <div className="flex w-full gap-2 sm:gap-4">
          <Link to="../confirm" className={`w-1/2 ${tertiaryButtonClass}`}>
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </Link>
          <button
            onClick={handleSubmit(onSubmit)}
            className={`w-1/2 ${primaryButtonClass}`}
            disabled={
              !isValid ||
              !isDirty ||
              isLoadingUpdateInsurance ||
              isLoadingNavigateAccordinglyToFlow
            }
          >
            {isLoadingUpdateInsurance || isLoadingNavigateAccordinglyToFlow ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                Loading
              </>
            ) : (
              <>
                Next
                <ChevronRightIcon className="h-5 w-5" />
              </>
            )}
          </button>
        </div>
        <SkipAndComeBackLater />
      </div>
    </div>
  )
}

export default InsuranceInfoPolicyHolderAddressEdit
