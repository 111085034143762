import React, { useMemo } from 'react'
import { Link, Navigate } from 'react-router-dom'
import SessionComponent from '../../components/SessionComponent'

import { usePatient } from '../../contexts/PatientProvider'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { CarePlan, Condition } from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import type { Session } from '../../types/Session'

export const AlreadyBooked: React.FC = () => {
  const { patient } = usePatient()
  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const carePlan: CarePlan = patient?.conditions
    ?.find((c: Condition) => !c.isIep)
    ?.carePlans.find(
      (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
    )
  const pendingSession: Session = carePlan.sessions.find(
    (s: Session) => s.status === 'pending'
  )
  const isAssessment: boolean = useMemo(
    () => getIsAssessmentStatus(patient, currentServiceLine?.displayName),
    [patient, currentServiceLine]
  )

  if (!isAssessment || !pendingSession) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-5xl items-center justify-center">
      <p className="text-center text-2xl font-medium xs:text-lg">
        Looks like{' '}
        {forSelf ? (
          'you'
        ) : (
          <span className="text-cta-default">{patient.firstName}</span>
        )}{' '}
        already {forSelf ? 'have' : 'has'} a session booked with{' '}
        {forSelf ? 'your' : 'their'} provider
      </p>
      <div className="flex max-w-lg flex-col gap-10 xs:gap-6">
        <p className="text-center text-base xs:text-sm">
          We want to make sure that the care is right for{' '}
          {forSelf ? 'you' : patient.firstName}. You will be able to book
          additional sessions after {forSelf ? 'you' : 'they'} complete the
          following booked session.
        </p>
        <div className="h-0.5 w-full bg-components-fields" />
        <div className="flex max-w-md self-center">
          <SessionComponent session={pendingSession} showInfo />
        </div>
        <Link
          to="/dashboard"
          className="mb-4 flex self-center text-sm font-medium text-text-secondary underline sm:text-base xs:mb-6"
        >
          Go to my dashboard
        </Link>
      </div>
    </div>
  )
}
