import React from 'react'

import ResourceGridLoader from './components/ResourceGridLoader'
import FilteredResourcesGrid from './components/FilteredResourcesGrid'
import HighlightedResources from './components/HighlightedResources'
import ResourcesGrid from './components/ResourcesGrid'
import type {
  ResourceFormat,
  ResourceSection,
  ResourceTopic,
} from '../../types/Resources'
import { FOR_WHO_RESOURCE } from '../../types/Resources'
import type { Patient } from '../../types/Patient'
import { useAuth } from '../../contexts/AuthProvider'

const ResourcesView: React.FC<{
  selectedFormats: ResourceFormat[]
  selectedTopics: ResourceTopic[]
  debouncedMainSearchValue: string
  isLoadingFilteredResources: boolean
  filteredResources: ResourceSection
  currentPageFilteredResources: number
  setCurrentPageFilteredResources: React.Dispatch<React.SetStateAction<number>>
}> = ({
  selectedFormats,
  selectedTopics,
  debouncedMainSearchValue,
  isLoadingFilteredResources,
  filteredResources,
  currentPageFilteredResources,
  setCurrentPageFilteredResources,
}) => {
  const { user } = useAuth()

  const appliedFilters: boolean =
    Boolean(selectedFormats?.length) ||
    Boolean(selectedTopics?.length) ||
    Boolean(debouncedMainSearchValue?.length)

  return (
    <>
      {/* Filtered resources */}
      {appliedFilters &&
        (isLoadingFilteredResources ? (
          <ResourceGridLoader />
        ) : filteredResources?.totalElements ? (
          <FilteredResourcesGrid
            currentPage={currentPageFilteredResources}
            setCurrentPage={setCurrentPageFilteredResources}
            resourceSection={filteredResources}
            isLoadingResourceSection={isLoadingFilteredResources}
          />
        ) : null)}

      {/* No filters applied or no filtered resources found */}
      {(!appliedFilters ||
        (!isLoadingFilteredResources && !filteredResources?.totalElements)) && (
        <>
          {/* Highlighted resources */}
          <HighlightedResources />

          {/* No patients -> resources for user */}
          {!user.roster.some(
            (p: Patient) => p.relationship?.key !== 'myself'
          ) && <ResourcesGrid forCaregiver={true} patientId={user.data.id} />}

          {/* Resources for caregivers */}
          {user.roster.some(
            (p: Partial<Patient>) => p.relationship?.key !== 'myself'
          ) && (
            <ResourcesGrid
              forCaregiver={true}
              patientId={user.data.id}
              forWhoTitle={FOR_WHO_RESOURCE.FOR_CAREGIVERS}
              lineColor="border-primaryAccents-pink"
            />
          )}

          {/* Resources for patients */}
          {React.Children.toArray(
            user.roster
              ?.filter((p: Patient) => p.relationship?.key !== 'myself')
              .map((p: Patient) => (
                <ResourcesGrid
                  patientId={p.id}
                  forWhoTitle={`${FOR_WHO_RESOURCE.FOR_PATIENT} ${p.firstName}`}
                  lineColor="border-secondaryAccents-lightGreen"
                />
              ))
          )}
        </>
      )}
    </>
  )
}

export default ResourcesView
