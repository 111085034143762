import { useController, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import type { KeyboardEvent } from 'react'
import { Children, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, RefreshIcon } from '@heroicons/react/solid'

import CenteredModal from '../CenteredModal'
import {
  comboBoxClass,
  comboBoxErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useSaveFeedbackChangedProvider } from '../../mutations/surveys/SaveFeedbackChangedProvider'
import { useSkipSurveyChangeProvider } from '../../mutations/surveys/SkipSurveyChangeProvider'
import { usePatient } from '../../contexts/PatientProvider'

export interface ChangeProviderFeedbackForm {
  reasonDropdownOption: ReasonOption
  otherReasonText: string
  additionalFeedback: string
}

interface ReasonOption {
  name: string
  id: number
}

const formSchema = Yup.object().shape({
  reasonDropdownOption: Yup.mixed().required('Reason is required.'),
  otherReasonText: Yup.string(),
  additionalFeedback: Yup.string(),
})

const reasonOptions: ReasonOption[] = [
  { id: 1, name: 'Availability or schedule issues' },
  { id: 2, name: 'Want new treatment approach' },
  { id: 3, name: 'Therapist not a good match' },
  { id: 4, name: 'No improvement or progress' },
  { id: 5, name: 'Need more specialized support' },
  { id: 0, name: 'Other' },
]

const ChangedTherapistFeedbackModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentCarePlanProviderId: string
}> = ({ open, setOpen, currentCarePlanProviderId }) => {
  const { patient } = usePatient()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
    control,
    setValue,
  } = useForm<ChangeProviderFeedbackForm>({
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const { field: fieldReasonOption } = useController({
    control: control,
    name: 'reasonDropdownOption',
    defaultValue: null,
  })

  const { mutate: saveFeedback, isLoading: isLoadingSaveFeedback } =
    useSaveFeedbackChangedProvider()
  const { mutate: skipSurvey, isLoading: isLoadingSkipSurvey } =
    useSkipSurveyChangeProvider()

  const onSubmit = (data: ChangeProviderFeedbackForm) => {
    saveFeedback(
      {
        ...data,
        patientId: patient.id,
        providerId: currentCarePlanProviderId,
      },
      {
        onSuccess: () => {
          navigate('/dashboard')
        },
      }
    )
  }

  const onSkip = () => {
    skipSurvey(
      { patientId: patient.id, providerId: currentCarePlanProviderId },
      {
        onSuccess: () => {
          navigate('/dashboard')
        },
      }
    )
  }

  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  return (
    <CenteredModal setOpen={setOpen} open={open} noClose>
      <div className="flex flex-col gap-4 text-text-primary">
        {/* Header */}
        <h3 className="text-lg font-semibold">
          Your feedback matters - tell us why
        </h3>
        <p className="text-sm sm:text-base">
          Please tell us why you want a different therapist.
          <br />
          Don't worry, your therapist will not see your response.
        </p>
        <p className="text-sm sm:text-base">
          Our goal is to make sure that we offer the best care experience
          possible.
        </p>

        {/* I want to change my therapist because */}
        <div>
          <Listbox {...fieldReasonOption}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  I want to change my therapist because
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = reasonOptions.find((r: ReasonOption) =>
                        r?.name?.toLowerCase().startsWith(e.key.toLowerCase())
                      )
                      if (match) {
                        setValue('reasonDropdownOption', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={
                      errors.reasonDropdownOption
                        ? comboBoxErrorClass
                        : comboBoxClass
                    }
                  >
                    <span
                      className={`${
                        Boolean(fieldReasonOption?.value)
                          ? ''
                          : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {fieldReasonOption?.value?.name || 'Select'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-text-primary"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {Children.toArray(
                        reasonOptions.map((r: ReasonOption) => (
                          <Listbox.Option
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                            value={r}
                          >
                            <span className={'block truncate'}>{r?.name}</span>
                          </Listbox.Option>
                        ))
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.reasonDropdownOption && (
            <p className="mt-2 text-sm text-status-error">
              {errors.reasonDropdownOption.message}
            </p>
          )}
        </div>

        {/* Please explain ‘other’ */}
        {fieldReasonOption?.value?.name === 'Other' && (
          <div className="flex flex-col gap-1">
            <label
              htmlFor="feedback"
              className="text-sm font-semibold sm:text-base"
            >
              Please explain 'other'
            </label>
            <textarea
              className={`${inputValidClass} flex-1`}
              placeholder="Enter your reason"
              {...register('otherReasonText')}
            />
          </div>
        )}

        {/* Any additional feedback? */}
        <div className="flex flex-col gap-1">
          <label
            htmlFor="feedback"
            className="text-sm font-semibold sm:text-base"
          >
            Any additional feedback?
          </label>
          <textarea
            className={`${inputValidClass} flex-1`}
            placeholder="Enter your feedback here"
            {...register('additionalFeedback')}
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4">
          <button
            className={tertiaryButtonClass}
            onClick={() => onSkip()}
            disabled={
              isLoadingSaveFeedback || isLoadingSkipSurvey || isSubmitting
            }
          >
            Skip
          </button>
          <button
            disabled={
              !isValid ||
              !isDirty ||
              isLoadingSaveFeedback ||
              isLoadingSkipSurvey ||
              isSubmitting
            }
            className={primaryButtonClass}
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting || isLoadingSaveFeedback ? (
              <>
                <RefreshIcon className="loader h-5 w-5 text-white" />
                Loading
              </>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default ChangedTherapistFeedbackModal
