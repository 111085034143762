import React from 'react'
import { useNavigate } from 'react-router-dom'

import LOGO_404 from '../assets/images/404.svg'
import { primaryButtonClass } from '../constants/classConstants'

const Page404: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background-offwhite text-center text-text-primary">
      <div className="flex flex-col items-center justify-center gap-6 sm:gap-10">
        <div className="flex flex-col gap-4 sm:gap-8">
          <img src={LOGO_404} alt="404" className="h-9 sm:h-20" />
          <div className="flex flex-col gap-2">
            <h1 className="text-lg font-semibold sm:text-2xl">
              Uh oh, page not found.
            </h1>
            <p className="text-sm font-normal sm:text-base">
              Sorry, we couldn't find the page you're looking for.
            </p>
          </div>
        </div>
        <button
          className={primaryButtonClass}
          onClick={() => navigate('/dashboard')}
        >
          Go back home
        </button>
      </div>
    </div>
  )
}

export default Page404
