import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface SendEmailVerificationProps {
  queryParams?: any
  clientId: string
}

const sendEmailVerification = async ({
  queryParams = {},
  clientId,
}: SendEmailVerificationProps): Promise<boolean> => {
  try {
    const token = await auth.currentUser.getIdToken()

    const response = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/user-actions/send-email-verification`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          queryParams: { ...queryParams },
          clientId,
        }),
      }
    )

    if (!response.ok) throw new Error()

    return true
  } catch (error) {
    throw new Error("Couldn't send email verification")
  }
}

export const useSendEmailVerification = () =>
  useMutation((params: SendEmailVerificationProps) =>
    sendEmailVerification(params)
  )
