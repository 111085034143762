import React from 'react'

import LOGO from '../assets/images/Logo.svg'
import LOGO_TEXT from '../assets/images/LogoText.svg'
import LOGO_WHITE from '../assets/images/LogoWhite.svg'
import LOGO_WHITE_TEXT from '../assets/images/LogoWhiteText.svg'

const AppLogo: React.FC<{
  classes?: string
  smallLogo?: boolean
  white?: boolean
  logoWithText?: boolean
}> = ({ classes, smallLogo, white, logoWithText }) => {
  return (
    <img
      className={(() => {
        let sizeClasses = 'h-24 w-24 xs:h-14 xs:w-14'
        if (smallLogo) sizeClasses = 'h-13 w-13'
        else if (logoWithText) sizeClasses = 'h-14 xs:h-10'

        return `${classes} ${sizeClasses}`
      })()}
      src={(() => {
        if (white) return logoWithText ? LOGO_WHITE_TEXT : LOGO_WHITE
        else return logoWithText ? LOGO_TEXT : LOGO
      })()}
      alt="logo"
    />
  )
}

export default AppLogo
