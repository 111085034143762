import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import GlobalLoader from '../../components/GlobalLoader'
import { primaryButtonClass } from '../../constants/classConstants'
import { states } from '../../constants/values'
import { usePatient } from '../../contexts/PatientProvider'
import {
  formatPossessiveNoun,
  getMultipleRandomFromArray,
  splitArrayToChunks,
} from '../../helpers/generic'
import { useGetProviders } from '../../queries/booking/Providers'
import type { ServiceLine } from '../../types/ServiceLine'
import type { State } from '../../types/State'
import type { Therapist } from '../../types/Therapist'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { CarePlan, Condition } from '../../types/Patient'
import { useCheckProviderInInsuranceNetwork } from '../../queries/booking/ProviderInsuranceNetwork'
import TherapistBubbles from '../booking/components/TherapistBubbles/TherapistBubbles'
import { useProviderContext } from '../../contexts/ProviderContext'
import handleClickSupportEmail from '../../helpers/supportClick'
import { useAuth } from '../../contexts/AuthProvider'

// INSURANCE REVERTED

const TherapistOutOfNetworkInsuranceMedicaid = () => {
  const { patient } = usePatient()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const patientCarePlans: CarePlan[] = patient?.conditions?.find(
    (c: Condition) => !c.isIep
  )?.carePlans

  const { data: therapists, isLoading: isLoadingTherapists } = useGetProviders({
    serviceType: currentServiceLine.serviceType,
    language: patient.preferredLanguage,
    licenseJurisdiction: states.find((s: State) => s?.name === patient?.state)
      ?.abbrev,
    patient,
    withFirstAvailability: false,
    ignoreTakingNewPrivatePatients: true,
  })

  const currentProvider: Therapist = (therapists || []).find(
    (t: Therapist) =>
      t.id ===
      patientCarePlans.find(
        (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
      ).providerId
  )

  const isProviderInsuranceNetworkEnabled = Boolean(
    !patient.unassociatedInsurance &&
      patient.insurance?.id &&
      currentProvider?.id
  )

  const {
    data: providerInsuranceNetwork,
    isLoading: isLoadingProviderInsuranceCheck,
  } = useCheckProviderInInsuranceNetwork({
    patient,
    serviceType: currentServiceLine.serviceType,
    language: patient.preferredLanguage,
    licenseJurisdiction: states.find(
      (state: State) => state?.name === patient?.state
    )?.abbrev,
    providerId: currentProvider?.id,
    insuranceId: patient.insurance?.id,
    enabled: isProviderInsuranceNetworkEnabled,
  })

  if (
    isLoadingTherapists ||
    (isProviderInsuranceNetworkEnabled && isLoadingProviderInsuranceCheck)
  )
    return <GlobalLoader />

  return providerInsuranceNetwork.otherProvidersInNetwork?.length >= 1 ? (
    <OtherTherapistsFound
      therapists={providerInsuranceNetwork.otherProvidersInNetwork}
      currentServiceLine={currentServiceLine}
    />
  ) : (
    <NoOtherTherapistsFound
      currentProvider={currentProvider}
      currentServiceLine={currentServiceLine}
    />
  )
}

export default TherapistOutOfNetworkInsuranceMedicaid

const OtherTherapistsFound: React.FC<{
  therapists: Therapist[]
  currentServiceLine: ServiceLine
}> = ({ therapists, currentServiceLine }) => {
  const { patient } = usePatient()
  const { setWantDifferentTherapist } = useProviderContext()
  const navigate = useNavigate()

  const forSelf: boolean = patient?.relationship?.key === 'myself'

  const matchesMax4: Therapist[] = React.useMemo(() => {
    if (!therapists) return []
    return getMultipleRandomFromArray(
      therapists,
      Math.min(
        4,
        Math.max(
          ...Array.from(
            { length: Math.floor(therapists?.length / 2) },
            (_, i) => (i + 1) * 2
          )
        )
      )
    )
  }, [therapists])

  const matchesMax4Split: Therapist[][] = React.useMemo(
    () => splitArrayToChunks(matchesMax4, 2),
    [matchesMax4]
  )

  const handleClick = () => {
    setWantDifferentTherapist(true)
    navigate(`/booking/${currentServiceLine.url}/providers`)
  }

  return (
    <div className="max-w-4xl text-center">
      <div className="flex flex-col gap-6">
        <h1 className="text-lg font-semibold sm:text-2xl">
          Sorry,{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : formatPossessiveNoun(patient?.firstName)}
          </span>{' '}
          therapist does not accept Medicaid insurance at this time
        </h1>
        <h3 className="text-base font-semibold sm:text-lg">
          Don't worry! We found{' '}
          <span className="text-primaryAccents-orange">
            {therapists?.length}
          </span>{' '}
          other mental health therapist{therapists?.length === 1 ? '' : 's'}{' '}
          that do{therapists?.length === 1 ? 'es' : ''}!
        </h3>
        <TherapistBubbles
          serviceLine={currentServiceLine}
          matchesMax4Split={matchesMax4Split}
        />
      </div>
      <div className="mx-auto flex w-full max-w-lg flex-col gap-4 sm:gap-12">
        <button onClick={handleClick} className={`${primaryButtonClass}`}>
          View my option{therapists?.length === 1 ? '' : 's'}
        </button>
        <Link
          to="/dashboard"
          className="text-sm font-medium text-text-secondary underline sm:text-base"
        >
          Cancel
        </Link>
      </div>
    </div>
  )
}

const NoOtherTherapistsFound: React.FC<{
  currentProvider: Therapist
  currentServiceLine: ServiceLine
}> = ({ currentProvider, currentServiceLine }) => {
  const { patient } = usePatient()
  const { user } = useAuth()

  const forSelf: boolean = patient?.relationship?.key === 'myself'

  return (
    <div className="max-w-3xl text-center">
      <div className="flex flex-col gap-6">
        <h1 className="mx-auto max-w-2xl text-lg font-semibold sm:text-2xl">
          Sorry,{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : formatPossessiveNoun(patient?.firstName)}
          </span>{' '}
          {currentServiceLine.type} therapist, {currentProvider?.name}, does not
          accept Medicaid insurance at this time
        </h1>
        <p className="text-sm font-medium sm:text-base">
          Unfortunately, we currently do not have any therapists that are
          covered under your insurance.
          <br />
          If you have any questions, please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="text-sm font-medium text-cta-default underline sm:text-base"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>
      <div className="mx-auto grid w-full max-w-lg grid-cols-2 gap-2">
        {/* <Link
          to={`/booking-non-sponsored/${currentServiceLine.url}/insurance-info/edit`}
          className={`${tertiaryButtonClass} !px-4`}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Use another insurance
        </Link> */}
        <Link to="/dashboard" className={`${primaryButtonClass} !px-4`}>
          Go to my dashboard
          <ChevronRightIcon className="h-5 w-5" />
        </Link>
      </div>
    </div>
  )
}
