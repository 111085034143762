import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import {
  convertFrontendDateToBackend,
  frontendToBackendTz,
} from '../../helpers/generic'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import type { User } from '../../types/User'
import { states } from '../../constants/values'
import type { State } from '../../types/State'
import { toPatient } from '../../helpers/transformers'

interface UpdateProfileProps {
  user: User
  patient: Partial<Patient>
  retryConditions?: boolean
}

const toNewPatientData = (patient: Partial<Patient>) => ({
  firstName: patient.firstName,
  lastName: patient.lastName,
  birthDate: patient.birthDate
    ? convertFrontendDateToBackend(patient.birthDate)
    : undefined,
  gender: patient.gender?.toUpperCase(),
  genderIdentity: patient.genderIdentity?.key.toUpperCase(),
  pronouns: patient.preferredPronouns,
  city: patient.city,
  zip: patient.zip,
  streetAddress: patient.streetAddress,
  apartmentUnit: patient.apartmentUnit,
  allowedToBook: patient.allowedToBook,
  state:
    states?.find((s: State) => s?.name === patient?.state)?.abbrev ||
    patient?.state,
  preferredName: patient.preferredName,
  studentId: patient.studentId,
  locationId: patient.locationId,
  externalId: patient.externalId || null,
  email: patient.email,
  timeZone: patient.timeZone ? frontendToBackendTz(patient.timeZone) : null,
  race: patient?.race?.key,
  relationship: patient?.relationship?.key?.toUpperCase(),
  language: patient.preferredLanguage,
  conditions: patient?.conditions?.map((c: Condition) => {
    return {
      id: c.id,
      care_plans_attributes: c?.carePlans?.map(
        (plan: CarePlan & { serviceType: string; language: string }) => ({
          id: plan.id,
          service_type:
            plan?.serviceType ||
            SERVICE_LINES_ARRAY.find(
              (line: ServiceLine) => line.displayName === plan.displayName
            )?.serviceType,
          language: patient.preferredLanguage,
          provider_id: plan.providerId,
          patient_onboarding_note: plan?.notes,
          billing_information_confirmed: plan.billingInformationConfirmed,
          dont_have_insurance: plan.dontHaveInsurance,
          product_id: plan.productId,
        })
      ),
    }
  }),
  emergencyContact: {
    personId: patient?.emergencyContact?.personId,
    firstName: patient?.emergencyContact?.firstName,
    lastName: patient?.emergencyContact?.lastName,
    relationship: patient?.emergencyContact?.relationship,
    phoneNumber: patient?.emergencyContact?.phoneNumber,
  },
  billingInformation: {
    billingStreetAddress: patient?.billingStreetAddress,
    billingApartmentUnit: patient?.billingApartmentUnit,
    billingCity: patient?.billingCity,
    billingState: patient?.billingState,
    billingZip: patient?.billingZip,
  },
  personId: patient?.personId,
  ableToBookAndCancel: patient?.ableToBookAndCancel,
  invitedAt: patient?.separateLoginInvitedAt,
  userId: patient?.userId,
})

const updateProfile = async ({
  user,
  patient,
  retryConditions,
}: UpdateProfileProps): Promise<Partial<Patient>> => {
  const token: string = await auth.currentUser.getIdToken()

  const resultPatient = await fetch(
    `${import.meta.env.VITE_DOTCOM_BFF_URL}patient/${patient.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...toNewPatientData(patient),
        clientId: user.data.clientId,
        userPersonId: user.data.personId,
        retryConditions,
      }),
    }
  ).then((response) => response.json())

  return toPatient(user, resultPatient)
}

export const useUpdateProfile = () =>
  useMutation((params: UpdateProfileProps) => updateProfile(params))
