import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface SendAfterOnboardingEmailProps {
  clientId: string
  forSelf: boolean
}

const sendAfterOnboarding = async ({
  clientId,
  forSelf,
}: SendAfterOnboardingEmailProps) => {
  const token = await auth.currentUser.getIdToken()

  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
    }v1/persons/send-account-holder-onboarding-success-email`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        clientId,
        forSelf,
      }),
    }
  )
}

export const useSendAfterOnboardingEmail = () =>
  useMutation((params: SendAfterOnboardingEmailProps) =>
    sendAfterOnboarding(params)
  )
