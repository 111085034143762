import React, { useEffect, useState } from 'react'

import { imageCache } from '../../../helpers/utils'

const ImageLoader: React.FC<{
  src: string
  classes?: string
  horizontal?: boolean
}> = ({ src, classes, horizontal = true }) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    const loadImage = async () => {
      if (imageCache.has(src)) {
        setImage(imageCache.get(src))
      } else {
        try {
          const response = await fetch(src)
          const blob = await response.blob()
          const imageURL = URL.createObjectURL(blob)

          imageCache.set(src, imageURL)
          setImage(imageURL)
        } catch (error) {}
      }
    }

    loadImage()

    return () => {
      if (image && !imageCache.has(src)) {
        URL.revokeObjectURL(image)
      }
    }
  }, [src])

  return (
    <img
      src={image}
      className={`${classes} ${
        horizontal ? 'h-46 xs:h-30' : 'h-72 xs:h-32'
      } w-full object-cover`}
      alt=""
    />
  )
}

export default ImageLoader
