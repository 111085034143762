import type { CarePlan, Condition, Patient } from '../types/Patient'
import type { Session } from '../types/Session'
import { newDate } from './generic'

export const getIsAssessmentStatus = (
  patient: Patient | Partial<Patient>,
  serviceLineDisplayName: string
): boolean => {
  const currentCarePlan: CarePlan = patient?.conditions
    ?.find((c: Condition) => !c.isIep)
    ?.carePlans?.find(
      (cp: CarePlan) => cp.displayName === serviceLineDisplayName
    )

  const usedAllFreeSessions: boolean =
    currentCarePlan?.remainingSessions <= 0 &&
    Boolean(currentCarePlan.allowedSessions)

  const pendingSessions = currentCarePlan?.sessions.some(
    (s: Session) => s.status === 'pending'
  )

  if (usedAllFreeSessions && pendingSessions) return false

  const completedSessions = currentCarePlan?.sessions.some(
    (s: Session) => s.status === 'completed'
  )

  // did not complete at least one session
  return !completedSessions
}

export const isValidPastSession = (
  session: Session,
  patient: Partial<Patient>,
  returnWithStatus = false
) => {
  switch (session.status) {
    case 'completed':
    case 'absent':
    case 'no show':
      return returnWithStatus ? [true, session.status] : true
    case 'cancelled': {
      /**
        if we have canceledAt => 
          - we need to check if cancellation happened less than 24 hrs before start time
          - why?
          - because, only sessions that get cancelled less than 24 hrs before start time should show up in the previous sessions
     */
      const booleanResult =
        (Boolean(session.canceledAt) &&
          (new Date(session.startTime).getTime() -
            new Date(session.canceledAt).getTime()) /
            1000 /
            60 /
            60 <
            24) ||
        patient?.isIep
      return returnWithStatus ? [booleanResult, session.status] : booleanResult
    }
    case 'late_cancel':
      return returnWithStatus ? [true, session.status] : true
    case 'pending': {
      const isPendingButPreviousSession =
        newDate(new Date(session.endTime), patient.timeZone) <
        newDate(new Date(), patient.timeZone)
      return returnWithStatus
        ? [isPendingButPreviousSession, session.status]
        : isPendingButPreviousSession
    }
    default:
      return returnWithStatus ? [false, session.status] : false
  }
}
