import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'
import { RefreshIcon } from '@heroicons/react/solid'

import { primaryButtonClass } from '../../constants/classConstants'
import { usePatient } from '../../contexts/PatientProvider'
import { useAuth } from '../../contexts/AuthProvider'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import handleClickSupportEmail from '../../helpers/supportClick'
import useNavigateInsuranceComplete from '../bookingNonSponsored/hooks/useNavigateInsuranceComplete'
import useSeenCOSPage from '../../hooks/useSeenCOSPage'

// INSURANCE REVERTED

const BulkModelOutOfFreeSessions = () => {
  // const navigate = useNavigate()
  const { markCOSPageAsSeen } = useSeenCOSPage()
  const serviceLine = useCurrentServiceLine()
  const { patient, setPatient } = usePatient()
  const { user, setUser } = useAuth()
  const clientInfo = user.data.clientData
  const [navigateAccordinglyToFlow, isLoadingNavigateAccordinglyToFlow] =
    useNavigateInsuranceComplete()

  const handleContinueBooking = () => {
    const newUser = cloneDeep(user)
    const newPatient = cloneDeep(patient)

    const patientIdx = newUser?.roster.findIndex((p) => p.id === newPatient?.id)

    const conditionsIdx = newPatient?.conditions.findIndex(
      (c) => !c.isIep && c.carePlans.length > 0
    )

    const currentCarePlanIdx = patient?.conditions[
      conditionsIdx
    ]?.carePlans.findIndex((cp) => cp.displayName === serviceLine.displayName)

    newPatient.conditions[conditionsIdx].carePlans[
      currentCarePlanIdx
    ].bookingNonSponsoredInformationConfirmed = true

    newUser.roster[patientIdx] = newPatient

    flushSync(() => {
      setPatient(newPatient)
      setUser(newUser)

      navigateAccordinglyToFlow({ unassociatedInsurance: false })

      // const baseUrl = `/booking-non-sponsored/${serviceLine.url}/insurance-info`

      // if (!patient?.insurance) navigate(`${baseUrl}/add`)
      // else if (patient.unassociatedInsurance)
      //   navigate(`${baseUrl}/pending-verification`)
      // else navigate(`${baseUrl}/confirm`)
    })
  }

  // mark in local storage that user seen the COS page
  useEffect(() => {
    markCOSPageAsSeen()
  }, [])

  const schoolOrOrganization =
    clientInfo?.clientType === 'EDUCATION' ? 'school' : 'organization'

  return (
    <div className="max-w-2xl items-center text-center">
      <div className="flex flex-col items-center gap-6">
        <h1 className="text-2xl font-semibold xs:text-lg">
          Information about therapy payment options
        </h1>
        <p className="max-w-xl whitespace-pre-wrap text-base xs:text-sm">
          Looks like your {schoolOrOrganization} has run out of covered
          sessions, and any future sessions will need to be paid out of pocket.
          For more information, please reach out to your {schoolOrOrganization}.
          <br />
          <br />
          If you have any questions or concerns about payment options, please
          don't hesitate to contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline xs:text-sm"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>
      <div className="flex w-full max-w-lg flex-col gap-6 sm:gap-12">
        <button
          type="button"
          onClick={() => handleContinueBooking()}
          disabled={isLoadingNavigateAccordinglyToFlow}
          className={`${primaryButtonClass} mx-auto w-full`}
        >
          {isLoadingNavigateAccordinglyToFlow ? (
            <>
              <RefreshIcon className="loader h-5 w-5" />
              Loading
            </>
          ) : (
            'I understand, continue booking'
          )}
        </button>
        <Link
          to="/dashboard"
          className="flex self-center text-sm font-medium text-text-secondary underline sm:text-base"
        >
          Go to my dashboard
        </Link>
      </div>
    </div>
  )
}

export default BulkModelOutOfFreeSessions
