import React from 'react'
import { Link } from 'react-router-dom'

import DashboardElement from '../DashboardElement'
import { useGetResources } from '../../../queries/resources/GetResources'
import type { Patient } from '../../../types/Patient'
import ResourceGridLoader from '../../resources/components/ResourceGridLoader'
import { secondaryButtonClass } from '../../../constants/classConstants'
import ResourcesGridDisplay from '../../resources/components/ResourceGridDisplay'
import { paginationToken } from '../../../helpers/utils'
import useMediaQuery from '../../../hooks/useMediaQuery'
import CoursesGridDisplay from '../../resources/courses/CourseGridDisplay'
import { useGetCourses } from '../../../queries/courses/GetCourses'
import CourseGridLoader from '../../resources/courses/CourseGridLoader'
import { useAuth } from '../../../contexts/AuthProvider'
import getAvatarSrc from '../../../helpers/getAvatarSrc'

const RecommendedResources: React.FC = () => {
  const { user } = useAuth()

  if (user.roster.every((p) => p.isDisabled)) return null

  return (
    <>
      {React.Children.toArray(
        user.roster.map((p: Patient, index: number) => (
          <ResourceComponent patient={p} showHeaderTitle={index === 0} />
        ))
      )}
    </>
  )
}

const ResourceComponent: React.FC<{
  patient: Patient
  showHeaderTitle: boolean
}> = ({ patient, showHeaderTitle }) => {
  const { user } = useAuth()
  const isBigScreen = useMediaQuery('(min-width: 640px)')
  const { data: resourceSection, isLoading } = useGetResources({
    patientId: patient.id,
    forCaregiver: patient?.relationship?.key === 'myself',
    pageSize: 4,
    paginationToken: paginationToken,
  })

  const { data: courseSection, isLoading: isLoadingCourseSection } =
    useGetCourses({
      user,
      patientId: patient.id,
      forCaregiver: patient?.relationship?.key === 'myself',
      pageSize: 2,
      paginationToken: paginationToken,
    })

  if (isLoading || isLoadingCourseSection)
    return (
      <DashboardElement>
        <ForWhoHeader patient={patient} />
        <ResourceGridLoader loadingNumber={isBigScreen ? 3 : 4} noPadding />
        <CourseGridLoader loadingNumber={2} noPadding />
      </DashboardElement>
    )

  if (!resourceSection?.resources) return

  return (
    <DashboardElement
      headerTitle={showHeaderTitle ? 'Recommended Resources' : null}
    >
      <ForWhoHeader patient={patient} />

      {/* Resources */}
      <ResourcesGridDisplay
        resources={
          isBigScreen
            ? resourceSection?.resources.slice(0, 3)
            : resourceSection?.resources
        }
        noPadding
        patientId={patient?.relationship?.key === 'myself' ? null : patient.id}
      />

      {/* Courses */}
      <CoursesGridDisplay
        noPadding
        courses={courseSection?.resources}
        showProgressDetails
        patientId={patient?.relationship?.key === 'myself' ? null : patient.id}
      />

      {/* Explore */}
      <Link
        to="/resources"
        className={`${secondaryButtonClass} w-full max-w-xs self-center`}
      >
        Explore more
      </Link>
    </DashboardElement>
  )
}

const ForWhoHeader: React.FC<{ patient: Patient }> = ({ patient }) => {
  const { user } = useAuth()

  return (
    <div className="flex items-center gap-1">
      {/* Name */}
      <p className="text-lg font-semibold">
        For{' '}
        {patient?.relationship?.key === 'myself' ? 'you' : patient.firstName}
      </p>

      {/* Image */}
      <img
        src={getAvatarSrc(user, patient)}
        alt="patient-avatar"
        className="h-8 w-8"
      />
    </div>
  )
}

export default RecommendedResources
