import { useMutation } from '@tanstack/react-query'

import { convertFrontendDateToBackend } from '../../helpers/generic'

interface ClaimPersonProps {
  bearerToken: string
  dateOfBirth: string
  clientId: string
  externalId: string
}

const claimPerson = async ({
  bearerToken,
  dateOfBirth,
  clientId,
  externalId,
}: ClaimPersonProps): Promise<string> => {
  try {
    const patient_id: string = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/patients/claimCustomer`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify({
          dob: convertFrontendDateToBackend(dateOfBirth),
          clientId,
          externalId,
        }),
      }
    ).then((response) => response.json())

    if (!patient_id?.length) throw new Error('Something went wrong.')

    return patient_id
  } catch (error) {
    throw new Error('Something went wrong.')
  }
}

export const useClaimPerson = () =>
  useMutation((params: ClaimPersonProps) => claimPerson(params))
