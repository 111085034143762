import { useQuery } from '@tanstack/react-query'

import type { GetProvidersProps } from './Providers'
import { buildQueryParamsForProviders } from './Providers'
import { auth } from '../../config/firebase'
import { toCamelCase } from '../../helpers/serializers'
import type { Therapist } from '../../types/Therapist'
import { toTherapist } from '../../helpers/transformers'

interface CheckProviderInInsuranceNetworkProps extends GetProvidersProps {
  providerId: string
  insuranceId: string
  enabled: boolean
}

interface CheckProviderInInsuranceNetworkReturnValue {
  currentProviderInNetwork: boolean
  otherProvidersInNetwork: Therapist[]
}

export const useCheckProviderInInsuranceNetwork = (
  params: CheckProviderInInsuranceNetworkProps
) =>
  useQuery(
    [
      'checkProviderInInsuranceNetwork',
      {
        serviceType: params.serviceType,
        licenseJurisdiction: params.licenseJurisdiction,
        language: params.language,
      },
    ],
    async () => checkProviderInInsuranceNetwork(params),
    { enabled: Boolean(params.enabled) }
  )

export const checkProviderInInsuranceNetwork = async ({
  ...params
}: CheckProviderInInsuranceNetworkProps): Promise<CheckProviderInInsuranceNetworkReturnValue> => {
  const token: string = await auth.currentUser.getIdToken()

  const queryParams = buildQueryParamsForProviders(params).concat([
    params.insuranceId ? `insurance_id=${params.insuranceId}` : '',
  ])
  const queryParamsFiltered = queryParams.filter((qp) => qp)

  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/providers/${
      params.providerId
    }/provider_in_network?${queryParamsFiltered.join('&')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  if (result?.error) {
    throw new Error(result.error)
  }

  const camelCased: CheckProviderInInsuranceNetworkReturnValue =
    toCamelCase(result)

  return {
    currentProviderInNetwork: camelCased?.currentProviderInNetwork,
    otherProvidersInNetwork:
      camelCased?.otherProvidersInNetwork?.map((t: Therapist) =>
        toTherapist(t)
      ) || [],
  }
}
