import React from 'react'

import type { ServiceLine } from '../../../../types/ServiceLine'
import type { Therapist } from '../../../../types/Therapist'
import TherapistAvatarWrapper from './components/TherapistAvatarWrapper'
import TherapistAvatar from './components/TherapistAvatar'
import TherapistAvatarMiddle from './components/TherapistAvatarMiddle'

interface TherapistBubblesProps {
  serviceLine: ServiceLine
  matchesMax4Split: Therapist[][]
  classesContainer?: string
  smaller?: boolean
}

const TherapistBubbles: React.FC<TherapistBubblesProps> = ({
  serviceLine,
  matchesMax4Split,
  classesContainer = '',
  smaller = false,
}) => {
  return (
    <div
      className={`isolate flex flex-row items-center justify-center pb-8 drop-shadow-2xl sm:py-0.5 ${classesContainer}`}
    >
      <TherapistAvatarWrapper reversed>
        {React.Children.toArray(
          matchesMax4Split[0].map((match, matchIndex) => (
            <TherapistAvatar
              smaller={smaller}
              reversed
              index={matchIndex}
              src={match.src}
            />
          ))
        )}
      </TherapistAvatarWrapper>
      <TherapistAvatarMiddle
        smaller={smaller}
        currentServiceLine={serviceLine}
      />
      <TherapistAvatarWrapper>
        {React.Children.toArray(
          matchesMax4Split[1].map((match, matchIndex) => (
            <TherapistAvatar
              smaller={smaller}
              index={matchIndex}
              src={match.src}
            />
          ))
        )}
      </TherapistAvatarWrapper>
    </div>
  )
}

export default TherapistBubbles
