import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'

import AVATAR from '../../assets/avatars/User.svg'
import type { Option } from './Navbar'

const AccountButton: React.FC<{ name: string; menuItems: Option[] }> = ({
  name,
  menuItems,
}) => {
  const buttonClass =
    'flex items-center bg-gray-100 px-4 py-2 text-sm text-text-primary hover:bg-components-fillBorders w-full'

  return (
    <Menu as="div" className="relative hidden w-full text-left lg:inline-block">
      <div>
        <Menu.Button className="flex h-14 w-full items-center justify-start rounded-md border border-components-fields px-1 py-6 lg:mt-20">
          <img src={AVATAR} alt="patient-avatar" className="h-10 w-10" />
          <p className="ml-4 hidden max-h-11 w-28 text-left text-base font-semibold !leading-snug text-white group-hover:block group-hover:line-clamp-2">
            {React.Children.toArray(
              name.split(' ').map((word, i, arr) => (
                <span
                  className={`truncate overflow-ellipsis ${
                    name?.length <= 10 ? '' : 'block'
                  }`}
                >
                  {word}
                  {i !== arr?.length - 1 && ' '}
                </span>
              ))
            )}
          </p>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-14 bottom-0 z-10 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {menuItems.map((obj) => (
            <Menu.Item key={obj.text}>
              {obj.to ? (
                <Link to={obj.to} className={buttonClass}>
                  {obj.icon}
                  {obj.text}
                </Link>
              ) : (
                <button className={buttonClass} onClick={obj.handleOnClick}>
                  {obj.icon}
                  {obj.text}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default AccountButton
