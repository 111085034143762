import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { User } from '../../types/User'

interface UpdateUserPersonProps {
  user: User
}

const updateUserPerson = async ({ user }: UpdateUserPersonProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const result = await fetch(`${import.meta.env.VITE_DOTCOM_BFF_URL}user`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      personId: user.data.personId,
      firstName: user.data.firstName,
      lastName: user.data.lastName,
      id: user.data.id,
      email: user.data.email,
      phoneNumber: user.data.phoneNumber,
      whatBringsYouHere: user.data.whatBringsYouHere.map((item) => item.key),
      preferredName: user.data.preferredName,
      marketingOptInText: user?.data?.marketingOptInText,
      marketingOptInEmail: user?.data?.marketingOptInEmail,
      bookingUpdatesText: user?.data?.bookingUpdatesText,
    }),
  }).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useUpdateUserPerson = () =>
  useMutation((params: UpdateUserPersonProps) => updateUserPerson(params))
