import React from 'react'
import { Navigate } from 'react-router-dom'

import { usePatient } from '../../contexts/PatientProvider'

const RequireNotPhysicalAddress: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { patient } = usePatient()

  if (
    !patient ||
    (patient.streetAddress && patient.city && patient.state && patient.zip)
  )
    return <Navigate to="/dashboard" replace />

  return <>{children}</>
}

export default RequireNotPhysicalAddress
