import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type {
  ResourceFormat,
  ResourceSection,
  ResourceTopic,
} from '../../types/Resources'
import { toResource } from '../../helpers/transformers'

interface GetFilteredResourcesProps {
  selectedTopics: ResourceTopic[]
  selectedFormats: ResourceFormat[]
  mainSearchValue: string
  pageNumber?: number
  isEnabled: boolean
}

export const useGetFilteredResources = (
  params: GetFilteredResourcesProps,
  deps = params
) =>
  useQuery(
    ['getFilteredResources', deps],
    async () => getFilteredResources(params),
    {
      enabled:
        params.isEnabled &&
        (params.mainSearchValue?.length > 2 ||
          params.selectedTopics.length > 0 ||
          params.selectedFormats.length > 0),
    }
  )

const getFilteredResources = async ({
  selectedTopics,
  selectedFormats,
  mainSearchValue,
  pageNumber = 0,
}: GetFilteredResourcesProps): Promise<ResourceSection> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/multimedia/search?pageSize=6&page=${pageNumber}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          resourceTypes: selectedFormats.map((f: ResourceFormat) => f.id),
          searchString: mainSearchValue,
          topics: selectedTopics.map((f: ResourceTopic) => f.key),
        }),
      }
    ).then((response) => response.json())

    return {
      resources: response.resources.map((rawResource: any) =>
        toResource(rawResource)
      ),
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }
  } catch (error) {
    return null
  }
}
