import React from 'react'

import OneCourse from './OneCourse'
import type { Course } from '../../../types/Courses'

const CoursesGridDisplay: React.FC<{
  courses: Partial<Course[]>
  noPadding?: boolean
  showBadge?: boolean
  showProgressDetails?: boolean
  patientId?: string
  onClick?: (...args: any[]) => any
}> = ({
  courses,
  noPadding,
  showBadge = false,
  showProgressDetails = false,
  patientId,
  onClick = () => {},
}) => {
  if (!courses?.length) return <p>No courses.</p>

  return (
    <div
      className={`${
        noPadding ? '' : 'sm:px-6'
      } grid w-full grid-cols-1 gap-6 lg:grid-cols-2 xs:gap-4`}
    >
      {React.Children.toArray(
        courses.map((c: Course) => (
          <OneCourse
            course={c}
            key={c.id}
            showBadge={showBadge}
            showProgressDetails={showProgressDetails}
            patientId={patientId}
            onClick={onClick}
          />
        ))
      )}
    </div>
  )
}

export default CoursesGridDisplay
