import React, { Fragment, useCallback, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useController, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { EMAIL_REGEX, VALID_PHONE_NUMBER_REGEX } from '../../constants/regex'
import { formatPhoneNumber } from '../../helpers/generic'

interface AccountInfoFormData {
  firstName: string
  lastName: string
  preferredName: string
  phoneNumber: string
  email: string
  password: string
  confirmPassword: string
}

const accountInfoFormSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('First name is required.').trim(),
    lastName: Yup.string().required('Last name is required.').trim(),
    preferredName: Yup.mixed(),
    phoneNumber: Yup.string()
      .when('phoneNumber', {
        is: (phoneNumber: string): boolean => Boolean(phoneNumber),
        then: (schema) =>
          schema.matches(VALID_PHONE_NUMBER_REGEX, {
            name: 'validPhoneNumber',
            message: 'Please complete the phone number validly.',
          }),
        otherwise: (schema) => schema,
      })
      .nullable(),
    email: Yup.string().optional().matches(EMAIL_REGEX, 'Email is not valid.'),
    password: Yup.string().trim(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Please make sure they match.')
      .trim(),
  },
  [['phoneNumber', 'phoneNumber']]
)

interface AccountInfoModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultValues?: Partial<AccountInfoFormData>
  onFinish: (data: Partial<AccountInfoFormData>) => void
}

const AccountInfoModal: React.FC<AccountInfoModalProps> = ({
  isOpen,
  setIsOpen,
  defaultValues = {},
  onFinish,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors, isValid, isSubmitSuccessful, isDirty },
  } = useForm<AccountInfoFormData>({
    defaultValues: { ...defaultValues, password: '' },
    mode: 'all',
    resolver: yupResolver(accountInfoFormSchema),
  })

  const { field: fieldPhoneNumber } = useController({
    control: control,
    name: 'phoneNumber',
    defaultValue: null,
    rules: { maxLength: 14 },
  })

  const resetForm = () => {
    reset(defaultValues)
    setValue('password', '')
    setValue('confirmPassword', '')
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      resetForm()
    }
  }, [isSubmitSuccessful])

  const handleClose = () => {
    setIsOpen(false)
    resetForm()
  }

  const onSubmit = (data: AccountInfoFormData) => {
    if (!isValid || !isDirty) return

    if (data.password?.length > 0 && data.password?.length < 8) {
      setError('password', {
        message: 'Please enter a password, must have 8 characters.',
      })
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, confirmPassword, ...dataForOnFinish } = data
    if (!data.password) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { password, ...dataForOnFinishWithoutPassword } = dataForOnFinish
      onFinish(dataForOnFinishWithoutPassword)
    } else {
      onFinish(dataForOnFinish)
    }

    handleClose()
  }

  const handleEnterKeyPressDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.key === 'Enter') {
        e.preventDefault()
        handleSubmit(onSubmit)()
      }
    },
    [handleSubmit, onSubmit]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEnterKeyPressDown)
    return () => window.removeEventListener('keydown', handleEnterKeyPressDown)
  }, [handleEnterKeyPressDown])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex max-w-xl flex-1 transform flex-col gap-6 overflow-hidden rounded-md bg-white p-6 text-left text-text-primary shadow-elevate transition-all">
                <Dialog.Title as="h3" className="text-lg font-semibold">
                  Edit Account Information
                </Dialog.Title>

                <div className="grid grid-cols-2 gap-4 text-sm font-semibold sm:text-base">
                  <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                    <label
                      htmlFor="firstName"
                      className="text-sm font-semibold sm:text-base"
                    >
                      First name
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          errors.firstName ? inputErrorClass : inputValidClass
                        } font-normal`}
                        placeholder="First name"
                        {...register('firstName')}
                      />
                      {Boolean(errors.firstName) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.firstName) && (
                      <p className="text-sm text-status-error">
                        {errors.firstName?.message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                    <label
                      htmlFor="lastName"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Last name
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          errors.lastName ? inputErrorClass : inputValidClass
                        } font-normal`}
                        placeholder="Last name"
                        {...register('lastName')}
                      />
                      {Boolean(errors.lastName) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.lastName) && (
                      <p className="text-sm text-status-error">
                        {errors.lastName?.message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <label
                      htmlFor="preferredName"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Preferred name (optional)
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          errors.preferredName
                            ? inputErrorClass
                            : inputValidClass
                        } font-normal`}
                        placeholder="Preferred name"
                        {...register('preferredName')}
                      />
                      {Boolean(errors.preferredName) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.preferredName) && (
                      <p className="text-sm text-status-error">
                        {errors.preferredName?.message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <label
                      htmlFor="phoneNumber"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Phone number (optional)
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          errors.phoneNumber &&
                          errors.phoneNumber.type !== 'validPhoneNumber'
                            ? inputErrorClass
                            : inputValidClass
                        } font-normal`}
                        placeholder="(000) 000-0000"
                        {...fieldPhoneNumber}
                        onChange={(e) => {
                          fieldPhoneNumber.onChange(
                            formatPhoneNumber(e.target.value)
                          )
                        }}
                        maxLength={14}
                      />
                      {Boolean(
                        errors.phoneNumber &&
                          errors.phoneNumber.type !== 'validPhoneNumber'
                      ) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.phoneNumber) && (
                      <p
                        className={`text-sm ${
                          errors.phoneNumber &&
                          errors.phoneNumber.type !== 'validPhoneNumber'
                            ? 'text-status-error'
                            : ''
                        }`}
                      >
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <label
                      htmlFor="email"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Email
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        className={`${
                          errors.email ? inputErrorClass : inputValidClass
                        } border-none font-normal shadow-none`}
                        placeholder="Email"
                        disabled
                        {...register('email')}
                      />
                      {Boolean(errors.email) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.email) && (
                      <p className="text-sm text-status-error">
                        {errors.email?.message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <label
                      htmlFor="password"
                      className="text-sm font-semibold sm:text-base"
                    >
                      New password
                    </label>
                    <div className="relative">
                      <input
                        type="password"
                        className={`${
                          errors.password ? inputErrorClass : inputValidClass
                        } font-normal`}
                        placeholder="New password"
                        {...register('password')}
                      />
                      {Boolean(errors.password) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.password) && (
                      <p className="text-sm text-status-error">
                        {errors.password?.message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <label
                      htmlFor="confirmPassword"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Confirm new password
                    </label>
                    <div className="relative">
                      <input
                        type="password"
                        className={`${
                          errors.confirmPassword
                            ? inputErrorClass
                            : inputValidClass
                        } font-normal`}
                        placeholder="Confirm new password"
                        {...register('confirmPassword')}
                      />
                      {Boolean(errors.confirmPassword) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(errors.confirmPassword) && (
                      <p className="text-sm text-status-error">
                        {errors.confirmPassword?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex justify-end">
                  <div className="grid grid-cols-2 gap-4">
                    <button
                      className={tertiaryButtonClass}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={!isValid || !isDirty}
                      className={primaryButtonClass}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AccountInfoModal
