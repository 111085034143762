import useProductAdditionalSessionsType from './useProductAdditionalSessionsType'
import useHasUsedAllFreeSessions from './useHasUsedAllFreeSessions'

const useIsPatientPayCOS = () => {
  const additionalSessionsType = useProductAdditionalSessionsType()
  const usedAllFreeSessions = useHasUsedAllFreeSessions()

  return usedAllFreeSessions && additionalSessionsType === 'PATIENT_PAY'
}

export default useIsPatientPayCOS
