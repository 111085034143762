import React from 'react'
import { flushSync } from 'react-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useNavigate } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { signOut } from 'firebase/auth'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { auth } from '../../config/firebase'
import { useAuth } from '../../contexts/AuthProvider'

const ThankYouSignOut: React.FC = () => {
  const { setUser } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const onClickSignOut = async () =>
    flushSync(async () => {
      setUser(null)
      await signOut(auth)
      navigate('/sign-in')
      queryClient.invalidateQueries()
      queryClient.clear()
    })

  return (
    <div className="max-w-lg">
      <div className="flex flex-col items-center justify-center gap-10">
        <p className="whitespace-pre-wrap text-center text-2xl font-semibold">
          Thank you for choosing Huddle Up!
        </p>
        <p className="whitespace-pre-wrap text-center text-base">
          Our support team will be in touch shortly to assist with verifying the
          eligibility of the added profile(s). We appreciate your patience and
          cooperation! If you have any questions, feel free to contact us
          anytime at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline xs:text-sm"
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>

      <div className="flex w-full items-center gap-2">
        <button
          type="button"
          className={`w-1/2 ${tertiaryButtonClass}`}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
        <button
          className={`w-1/2 ${primaryButtonClass}`}
          onClick={onClickSignOut}
        >
          Sign me out
        </button>
      </div>
    </div>
  )
}

export default ThankYouSignOut
