import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import {
  convertFrontendDateToBackend,
  dataURLtoFile,
} from '../../helpers/generic'
import type { Insurance } from '../../types/Insurance'

interface UpdateInsuranceProps {
  patientId: string
  insurance: Insurance
}

const updateInsurance = async ({
  patientId,
  insurance,
}: UpdateInsuranceProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const formData = new FormData()
  formData.append('run_eligibility_job', '1')
  formData.append('insurance_membership[payer_id]', insurance.insurance.id)
  formData.append('insurance_membership[member_id]', insurance.memberId)
  formData.append('insurance_membership[group_number]', insurance.groupId)
  formData.append(
    'insurance_membership[relationship_to_insured]',
    insurance.relationship?.key
  )
  formData.append(
    'insurance_membership[insured_first_name]',
    insurance.firstName
  )
  formData.append('insurance_membership[insured_last_name]', insurance.lastName)
  formData.append(
    'insurance_membership[insured_date_of_birth]',
    convertFrontendDateToBackend(insurance.birthDate)
  )
  formData.append(
    'insurance_membership[insured_gender_at_birth]',
    insurance.gender.toLowerCase()
  )
  formData.append(
    'insurance_membership[insured_address_line_1]',
    insurance.streetAddress
  )
  formData.append(
    'insurance_membership[insured_address_line_2]',
    insurance.apartmentUnit
  )
  formData.append('insurance_membership[insured_city]', insurance.city)
  formData.append('insurance_membership[insured_country]', 'US')
  formData.append('insurance_membership[insured_state]', insurance.state)
  formData.append('insurance_membership[insured_zip_code]', insurance.zip)
  formData.append('insurance_membership[insured_phone_number]', '')
  formData.append('insurance_membership[coordination_of_benefits]', 'primary')

  // convert image if it was changed, if it break means file was not base64 which means not changed
  try {
    const cardFrontPhoto: File = dataURLtoFile(
      insurance.frontInsurance,
      'frontInsurance.png'
    )
    formData.append('insurance_membership[card_front]', cardFrontPhoto)
  } catch (error) {}

  try {
    const cardBackPhoto: File = dataURLtoFile(
      insurance.backInsurance,
      'backInsurance.png'
    )
    formData.append('insurance_membership[card_back]', cardBackPhoto)
  } catch (error) {}

  // delete pics if they were deleted and marked with null
  if (insurance.frontInsurance === null)
    formData.append('insurance_membership[remove_card_front]', '1')

  if (insurance.backInsurance === null)
    formData.append('insurance_membership[remove_card_back]', '1')

  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/patients/${patientId}/insurance_memberships/${insurance.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useUpdateInsurance = () =>
  useMutation((params: UpdateInsuranceProps) => updateInsurance(params))
