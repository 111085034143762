import React, { useEffect, useMemo } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'
import { RefreshIcon } from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'

import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import CONFETTI from '../../assets/images/SuccessGlobe.svg'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import type { ServiceLine } from '../../types/ServiceLine'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'
import type { Condition } from '../../types/Patient'
import {
  ONBOARDING_STEP,
  type CarePlan,
  type Patient,
} from '../../types/Patient'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import { usePatient } from '../../contexts/PatientProvider'
import { formatPossessiveNoun } from '../../helpers/generic'
import { useAuth } from '../../contexts/AuthProvider'
import useGetProviders from '../../queries/booking/UseGetProviders'

const ProviderSuccess: React.FC = () => {
  const { user, setUser } = useAuth()
  const location = useLocation()
  const provider = location.state?.provider
  const newAppointment = location.state?.newAppointment
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const navigate = useNavigate()
  const { patient, setPatient } = usePatient()
  const forSelf = patient?.relationship?.name === 'Myself'
  const patientCarePlans: CarePlan[] = patient?.conditions?.find(
    (c: Condition) => !c.isIep
  )?.carePlans
  const { data: result, isLoading: isCheckingServiceLines } = useGetProviders({
    patients: [patient],
  })

  const multipleCarePlansLeft: CarePlan[] = useMemo(() => {
    if (!result?.length) return []

    const resultForPatient = result.find((r) => r.patient.id === patient.id)

    return patientCarePlans.filter(
      (cp: CarePlan) =>
        cp.displayName !== currentServiceLine.displayName &&
        resultForPatient.availableServiceLinesNames.includes(cp.displayName) &&
        patient.onboardingStep === ONBOARDING_STEP.booking
    )
  }, [result])

  const isAssessment: boolean = useMemo(
    () => getIsAssessmentStatus(patient, currentServiceLine?.displayName),
    [patient, currentServiceLine]
  )

  const otherPatientInBooking = user.roster
    .find(
      (p: Patient) =>
        p.onboardingStep === ONBOARDING_STEP.booking &&
        p.allowedToBook &&
        p.id !== patient.id
    )
    ?.conditions.find((c: Condition) => !c.isIep)
    .carePlans?.some((cp: CarePlan) => !cp.sessions?.length)

  const goToDashboard = () =>
    flushSync(() => {
      setPatient(null)
      navigate('/dashboard')
    })

  const continueBooking = () => {
    if (otherPatientInBooking) {
      const newUser = cloneDeep(user)
      const currentPatientIdx = newUser.roster.findIndex(
        (p: Patient) => p.id === patient.id
      )
      newUser.roster[currentPatientIdx].onboardingStep = ONBOARDING_STEP.booking

      flushSync(() => {
        setUser(newUser)
        setPatient(null)
        navigate(`/select-service-line?multipleOnboarding=true`)
      })
    } else if (multipleCarePlansLeft?.length > 1) {
      navigate(
        `/booking/${
          SERVICE_LINES_ARRAY.find(
            (sl: ServiceLine) =>
              sl.displayName === multipleCarePlansLeft[0].displayName
          ).url
        }/providers${
          multipleCarePlansLeft[0].providerId
            ? `/${multipleCarePlansLeft[0].providerId}`
            : ''
        }`
      )
    } else navigate('/dashboard')
  }

  useEffect(() => {
    if (!provider) return

    // TODO - Add Mixpanel log
    // setExtraLog(
    //   `Booking / ${currentServiceLine.displayName} / Appointment Booked Successfully Screen / ${provider.preferredName}`
    // )
  }, [])

  if (!newAppointment) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-6xl items-center text-center sm:px-4">
      <p className="flex flex-wrap justify-center gap-6 text-base font-semibold sm:text-2xl xs:gap-1">
        <span>
          You booked{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : `${formatPossessiveNoun(patient.firstName)}`}
          </span>{' '}
          {`${
            isAssessment ? 'first' : 'new'
          } ${currentServiceLine.displayName.toLowerCase()} session,`}{' '}
          Yay!
        </span>
      </p>
      <img
        src={CONFETTI}
        alt="success confetti"
        className="h-24 w-24 sm:h-48 sm:w-48"
      />
      {isCheckingServiceLines ? (
        <button className={tertiaryButtonClass} disabled>
          <RefreshIcon className="loader h-5 w-5" />
          Loading
        </button>
      ) : multipleCarePlansLeft?.length > 1 || otherPatientInBooking ? (
        <div className="flex flex-col gap-6 sm:gap-8">
          <p className="text-base font-semibold text-text-secondary sm:text-lg">
            Let's continue and book more therapy sessions
          </p>
          <div className="flex flex-col gap-6 sm:gap-12">
            <button
              onClick={continueBooking}
              className={`${primaryButtonClass} w-full sm:w-auto`}
            >
              Continue booking
            </button>
            <SkipAndComeBackLater />
          </div>
        </div>
      ) : (
        <button
          onClick={goToDashboard}
          className={`${tertiaryButtonClass} w-full sm:w-auto`}
        >
          Go to my dashboard
        </button>
      )}
    </div>
  )
}

export default ProviderSuccess
