import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface ResourceProgressProps {
  resourceProgressId: string
}

const touchResourceProgress = async ({
  resourceProgressId,
}: ResourceProgressProps) => {
  const token: string = await auth.currentUser.getIdToken()
  fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
    }v1/progress/${resourceProgressId}/touch`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        duration: 10,
      }),
    }
  )
}

export const useTouchResourceProgress = () =>
  useMutation((params: ResourceProgressProps) => touchResourceProgress(params))
