import { useAuth } from '../contexts/AuthProvider'

const useSeenCOSPage = () => {
  const { user } = useAuth()

  const seenCOSPage = JSON.parse(localStorage.getItem('DOTCOM_SEEN_COS_PAGE'))

  const markCOSPageAsSeen = () =>
    localStorage.setItem(
      'DOTCOM_SEEN_COS_PAGE',
      JSON.stringify({
        ...seenCOSPage,
        [user.data.id]: true,
      })
    )

  return { markCOSPageAsSeen }
}

export default useSeenCOSPage
