import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface SkipSurveyProps {
  surveyId: string
  customDate?: string
}

const skipSurvey = async ({ surveyId, customDate }: SkipSurveyProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
    }/v1/survey/${surveyId}/sendAnswers${
      customDate ? `?customDate=${customDate}` : ''
    }`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([]),
    }
  )

  if (!result.ok) throw new Error('Something went wrong.')
}

export const useSkipSurvey = () =>
  useMutation((params: SkipSurveyProps) => skipSurvey(params))
