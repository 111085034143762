import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeftIcon, RefreshIcon } from '@heroicons/react/solid'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useSaveContactInfo } from '../../mutations/eligibility/SaveContactInvalidVerification'
import { useAuth } from '../../contexts/AuthProvider'
import type { Patient } from '../../types/Patient'

const EligibilityFailed: React.FC = () => {
  const location = useLocation()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { mutate: callSaveContactInfo, isLoading } = useSaveContactInfo()
  const userInfo = location?.state?.userInfo

  const onSoundsGoodClick = () => {
    callSaveContactInfo(
      {
        email: user.data.email,
        clientName: user.data.clientData.clientName,
        ...userInfo,
      },
      {
        onSuccess: () => {
          if (
            user.roster.some((p: Patient) => Boolean(p?.onboardingStep?.length))
          )
            navigate('/onboarding/add-another-person')
          else navigate('/dashboard')
        },
      }
    )
  }

  return (
    <div className="max-w-lg">
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-2xl font-semibold">
          Let's figure this out
        </p>
        <p className="whitespace-pre text-center text-sm">
          Looks like we are having trouble verifying your eligibility.{'\n'}
          Don't worry, we will have our support team contact you to get this
          figured out!
        </p>
      </div>

      <div className="col-span-2 flex w-full items-center">
        <button
          type="button"
          className={`mr-2 w-1/2 ${tertiaryButtonClass}`}
          onClick={() => navigate(-1)}
          disabled={isLoading}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Try again
        </button>
        <button
          className={`w-1/2 ${primaryButtonClass}`}
          onClick={onSoundsGoodClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <RefreshIcon className="loader h-5 w-5 text-white" /> Loading
            </>
          ) : (
            'Sounds good'
          )}
        </button>
      </div>
    </div>
  )
}

export default EligibilityFailed
