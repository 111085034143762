import type { Patient } from '../types/Patient'
import { useAuth } from '../contexts/AuthProvider'

const useRanOutOfBulkSessionsAndPatientPays = () => {
  const { user } = useAuth()

  return user.roster.some((p: Patient) =>
    p?.conditions
      ?.find((c) => !c.isIep)
      ?.carePlans.some(
        (cp) =>
          cp?.bulkModel?.isBulkModel &&
          !cp?.bulkModel?.remainingSessionsOnBulkModel &&
          user.products.find((p) => p.id === cp.productId)
            ?.additionalSessions === 'PATIENT_PAY'
      )
  )
}

export default useRanOutOfBulkSessionsAndPatientPays
