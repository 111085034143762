import React, { useEffect, useState } from 'react'

import DashboardElement from '../DashboardElement'
import { useAuth } from '../../../contexts/AuthProvider'
import type { Patient } from '../../../types/Patient'
import { enumeratedElementsFromArray } from '../../../helpers/generic'
import handleClickSupportEmail from '../../../helpers/supportClick'

// DCT-2931
const NotAllowedToBookAnymore: React.FC = () => {
  const { user } = useAuth()
  const [patientsToShowMsgFor, setPatientsToShowMsgFor] = useState<
    (Patient | Partial<Patient>)[]
  >([])

  const allowedToBookHistories = user.allowedToBookHistories

  useEffect(() => {
    if (!user?.roster?.length) return

    const { patientsNotAllowedToBookNow, ineligibilePatients } =
      user.roster.reduce(
        (
          acc: {
            patientsNotAllowedToBookNow: (Patient | Partial<Patient>)[]
            ineligibilePatients: (Patient | Partial<Patient>)[]
          },
          p: Patient | Partial<Patient>
        ) => {
          if (
            !p.allowedToBook &&
            allowedToBookHistories[p.id]?.[0]?.changedTo === false &&
            allowedToBookHistories[p.id]?.[1]?.changedTo === true
          ) {
            acc.patientsNotAllowedToBookNow.push(p)
          }

          if (!p.isEligible && !p.isIep) {
            acc.ineligibilePatients.push(p)
          }

          return acc
        },
        {
          patientsNotAllowedToBookNow: [],
          ineligibilePatients: [],
        }
      )

    if (user.roster.length === patientsNotAllowedToBookNow.length)
      setPatientsToShowMsgFor(patientsNotAllowedToBookNow)
    else if (Boolean(ineligibilePatients.length))
      setPatientsToShowMsgFor(ineligibilePatients)
  }, [user, allowedToBookHistories])

  if (!patientsToShowMsgFor?.length) return <></>

  return (
    <DashboardElement
      noGap
      title={
        patientsToShowMsgFor.length === 1 &&
        patientsToShowMsgFor[0]?.relationship?.key === 'myself' ? (
          <span>You cannot schedule any sessions at this time.</span>
        ) : (
          <span>
            You cannot schedule any sessions for{' '}
            <span className="text-cta-default">
              {enumeratedElementsFromArray(
                patientsToShowMsgFor.map((p) => p.firstName)
              )}
            </span>{' '}
            at this time.
          </span>
        )
      }
    >
      <p className="text-base xs:text-sm">
        Please contact your school or organization regarding the service
        eligibility. For all other questions, contact{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium text-cta-default underline"
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>
    </DashboardElement>
  )
}

export default NotAllowedToBookAnymore
