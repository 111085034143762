import React from 'react'

const ResourceGridLoader: React.FC<{
  loadingNumber?: number
  noPadding?: boolean
}> = ({ loadingNumber = 6, noPadding = false }) => {
  return (
    <div
      className={`${
        noPadding ? '' : 'sm:px-6'
      } grid w-full grid-cols-3 gap-6 xs:grid-cols-2 xs:gap-4`}
    >
      {React.Children.toArray(
        [...Array(loadingNumber)].map(() => (
          <div className="flex max-w-sm flex-col overflow-hidden rounded-2xl border border-components-fields bg-white shadow-subtle">
            <div className="h-46 animate-pulse rounded bg-components-fields object-cover xs:h-30" />
            <div className="flex flex-1 flex-col justify-between gap-6 px-4 pt-4 pb-6 text-base text-text-primary">
              <p className="h-5 w-full animate-pulse rounded bg-components-fields font-semibold" />
              <p className="h-5 w-full animate-pulse rounded bg-components-fields font-semibold" />
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default ResourceGridLoader
