import { useMutation } from '@tanstack/react-query'
import { format } from 'date-fns'

import { auth } from '../../config/firebase'
import type { Question } from '../../queries/outcomes/GetQuestions'
import { newDate } from '../../helpers/generic'

interface CompleteQuizProps {
  results: Question[]
  personId: string
  patientTimeZone: string
}

const completeQuiz = async ({
  results,
  personId,
  patientTimeZone,
}: CompleteQuizProps) => {
  try {
    const quizOrder = {
      'PHQ-9': 1,
      'GAD-7': 2,
      'PHQ-2': 3,
      'GAD-2': 4,
    }

    const containsBothPHQLongAndShort =
      results.some((q) => q.quizName === 'PHQ-9') &&
      results.some((q) => q.quizName === 'PHQ-2')

    const containsBothGADLongAndShort =
      results.some((q) => q.quizName === 'GAD-7') &&
      results.some((q) => q.quizName === 'GAD-2')

    if (containsBothPHQLongAndShort)
      results = results.filter((q) => q.quizName !== 'PHQ-2')

    if (containsBothGADLongAndShort)
      results = results.filter((q) => q.quizName !== 'GAD-2')

    const groupedQuestions: { [key: string]: Question[] } = results.reduce(
      (acc, obj) => {
        const key = obj.quizId
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      },
      {}
    )

    const sortedQuizes: { quizId: string; questions: Question[] }[] =
      Object.entries(groupedQuestions)
        .map(([quizId, questions]) => ({ quizId, questions }))
        .sort(
          (a, b) =>
            quizOrder[a.questions[0].quizName] -
            quizOrder[b.questions[0].quizName]
        )

    for (const { quizId, questions } of sortedQuizes) {
      const token: string = await auth.currentUser.getIdToken()
      await fetch(
        `${
          import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
        }/v1/survey/${quizId}/sendAnswers?personId=${personId}&customDate=${format(
          newDate(new Date(), patientTimeZone),
          'yyyy-MM-dd'
        )}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(
            questions.map((q: Question) => ({
              answerInt: q.answer,
              internalQuestionId: q.id,
            }))
          ),
        }
      )
    }
  } catch {
    throw new Error('Something went wrong.')
  }
}

export const useCompleteQuiz = () =>
  useMutation((params: CompleteQuizProps) => completeQuiz(params))
