import { ChevronRightIcon, PlusIcon, XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import { useAuth } from '../../contexts/AuthProvider'
import { usePatient } from '../../contexts/PatientProvider'
import {
  ONBOARDING_STEP,
  type CarePlan,
  type Patient,
  type Condition,
} from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import type { ResultGetProviders } from '../../queries/booking/UseGetProviders'
import useGetProviders from '../../queries/booking/UseGetProviders'
import AddAreasOfFocusModal from '../../components/Modals/AddAreasOfFocusModal'
import { useToastContext } from '../../contexts/ToastContext'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import { useGetClientServiceLines } from '../../queries/onboarding/GetClientServiceLines'
import { enumeratedElementsFromArray } from '../../helpers/generic'
import { uniqueCarePlans } from '../../helpers/utils'
import useIsIEPOnly from '../../hooks/useIsIEPOnly'
import type { Product } from '../../types/User'
import type { Therapist } from '../../types/Therapist'
import BookingStatusTooltip from '../../components/BookingStatusTooltip'
import GlobalLoader from '../../components/GlobalLoader'
import { states } from '../../constants/values'
import useGiveNotAllowedToBookPatientsForMessaging from '../../hooks/useHandleNotAllowedMessageOnSSL'
import getAvatarSrc from '../../helpers/getAvatarSrc'
import handleClickSupportEmail from '../../helpers/supportClick'

const SelectServiceLine: React.FC = () => {
  const iepOnlyExperience = useIsIEPOnly()
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const multipleOnboarding: boolean =
    params.get('multipleOnboarding') === 'true'
  const { user, setUser } = useAuth()
  const { patient, setPatient } = usePatient()
  const [patientsToDisplay, setPatientsToDisplay] = useState<
    Partial<Patient>[]
  >([])

  const { patientsToShowNotAllowedMsgFor, handleDismissMsgNotAllowedPatients } =
    useGiveNotAllowedToBookPatientsForMessaging()

  useEffect(() => {
    if (!user) return

    if (iepOnlyExperience) {
      endBookingAndGoToDashboard()
      return
    }

    const patients = user.roster.filter(
      (p) => !p.isDisabled && Boolean(p.state)
    )

    const patientsWithOnboardingStep = patients.filter(
      (p: Patient) =>
        p.onboardingStep === ONBOARDING_STEP.booking && !p?.isIepOnly
    )

    const potentialPatients =
      multipleOnboarding && patientsWithOnboardingStep?.length
        ? patientsWithOnboardingStep
        : patients.filter((p) => !p.isIepOnly)

    if (
      patient?.id &&
      !multipleOnboarding &&
      !patient.isIepOnly &&
      !patient.isDisabled
    ) {
      setPatientsToDisplay([patients.find((p) => p.id === patient.id)])
    } else if (potentialPatients?.length > 0)
      setPatientsToDisplay(potentialPatients)
    else endBookingAndGoToDashboard()
  }, [user])

  const {
    data: resultProvidersForEachPatient,
    isLoading,
    setRefetch,
  } = useGetProviders({
    patients: patientsToDisplay,
    enabled: Boolean(patientsToDisplay?.length),
  })

  const endBookingAndGoToDashboard = () => {
    const newUser = cloneDeep(user)
    const newRoster = newUser.roster.map((p: Patient) => ({
      ...p,
      onboardingStep: null,
    }))
    newUser.roster = newRoster

    // Use setTimeout to move the flushSync call to a micro task
    setTimeout(() => {
      flushSync(() => {
        setUser(newUser)
        setPatient(null)
        navigate('/dashboard')
      })
    }, 0)
  }

  return (
    <div className="max-w-5xl items-center text-center">
      <div className="flex flex-col items-center gap-6 text-center">
        <h2 className="text-base font-semibold sm:text-2xl">
          Let's book your session!
        </h2>
        <p className="text-center text-sm">
          Select the therapy session you would like to book
        </p>
      </div>
      {Boolean(patientsToShowNotAllowedMsgFor.length) && (
        <div className="flex max-w-2xl flex-row gap-2 rounded-2xl bg-components-fillBorders p-4 text-start">
          <div className="flex flex-col gap-1">
            {patientsToShowNotAllowedMsgFor.length === 1 &&
            patientsToShowNotAllowedMsgFor[0]?.relationship?.key ===
              'myself' ? (
              <p className="text-sm font-semibold sm:text-base">
                You cannot schedule any sessions at this time.
              </p>
            ) : (
              <p className="text-sm font-semibold sm:text-base">
                You cannot schedule any sessions for{' '}
                <span className="text-cta-default">
                  {enumeratedElementsFromArray(
                    patientsToShowNotAllowedMsgFor.map((p) => p.firstName)
                  )}
                </span>{' '}
                at this time.
              </p>
            )}
            <p className="text-xs sm:text-sm">
              Please contact your school or organization regarding the service
              eligibility. For all other questions, contact{' '}
              <a
                href="mailto:support@huddleupcare.com"
                className="font-medium text-cta-default underline"
                onClick={() => handleClickSupportEmail(user, location)}
              >
                support@huddleupcare.com
              </a>
              .
            </p>
          </div>
          <button
            type="button"
            className="self-start text-text-label"
            onClick={() => handleDismissMsgNotAllowedPatients()}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      )}
      <div className="flex flex-wrap justify-center gap-6">
        {React.Children.toArray(
          patientsToDisplay
            .filter((p) => p.allowedToBook)
            .map((p: Patient) => (
              <SelectPatientCard
                resultProvidersForPatient={(
                  resultProvidersForEachPatient || []
                ).find((r: ResultGetProviders) => r.patient.id === p.id)}
                isLoading={isLoading}
                setRefetch={setRefetch}
                p={p}
                redirectDirectly={patientsToDisplay?.length === 1}
                avatarPicture={getAvatarSrc(user, p)}
                endBookingAndGoToDashboard={endBookingAndGoToDashboard}
              />
            ))
        )}
      </div>
      <SkipAndComeBackLater handleClick={endBookingAndGoToDashboard} />
    </div>
  )
}

export default SelectServiceLine

const SelectPatientCard: React.FC<{
  p: Patient
  redirectDirectly: boolean
  resultProvidersForPatient: ResultGetProviders
  isLoading: boolean
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
  avatarPicture: string
  endBookingAndGoToDashboard: () => void
}> = ({
  p,
  redirectDirectly,
  resultProvidersForPatient,
  isLoading,
  setRefetch,
  avatarPicture,
  endBookingAndGoToDashboard,
}) => {
  const addToast = useToastContext()
  const [currentPatient, setCurrentPatient] = useState<Patient>(p)
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { setPatient } = usePatient()
  const [bookableLines, setBookableLines] = useState<ServiceLine[]>([])
  const [isOpenAddAreasOfFocusModal, setIsOpenAddAreasOfFocusModal] =
    useState<boolean>(false)
  const { mutate: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const { data: clientAvailableSLs, isLoading: isLoadingClientAvailableSLs } =
    useGetClientServiceLines({
      clientId: user.data.clientId,
      clientType: user.data.clientData.clientType,
    })
  const patientCarePlans: CarePlan[] = uniqueCarePlans(
    p?.conditions?.flatMap((c: Condition) => c.carePlans)
  )

  const goToServiceLine = (serviceLine: ServiceLine) => {
    const providerId: string = p?.conditions
      ?.find((c: Condition) => !c.isIep)
      ?.carePlans?.find(
        (cp: CarePlan) => cp.displayName == serviceLine.displayName
      )?.providerId

    const providerToBookWith = resultProvidersForPatient?.therapists?.find(
      (t) => t.id === providerId
    )

    let nextRouteUrl = ''

    if (!providerToBookWith) {
      const currentProviderFromSessions = patientCarePlans?.find(
        (cp: CarePlan) => cp.displayName === serviceLine.displayName
      )?.sessions?.[0]?.therapist

      const changedState =
        Boolean(currentProviderFromSessions) &&
        !currentProviderFromSessions?.licenseCredentials?.includes(
          `US-${states.find((s) => s.name === p?.state)?.abbrev}`
        )

      nextRouteUrl = `/booking/${serviceLine.url}?changedState=${changedState}`
    } else
      nextRouteUrl = `/booking/${serviceLine.url}/providers/${providerToBookWith.id}`

    // Use setTimeout to move the flushSync call to a micro task
    setTimeout(() => {
      flushSync(() => {
        setPatient(currentPatient)
        navigate(nextRouteUrl, {
          replace: !bookableLines?.length,
          state: { from: '/select-service-line' },
        })
      })
    }, 0)
  }

  useEffect(() => {
    if (
      isLoading ||
      !currentPatient ||
      !resultProvidersForPatient ||
      isLoadingClientAvailableSLs
    )
      return

    const tempLines: ServiceLine[] = []

    patientCarePlans.forEach((carePlan: CarePlan) => {
      const serviceLine: ServiceLine = SERVICE_LINES_ARRAY.find(
        (sl: ServiceLine) => sl.displayName === carePlan.displayName
      )

      if (
        resultProvidersForPatient.availableServiceLinesNames.includes(
          carePlan.displayName
        )
      )
        tempLines.push(serviceLine)
    })

    if (redirectDirectly)
      if (tempLines?.length === 1 && clientAvailableSLs?.length === 1)
        goToServiceLine(tempLines[0])
      else if (tempLines?.length === 0) endBookingAndGoToDashboard()

    setBookableLines(tempLines)
  }, [
    isLoading,
    resultProvidersForPatient,
    currentPatient,
    isLoadingClientAvailableSLs,
  ])

  const onFinishAddAreaOfFocus = (newAreasOfFocusAdded: ServiceLine[]) => {
    setIsOpenAddAreasOfFocusModal(false)

    const newConditions: Condition[] = []

    if (newAreasOfFocusAdded?.length) {
      const newCarePlans = []

      newAreasOfFocusAdded.forEach((sl: ServiceLine) => {
        newCarePlans.push({
          serviceType: sl.serviceType,
          language: currentPatient.preferredLanguage,
          productId: user.products.find(
            (p: Product) =>
              p.serviceLine.serviceType === sl.serviceType && !p.isIep
          )?.id,
        })
      })

      newConditions.push({
        id: p.conditions.find((c: Condition) => !c.isIep).id,
        carePlans: newCarePlans,
      })
    }

    callUpdateProfile(
      {
        patient: {
          ...currentPatient,
          conditions: newConditions,
        },
        user,
      },
      {
        onSettled: (newPatient: Patient) => {
          const newUser = cloneDeep(user)
          const patientIndex = newUser.roster.findIndex(
            (patient: Patient) => patient.id === currentPatient.id
          )
          newUser.roster[patientIndex] = newPatient
          setCurrentPatient(newPatient)
          flushSync(() => {
            setUser(newUser)
            setRefetch(true)
          })
          addToast('success', 'Area(s) of focus added successfully!')
        },
      }
    )
  }

  const isLoadingCard: boolean =
    isLoading || isLoadingClientAvailableSLs || isLoadingUpdateProfile

  if (redirectDirectly && !bookableLines?.length) return <GlobalLoader />

  return (
    <div className="flex min-h-xs w-80 flex-col gap-4 rounded-lg border border-components-fields bg-white p-6 xs:p-4">
      <div className="flex gap-6 pb-2.5">
        <img src={avatarPicture} alt="self" className="h-14 w-14" />
        <p className="self-center text-left text-lg font-semibold text-text-primary">
          {currentPatient.firstName}
        </p>
      </div>
      <div className="flex flex-col gap-4 sm:gap-6">
        {isLoadingCard ? (
          <>
            <div className="flex h-14 w-full animate-pulse rounded-md bg-components-fields" />
            <div className="flex h-14 w-full animate-pulse rounded-md bg-components-fields" />
            <div className="flex h-14 w-full animate-pulse rounded-md bg-components-fields" />
          </>
        ) : (
          React.Children.toArray(
            clientAvailableSLs
              .filter((sl: ServiceLine) =>
                patientCarePlans.find(
                  (cp: CarePlan) => cp.displayName === sl.displayName
                )
              )
              ?.map((serviceLine: ServiceLine) => {
                const currentCarePlan = patientCarePlans.find(
                  (cp: CarePlan) => cp.displayName === serviceLine.displayName
                )

                const currentProvider: Therapist =
                  currentCarePlan?.sessions[currentCarePlan.sessions.length - 1]
                    ?.therapist

                const stateAbbrev = states.find(
                  (s) => s.name === currentPatient?.state
                )?.abbrev

                const currentProviderIsInSameState: boolean =
                  currentProvider?.licenseCredentials?.includes(
                    `US-${stateAbbrev}`
                  )

                const providerNotAvailableForPatient: boolean =
                  Boolean(currentProvider) &&
                  currentProviderIsInSameState &&
                  !resultProvidersForPatient?.therapists?.some(
                    (t: Therapist) => t.id === currentProvider.id
                  )

                return (
                  <BookTherapyButton
                    bookableLines={bookableLines}
                    p={currentPatient}
                    providerNotAvailableForPatient={
                      providerNotAvailableForPatient
                    }
                    serviceLine={serviceLine}
                    onBtnClick={() => goToServiceLine(serviceLine)}
                  />
                )
              })
          )
        )}
        {!isLoadingCard &&
          patientCarePlans?.length < clientAvailableSLs?.length && (
            <>
              <AddAreasOfFocusModal
                open={isOpenAddAreasOfFocusModal}
                setOpen={setIsOpenAddAreasOfFocusModal}
                onFinish={(newServiceLines: ServiceLine[]) =>
                  onFinishAddAreaOfFocus(newServiceLines)
                }
                onCancel={() => setIsOpenAddAreasOfFocusModal(false)}
                options={clientAvailableSLs.filter(
                  (sl: ServiceLine) =>
                    !patientCarePlans.find(
                      (cp: CarePlan) => cp.displayName === sl.displayName
                    )
                )}
              />
              <button
                className="flex items-center justify-center gap-1 self-center text-cta-default"
                onClick={() => setIsOpenAddAreasOfFocusModal(true)}
              >
                <PlusIcon className="h-4 w-4" />
                <p className="text-base underline xs:text-sm">
                  Add area of focus
                </p>
              </button>
            </>
          )}
      </div>
    </div>
  )
}

const BookTherapyButton: React.FC<{
  bookableLines: ServiceLine[]
  p: Partial<Patient>
  serviceLine: ServiceLine
  providerNotAvailableForPatient: boolean
  onBtnClick: () => void
}> = ({
  bookableLines,
  p,
  serviceLine,
  providerNotAvailableForPatient,
  onBtnClick,
}) => {
  const [isDisabledBooking, setIsDisabledBooking] = useState<boolean>(false)

  return (
    <BookingStatusTooltip
      bookableLines={bookableLines}
      patient={p}
      serviceLine={serviceLine}
      providerNotAvailable={providerNotAvailableForPatient}
      position="right"
      setIsDisabled={setIsDisabledBooking}
    >
      <button
        disabled={isDisabledBooking}
        onClick={onBtnClick}
        className="flex w-full items-center justify-between gap-1 rounded-md border-2 border-components-fields bg-white p-4 shadow-sm disabled:text-components-fields disabled:opacity-60"
      >
        <div className="flex items-center gap-1">
          <img
            className="h-5 w-5"
            src={serviceLine.avatar}
            alt="Service Line"
          />
          <p className="whitespace-nowrap text-base font-semibold capitalize">
            {serviceLine.displayName}
          </p>
        </div>
        <ChevronRightIcon className="h-4 w-4 sm:h-5 sm:w-5" />
      </button>
    </BookingStatusTooltip>
  )
}
