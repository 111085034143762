import React, { useState } from 'react'

import { useGetHighlightedResources } from '../../../queries/resources/GetHighlightedResources'
import { paginationToken } from '../../../helpers/utils'
import ResourcesGridDisplay from './ResourceGridDisplay'
import STAR from '../../../assets/icons/StarFilled.svg'
import { ResourcePaginator } from './ResourcesGrid'
import ResourceGridLoader from './ResourceGridLoader'

const HighlightedResources: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data: resourceSection, isLoading } = useGetHighlightedResources({
    paginationToken,
    pageNumber: currentPage,
    pageSize: 3,
  })

  if (!isLoading && !resourceSection?.totalElements) return null

  return (
    <div className="flex flex-col gap-6 rounded-2xl bg-beige p-6 xs:gap-3 xs:p-3">
      <div className="flex gap-1">
        <img src={STAR} alt="star" />
        <p className="text-lg font-semibold">Highlighted Resources</p>
      </div>

      {isLoading ? (
        <ResourceGridLoader loadingNumber={3} noPadding />
      ) : (
        <>
          <ResourcesGridDisplay
            resources={resourceSection?.resources.slice(0, 3)}
            noPadding
          />

          <ResourcePaginator
            pageMapId="highlightedResources"
            currentPage={currentPage}
            isLoadingResourceSection={isLoading}
            resourceSection={resourceSection}
            setCurrentPage={setCurrentPage}
            altLineColor
          />
        </>
      )}
    </div>
  )
}

export default HighlightedResources
