import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import type { ProviderBookingLocationState } from '../../types/Booking'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { ServiceLine } from '../../types/ServiceLine'
import { usePatient } from '../../contexts/PatientProvider'
import PatientPacket from '../../components/PatientPacket'

const PatientPacketSignature: React.FC = (): JSX.Element => {
  const { providerId } = useParams()
  const { patient } = usePatient()
  const navigate = useNavigate()
  const location = useLocation()
  const provider = location.state?.provider

  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const locationState = location.state as ProviderBookingLocationState

  useEffect(() => {
    if (!provider) return

    // TODO - Add Mixpanel log
    // setExtraLog(
    //   `Booking / Patient Packet / ${currentServiceLine.displayName} / ${provider.preferredName}`
    // )
  }, [])

  const handleNavigateNext = async () => {
    const teleAppropriatenessUrl = `/booking/${currentServiceLine.url}/providers/${providerId}/tele-appropriateness`

    navigate(teleAppropriatenessUrl, {
      state: {
        ...locationState,
      },
    })
  }

  return (
    <PatientPacket
      onSuccessPostPatientPacket={handleNavigateNext}
      patient={patient}
      showSkipAndComeBackLater
    />
  )
}

export default PatientPacketSignature
