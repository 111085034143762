export const primaryButtonClass =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-transparent bg-cta-default px-4 py-2.5 text-sm font-semibold text-white shadow-sm outline-none hover:bg-cta-active focus:bg-cta-default focus:ring-2 focus:ring-cta-default focus:ring-offset-2 active:bg-cta-default active:ring-2 active:ring-cta-default active:ring-offset-2 disabled:bg-cta-disabled disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0 sm:px-6 sm:py-4 sm:text-base'
export const secondaryButtonClass =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-cta-default bg-white px-4 py-2.5 text-sm font-semibold text-cta-default shadow-sm outline-none hover:border-cta-active hover:bg-components-paleBlue hover:text-cta-active focus:border-cta-default focus:bg-components-paleBlue focus:text-cta-active focus:ring-2 focus:ring-cta-default focus:ring-offset-2 active:border-cta-default active:bg-components-paleBlue active:text-cta-active active:ring-2 active:ring-cta-default active:ring-offset-2 disabled:border-cta-disabled disabled:bg-white disabled:text-cta-disabled disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0 sm:px-6 sm:py-4 sm:text-base'
export const tertiaryButtonClass =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 bg-white border-components-fields px-4 py-2.5 text-sm font-semibold text-text-primary shadow-sm outline-none hover:bg-components-fillBorders focus:ring-2 focus:ring-cta-default focus:ring-offset-2 active:ring-2 active:ring-cta-default active:ring-offset-2 disabled:bg-components-fillBorders disabled:text-text-placeholder disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0 sm:px-6 sm:py-4 sm:text-base'
export const deleteButtonClass =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-transparent bg-status-error px-4 py-2.5 text-sm font-semibold text-white shadow-sm outline-none hover:bg-opacity-90 focus:bg-status-error focus:ring-2 focus:ring-status-error focus:ring-offset-2 active:bg-status-error active:ring-2 active:ring-status-error active:ring-offset-2 disabled:bg-opacity-50 disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0 sm:px-6 sm:py-4 sm:text-base'
export const inputValidClass =
  'text-sm px-4 py-3.5 block w-full rounded-md border border-components-fields shadow-sm placeholder:text-text-placeholder focus:border-cta-default focus:ring-cta-default sm:text-base disabled:border-transparent disabled:shadow-none disabled:bg-transparent'
export const inputErrorClass =
  'text-sm block w-full rounded-md border-red-300 pl-4 py-3.5 pr-9 text-red-900 placeholder-red-300 placeholder:text-text-placeholder focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-base'
export const comboBoxClass =
  'xs:text-sm w-full text-left rounded-md bg-white pl-4 py-3.5 pr-10 border border-components-fields shadow-sm placeholder:text-text-placeholder focus:border-cta-default focus:ring-cta-default sm:text-base disabled:border-transparent disabled:bg-transparent disabled:shadow-none'
export const comboBoxErrorClass =
  'xs:text-sm w-full text-left rounded-md border border-red-300 bg-white pl-4 py-3.5 pr-10 shadow-sm placeholder:text-text-placeholder focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm'
export const panelClass =
  'flex items-center p-4 xs:p-3 max-w-xs w-full overflow-hidden rounded-xl bg-white shadow gap-6 xs:gap-4 self-center cursor-pointer border-components-fields border-solid border'
export const panelClassActive =
  'border-2 border-cta-active bg-components-paleBlue'

// smaller buttons
export const tertiaryButtonClassSmall =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-components-fields bg-white px-4 py-2.5 text-base font-semibold text-text-primary shadow-sm outline-none hover:bg-components-fillBorders focus:bg-white focus:ring-2 focus:ring-cta-default focus:ring-offset-2 active:bg-white active:ring-2 active:ring-cta-default active:ring-offset-2 disabled:bg-components-fillBorders disabled:text-text-placeholder disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0'
export const primaryButtonClassSmall =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-transparent bg-cta-default px-4 py-2.5 text-base font-semibold text-white shadow-sm outline-none hover:bg-cta-active focus:bg-cta-default focus:ring-2 focus:ring-cta-default focus:ring-offset-2 active:bg-cta-default active:ring-2 active:ring-cta-default active:ring-offset-2 disabled:bg-cta-disabled disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0'
export const secondaryButtonClassSmall =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-cta-default bg-white px-4 py-2.5 text-base font-semibold text-cta-default shadow-sm outline-none hover:border-cta-active hover:bg-components-paleBlue hover:text-cta-active focus:border-cta-default focus:bg-components-paleBlue focus:text-cta-active focus:ring-2 focus:ring-cta-default focus:ring-offset-2 active:border-cta-default active:bg-components-paleBlue active:text-cta-active active:ring-2 active:ring-cta-default active:ring-offset-2 disabled:border-cta-disabled disabled:bg-white disabled:text-cta-disabled disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0'
export const deleteButtonClassSmall =
  'flex flex-row items-center justify-center gap-1 rounded-md border-2 border-transparent bg-status-error px-4 py-2.5 text-base font-semibold text-white shadow-sm outline-none hover:bg-opacity-90 focus:bg-status-error focus:ring-2 focus:ring-status-error focus:ring-offset-2 active:bg-status-error active:ring-2 active:ring-status-error active:ring-offset-2 disabled:bg-opacity-50 disabled:focus:ring-0 disabled:focus:ring-offset-0 disabled:active:ring-0 disabled:active:ring-offset-0'
