import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useRef,
} from 'react'
import {
  CheckIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ExclamationIcon } from '@heroicons/react/solid'

type ToastType = 'success' | 'warning' | 'error' | 'infoError'
interface Toast {
  type: ToastType
  id: string
  message: string
  buttonText: string
  buttonOnClick: () => void
}

interface ToastConfig {
  durationMs: number
  buttonText: string
  buttonOnClick: () => void
}

interface ToastTimeoutsMap {
  [toastId: string]: NodeJS.Timeout
}

const toastConfigMap = {
  success: {
    bg: 'bg-status-success',
    text: 'text-status-success',
    icon: (
      <CheckIcon
        className="h-5 w-5 flex-shrink-0 text-white"
        aria-hidden="true"
      />
    ),
  },
  error: {
    bg: 'bg-status-error',
    text: 'text-status-error',
    icon: (
      <XIcon className="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
    ),
  },
  warning: {
    bg: 'bg-status-warning',
    text: 'text-status-warning',
    icon: (
      <ExclamationIcon
        className="h-5 w-5 flex-shrink-0 text-white"
        aria-hidden="true"
      />
    ),
  },
  infoError: {
    bg: 'bg-status-error',
    text: 'text-status-error',
    icon: (
      <InformationCircleIcon
        className="h-5 w-5 flex-shrink-0 text-white"
        aria-hidden="true"
      />
    ),
  },
}

export const ToastContext = createContext(
  (
    type: string,
    message: string,
    config: Partial<ToastConfig> = {
      durationMs: 5000,
      buttonText: 'Ok',
      buttonOnClick: () => {},
    }
  ) => {
    // eslint-disable-next-line no-console
    console.log(type, message, config)
  }
)

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])
  const toastTimeouts = useRef<ToastTimeoutsMap>({})

  const addToast = (
    type: ToastType,
    message: string,
    config: Partial<ToastConfig> = {
      durationMs: 5000,
      buttonText: 'Ok',
      buttonOnClick: () => {},
    }
  ) => {
    const toastId = crypto.randomUUID()
    const newToast: Toast = {
      type,
      id: toastId,
      message,
      buttonText: config?.buttonText || 'Ok',
      buttonOnClick: config?.buttonOnClick || (() => {}),
    }

    setToasts((currentToasts) => [...currentToasts, newToast])

    const durationMs = config?.durationMs || 5000
    const timeoutId = setTimeout(() => {
      setToasts((currentToasts) =>
        currentToasts.filter((toast: Toast) => toast.id !== toastId)
      )
    }, durationMs)

    toastTimeouts.current = { ...toastTimeouts.current, [toastId]: timeoutId }
  }

  useEffect(() => {
    return () => {
      Object.values(toastTimeouts.current).forEach((t) => clearTimeout(t))
    }
  }, [])

  const removeToastById = (id: string) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast: Toast) => toast.id !== id)
    )
    clearTimeout(toastTimeouts.current[id])
    delete toastTimeouts.current[id]
  }

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="fixed top-3 left-1/2 z-[999] flex -translate-x-1/2 flex-col gap-2">
        {React.Children.toArray(
          toasts.map((toast: Toast) => (
            <div
              className={`relative rounded-md ${
                toastConfigMap[toast.type].bg
              } p-2`}
            >
              <div className="flex items-center gap-4">
                {/* Check icon */}
                {toastConfigMap[toast.type].icon}
                <p
                  className={`${
                    !toast.buttonText ? 'pr-4' : ''
                  } whitespace-pre-wrap text-sm font-semibold text-white`}
                >
                  {toast.message}
                </p>
                {/* Ok button */}
                {toast.buttonText && (
                  <button
                    className={`mr-4 rounded-md bg-white py-2 px-4 text-sm font-semibold ${
                      toastConfigMap[toast.type].text
                    }`}
                    onClick={() => {
                      toast.buttonOnClick()
                      removeToastById(toast.id)
                    }}
                    type="button"
                  >
                    {toast.buttonText}
                  </button>
                )}
                {/* Close icon */}
                <button
                  onClick={() => removeToastById(toast.id)}
                  type="button"
                  className="absolute top-0 right-0 p-1.5 text-white"
                >
                  <XIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </ToastContext.Provider>
  )
}

export const useToastContext = () => useContext(ToastContext)
