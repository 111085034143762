import React, { useEffect, useRef, useState } from 'react'
import { RefreshIcon, StarIcon } from '@heroicons/react/solid'
import {
  addMonths,
  subMonths,
  parse,
  format,
  differenceInMilliseconds,
} from 'date-fns'

import CenteredModal from '../../../components/CenteredModal'
import type { Star } from '../../../components/Modals/SessionRatingModal'
import { stars } from '../../../components/Modals/SessionRatingModal'
import {
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import { useToastContext } from '../../../contexts/ToastContext'
import { auth } from '../../../config/firebase'
import { useDashboardSurveySendAnswer } from '../../../mutations/surveys/DashboardSurveySendAnswer'
import { useSkipSurvey } from '../../../mutations/surveys/SkipSurvey'
import { DASHBOARD_RATING_SURVEY_ID } from '../../../constants/values'
import CrisisMessaging from '../../../components/CrisisMessaging'
import { useAuth } from '../../../contexts/AuthProvider'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'

const FEEDBACK_VERBIAGE_MAP: { [key: number]: string } = stars.reduce(
  (acc, { value }) => ({
    ...acc,
    [value]:
      value >= 4 ? 'Any additional feedback?' : 'What could be improved?',
  }),
  { 0: 'What could be improved?' }
)

const DashboardFeedbackModal: React.FC = () => {
  const { user, setUser } = useAuth()
  const dashboardSurveyLatest = user?.dashboardSurveyLatest
  const [open, setOpen] = useState<boolean>(false)

  const [ratingBeingHovered, setRatingBeingHovered] = useState<boolean>(false)

  const [tempRating, setTempRating] = useState<number>(0)
  const [tempRatingVerbiage, setTempRatingVerbiage] =
    useState<string>('Please rate')

  const [rating, setRating] = useState<number>(0)
  const [ratingVerbiage, setRatingVerbiage] = useState<string>('Please rate')

  const [feedback, setFeedback] = useState<string>('')

  const submitButtonRef = useRef()
  const addToast = useToastContext()

  const { mutate: skipSurvey, isLoading: isLoadingSkip } = useSkipSurvey()
  const { mutate: sendAnswerDashboardSurvey, isLoading: isLoadingSendAnswer } =
    useDashboardSurveySendAnswer()

  // Show on second time login
  // Show once every 3 months after if rating submitted
  // Show once every month if skipped
  useEffect(() => {
    if (!auth.currentUser) return

    if (
      Math.abs(
        differenceInMilliseconds(
          new Date(auth.currentUser.metadata.creationTime),
          new Date(auth.currentUser.metadata.lastSignInTime)
        )
      ) > 1000 &&
      !dashboardSurveyLatest
    ) {
      setOpen(true)
      return
    }

    if (dashboardSurveyLatest) {
      const latestAnswerDate = parse(
        dashboardSurveyLatest,
        'yyyy-MM-dd',
        new Date()
      )

      if (subMonths(new Date(), 3).valueOf() >= latestAnswerDate.valueOf()) {
        setOpen(true)
        return
      }
    }
  }, [dashboardSurveyLatest, auth.currentUser])

  const handleSkip = () => {
    skipSurvey(
      {
        surveyId: DASHBOARD_RATING_SURVEY_ID,
        customDate: format(
          addMonths(subMonths(new Date(), 3), 1),
          'yyyy-MM-dd'
        ),
      },
      {
        onSuccess: () => {
          setOpen(false)
          setUser({
            ...user,
            dashboardSurveyLatest: format(new Date(), 'yyyy-MM-dd'),
          })
        },
      }
    )
  }

  const handleSubmitFeedback = () => {
    sendAnswerDashboardSurvey(
      { answerInt: rating, answerText: feedback.trim() },
      {
        onSuccess: () => {
          setOpen(false)
          setUser({
            ...user,
            dashboardSurveyLatest: format(new Date(), 'yyyy-MM-dd'),
          })
          addToast('success', 'Thank you for your feedback!', {
            durationMs: 3000,
            buttonText: 'Ok',
          })

          trackMixPanel({
            eventName: MIXPANEL_EVENT.MODAL_VIEWED,
            properties: {
              name: 'Modal - Rating',
              type: 'Rate the platform',
              stars: rating,
            },
          })
        },
      }
    )
  }

  const isLoading = isLoadingSkip || isLoadingSendAnswer
  const isDisabledSubmit = !rating || isLoading
  const isDisabledSkip = isLoading

  return (
    <CenteredModal
      setOpen={setOpen}
      open={open}
      initialFocus={submitButtonRef}
      noClose
    >
      <div className="flex flex-col gap-4 sm:gap-6">
        {/* Title */}
        <h3 className="text-base font-semibold sm:text-lg">
          How are you liking the dashboard experience so far?
        </h3>

        {/* Rating */}
        <div className="flex flex-col items-center gap-2 sm:gap-2.5">
          <div
            className="star-rating flex flex-row-reverse gap-1"
            onMouseEnter={() => setRatingBeingHovered(true)}
            onMouseLeave={() => setRatingBeingHovered(false)}
          >
            {React.Children.toArray(
              stars.map(({ value, verbiage }: Star) => (
                <StarIcon
                  className={`${
                    (ratingBeingHovered ? tempRating : rating) >= value
                      ? 'text-status-warning'
                      : 'text-components-disabled'
                  } star h-11 w-11 cursor-pointer`}
                  onMouseOver={() => {
                    setTempRating(value)
                    setTempRatingVerbiage(verbiage)
                  }}
                  onClick={() => {
                    setRating(value)
                    setRatingVerbiage(verbiage)
                  }}
                />
              ))
            )}
          </div>
          <p className="text-sm sm:text-base">
            {ratingBeingHovered ? tempRatingVerbiage : ratingVerbiage}
          </p>
        </div>

        {/* Textual Feedback */}
        {Boolean(rating) && (
          <div className="flex flex-col gap-1">
            <label
              htmlFor="feedback"
              className="text-sm font-semibold sm:text-base"
            >
              {FEEDBACK_VERBIAGE_MAP[rating]}
            </label>
            <textarea
              name="feedback"
              value={feedback}
              onChange={({ currentTarget: { value } }) => setFeedback(value)}
              className={inputValidClass + 'flex-1'}
              placeholder="Enter your feedback here"
            />
          </div>
        )}

        {rating > 0 && <CrisisMessaging noIcon />}

        {/* Buttons */}
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-2 sm:gap-4">
            <button
              disabled={isDisabledSkip}
              type="button"
              className={tertiaryButtonClass}
              onClick={() => handleSkip()}
            >
              {isLoadingSkip ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-text-placeholder" />
                  Loading
                </>
              ) : (
                'Skip'
              )}
            </button>
            <button
              ref={submitButtonRef}
              className={primaryButtonClass}
              disabled={isDisabledSubmit}
              onClick={() => handleSubmitFeedback()}
            >
              {isLoadingSendAnswer ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-white" />
                  Loading
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </div>
    </CenteredModal>
  )
}

export default DashboardFeedbackModal
