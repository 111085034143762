import React, { useState } from 'react'

import { paginationToken } from '../../../helpers/utils'
import { ResourcePaginator } from '../components/ResourcesGrid'
import CourseGridLoader from './CourseGridLoader'
import CoursesGridDisplay from './CourseGridDisplay'
import { useGetCourses } from '../../../queries/courses/GetCourses'
import { useAuth } from '../../../contexts/AuthProvider'

const CoursesGrid: React.FC<{
  patientId: string
  forWhoTitle?: string
  lineColor?: string
  forCaregiver?: boolean
}> = ({ patientId, forWhoTitle, lineColor, forCaregiver = false }) => {
  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data: courseSection, isLoading: isLoadingCourseSection } =
    useGetCourses({
      user,
      patientId,
      forCaregiver,
      pageNumber: currentPage,
      paginationToken: paginationToken,
    })

  if (!courseSection?.resources?.length && !isLoadingCourseSection) return

  return (
    <>
      {/* Title */}
      {forWhoTitle && (
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-semibold capitalize text-text-secondary xs:text-sm">
            {forWhoTitle}
          </p>
          <div className={`border-b-4 ${lineColor}`} />
        </div>
      )}

      {isLoadingCourseSection ? (
        <CourseGridLoader loadingNumber={4} />
      ) : (
        <CoursesGridDisplay
          courses={courseSection?.resources}
          showProgressDetails
          patientId={forCaregiver ? null : patientId}
        />
      )}

      {/* Paginator */}
      <ResourcePaginator
        pageMapId={`courses${patientId}${forWhoTitle}`}
        currentPage={currentPage}
        resourceSection={courseSection}
        setCurrentPage={setCurrentPage}
        isLoadingResourceSection={isLoadingCourseSection}
      />
    </>
  )
}

export default CoursesGrid
