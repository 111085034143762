import { CalendarIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import type { UseFormSetValue, FieldError, Control } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  format,
  getDay,
  getMonth,
  isSameDay,
  isSameMonth,
  setMonth,
  startOfMonth,
  setYear,
  isFuture,
} from 'date-fns'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid'

import { inputErrorClass, inputValidClass } from '../constants/classConstants'
import { DAY_LABELS, MONTH_LABELS } from '../constants/values'
import type { DisplayDate } from '../types/Booking'
import { formatDefaultDateFormat, newDate } from '../helpers/generic'
import { usePatient } from '../contexts/PatientProvider'

const DatePicker: React.FC<{
  setValue: UseFormSetValue<any>
  control: Control<any>
  error?: FieldError
  fieldKey: string
  isDisabled?: boolean
  disableFutureDays?: boolean
}> = ({
  setValue,
  control,
  error,
  fieldKey,
  isDisabled = false,
  disableFutureDays = false,
}) => {
  const { patient } = usePatient()
  const initiallySelectedDate = newDate(new Date(), patient?.timeZone)

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [chooseYear, setChooseYear] = useState<boolean>(false)
  const [dates, setDates] = useState<DisplayDate[]>([])

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initiallySelectedDate
  )
  const [currentDateCursor, setCurrentDateCursor] = useState<Date>(
    initiallySelectedDate
  )

  const handleGoToPreviousMonth = () =>
    setCurrentDateCursor(addMonths(currentDateCursor, -1))

  const handleGoToNextMonth = () =>
    setCurrentDateCursor(addMonths(currentDateCursor, 1))

  const handleCalendarDayClick = (day: DisplayDate) => {
    if (day.isNotSelectable) return

    setSelectedDate(day.date)

    setValue(fieldKey, format(day.date, 'MM/dd/yyyy'), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
    setShowDatePicker(false)

    if (!day.isCurrentMonth) {
      const selectedMonth = getMonth(day.date)
      setCurrentDateCursor(setMonth(currentDateCursor, selectedMonth))
    }
  }

  const range = (start: number, end: number, step: number) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start) / step)).keys()),
      (x) => start + x * step
    )
  }

  const currentMonth = currentDateCursor.getMonth()
  const currentYear = currentDateCursor.getFullYear()
  const currentMonthLabel = MONTH_LABELS[currentMonth]
  const realtimeYear = newDate(new Date(), patient?.timeZone).getFullYear()

  useEffect(() => {
    let startDate = startOfMonth(currentDateCursor)
    const daysNeededForLastMonth = getDay(startDate)
    startDate = addDays(startDate, -daysNeededForLastMonth)

    // generate 6 full weeks to keep the formatting consistent
    const endDate = addDays(startDate, 34)

    const allDates: DisplayDate[] = eachDayOfInterval({
      start: startDate,
      end: endDate,
    }).map((date) => ({
      date,
      isCurrentMonth: isSameMonth(currentDateCursor, date),
      isSelected: selectedDate && isSameDay(selectedDate, date),
      isNotSelectable: disableFutureDays ? isFuture(date) : false,
    }))

    setDates(allDates)
  }, [currentDateCursor, selectedDate])

  return (
    <div
      className="relative w-full rounded-md"
      onBlur={() => setShowDatePicker(false)}
    >
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <CalendarIcon
            className="h-5 w-5 text-text-label"
            aria-hidden="true"
          />
        </div>
        <Controller
          control={control}
          name={fieldKey}
          rules={{ maxLength: 10 }}
          defaultValue=""
          render={({ field }) => (
            <input
              autoComplete="off"
              {...field}
              onChange={(e) => {
                field.onChange(formatDefaultDateFormat(e.target.value))
              }}
              maxLength={10}
              type="text"
              placeholder="MM/DD/YYYY"
              className={`!pl-10 ${error ? inputErrorClass : inputValidClass}`}
              onClick={() => {
                setShowDatePicker(true)
              }}
              disabled={isDisabled}
            />
          )}
        />
      </div>
      {error && (
        <p className="mt-1 text-sm text-status-error">{error.message}</p>
      )}
      {showDatePicker && (
        <div
          role="presentation"
          onMouseDown={(e) => e.preventDefault()}
          className="absolute top-[110%] z-10 flex w-full flex-col gap-2 self-stretch rounded-lg border border-components-fields bg-white px-2 pt-4 pb-2 sm:gap-6 sm:p-4"
        >
          <div className="flex flex-row justify-between">
            <div className="relative flex flex-row items-center justify-center gap-1">
              <p className="text-xs font-semibold text-text-primary sm:text-base">
                {currentMonthLabel}
              </p>
              <p className="text-xs font-semibold text-text-primary sm:text-base">
                {currentYear}
              </p>
              <button
                onClick={() => setChooseYear(!chooseYear)}
                className="text-cta-default"
                type="button"
              >
                {!chooseYear ? (
                  <ChevronDownIcon className="h-4 w-4 sm:h-5 sm:w-5" />
                ) : (
                  <ChevronUpIcon className="h-4 w-4 sm:h-5 sm:w-5" />
                )}
              </button>
              {chooseYear && (
                <div className="absolute top-[110%] z-20 flex h-52 flex-col overflow-y-scroll rounded-tl-md rounded-bl-md border border-components-fields bg-white p-1">
                  {React.Children.toArray(
                    Array.from(range(realtimeYear, 1900, -1)).map(
                      (year: number) => {
                        return (
                          <button
                            onClick={() => {
                              setChooseYear(false)
                              setCurrentDateCursor(
                                setYear(currentDateCursor, year)
                              )
                            }}
                            className="flex rounded-md py-2 px-6 hover:bg-components-fillBorders sm:text-sm xs:px-4 xs:text-xs"
                          >
                            {year}
                          </button>
                        )
                      }
                    )
                  )}
                </div>
              )}
            </div>
            <div className="flex gap-2">
              <button
                onClick={handleGoToPreviousMonth}
                className="text-cta-default"
                type="button"
              >
                <ChevronLeftIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              </button>
              <button
                onClick={handleGoToNextMonth}
                className="text-cta-default"
                type="button"
              >
                <ChevronRightIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-2.5">
            {React.Children.toArray(
              DAY_LABELS.map((day) => (
                <p className="p-2 text-center text-xs font-normal text-text-label sm:text-sm">
                  {day}
                </p>
              ))
            )}
            {React.Children.toArray(
              dates.map((day) => (
                <button
                  className={`rounded-full p-2 ${
                    day.isSelected
                      ? 'bg-components-paleBlue text-cta-default'
                      : ''
                  } ${day.isNotSelectable ? 'cursor-default' : ''}`}
                  onClick={() => handleCalendarDayClick(day)}
                  type="button"
                >
                  <p
                    className={`text-xs sm:text-sm ${
                      day.isNotSelectable || !day.isCurrentMonth
                        ? 'font-normal text-text-placeholder'
                        : day.isSelected
                        ? 'font-semibold text-cta-default'
                        : 'font-normal text-text-primary'
                    }`}
                  >
                    {format(day.date, 'd')}
                  </p>
                </button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DatePicker
