import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type {
  ResourceSection,
  ResourceStatus,
  ResourceTopic,
} from '../../types/Resources'
import { toCourse } from '../../helpers/transformers'
import type { User } from '../../types/User'

interface GetFilteredCourses {
  user: User
  selectedTopics: ResourceTopic[]
  selectedStatuses: ResourceStatus[]
  mainSearchValue: string
  pageNumber?: number
  isEnabled: boolean
}

export const useGetFilteredCourses = (
  params: GetFilteredCourses,
  deps = params
) =>
  useQuery(
    ['getFilteredCourses', deps],
    async () => getFilteredCourses(params),
    {
      enabled:
        params.isEnabled &&
        (params.mainSearchValue?.length > 2 ||
          params.selectedTopics.length > 0 ||
          params.selectedStatuses.length > 0),
    }
  )

const getFilteredCourses = async ({
  user,
  selectedTopics,
  selectedStatuses,
  mainSearchValue,
  pageNumber = 0,
}: GetFilteredCourses): Promise<ResourceSection> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/courses/search?pageSize=6&page=${pageNumber}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userCourseStatuses: selectedStatuses.map((f) => f.id.toUpperCase()),
          searchString: mainSearchValue,
          topics: selectedTopics.map((t) => t.key),
        }),
      }
    ).then((response) => response.json())

    return {
      resources: response.courses.map((rawCourse: any) =>
        toCourse({
          ...rawCourse,
          courseProgresses: rawCourse.courseProgresses.map((cp: any) => ({
            ...cp,
            patient: user.roster.find((p) => p.id === cp.patientId),
          })),
        })
      ),
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }
  } catch (error) {
    return null
  }
}
