import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface CompleteModuleProps {
  courseId: string
  patientId: string
  moduleId: string
}

const markVideoAsComplete = async ({
  courseId,
  patientId,
  moduleId,
}: CompleteModuleProps) => {
  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
    }v1/courses/complete-module`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        courseId,
        patientId,
        moduleId,
      }),
    }
  )
}

export const useMarkVideoAsComplete = () =>
  useMutation((params: CompleteModuleProps) => markVideoAsComplete(params))
