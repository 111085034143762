import { useEffect, useState } from 'react'

import {
  enableCardExpiringFlag,
  isCreditCardExpired,
  isLessThanOneDayRemainingBeforeExpiration,
  isLessThanOneMonthRemainingBeforeExpiration,
  isLessThanOneWeekRemainingBeforeExpiration,
  isLessThanTwoMonthsRemainingBeforeExpiration,
} from '../../../helpers/payments'
import CardExpiredModal from '../../../components/Modals/CardExpiredModal'
import CardExpiringModal from '../../../components/Modals/CardExpiringModal'
import { useAuth } from '../../../contexts/AuthProvider'

const CardExpiredOrExpiringModalHandler = () => {
  const { user } = useAuth()
  const paymentMethod = user.paymentMethod

  const [openExpiredModal, setOpenExpiredModal] = useState<boolean>(false)
  const [openExpiringModal, setOpenExpiringModal] = useState<boolean>(false)

  const [canDoChecks, setCanDoChecks] = useState<boolean>(false)

  // pre-checks for expired or expiring card modal
  useEffect(() => {
    if (!user || !paymentMethod) return

    try {
      const dotcomCardExpiring = JSON.parse(
        localStorage.getItem('DOTCOM_CARD_EXPIRING')
      )

      if (
        isCreditCardExpired(
          paymentMethod.cardExpirationMonth,
          paymentMethod.cardExpirationYear
        )
      ) {
        if (dotcomCardExpiring?.[user.data.id]?.['0D'] == 2) {
          return
        } else {
          enableCardExpiringFlag(user.data.id, '0D')
          setCanDoChecks(true)
          return
        }
      }

      const oneDayOrLessRemaining = isLessThanOneDayRemainingBeforeExpiration(
        paymentMethod.cardExpirationMonth,
        paymentMethod.cardExpirationYear
      )

      const oneWeekOrLessRemaining = isLessThanOneWeekRemainingBeforeExpiration(
        paymentMethod.cardExpirationMonth,
        paymentMethod.cardExpirationYear
      )

      const oneMonthOrLessRemaining =
        isLessThanOneMonthRemainingBeforeExpiration(
          paymentMethod.cardExpirationMonth,
          paymentMethod.cardExpirationYear
        )

      const twoMonthsOrLessRemaining =
        isLessThanTwoMonthsRemainingBeforeExpiration(
          paymentMethod.cardExpirationMonth,
          paymentMethod.cardExpirationYear
        )

      if (oneDayOrLessRemaining) {
        if (dotcomCardExpiring?.[user.data.id]?.['1D'] == 2) {
          return
        }
        enableCardExpiringFlag(user.data.id, '1D')
      } else if (oneWeekOrLessRemaining) {
        if (dotcomCardExpiring?.[user.data.id]?.['1W'] == 2) {
          return
        }
        enableCardExpiringFlag(user.data.id, '1W')
      } else if (oneMonthOrLessRemaining) {
        if (dotcomCardExpiring?.[user.data.id]?.['1M'] == 2) {
          return
        }
        enableCardExpiringFlag(user.data.id, '1M')
      } else if (twoMonthsOrLessRemaining) {
        if (dotcomCardExpiring?.[user.data.id]?.['2M'] == 2) {
          return
        }
        enableCardExpiringFlag(user.data.id, '2M')
      }

      setCanDoChecks(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [user, paymentMethod])

  useEffect(() => {
    if (!canDoChecks || !user || !paymentMethod) return

    const dotcomCardExpiring = JSON.parse(
      localStorage.getItem('DOTCOM_CARD_EXPIRING')
    )

    // expired
    if (
      dotcomCardExpiring?.[user.data.id] &&
      dotcomCardExpiring[user.data.id]['0D'] == 1
    ) {
      setOpenExpiredModal(true)
      return
    }

    // will expire soon
    if (
      dotcomCardExpiring?.[user.data.id] &&
      Object.values(dotcomCardExpiring[user.data.id]).some((v) => v == 1)
    ) {
      setOpenExpiringModal(true)
      return
    }
  }, [canDoChecks, user, paymentMethod])

  if (!paymentMethod) return <></>

  return (
    <>
      <CardExpiredModal open={openExpiredModal} setOpen={setOpenExpiredModal} />
      <CardExpiringModal
        open={openExpiringModal}
        setOpen={setOpenExpiringModal}
      />
    </>
  )
}

export default CardExpiredOrExpiringModalHandler
