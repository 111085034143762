import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { useCompleteTele } from '../../mutations/outcomes/PostTeleAppropriateness'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { usePatient } from '../../contexts/PatientProvider'
import { useAuth } from '../../contexts/AuthProvider'
import GlobalLoader from '../../components/GlobalLoader'
import { useGetTeleForm } from '../../queries/surveys/GetTeleForm'

type SessionRequirements = {
  reliableInternet: boolean
  reliableCamera: boolean
  privateLocation: boolean
  noneOfTheAbove: boolean
}

export type TeleAppropriatenessForm = {
  sessionRequirements: SessionRequirements
  adultSupervision: string
}

type SessionRequirementsKeys =
  | 'sessionRequirements.reliableInternet'
  | 'sessionRequirements.reliableCamera'
  | 'sessionRequirements.privateLocation'
  | 'sessionRequirements.noneOfTheAbove'

const sessionRequirements: { id: SessionRequirementsKeys; label: string }[] = [
  {
    id: 'sessionRequirements.reliableInternet',
    label: 'Reliable internet access',
  },
  {
    id: 'sessionRequirements.reliableCamera',
    label: 'Reliable device with camera and microphone',
  },
  {
    id: 'sessionRequirements.privateLocation',
    label: 'Quiet/Private Location',
  },
  {
    id: 'sessionRequirements.noneOfTheAbove',
    label: 'None of the above',
  },
]

const adultSupervisionOptions: string[] = ['Yes', 'No', 'Not Applicable']

const formSchema = (forSelf: boolean) =>
  Yup.object().shape({
    sessionRequirements: Yup.object()
      .shape({
        reliableInternet: Yup.boolean(),
        reliableCamera: Yup.boolean(),
        privateLocation: Yup.boolean(),
        noneOfTheAbove: Yup.boolean(),
      })
      .test('oneChecked', 'At least one checkbox must be checked', (value) =>
        Object.values(value).some((v) => v === true)
      ),
    adultSupervision: forSelf
      ? Yup.string()
      : Yup.string().required('This field is required'),
  })

const TeleAppropriateness: React.FC = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location?.state
  const currentServiceLine = useCurrentServiceLine()
  const { providerId } = useParams()
  const { patient } = usePatient()
  const forSelf = patient?.relationship?.key === 'myself'

  const { mutate: callCompleteTele, isLoading: isLoadingTele } =
    useCompleteTele()
  const { data: isTeleSigned, isLoading: isLoadingGetTele } = useGetTeleForm({
    personId: patient?.personId,
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<TeleAppropriatenessForm>({
    mode: 'all',
    resolver: yupResolver(formSchema(forSelf)),
  })

  const noneOfTheAbove = watch('sessionRequirements.noneOfTheAbove')

  const onSubmit = (data: TeleAppropriatenessForm) =>
    callCompleteTele(
      {
        results: data,
        personId: patient.personId,
        firstName: patient.firstName,
        lastName: patient.lastName,
        email: user.data.email,
      },
      { onSettled: handleNavigateNext }
    )

  const handleNavigateNext = () => {
    const bookingUrl = `/booking/${currentServiceLine.url}/providers/${providerId}/confirmation`
    navigate(locationState?.redirectAfter || bookingUrl, {
      state: {
        ...locationState,
      },
    })
  }

  useEffect(() => {
    if (isLoadingGetTele) return

    if (isTeleSigned) handleNavigateNext()
  }, [isLoadingGetTele, isTeleSigned])

  const isDisabledNext = isLoadingTele || !isValid

  if (isLoadingGetTele || isTeleSigned) return <GlobalLoader />

  return (
    <div className="max-w-5xl items-center text-center">
      <div className="flex w-full max-w-3xl flex-col items-center gap-4 sm:gap-6">
        <p className="text-base font-medium sm:text-2xl">
          Just a few questions before we confirm your booking
        </p>
        <p className="text-sm font-normal sm:text-base">
          Don't worry, you only need to do this once!
        </p>
      </div>

      <div className="flex max-w-md flex-col gap-4">
        <div>
          <p className="text-left text-base font-semibold">
            Please confirm that {forSelf ? 'you' : patient.firstName} will have
            the following for the sessions
            <span className="text-status-error">*</span>
          </p>
          <p className="text-left text-sm">Please select all that apply</p>
        </div>
        {React.Children.toArray(
          sessionRequirements.map((requirement) => {
            if (
              noneOfTheAbove &&
              requirement.id !== 'sessionRequirements.noneOfTheAbove'
            ) {
              setValue(requirement.id, false)
            }
            return (
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-cta-default focus:ring-cta-disabled"
                  disabled={
                    noneOfTheAbove &&
                    requirement.id !== 'sessionRequirements.noneOfTheAbove'
                  }
                  {...register(requirement.id)}
                />
                <p
                  className={`text-left ${
                    noneOfTheAbove &&
                    requirement.id !== 'sessionRequirements.noneOfTheAbove'
                      ? 'text-components-disabled'
                      : ''
                  }`}
                >
                  {requirement.label}
                </p>
              </div>
            )
          })
        )}

        {!forSelf && (
          <>
            <p className="text-left text-base font-semibold">
              If {patient.firstName} needs assistance during the session, will a
              responsible adult be available to provide support?
              <span className="text-status-error">*</span>
            </p>
            {React.Children.toArray(
              adultSupervisionOptions.map((option) => (
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="adultSupervision"
                    value={option}
                    className="h-4 w-4 rounded-full border-gray-300 text-cta-default focus:ring-cta-disabled"
                    {...register('adultSupervision')}
                  />
                  <p className="text-left">{option}</p>
                </div>
              ))
            )}
          </>
        )}
      </div>

      <div className="flex w-full max-w-lg flex-col items-center gap-4 pt-8 sm:gap-12 sm:pt-0">
        <div className="flex w-full flex-row gap-2 sm:gap-4">
          <button
            disabled={isLoadingTele}
            onClick={() => navigate(-1)}
            className={`${tertiaryButtonClass} w-full`}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            <p>Back</p>
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={isDisabledNext}
            className={`${primaryButtonClass} w-full`}
          >
            {isLoadingTele ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                <p>Loading</p>
              </>
            ) : (
              <>
                <p>Next</p>
                <ChevronRightIcon className="h-5 w-5" />
              </>
            )}
          </button>
        </div>
        {!isLoadingTele && <SkipAndComeBackLater />}
      </div>
    </div>
  )
}

export default TeleAppropriateness
