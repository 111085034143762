import {
  ChevronDownIcon,
  ChevronUpIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { PencilIcon } from '@heroicons/react/outline'
import { useLocation, useNavigate } from 'react-router'

import { useAuth } from '../../contexts/AuthProvider'
import { tertiaryButtonClassSmall } from '../../constants/classConstants'
import { useToastContext } from '../../contexts/ToastContext'
import ManagePermissionModal from '../../components/Modals/ManagePermissionModal'
import type { Patient } from '../../types/Patient'
import getAvatarSrc from '../../helpers/getAvatarSrc'

const PermissionSettings: React.FC = () => {
  const addToast = useToastContext()
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const patientsForDisplay = user?.roster?.filter(
    (p) => p?.relationship?.key !== 'myself' && p?.age >= 12
  )

  const [openManageModel, setOpenManageModel] = useState<boolean>(false)
  const [currentPatient, setCurrentPatient] = useState(null)

  useEffect(() => {
    if (location?.state?.consentFormSuccessful)
      addToast('success', 'Separate Login invite sent successfully!')
    else if (location?.state?.managePermissionChanged)
      addToast('success', 'Separate Login settings updated successfully!')
  }, [])

  const getSeparateLoginStatus = (patient: Partial<Patient>) => {
    if (patient?.invitedForSeparateLogin) return 'Invited'
    else if (patient?.hasSeparateLogin) return 'Enabled'
    else return 'Disabled'
  }

  return (
    <>
      <div className="mb-auto mt-10 flex w-full flex-col gap-6 pt-1 pb-6 text-text-primary sm:mt-24 sm:w-2/3">
        <h1 className="pl-4 text-lg font-semibold sm:pl-0 sm:text-2xl">
          Permission Settings
        </h1>
        <SeparateLoginInfo />

        {patientsForDisplay?.length > 0 ? (
          React.Children.toArray(
            patientsForDisplay?.map((patient) => (
              <div className="flex justify-between rounded-lg border border-components-fields bg-white p-4 shadow-lg sm:items-center xs:flex-col xs:gap-4 xs:rounded-none xs:border-none xs:p-4">
                <PatientAvatarAndName patient={patient} />

                <button
                  className={`${tertiaryButtonClassSmall} w-full sm:max-w-xs`}
                  onClick={
                    patient?.hasSeparateLogin || patient.invitedForSeparateLogin
                      ? () => {
                          setCurrentPatient(patient)
                          setOpenManageModel(true)
                        }
                      : () => navigate(`/create-permission/${patient.id}`)
                  }
                >
                  <PencilIcon className="h-4 w-4 text-text-primary" />
                  Separate Login:{' '}
                  <span
                    className={
                      patient.hasSeparateLogin
                        ? 'text-status-success'
                        : 'text-text-label'
                    }
                  >
                    {getSeparateLoginStatus(patient)}
                  </span>
                </button>
              </div>
            ))
          )
        ) : (
          <p className="text-base">No patients to display.</p>
        )}
      </div>

      <ManagePermissionModal
        open={openManageModel}
        setOpen={setOpenManageModel}
        patient={currentPatient}
      />
    </>
  )
}

const PatientAvatarAndName: React.FC<{ patient: Partial<Patient> }> = ({
  patient,
}) => {
  const { user } = useAuth()

  return (
    <div className="flex items-center gap-4">
      {/* Image */}
      <img
        src={getAvatarSrc(user, patient)}
        alt="patient-avatar"
        className="h-16 w-16 xs:h-11 xs:w-11"
      />

      {/* Name, etc. */}
      <div className="flex flex-col sm:gap-6 xs:gap-2">
        <p className="font-semibold text-text-primary sm:text-lg xs:text-base">
          {patient.preferredName || `${patient.firstName} ${patient.lastName}`}
        </p>
      </div>
    </div>
  )
}

const SeparateLoginInfo = () => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div className="mb-2 flex flex-col gap-6">
      <div className="gap-4 rounded-lg bg-components-fillBorders p-4 xs:rounded-none">
        <button
          className="flex w-full items-center justify-between"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex items-center gap-1">
            <QuestionMarkCircleIcon className="h-5 w-5 text-text-primary" />
            <p className="text-base font-semibold xs:text-sm">
              What is a Separate Login?
            </p>
          </div>
          {expanded ? (
            <ChevronUpIcon className="h-6 w-6 text-cta-default" />
          ) : (
            <ChevronDownIcon className="h-6 w-6 text-cta-default" />
          )}
        </button>

        {expanded && (
          <p className="ml-5 mt-4 text-base xs:text-sm">
            <strong>Separate Login</strong> allows your minor to access their
            account using their own email and password. Having a separate login
            will allow them to manage their profile and navigate the experience
            independently. This is only available for those who are 12 and
            older.
          </p>
        )}
      </div>
    </div>
  )
}

export { PermissionSettings, PatientAvatarAndName }
