import React, { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import { useGetResources } from '../../../queries/resources/GetResources'
import ResourceGridLoader from './ResourceGridLoader'
import ResourcesGridDisplay from './ResourceGridDisplay'
import { paginationToken } from '../../../helpers/utils'
import { useResourceGridContext } from '../../../contexts/ResourceGridContext'
import type { ResourceSection } from '../../../types/Resources'
import type { ResumeCourseSection } from '../../../types/Courses'

const ResourcesGrid: React.FC<{
  patientId: string
  forWhoTitle?: string
  lineColor?: string
  forCaregiver?: boolean
}> = ({ patientId, forWhoTitle, lineColor, forCaregiver = false }) => {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data: resourceSection, isLoading: isLoadingResourceSection } =
    useGetResources({
      patientId,
      forCaregiver,
      pageNumber: currentPage,
      paginationToken: paginationToken,
    })

  if (!resourceSection?.resources?.length && !isLoadingResourceSection) return

  return (
    <>
      {/* Title */}
      {forWhoTitle && (
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-semibold capitalize text-text-primary xs:text-sm">
            {forWhoTitle}
          </p>
          <div className={`border-b-4 ${lineColor}`} />
        </div>
      )}

      {isLoadingResourceSection ? (
        <ResourceGridLoader />
      ) : (
        <ResourcesGridDisplay
          resources={resourceSection?.resources}
          patientId={forCaregiver ? null : patientId}
        />
      )}

      <ResourcePaginator
        pageMapId={`resources${patientId}${forWhoTitle}`}
        currentPage={currentPage}
        resourceSection={resourceSection}
        setCurrentPage={setCurrentPage}
        isLoadingResourceSection={isLoadingResourceSection}
      />
    </>
  )
}

export default ResourcesGrid

export const ResourcePaginator: React.FC<{
  pageMapId: string
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  resourceSection: ResourceSection | ResumeCourseSection
  isLoadingResourceSection: boolean
  altLineColor?: boolean
}> = ({
  pageMapId,
  currentPage,
  setCurrentPage,
  resourceSection,
  isLoadingResourceSection,
  altLineColor,
}) => {
  const { mapGridToPage, setMapGridToPage } = useResourceGridContext()

  const changePage = (newPage: number) => {
    setCurrentPage(newPage)
    setMapGridToPage({ ...mapGridToPage, [pageMapId]: newPage })
  }

  useEffect(() => {
    if (isLoadingResourceSection) return

    setCurrentPage(mapGridToPage[pageMapId] || currentPage)
  }, [isLoadingResourceSection])

  return (
    <div
      className={`flex w-full items-center justify-between gap-8 border-t-2 ${
        altLineColor ? 'border-t-white' : ''
      } sm:px-6 xs:gap-2`}
    >
      {/* Previous */}
      <button
        className="flex gap-4 text-sm font-semibold disabled:text-text-placeholder"
        disabled={currentPage === 0 || isLoadingResourceSection}
        onClick={() => changePage(currentPage - 1)}
      >
        <ChevronLeftIcon className="h-5 w-5" />{' '}
        <span className="xs:hidden">Previous</span>
      </button>

      {/* Pages */}
      <div className="-mt-0.5 flex">
        {React.Children.toArray(
          [
            ...Array(
              isLoadingResourceSection
                ? currentPage
                : resourceSection?.totalPages
            ),
          ].map((_, page) => {
            return (
              <button
                className={`flex w-8 items-center justify-center border-t-2 border-t-transparent pt-4 font-semibold text-text-label xs:pt-2 ${
                  page === currentPage &&
                  '!border-t-cta-default text-cta-default'
                } ${
                  (page <=
                    (currentPage >= resourceSection?.totalPages - 2
                      ? resourceSection?.totalPages - 5
                      : currentPage - 3) ||
                    page >= (currentPage <= 2 ? 5 : currentPage + 3)) &&
                  'hidden'
                } ${
                  isLoadingResourceSection ? 'text-components-disabled' : ''
                }`}
                disabled={isLoadingResourceSection}
                onClick={() => changePage(page)}
              >
                {page + 1}
              </button>
            )
          })
        )}
      </div>

      {/* Next */}
      <button
        className="flex gap-4 text-sm font-semibold disabled:text-text-placeholder"
        disabled={
          currentPage >= resourceSection?.totalPages - 1 ||
          isLoadingResourceSection
        }
        onClick={() => changePage(currentPage + 1)}
      >
        <span className="xs:hidden">Next</span>{' '}
        <ChevronRightIcon className="h-5 w-5" />
      </button>
    </div>
  )
}
