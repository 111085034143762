import { useMutation } from '@tanstack/react-query'
import { parseISO } from 'date-fns'

import { auth } from '../../config/firebase'
import type { Patient } from '../../types/Patient'

interface SaveAppointmentProps {
  providerId: string
  clientId: string
  patient: Partial<Patient>
  startTime: string
  endTime: string
}

const saveAppointment = async ({
  providerId,
  clientId,
  patient,
  startTime,
  endTime,
}: SaveAppointmentProps): Promise<any> => {
  const token: string = await auth.currentUser.getIdToken()

  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_BFF_URL}save_appointment`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        providerId,
        clientId,
        patientId: patient.id,
        locationId: patient.locationId,
        startTime: parseISO(startTime).getTime(),
        endTime: parseISO(endTime).getTime(),
      }),
    }
  ).then((response) => response.json())

  return result
}

export const useSaveAppointment = () =>
  useMutation((params: SaveAppointmentProps) => saveAppointment(params))
