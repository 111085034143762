import { useEffect, useRef, useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import type { ProviderBookingLocationState } from '../../types/Booking'
import type { CreditCardRefType } from '../../components/CreditCardInput'
import CreditCardInput from '../../components/CreditCardInput'
import { useToastContext } from '../../contexts/ToastContext'
import { CARD_SAVING_ERROR_TEXT } from '../../constants/values'
import { getCardBrandText } from '../../helpers/utils'
import { useAuth } from '../../contexts/AuthProvider'

type CARD_VALIDITY_STATUS = 'expired' | 'willExpireBeforeSession'

const CARD_VALIDITY_STATUS_TEXT = (
  cardBrand: string
): Record<CARD_VALIDITY_STATUS, string> => ({
  expired: `It looks like your ${getCardBrandText(cardBrand)} is expired.`,
  willExpireBeforeSession: `It looks like your ${getCardBrandText(
    cardBrand
  )} will expire before this session date.`,
})

const ConfirmCardInfo = () => {
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const cardStatus: CARD_VALIDITY_STATUS = searchParams.get(
    'cardStatus'
  ) as CARD_VALIDITY_STATUS
  const paymentMethod = user.paymentMethod
  const addToast = useToastContext()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as ProviderBookingLocationState

  const [isCreditCardError, setIsCreditCardError] = useState<boolean>(false)
  const [isCreditCardSubmitReady, setIsCreditCardSubmitReady] =
    useState<boolean>(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState<boolean>(false)
  const [isCreditCardComplete, setIsCreditCardComplete] =
    useState<boolean>(false)
  const [readyToNavigate, setReadyToNavigate] = useState<boolean>(false)

  const creditCardRef: { current: CreditCardRefType } = useRef()

  const isLoading = isLoadingCreditCard || !isCreditCardSubmitReady
  const isDisabledNext =
    !isCreditCardComplete || isLoading || !isCreditCardSubmitReady
  const isDisabledBack = isLoadingCreditCard

  // navigate to next screen once card saving requests are successful
  useEffect(() => {
    if (readyToNavigate)
      navigate('../confirmation', {
        state: locationState,
      })
  }, [readyToNavigate])

  const handleNext = async () => {
    if (isDisabledNext) return

    try {
      await creditCardRef.current.submitCreditCard()
      setReadyToNavigate(true)
    } catch (error) {
      addToast('error', 'Something went wrong 🫤')
    }
  }

  return (
    <div className="max-w-lg text-center">
      <div className="flex flex-col gap-6">
        <h2 className="text-lg font-semibold sm:text-2xl sm:font-medium">
          Let's confirm your billing information
        </h2>
        <p className="text-sm font-normal !leading-5 sm:text-base">
          {CARD_VALIDITY_STATUS_TEXT(paymentMethod?.cardBrand)[cardStatus] ||
            CARD_VALIDITY_STATUS_TEXT(paymentMethod?.cardBrand)['invalid']}
          <br />
          Please enter the new card information below.
        </p>
      </div>
      <div className="mx-auto flex w-full max-w-md flex-col gap-2">
        <CreditCardInput
          ref={creditCardRef}
          setIsComplete={setIsCreditCardComplete}
          setIsLoading={setIsLoadingCreditCard}
          setIsSubmitReady={setIsCreditCardSubmitReady}
          setIsError={setIsCreditCardError}
        />
        {isCreditCardError && (
          <p className="text-sm text-status-error">{CARD_SAVING_ERROR_TEXT}</p>
        )}
      </div>
      <div className="flex flex-col gap-6 sm:gap-12">
        <div className="flex gap-2 sm:gap-4">
          <button
            type="button"
            className={`w-1/2 ${tertiaryButtonClass}`}
            disabled={isDisabledBack}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </button>
          <button
            type="button"
            className={`w-1/2 ${primaryButtonClass}`}
            disabled={isDisabledNext}
            onClick={() => handleNext()}
          >
            {isLoading ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                Loading
              </>
            ) : (
              <>
                Next
                <ChevronRightIcon className="h-5 w-5" />
              </>
            )}
          </button>
        </div>
        <SkipAndComeBackLater />
      </div>
    </div>
  )
}

export default ConfirmCardInfo
