import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { Resource } from '../../types/Resources'
import { toResource } from '../../helpers/transformers'

interface GetRecommendationsProps {
  resourceId: string
  patientId: string
}

export const useGetRecommendations = (
  params: GetRecommendationsProps,
  deps = params
) =>
  useQuery(['getRecommendations', deps], async () => getRecommendations(params))

const getRecommendations = async ({
  resourceId,
  patientId,
}: GetRecommendationsProps): Promise<Resource[]> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/resources/${resourceId}/recommendations${
        patientId ? `?patientId=${patientId}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return response.map((rawResource: any) => toResource(rawResource))
  } catch (error) {
    return null
  }
}
