import React from 'react'

import type { Resource } from '../../../types/Resources'
import OneResource from './OneResource'

const ResourcesGridDisplay: React.FC<{
  resources: Partial<Resource[]>
  noPadding?: boolean
  onClick?: (...args: any[]) => any
  patientId?: string
}> = ({ resources, noPadding, onClick = () => {}, patientId }) => {
  if (!resources?.length) return <p>No resources.</p>

  return (
    <div
      className={`${
        noPadding ? '' : 'sm:px-6'
      } grid w-full grid-cols-3 gap-6 xs:grid-cols-2 xs:gap-4`}
    >
      {React.Children.toArray(
        resources?.map((resource: Resource) => (
          <OneResource
            resource={resource}
            key={resource.id}
            onClick={onClick}
            patientId={patientId}
          />
        ))
      )}
    </div>
  )
}

export default ResourcesGridDisplay
