import ADULT from '../assets/avatars/User.svg'
import CHILD1 from '../assets/avatars/Child1.svg'
import CHILD2 from '../assets/avatars/Child2.svg'
import CHILD3 from '../assets/avatars/Child3.svg'
import CHILD4 from '../assets/avatars/Child4.svg'
import CHILD5 from '../assets/avatars/Child5.svg'
import type { Patient } from '../types/Patient'
import type { User } from '../types/User'

const picMap = {
  0: CHILD1,
  1: CHILD2,
  2: CHILD3,
  3: CHILD4,
  4: CHILD5,
}

const getAvatarSrc = (user: User, patient?: Partial<Patient>): string => {
  if (!user?.roster?.length || !patient) return ADULT

  return patient?.relationship?.key === 'myself'
    ? ADULT
    : picMap[user.roster.findIndex((p) => p.id === patient.id) % 5]
}

export default getAvatarSrc
