import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface RateSessionProps {
  sessionId: string
  rating: number
  feedback: string
}

const rateSession = async ({
  sessionId,
  rating,
  feedback,
}: RateSessionProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/appointments/${sessionId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ rating, feedback }),
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useRateSession = () =>
  useMutation((params: RateSessionProps) => rateSession(params))
