import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

export type Question = {
  id: string
  text: string
  quizName: string
  quizId: string
  followUpSurveyId: string
  answer?: number
}

type GetQuestionsParams = {
  quizIds: string[]
}

export const useGetQuestions = (params: GetQuestionsParams) =>
  useQuery(
    ['getQuestions', params.quizIds],
    async () => getQuestions(params.quizIds),
    { enabled: Boolean(params?.quizIds?.length) }
  )

export const fetchQuestions = async (quizIds: string[]) => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
    }/v1/survey/getQuestions?${quizIds
      ?.map((id) => `surveyIds=${id}`)
      ?.join('&')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return result || []
}

const getQuestions = async (quizIds: string[]): Promise<Question[]> => {
  try {
    const result = await fetchQuestions(quizIds)

    return result.flatMap((quiz: any) =>
      quiz?.surveyQuestions?.map((q: any) => ({
        id: q.id,
        text: q.text,
        quizName: quiz.name,
        quizId: quiz.id,
        followUpSurveyId: quiz.followUpSurveyId,
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
