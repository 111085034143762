import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { ResourceTopic } from '../../types/Resources'

interface BackendTopicFormat {
  id: string
  key: string
  section: string
  value: string
}

export const convertBackendTopicForFrontend = (
  backendTopics: BackendTopicFormat[]
): ResourceTopic[] =>
  backendTopics.map((topic: BackendTopicFormat) => {
    if (topic?.key.includes('OTHER')) {
      return {
        key: topic.key,
        name: topic.value,
        section: topic.section,
        id: topic.id,
      }
    }

    const topicValue = topic?.value || topic.key
    const words = topicValue.toLowerCase().split('_')

    for (let i = 0; i < words?.length; i++) {
      if (words[i] === 'and') {
        words[i] = '&'
      } else {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
      }
    }

    return {
      key: topicValue,
      name: words.join(' '),
      section: topic.section,
      id: topic.id,
    }
  })

export const useGetResourceTopics = () =>
  useQuery(['getResourceTopics'], async () => getResourceTopics())

const getResourceTopics = async (): Promise<ResourceTopic[]> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const backendTopics: any = await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL}v1/topics/extended`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    const frontendTopics: ResourceTopic[] =
      convertBackendTopicForFrontend(backendTopics)

    frontendTopics.sort((a: ResourceTopic, b: ResourceTopic) =>
      a?.name?.localeCompare(b?.name)
    )

    const sections = [
      ...new Set(frontendTopics?.map((topic: ResourceTopic) => topic.section)),
    ]

    return [
      ...frontendTopics,
      ...sections.map((sectionTitle: string) => ({
        key: 'OTHER',
        name: 'Other',
        section: sectionTitle,
      })),
    ]
  } catch (error) {
    return []
  }
}
