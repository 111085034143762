import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface GetPatientAccessorsProps {
  patientId: string
}

export const useGetPatientAccessors = (
  params: GetPatientAccessorsProps,
  deps = params
) =>
  useQuery(['getPatientAccessors', deps], async () =>
    getPatientAccessors(params)
  )

const getPatientAccessors = async ({ patientId }: GetPatientAccessorsProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const result: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
    }v1/patients/${patientId}/accessors`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return result.map((accessor: any) => ({
    name: `${accessor?.firstName} ${accessor?.lastName}`,
    id: accessor?.userId,
  }))
}
