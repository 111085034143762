import { useState, useEffect } from 'react'

import { useAuth } from '../contexts/AuthProvider'
import type { Patient } from '../types/Patient'

const localStorageKey = 'DOTCOM_NOT_ALLOWED_SSL_MESSAGING'

const useGiveNotAllowedToBookPatientsForMessaging = () => {
  const { user } = useAuth()

  const [patientsNotAllowed, setPatientsNotAllowed] = useState<
    (Patient | Partial<Patient>)[]
  >([])

  useEffect(() => {
    if (!user?.roster?.length) return

    const dotcomNotAllowedSSLMessaging =
      JSON.parse(localStorage.getItem(localStorageKey)) || {}

    const tempPatientsNotAllowed: (Patient | Partial<Patient>)[] = []

    user.roster.forEach((p) => {
      // is patient on the list? YES.
      if (dotcomNotAllowedSSLMessaging[p.id]) {
        // patient on the list but allowed to book? take patient out from the list.
        if (p.allowedToBook) {
          delete dotcomNotAllowedSSLMessaging[p.id]

          localStorage.setItem(
            localStorageKey,
            JSON.stringify(dotcomNotAllowedSSLMessaging)
          )

          return
        } else {
          // patient on the list and NOT allowed to book? show msg for patient if status is TO_SHOW. (DISMISSED = do not show)
          if (dotcomNotAllowedSSLMessaging[p.id] === 'TO_SHOW') {
            tempPatientsNotAllowed.push(p)
            return
          }
        }
      }
      // is patient on the list? NO.
      else {
        // patient NOT on the list and NOT allowed to book? put patient on the list and show msg for patient.
        if (!p.allowedToBook) {
          tempPatientsNotAllowed.push(p)

          dotcomNotAllowedSSLMessaging[p.id] = 'TO_SHOW'

          localStorage.setItem(
            localStorageKey,
            JSON.stringify(dotcomNotAllowedSSLMessaging)
          )

          return
        }
      }
    })

    setPatientsNotAllowed(tempPatientsNotAllowed)
  }, [user])

  const handleDismiss = () => {
    const dotcomNotAllowedSSLMessaging =
      JSON.parse(localStorage.getItem(localStorageKey)) || {}

    for (let i = 0; i < patientsNotAllowed.length; i++) {
      const p = patientsNotAllowed[i]
      dotcomNotAllowedSSLMessaging[p.id] = 'DISMISSED'
    }

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(dotcomNotAllowedSSLMessaging)
    )

    setPatientsNotAllowed([])
  }

  return {
    patientsToShowNotAllowedMsgFor:
      // This message only shows if there is more than one patient on the roster and one can still book
      user?.roster?.length !== patientsNotAllowed?.length
        ? patientsNotAllowed
        : [],
    handleDismissMsgNotAllowedPatients: handleDismiss,
  }
}

export default useGiveNotAllowedToBookPatientsForMessaging
