import { useMutation } from '@tanstack/react-query'

interface VerifySecuredTokenProps {
  securedToken: string
}

const verifySecuredToken = async ({
  securedToken,
}: VerifySecuredTokenProps): Promise<boolean> => {
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/public/check-secured-token?securedToken=${securedToken}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) throw new Error()

    return true
  } catch (error) {
    throw new Error('Token is invalid.')
  }
}

export const useVerifySecuredToken = () =>
  useMutation((params: VerifySecuredTokenProps) => verifySecuredToken(params))
