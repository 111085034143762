interface TherapistAvatarProps {
  index: number
  src: string
  reversed?: boolean
  smaller?: boolean
}

const TherapistAvatar: React.FC<TherapistAvatarProps> = ({
  index,
  src,
  reversed,
  smaller = false,
}) => {
  const isFirstItem = index === 0
  const widthAndHeightAvatar = isFirstItem
    ? `h-9 w-9 ${smaller ? 'sm:h-16 sm:w-16' : 'sm:h-20 sm:w-20'}`
    : `h-7 w-7 ${smaller ? 'sm:h-10 sm:w-10' : 'sm:h-16 sm:w-16'}`
  const zIndexAvatar = isFirstItem ? 'z-40' : 'z-30'
  const positioning = isFirstItem
    ? reversed
      ? '-right-2 sm:-right-4'
      : '-left-2 sm:-left-4'
    : reversed
    ? '-right-4 sm:-right-8'
    : '-left-4 sm:-left-8'

  return (
    <img
      className={`relative inline-block rounded-full object-cover shadow-md ring-0 ${widthAndHeightAvatar} ${zIndexAvatar} ${positioning}`}
      src={src}
      alt="Therapist"
    />
  )
}

export default TherapistAvatar
