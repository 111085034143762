import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'

import type { ResumeStep } from '../../../types/Dashboard'
import DashboardElement from '../DashboardElement'
import { tertiaryButtonClass } from '../../../constants/classConstants'
import { useAuth } from '../../../contexts/AuthProvider'
import {
  ONBOARDING_STEP,
  type CarePlan,
  type Condition,
  type Patient,
} from '../../../types/Patient'
import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import type { ServiceLine } from '../../../types/ServiceLine'
import {
  enumeratedElementsFromArray,
  formatPossessiveNoun,
} from '../../../helpers/generic'
import { usePatient } from '../../../contexts/PatientProvider'
import type { ResultGetProviders } from '../../../queries/booking/UseGetProviders'
import type { InterestOption } from '../../onboarding/WhatBringsYouHere'
import useRanOutOfBulkSessionsAndNotAllowed from '../../../hooks/useRanOutOfBulkSessionsAndNotAllowed'
import getAvatarSrc from '../../../helpers/getAvatarSrc'

const PickUpSteps: React.FC<{
  resultProviders: ResultGetProviders[]
}> = ({ resultProviders }) => {
  const { user } = useAuth()

  const ranOutOfBulkSessionsAndNotAllowed =
    useRanOutOfBulkSessionsAndNotAllowed()

  if (user?.roster?.length && !resultProviders?.length) return null

  return (
    <>
      {user?.roster?.length ? (
        React.Children.toArray(
          user?.roster
            ?.filter((patient: Patient) => !patient.isDisabled)
            ?.map((patient: Patient) => {
              let stepToDisplay: string

              const patientCarePlans: CarePlan[] = patient?.conditions.find(
                (c: Condition) => !c.isIep
              )?.carePlans

              if (!patient?.state || !patient?.gender)
                stepToDisplay = ONBOARDING_STEP.buildProfile
              else if (
                !patient?.emergencyContact?.firstName &&
                !patient?.interests?.length
              )
                stepToDisplay = ONBOARDING_STEP.emergencyContact
              else if (!patient?.interests?.length && !patientCarePlans?.length)
                stepToDisplay = ONBOARDING_STEP.interest
              else if (
                !patientCarePlans?.length &&
                (patient.hasSeparateLogin
                  ? patient.allowedToBook && patient.ableToBookAndCancel
                  : patient.allowedToBook)
              )
                stepToDisplay = ONBOARDING_STEP.areaFocus
              else if (
                !ranOutOfBulkSessionsAndNotAllowed &&
                !patientCarePlans?.some((plan) => plan.sessions?.length > 0) &&
                patientCarePlans?.some((plan) => plan.remainingSessions > 0) &&
                (patient.hasSeparateLogin
                  ? patient.allowedToBook && patient.ableToBookAndCancel
                  : patient.allowedToBook)
              )
                stepToDisplay = ONBOARDING_STEP.booking

              return (
                <IndividualStep
                  stepName={stepToDisplay}
                  patient={patient}
                  resultProviders={resultProviders}
                />
              )
            })
        )
      ) : (
        <IndividualStep
          stepName={ONBOARDING_STEP.careFor}
          resultProviders={resultProviders}
        />
      )}
    </>
  )
}

export default PickUpSteps

const IndividualStep: React.FC<{
  stepName: string
  patient?: Patient
  resultProviders: ResultGetProviders[]
}> = ({ stepName, patient = null, resultProviders }) => {
  const { user } = useAuth()
  const { setPatient } = usePatient()
  const navigate = useNavigate()
  const [step, setStep] = useState<ResumeStep>(null)
  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const resultProvidersForPatient = resultProviders.find(
    (result: ResultGetProviders) => result?.patient?.id === patient?.id
  )

  const goToStep = (step: ResumeStep) =>
    flushSync(() => {
      setPatient({ ...patient, onboardingStep: step.name })
      navigate(step.to)
    })

  useEffect(() => {
    if (!stepName || (!resultProvidersForPatient && user?.roster?.length))
      return

    if (
      stepName === ONBOARDING_STEP.booking &&
      !resultProvidersForPatient.availableServiceLinesNames?.length
    )
      return

    const AVATAR_PICTURE: string = getAvatarSrc(user, patient)

    switch (stepName) {
      case ONBOARDING_STEP.careFor:
        setStep({
          upNext: 'Who is the care for?',
          image: AVATAR_PICTURE,
          buttonText: 'Resume onboarding',
          to: '/onboarding/your-story',
          patient: null,
          name: ONBOARDING_STEP.careFor,
        })
        break
      case ONBOARDING_STEP.buildProfile:
        setStep({
          upNext: `Let's build ${
            forSelf
              ? 'your'
              : patient?.firstName
              ? formatPossessiveNoun(patient?.firstName)
              : 'their'
          } profile`,
          image: AVATAR_PICTURE,
          buttonText: 'Resume onboarding',
          to: '/onboarding/build-profile',
          patient: null,
          name: ONBOARDING_STEP.buildProfile,
        })
        break
      case ONBOARDING_STEP.emergencyContact:
        setStep({
          upNext: `Who is ${
            forSelf ? 'your' : formatPossessiveNoun(patient.firstName)
          } emergency contact?`,
          image: AVATAR_PICTURE,
          buttonText: 'Resume onboarding',
          to: '/onboarding/emergency-contact',
          patient,
          name: ONBOARDING_STEP.emergencyContact,
        })
        break
      case ONBOARDING_STEP.interest:
        setStep({
          upNext: `What are ${
            forSelf ? 'your' : formatPossessiveNoun(patient.firstName)
          } topics of interest?`,
          image: AVATAR_PICTURE,
          buttonText: 'Resume onboarding',
          to: '/onboarding/interest',
          patient,
          name: ONBOARDING_STEP.interest,
        })
        break
      case ONBOARDING_STEP.areaFocus:
        if (
          user.data.whatBringsYouHere.some(
            (i: InterestOption) => i.key === 'therapy'
          )
        )
          setStep({
            upNext: `${
              !forSelf ? `For ${patient.firstName}, what` : 'What'
            } area of focus are you looking for?`,
            image: AVATAR_PICTURE,
            buttonText: 'Resume onboarding',
            to: '/onboarding/area-focus',
            patient,
            name: ONBOARDING_STEP.areaFocus,
          })
        break
      case ONBOARDING_STEP.booking:
        const availableLineNames: string[] =
          resultProvidersForPatient.availableServiceLinesNames.map(
            (displayName: string) =>
              SERVICE_LINES_ARRAY.find(
                (sl: ServiceLine) => sl.displayName === displayName
              ).type
          )

        if (!availableLineNames?.length || patient.isIep) return

        setStep({
          upNext: `We have ${enumeratedElementsFromArray(
            availableLineNames
          )} therapists available for ${forSelf ? 'you' : patient.firstName}!`,
          image: AVATAR_PICTURE,
          buttonText: 'View my options',
          to: `/select-service-line`,
          patient,
          name: ONBOARDING_STEP.booking,
        })
        break
    }
  }, [stepName, resultProvidersForPatient])

  if (!step) return

  return (
    <DashboardElement title="Pick up where you left off" filled noGap>
      <div className="flex w-full flex-col justify-between gap-8 sm:px-8 lg:flex-row lg:items-center">
        {/* Left */}
        <div className="flex max-w-2xl items-center gap-8">
          {/* Image */}
          <img
            src={step?.image}
            alt="patient-avatar"
            className="sm:h-24 sm:w-24 xs:h-20 xs:w-20"
          />

          {/* Texts */}
          <div className="flex flex-col gap-2">
            <p className="text-base xs:text-sm">Up Next:</p>
            <p className="text-lg font-semibold xs:text-base">{step?.upNext}</p>
          </div>
        </div>

        {/* Right */}
        <button className={tertiaryButtonClass} onClick={() => goToStep(step)}>
          {step?.buttonText}
        </button>
      </div>
    </DashboardElement>
  )
}
