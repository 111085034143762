import React from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'

import CenteredModal from '../CenteredModal'
import { usePatient } from '../../contexts/PatientProvider'
import { capitalize, formatPossessiveNoun } from '../../helpers/generic'
import {
  primaryButtonClass,
  secondaryButtonClass,
} from '../../constants/classConstants'
import type { CancelledTherapists } from '../../types/Profile'

interface PatientChangedStateModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm: () => void
  cancelledTherapists: CancelledTherapists[]
}

const PatientChangedStateModal: React.FC<PatientChangedStateModalProps> = ({
  open,
  setOpen,
  onConfirm,
  cancelledTherapists,
}) => {
  const { patient } = usePatient()

  const onConfirmChanges = () => {
    setOpen(false)
    onConfirm()
  }

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        <div className="flex flex-row gap-4">
          <div className="self-start rounded-full bg-status-error bg-opacity-20 p-2.5">
            <ExclamationIcon className="h-5 w-5 text-status-error" />
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-base font-semibold sm:text-lg">
              Sorry, looks like {formatPossessiveNoun(patient.firstName)} one or
              more therapist do not provide care in his new state
            </p>
            <p className="text-sm sm:text-base">
              Any future sessions you had booked with the following therapists
              will be cancelled within 24 hours:
            </p>
            <ul className="ml-10 list-disc">
              {React.Children.toArray(
                cancelledTherapists.map((item) => (
                  <li>
                    {capitalize(item.therapyType)} therapist, {item.name}
                  </li>
                ))
              )}
            </ul>
            <p className="text-xs text-status-error sm:text-sm">
              If the state was changed on accident, please hit 'Cancel changes'
              or update it within 24 hours to prevent any cancellations.
            </p>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <button
            onClick={() => setOpen(false)}
            type="button"
            className={secondaryButtonClass}
          >
            Cancel changes
          </button>
          <button
            onClick={onConfirmChanges}
            type="button"
            className={primaryButtonClass}
          >
            Confirm changes
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default PatientChangedStateModal
