import useProductAdditionalSessionsType from './useProductAdditionalSessionsType'
import useHasUsedAllFreeSessions from './useHasUsedAllFreeSessions'

const useIsClientPayCOS = () => {
  const additionalSessionsType = useProductAdditionalSessionsType()
  const usedAllFreeSessions = useHasUsedAllFreeSessions()

  return usedAllFreeSessions && additionalSessionsType === 'CLIENT_PAY'
}

export default useIsClientPayCOS
