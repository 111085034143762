import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface CancelBookingProps {
  carePlanId: string
  appointmentId: string
  isLateCancel: boolean
}

const cancelBooking = async ({
  carePlanId,
  appointmentId,
  isLateCancel,
}: CancelBookingProps): Promise<boolean> => {
  const token: string = await auth.currentUser.getIdToken()

  const r = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/care_plans/${carePlanId}/appointments/${appointmentId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        appointment: {
          status: isLateCancel
            ? 'late_cancel_by_patient'
            : 'canceled_by_patient',
          cancellation_reason: 0,
          cancellation_reason_text: '',
        },
      }),
    }
  )

  return r.status === 200
}

export const useCancelBooking = () =>
  useMutation((params: CancelBookingProps) => cancelBooking(params))
