import cloneDeep from 'lodash.clonedeep'

import type { Task } from '../types/Dashboard'

export const addTaskForUpdateCardInfo = (task: Task, userId: string) => {
  const userTasksFromLocalStorage = JSON.parse(
    localStorage.getItem('DOTCOM_USER_TASKS')
  )

  if (!userTasksFromLocalStorage) {
    localStorage.setItem(
      'DOTCOM_USER_TASKS',
      JSON.stringify({ [userId]: { ['UPDATE_CREDIT_CARD']: task } })
    )
  } else if (!userTasksFromLocalStorage?.[userId]) {
    localStorage.setItem(
      'DOTCOM_USER_TASKS',
      JSON.stringify({
        ...userTasksFromLocalStorage,
        [userId]: { ['UPDATE_CREDIT_CARD']: task },
      })
    )
  } else {
    localStorage.setItem(
      'DOTCOM_USER_TASKS',
      JSON.stringify({
        ...userTasksFromLocalStorage,
        [userId]: {
          ...userTasksFromLocalStorage[userId],
          ['UPDATE_CREDIT_CARD']: task,
        },
      })
    )
  }
}

export const removeTaskForUpdateCardInfo = (userId: string) => {
  const userTasksFromLocalStorage = JSON.parse(
    localStorage.getItem('DOTCOM_USER_TASKS')
  )

  if (userTasksFromLocalStorage?.[userId]?.['UPDATE_CREDIT_CARD']) {
    const clone = cloneDeep(userTasksFromLocalStorage)
    delete clone[userId]['UPDATE_CREDIT_CARD']
    localStorage.setItem('DOTCOM_USER_TASKS', JSON.stringify(clone))
  }
}
