import React, { useMemo } from 'react'
import type { PathMatch } from 'react-router-dom'
import { Navigate, useLocation, matchPath } from 'react-router-dom'

import { usePatient } from '../../contexts/PatientProvider'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { CarePlan, Condition } from '../../types/Patient'
// import useGetProviders from '../../queries/booking/UseGetProviders'
// import { states } from '../../constants/values'
// import type { State } from '../../types/State'
// import type { Therapist } from '../../types/Therapist'
// import { useCheckProviderInInsuranceNetwork } from '../../queries/booking/ProviderInsuranceNetwork'
// import { useGetInsuranceEligibility } from '../../queries/insurance/InsuranceEligibility'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'
import type { Session } from '../../types/Session'
import useProductAdditionalSessionsType from '../../hooks/useProductAdditionalSessionsType'
import useHasUsedAllFreeSessions from '../../hooks/useHasUsedAllFreeSessions'
import useIsPatientPayCOS from '../../hooks/useIsPatientPayCOS'

const ignoreRoutes = [
  'booking-non-sponsored',
  'confirmation',
  'success',
  'out-of-bulk-sessions',
]

// INSURANCE REVERTED

const RequireBookingLogic: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { patient } = usePatient()
  const isPatientPayCOS = useIsPatientPayCOS()
  const location = useLocation()
  const usedAllFreeSessions = useHasUsedAllFreeSessions()
  const currentServiceLine = useCurrentServiceLine()
  const additionalSessionsType: string = useProductAdditionalSessionsType()
  const isAssessment: boolean = useMemo(
    () => getIsAssessmentStatus(patient, currentServiceLine?.displayName),
    [patient, currentServiceLine]
  )
  const carePlan: CarePlan = patient?.conditions
    ?.find((c: Condition) => !c.isIep)
    ?.carePlans.find(
      (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
    )
  const haveCarePlanPendingSessions: boolean = carePlan?.sessions.some(
    (s: Session) => s.status === 'pending'
  )

  // const { data: resultProviders } = useGetProviders({
  //   patients: [patient],
  //   enabled: Boolean(patient),
  //   withFirstAvailability: false,
  //   ignoreTakingNewPrivatePatients: true,
  //   onlyServiceLines: [currentServiceLine],
  // })

  // const currentProvider = (resultProviders[0]?.therapists || []).find(
  //   (t: Therapist) => t.id === carePlan?.providerId
  // )

  // const isProviderInsuranceNetworkEnabled = Boolean(
  //   isPatientPayCOS &&
  //     !patient.unassociatedInsurance &&
  //     patient.insurance?.id &&
  //     currentProvider?.id
  // )

  // const {
  //   data: providerInsuranceNetwork,
  //   isLoading: isLoadingProviderInsuranceCheck,
  // } = useCheckProviderInInsuranceNetwork({
  //   patient,
  //   serviceType: currentServiceLine.serviceType,
  //   language: patient.preferredLanguage,
  //   licenseJurisdiction: states.find(
  //     (state: State) => state?.name === patient?.state
  //   )?.abbrev,
  //   providerId: currentProvider?.id,
  //   insuranceId: patient.insurance?.id,
  //   enabled: isProviderInsuranceNetworkEnabled,
  // })

  // const shouldCheckInsuranceEligibility = Boolean(
  //   patient.id &&
  //     patient.insurance?.id &&
  //     !patient.unassociatedInsurance &&
  //     currentServiceLine?.serviceType
  // )

  // const {
  //   data: insuranceEligibilityCheck,
  //   isLoading: isLoadingInsuranceEligibilityCheck,
  // } = useGetInsuranceEligibility({
  //   patientId: patient.id,
  //   insuranceId: patient.insurance?.id,
  //   serviceType: currentServiceLine.serviceType,
  //   enabled: shouldCheckInsuranceEligibility,
  // })

  if (patient?.isIepOnly || patient?.isDisabled)
    return <Navigate to="/dashboard" />

  // plan has bulk model
  // ran out of bulk model sessions
  // did not confirm understanding of bulk model
  if (
    [
      matchPath(`/booking/:serviceLine`, location.pathname),
      matchPath(`/booking/:serviceLine/providers`, location.pathname),
      matchPath(
        `/booking/:serviceLine/providers/:providerId`,
        location.pathname
      ),
    ].some((pm: PathMatch) => Boolean(pm)) &&
    isPatientPayCOS &&
    Boolean(carePlan.bulkModel) &&
    carePlan.bulkModel.isBulkModel &&
    !carePlan.bulkModel.remainingSessionsOnBulkModel &&
    !carePlan.bookingNonSponsoredInformationConfirmed
  )
    return (
      <Navigate
        to={`/booking/${currentServiceLine.url}/out-of-bulk-sessions`}
      />
    )

  if (
    isAssessment &&
    haveCarePlanPendingSessions &&
    !usedAllFreeSessions &&
    !location.pathname.includes('already-booked') &&
    !location.pathname.includes('confirmation') &&
    !location.pathname.includes('success')
  )
    return <Navigate to={`/booking/${currentServiceLine.url}/already-booked`} />

  // if
  // used all free sessions
  // and
  // is not allowed to book more
  if (
    usedAllFreeSessions &&
    additionalSessionsType === 'NOT_ALLOWED' &&
    !location.pathname.includes('maxed-out') &&
    !location.pathname.includes('confirmation') &&
    !location.pathname.includes('success')
  )
    return <Navigate to={`/booking/${currentServiceLine.url}/maxed-out`} />

  // continuation of services
  if (
    isPatientPayCOS &&
    !ignoreRoutes.some((l) => location.pathname.includes(l))
  ) {
    // first time
    if (!carePlan.bookingNonSponsoredInformationConfirmed) {
      return (
        <Navigate to={`/booking-non-sponsored/${currentServiceLine.url}`} />
      )
    }
    // after first time
    // else {
    //   if (patient.insurance?.insurance?.insuranceType === 'Medicaid') {
    //     if (
    //       insuranceEligibilityCheck &&
    //       !isLoadingInsuranceEligibilityCheck &&
    //       !insuranceEligibilityCheck.insuranceEligibile
    //     ) {
    //       return (
    //         <Navigate
    //           to={`/booking-non-sponsored/${currentServiceLine.url}/insurance-info/pending-verification`}
    //         />
    //       )
    //     }

    //     if (
    //       !isLoadingProviderInsuranceCheck &&
    //       !providerInsuranceNetwork.currentProviderInNetwork
    //     ) {
    //       return (
    //         <Navigate
    //           to={`/booking-non-sponsored/${currentServiceLine.url}/therapist-no-accept`}
    //         />
    //       )
    //     }
    //   }
    // }
  }

  return <>{children}</>
}

export default RequireBookingLogic
