import React from 'react'

const TopicLoader: React.FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5 animate-pulse rounded bg-components-fields" />
        <span className="h-5 w-full animate-pulse rounded bg-components-fields" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5 animate-pulse rounded bg-components-fields" />
        <span className="h-5 w-full animate-pulse rounded bg-components-fields" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5 animate-pulse rounded bg-components-fields" />
        <span className="h-5 w-full animate-pulse rounded bg-components-fields" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5 animate-pulse rounded bg-components-fields" />
        <span className="h-5 w-full animate-pulse rounded bg-components-fields" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5 animate-pulse rounded bg-components-fields" />
        <span className="h-5 w-full animate-pulse rounded bg-components-fields" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5 animate-pulse rounded bg-components-fields" />
        <span className="h-5 w-full animate-pulse rounded bg-components-fields" />
      </div>
    </div>
  )
}

export default TopicLoader
