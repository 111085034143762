import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface PricesResult {
  [key: string]: number
}

export const useGetPrices = () =>
  useQuery(['getPrices'], async () => getPrices())

const getPrices = async (): Promise<PricesResult> => {
  const token: string = await auth.currentUser.getIdToken()

  const data: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL
    }v1/prices?sessionDuration=HALF_HOUR`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return {
    30: data.amount,
    60: data.amount * 2,
  }
}
