import React from 'react'
import { Link } from 'react-router-dom'

import AppLogo from '../../components/AppLogo'

const SeparateLoginInviteExpired: React.FC = () => {
  return (
    <div className="flex w-full max-w-2xl flex-col items-center justify-center gap-8 xs:gap-6">
      <h1 className="text-center text-2xl font-semibold xs:text-lg">
        Welcome to Huddle Up!
      </h1>

      <AppLogo classes="self-center" />

      <div className="flex flex-col gap-2 rounded-2xl bg-background-offwhite p-4">
        <p className="text-center text-lg font-semibold xs:text-base">
          Sorry, it looks like your invitation link has expired.
        </p>
        <p className="whitespace-pre-wrap text-center xs:text-sm">
          Please have your parent or guardian resend the invite.{'\n'}
          If you have any questions, please contact{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="text-cta-default underline"
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>

      <Link to={'/sign-in'} className="self-center text-center underline">
        I already have an account
      </Link>
    </div>
  )
}

export default SeparateLoginInviteExpired
