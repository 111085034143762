import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { flushSync } from 'react-dom'
import cloneDeep from 'lodash.clonedeep'

import { usePatient } from '../contexts/PatientProvider'
import { useAuth } from '../contexts/AuthProvider'
import { ONBOARDING_STEP } from '../types/Patient'
import type { MixPanelTrackProps } from '../hooks/useMixPanel'
import trackMixPanel from '../hooks/useMixPanel'

const SkipAndComeBackLater: React.FC<{
  classes?: string
  to?: string
  handleClick?: () => any
  mixPanelData?: MixPanelTrackProps
}> = ({
  classes = '',
  to = '/dashboard',
  handleClick = null,
  mixPanelData = null,
}) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { patient, setPatient } = usePatient()

  const patientIdFromUrl = searchParams?.get('patientId')
  const signUpFromEmail = searchParams?.get('signUpFromEmail') == 'true'
  const errorDob = searchParams?.get('errorDob') == 'true'

  const handleSkip = () => {
    if (mixPanelData) trackMixPanel(mixPanelData)

    if (signUpFromEmail) {
      flushSync(() => {
        const newUser = cloneDeep(user)
        const patientIdx = newUser.roster.findIndex(
          (p) => p.id === patient?.id || p.id === patientIdFromUrl
        )
        if (patientIdx === -1) return navigate('/dashboard', { replace: true })
        newUser.roster[patientIdx].onboardingStep = errorDob
          ? ONBOARDING_STEP.skippedInErrorDob
          : ONBOARDING_STEP.skipped
        setUser(newUser)

        const potentialPatient = user.roster.find(
          (p) =>
            !p.state &&
            !p.gender &&
            p.onboardingStep !== ONBOARDING_STEP.skipped &&
            p.onboardingStep !== ONBOARDING_STEP.skippedInErrorDob &&
            p.id !== patient?.id &&
            p.id !== patientIdFromUrl
        )

        if (potentialPatient) {
          setPatient(potentialPatient)
          return navigate(
            `/onboarding/build-profile${location.search}&patientId=${
              potentialPatient.id
            }&key=${Date.now()}`,
            { replace: true }
          )
        } else
          return navigate(`/onboarding/add-another-person${location.search}`, {
            replace: true,
          })
      })
    } else if (handleClick) {
      handleClick()
    } else {
      flushSync(() => {
        const newUser = cloneDeep(user)
        newUser.roster.map((p) => ({
          ...p,
          conditions: p.conditions.map((c) => ({
            ...c,
            carePlans: c.carePlans.map(
              (cp) =>
                (cp.bookingNonSponsoredInformationConfirmed = Boolean(
                  newUser.paymentMethod
                ))
            ),
          })),
        }))

        setUser(newUser)
        setPatient(null)
        navigate(to, { replace: true })
      })
    }
  }

  return (
    <button
      onClick={handleSkip}
      className={`text-sm font-medium text-text-secondary underline sm:text-base ${classes} flex self-center`}
    >
      Skip and come back to this later
    </button>
  )
}

export default SkipAndComeBackLater
