import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetResumeCourses } from '../../../queries/courses/GetResumeCourses'
import type { ResumeCourse } from '../../../types/Courses'
import { primaryButtonClassSmall } from '../../../constants/classConstants'
import { PlayIcon } from '@heroicons/react/outline'
import { ResourcePaginator } from '../components/ResourcesGrid'
import ICON_TIME from '../../../assets/icons/Time.svg'
import COURSES_ICON from '../../../assets/icons/CoursesVideoIcon.svg'
import { useAuth } from '../../../contexts/AuthProvider'
import CoursePatientBadge from './CoursePatientBadge'

const ResumeCourses: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data: courseSection, isLoading } = useGetResumeCourses({
    user,
    pageNumber: currentPage,
    pageSize: 2,
  })

  const courses = courseSection?.courses

  if ((!isLoading && courses?.length === 0) || isLoading || !courseSection)
    return null

  return (
    <div className="flex flex-col gap-6 rounded-2xl border border-components-fields bg-white p-4 shadow-subtle sm:mx-6 lg:py-6 lg:px-8">
      <p className="text-base font-semibold lg:text-2xl">
        Resume Course{courseSection?.totalElements > 1 ? 's' : ''}
      </p>

      {React.Children.toArray(
        courses.map((course: ResumeCourse) => (
          <div
            className="relative flex h-40 rounded-2xl border border-components-fields lg:h-36"
            key={`${course.id}${course.courseProgress?.patient?.id}`}
          >
            <CoursePatientBadge progresses={[course.courseProgress]} />
            <img
              src={course.imageSrc}
              alt="preview"
              className="w-50 rounded-tl-2xl rounded-bl-2xl object-cover xs:w-26"
            />
            <div className="flex w-full flex-col gap-4 p-4">
              {/* Title and btn */}
              <div className="flex w-full flex-col justify-between gap-4 lg:flex-row lg:gap-0">
                {/* Title and modules */}
                <div className="flex flex-col">
                  <p className="text-sm font-semibold line-clamp-2 lg:text-lg">
                    {course.title}
                  </p>
                  <div className="flex items-center gap-1 text-xs text-text-secondary lg:gap-2 lg:text-base">
                    <img
                      src={COURSES_ICON}
                      alt="icon"
                      className="h-3.5 object-cover"
                    />
                    {course.numberOfVideos} Module
                    {course.numberOfVideos > 1 ? 's' : ''}
                  </div>
                </div>

                {/* Button */}
                {course?.courseProgress?.completePercentage < 100 ? (
                  <button
                    onClick={() =>
                      navigate(`/view-course/${course.id}`, {
                        state: {
                          resumeCourseForPatientId:
                            course.courseProgress.patient?.id || 'myself',
                          fromPathname: location.pathname,
                        },
                      })
                    }
                    className={`${primaryButtonClassSmall} lg:w-60`}
                  >
                    <PlayIcon className="h-5 w-5" />
                    Resume
                  </button>
                ) : (
                  <div className="self-start rounded-3xl bg-status-success py-2 px-3 text-sm font-semibold text-white xs:text-xs">
                    <p className="text-xs font-semibold">Completed just now</p>
                  </div>
                )}
              </div>

              {/* Up next and progress */}
              {course?.courseProgress?.completePercentage < 100 && (
                <div className="flex w-full justify-between lg:pl-4">
                  <div className="hidden gap-2 lg:flex">
                    <img
                      src={course.video.imageSrc}
                      alt="preview"
                      className="h-12 w-16 rounded-md object-cover"
                    />

                    <div className="ml-2 flex flex-col">
                      <p className="max-w-xxs text-sm font-semibold line-clamp-1">
                        {course.video.title}
                      </p>
                      <div className="flex gap-1 text-xs text-text-label">
                        <img src={ICON_TIME} alt="time" />
                        {course.video.duration}
                      </div>
                    </div>

                    <div className="self-start rounded-full bg-components-fillBorders py-1 px-3">
                      <p className="text-xs font-semibold">Up Next</p>
                    </div>
                  </div>

                  <div className="flex w-40 items-center gap-2 lg:w-60">
                    <div className="flex w-full rounded-full bg-components-fillBorders">
                      <div
                        className="h-2.5 rounded-full bg-secondaryAccents-lightGreen"
                        style={{
                          width: `${course?.courseProgress?.completePercentage}%`,
                        }}
                      />
                    </div>
                    <p className="whitespace-nowrap text-xs font-semibold lg:text-sm">
                      {course?.courseProgress?.completePercentage}% Complete
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))
      )}

      {courseSection?.totalElements > 2 && (
        <ResourcePaginator
          pageMapId="resumeCourses"
          currentPage={currentPage}
          resourceSection={courseSection}
          setCurrentPage={setCurrentPage}
          isLoadingResourceSection={isLoading}
        />
      )}
    </div>
  )
}

export default ResumeCourses
