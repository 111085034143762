import { useMemo } from 'react'
import type { FC } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import type { noProviderItem } from '../../types/Provider'
import {
  formatPossessiveNoun,
  getMultipleRandomFromArray,
  splitArrayToChunks,
} from '../../helpers/generic'
import type { Therapist } from '../../types/Therapist'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import TherapistBubbles from './components/TherapistBubbles/TherapistBubbles'
import { useProviderContext } from '../../contexts/ProviderContext'
import handleClickSupportEmail from '../../helpers/supportClick'
import { useAuth } from '../../contexts/AuthProvider'

const TherapistNoLongerAtDCT: FC = () => {
  const location = useLocation()
  const locationState: noProviderItem = location.state

  if (!locationState) return <Navigate to="/dashboard" />

  if (locationState.otherTherapistOptions.length > 0) {
    return <OtherTherapistsFound />
  } else {
    return <NoOtherTherapistsFound />
  }
}

const OtherTherapistsFound: FC = () => {
  const location = useLocation()
  const locationState: noProviderItem = location.state
  const navigate = useNavigate()
  const forSelf: boolean = locationState.patient.relationship.key === 'myself'
  const { setWantDifferentTherapist } = useProviderContext()

  const handleClick = () => {
    setWantDifferentTherapist(true)
    navigate(`/booking/${locationState.serviceLine.url}/providers`)
  }

  const matchesMax4: Therapist[] = useMemo(() => {
    if (!locationState.otherTherapistOptions) return []
    return getMultipleRandomFromArray(
      locationState.otherTherapistOptions,
      Math.min(
        4,
        Math.max(
          ...Array.from(
            {
              length: Math.floor(
                locationState.otherTherapistOptions.length / 2
              ),
            },
            (_, i) => (i + 1) * 2
          )
        )
      )
    )
  }, [locationState])

  const matchesMax4Split: Therapist[][] = useMemo(
    () => splitArrayToChunks(matchesMax4, 2),
    [matchesMax4]
  )

  return (
    <div className="max-w-2xl text-center">
      <div className="flex flex-col gap-6">
        <h1 className="text-lg font-semibold sm:text-2xl">
          Sorry,{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf
              ? 'your'
              : formatPossessiveNoun(locationState.patient.firstName)}
          </span>{' '}
          {locationState.serviceLine.type} therapist,{' '}
          {locationState.provider.preferredName}, no longer offers care through
          us
        </h1>
        <h3 className="text-base font-semibold sm:text-lg">
          Don't worry! We found{' '}
          <span className="text-primaryAccents-orange">
            {locationState.otherTherapistOptions.length}
          </span>{' '}
          other {locationState.serviceLine.type} therapist
          {locationState.otherTherapistOptions.length === 1 ? '' : 's'} that
          match what you are looking for!
        </h3>
        <TherapistBubbles
          serviceLine={locationState.serviceLine}
          matchesMax4Split={matchesMax4Split}
        />
      </div>
      <div className="mx-auto flex w-full max-w-lg flex-col gap-4 sm:gap-12">
        <button onClick={handleClick} className={`${primaryButtonClass}`}>
          View my option
          {locationState.otherTherapistOptions.length === 1 ? '' : 's'}
        </button>
        <Link
          to="/dashboard"
          className="text-sm font-medium text-text-secondary underline sm:text-base"
        >
          Cancel
        </Link>
      </div>
    </div>
  )
}

const NoOtherTherapistsFound: FC = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState: noProviderItem = location.state

  const forSelf: boolean = locationState.patient.relationship.key === 'myself'

  return (
    <div className="max-w-3xl text-center">
      <div className="flex flex-col gap-6">
        <h1 className="mx-auto max-w-2xl text-lg font-semibold sm:text-2xl">
          Sorry,{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf
              ? 'your'
              : formatPossessiveNoun(locationState.patient.firstName)}
          </span>{' '}
          {locationState.serviceLine.type} therapist,{' '}
          {locationState.provider.preferredName}, no longer offers care through
          us
        </h1>
        <p className="text-sm font-medium sm:text-base">
          Unfortunately, we currently do not have any therapists available for
          booking at this time.
          <br />
          If you have any questions, please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="text-sm font-medium text-cta-default underline sm:text-base"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>
      <div className="mx-auto grid w-full max-w-lg grid-cols-2 gap-2">
        <button
          onClick={() => {
            // -2 becuase -1 takes to provider screen
            navigate(-2)
          }}
          type="button"
          className={`${tertiaryButtonClass} !px-4`}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Go Back
        </button>
        <Link to="/dashboard" className={`${primaryButtonClass} !px-4`}>
          Go to my dashboard
          <ChevronRightIcon className="h-5 w-5" />
        </Link>
      </div>
    </div>
  )
}

export default TherapistNoLongerAtDCT
