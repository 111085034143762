import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { NewDocumentForm } from '../../screens/view-patient/tabs/DocumentsTab'

const uploadNewDocument = async ({
  file,
  name,
  patientId,
}: NewDocumentForm): Promise<string> => {
  const token: string = await auth.currentUser.getIdToken()

  // call to get google url
  const { id, url } = await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_STORAGE_URL}/v1/presigned-urls/upload`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fileName: name,
        documentType: 'PATIENT',
        ownerId: patientId,
        contentType: file.type,
      }),
    }
  ).then((response) => response.json())

  // upload to google
  await fetch(url, {
    method: 'PUT',
    body: file,
  })

  // confirm upload to java
  const data = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_STORAGE_URL
    }/v1/presigned-urls/${id}/confirm`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([
        {
          key: 'uploadedFrom',
          value: 'dot',
        },
        {
          key: 'name',
          value: name,
        },
        {
          key: 'addedBy',
          value: 'ACCOUNT_HOLDER',
        },
      ]),
    }
  ).then((response) => response.json())

  return data?.id
}

export const useUploadNewDocument = () =>
  useMutation((params: NewDocumentForm) => uploadNewDocument(params))
