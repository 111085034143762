import type { KeyboardEvent } from 'react'
import React, { Fragment } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'

import { useAuth } from '../../contexts/AuthProvider'
import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { emergencyContactRelationships } from '../../constants/values'
import {
  ONBOARDING_STEP,
  type Patient,
  type RelationshipType,
} from '../../types/Patient'
import { formatPhoneNumber, formatPossessiveNoun } from '../../helpers/generic'
import { VALID_PHONE_NUMBER_REGEX } from '../../constants/regex'
import { usePatient } from '../../contexts/PatientProvider'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import { flushSync } from 'react-dom'
import Stepper from '../../components/Stepper'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

interface EmergencyContactFormData {
  firstName: string
  lastName: string
  relationship: string
  phoneNumber: string
}

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.emergencyContact },
}

const emergencyContactFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required.').trim(),
  lastName: Yup.string().required('Last name is required.').trim(),
  relationship: Yup.string()
    .required('Their relationship to this person is required.')
    .trim(),
  phoneNumber: Yup.string()
    .required('Phone number is required.')
    .matches(VALID_PHONE_NUMBER_REGEX, {
      name: 'validPhoneNumber',
      message: 'Please complete the phone number validly.',
    })
    .trim(),
})

const EmergencyContact: React.FC = (): JSX.Element => {
  const location = useLocation()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { mutateAsync: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const { setPatient, patient } = usePatient()
  const forSelf = patient?.relationship?.name === 'Myself'
  const isDuringOnboarding = Boolean(patient?.onboardingStep?.length > 0)

  const {
    setValue,
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<EmergencyContactFormData>({
    mode: 'all',
    resolver: yupResolver(emergencyContactFormSchema),
    defaultValues: {
      firstName: patient?.emergencyContact?.firstName || '',
      lastName: patient?.emergencyContact?.lastName || '',
      relationship: patient?.emergencyContact?.relationship || '',
      phoneNumber: patient?.emergencyContact?.phoneNumber || '',
    },
  })

  const onSubmit = async (data: EmergencyContactFormData) => {
    if (isDuringOnboarding) trackMixPanel(MIXPANEL_DATA)

    if (isDirty) {
      const newPatient: Patient | Partial<Patient> = await callUpdateProfile({
        patient: {
          ...patient,
          emergencyContact: {
            firstName: data.firstName,
            lastName: data.lastName,
            relationship: data.relationship,
            phoneNumber: data.phoneNumber,
          },
        },
        user,
      })

      const newUser = cloneDeep(user)

      const patientIdx = newUser.roster.findIndex(
        (p: Patient) => p.id === patient.id
      )
      newUser.roster[patientIdx] = newPatient
      setUser(newUser)
      setPatient(newPatient)
    }

    flushSync(() => {
      if (isDuringOnboarding)
        return navigate('/onboarding/interest' + location.search)
      else
        navigate('/dashboard', {
          state: {
            updatedEmergencyContact: true,
          },
        })
    })
  }

  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  if (!patient) return <Navigate to="/dashboard" />

  return (
    <div
      className={
        isDuringOnboarding
          ? 'max-w-xl'
          : 'mb-auto flex max-w-lg flex-col items-center gap-6 text-text-primary sm:mt-28 sm:gap-10 sm:py-10'
      }
    >
      {/* Steppet */}
      {isDuringOnboarding && (
        <div className="mx-auto">
          <Stepper
            steps={
              user.data.whatBringsYouHere.some((o) => o?.name === 'Therapy') &&
              user.data.clientData.clientType !== 'EDUCATION'
                ? [
                    { name: '1', status: 'complete' },
                    { name: '2', status: 'current' },
                    { name: '3', status: '' },
                    { name: '4', status: '' },
                  ]
                : [
                    { name: '1', status: 'complete' },
                    { name: '2', status: 'current' },
                    { name: '3', status: '' },
                  ]
            }
          />
        </div>
      )}

      {/* Header */}
      <div className="flex flex-col gap-6 text-center">
        <p className="text-base font-semibold sm:text-2xl">
          Who is{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : `${formatPossessiveNoun(patient?.firstName)}`}
          </span>{' '}
          emergency contact?
        </p>
        {!isDuringOnboarding && (
          <p className="text-sm font-normal sm:text-base">
            {'You can find this information later in Roster > Profile'}
          </p>
        )}
      </div>

      {/* Form */}
      <div className="my-auto grid w-full grid-cols-2 gap-4">
        <Controller
          control={control}
          name="firstName"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
              <label
                htmlFor="firstName"
                className="text-sm font-semibold after:ml-0.5 after:text-status-error after:content-['*'] sm:text-base"
              >
                First name
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="First"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="lastName"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
              <label
                htmlFor="lastName"
                className="text-sm font-semibold after:ml-0.5 after:text-status-error after:content-['*'] sm:text-base"
              >
                Last name
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Last"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="relationship"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-1 flex flex-col justify-end gap-1">
              <Listbox {...field}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="font-semibold after:ml-0.5 after:text-status-error after:content-['*']">
                      {`Their relationship to ${
                        forSelf ? 'you' : `${patient.firstName}`
                      }`}
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = emergencyContactRelationships
                            .slice(1, emergencyContactRelationships?.length)
                            .find((r: RelationshipType) =>
                              r.name
                                .toLowerCase()
                                .startsWith(e.key.toLowerCase())
                            )
                          if (match) {
                            setValue('relationship', match.key, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={error ? comboBoxErrorClass : comboBoxClass}
                      >
                        <span
                          className={`${
                            !patient?.emergencyContact?.relationship &&
                            !field.value
                              ? 'text-text-placeholder'
                              : ''
                          } block truncate`}
                        >
                          {(field.value &&
                            emergencyContactRelationships.find(
                              (r: RelationshipType) => r.key === field.value
                            ).name) ||
                            'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          {Boolean(error) ? (
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-status-error"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {emergencyContactRelationships.map(
                            (r: RelationshipType) => (
                              <Listbox.Option
                                key={r.key}
                                className={({ active }) =>
                                  classNames(
                                    'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                    active && 'bg-components-fillBorders'
                                  )
                                }
                                value={r.key}
                              >
                                <span>{r.name}</span>
                              </Listbox.Option>
                            )
                          )}
                        </Listbox.Options>
                      </Transition>
                    </div>
                    {Boolean(error) && (
                      <p className="text-sm text-status-error">
                        {error.type === 'required'
                          ? error.message.replace(
                              'this person',
                              `${forSelf ? 'you' : `${patient.firstName}`}`
                            )
                          : error.message}
                      </p>
                    )}
                  </>
                )}
              </Listbox>
            </div>
          )}
        />

        <Controller
          control={control}
          name="phoneNumber"
          defaultValue=""
          rules={{ maxLength: 14 }}
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-1 flex flex-col justify-end gap-1">
              <label
                htmlFor="phoneNumber"
                className="text-sm font-semibold after:ml-0.5 after:text-status-error after:content-['*'] sm:text-base"
              >
                Phone number
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    Boolean(error && error.type !== 'validPhoneNumber')
                      ? inputErrorClass
                      : inputValidClass
                  } font-normal`}
                  placeholder="(000) 000-0000"
                  {...field}
                  onChange={(e) => {
                    field.onChange(formatPhoneNumber(e.target.value))
                  }}
                  maxLength={14}
                />
                {Boolean(error && error.type !== 'validPhoneNumber') && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p
                  className={`text-sm ${
                    Boolean(error && error.type !== 'validPhoneNumber')
                      ? 'text-status-error'
                      : ''
                  }`}
                >
                  {error.message}
                </p>
              )}
            </div>
          )}
        />
      </div>

      {/* Buttons */}
      <div
        className={`${
          isDuringOnboarding ? 'grid-cols-2' : 'grid-cols-1'
        } grid w-full  gap-4`}
      >
        {isDuringOnboarding && (
          <button
            type="button"
            className={tertiaryButtonClass}
            disabled={isLoadingUpdateProfile}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </button>
        )}
        <button
          disabled={!isValid || isLoadingUpdateProfile}
          className={primaryButtonClass}
          onClick={handleSubmit(onSubmit)}
        >
          {isLoadingUpdateProfile ? (
            <>
              <RefreshIcon
                className="loader h-5 w-5 text-white"
                aria-hidden="true"
              />
              Loading
            </>
          ) : isDuringOnboarding ? (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5 text-white" />
            </>
          ) : (
            'Complete task'
          )}
        </button>
      </div>

      <SkipAndComeBackLater
        mixPanelData={
          isDuringOnboarding
            ? {
                ...MIXPANEL_DATA,
                properties: {
                  ...MIXPANEL_DATA.properties,
                  skipped: true,
                },
              }
            : null
        }
      />
    </div>
  )
}

export default EmergencyContact
