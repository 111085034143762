import React, { useState, useContext, createContext, useMemo } from 'react'

export interface ProviderContextModel {
  wantDifferentTherapist: boolean
  setWantDifferentTherapist: React.Dispatch<React.SetStateAction<boolean>>
  showTherapistModal: boolean
  setShowTherapistModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProviderContext = createContext<ProviderContextModel>(
  {} as ProviderContextModel
)

export const useProviderContext = (): ProviderContextModel =>
  useContext(ProviderContext)

export const ProviderContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [wantDifferentTherapist, setWantDifferentTherapist] =
    useState<boolean>(false)
  const [showTherapistModal, setShowTherapistModal] = useState<boolean>(false)

  const contextValue = useMemo(
    () => ({
      wantDifferentTherapist,
      setWantDifferentTherapist,
      showTherapistModal,
      setShowTherapistModal,
    }),
    [
      wantDifferentTherapist,
      setWantDifferentTherapist,
      showTherapistModal,
      setShowTherapistModal,
    ]
  )

  return (
    <ProviderContext.Provider value={contextValue}>
      {children}
    </ProviderContext.Provider>
  )
}
