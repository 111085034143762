import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface BookmarkResourceProps {
  resourceId: string
}

const bookmarkResource = async ({ resourceId }: BookmarkResourceProps) => {
  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
    }v1/bookmarks/resources/${resourceId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const useBookmark = () =>
  useMutation((params: BookmarkResourceProps) => bookmarkResource(params))

const unBookmarkResource = async ({ resourceId }: BookmarkResourceProps) => {
  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
    }v1/bookmarks/resources/${resourceId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const useUnBookmark = () =>
  useMutation((params: BookmarkResourceProps) => unBookmarkResource(params))
