import React from 'react'

import { useAuth } from '../../contexts/AuthProvider'
import type { Patient } from '../../types/Patient'
import type {
  ResourceSection,
  ResourceStatus,
  ResourceTopic,
} from '../../types/Resources'
import { FOR_WHO_RESOURCE } from '../../types/Resources'
import CoursesGrid from './courses/CoursesGrid'
import ResumeCourses from './courses/ResumeCourses'
import CourseGridLoader from './courses/CourseGridLoader'
import FilteredCoursesGrid from './courses/FilteredCoursesGrid'

const CoursesView: React.FC<{
  selectedStatuses: ResourceStatus[]
  selectedTopics: ResourceTopic[]
  debouncedMainSearchValue: string
  isLoadingFilteredResources: boolean
  filteredResources: ResourceSection
  currentPageFilteredResources: number
  setCurrentPageFilteredResources: React.Dispatch<React.SetStateAction<number>>
}> = ({
  selectedStatuses,
  selectedTopics,
  debouncedMainSearchValue,
  isLoadingFilteredResources,
  filteredResources,
  currentPageFilteredResources,
  setCurrentPageFilteredResources,
}) => {
  const { user } = useAuth()

  const appliedFilters: boolean =
    Boolean(selectedStatuses?.length) ||
    Boolean(selectedTopics?.length) ||
    Boolean(debouncedMainSearchValue?.length)

  return (
    <>
      {/* Filtered resources */}
      {appliedFilters &&
        (isLoadingFilteredResources ? (
          <CourseGridLoader loadingNumber={6} />
        ) : filteredResources?.totalElements ? (
          <FilteredCoursesGrid
            currentPage={currentPageFilteredResources}
            setCurrentPage={setCurrentPageFilteredResources}
            resourceSection={filteredResources}
            isLoadingResourceSection={isLoadingFilteredResources}
          />
        ) : null)}

      {/* No filters applied or no filtered resources found */}
      {(!appliedFilters ||
        (!isLoadingFilteredResources && !filteredResources?.totalElements)) && (
        <>
          {/* Resume courses */}
          <ResumeCourses />

          {/* No patients -> courses for user */}
          {!user.roster.some(
            (p: Patient) => p?.relationship?.key !== 'myself'
          ) && <CoursesGrid forCaregiver={true} patientId={user.data.id} />}

          {/* Courses for caregivers */}
          {user.roster.some(
            (p: Partial<Patient>) => p.relationship?.key !== 'myself'
          ) && (
            <CoursesGrid
              forCaregiver={true}
              patientId={user.data.id}
              forWhoTitle={FOR_WHO_RESOURCE.FOR_CAREGIVERS}
              lineColor="border-primaryAccents-pink"
            />
          )}

          {/* Courses for patients */}
          {React.Children.toArray(
            user.roster
              ?.filter((p: Patient) => p.relationship?.key !== 'myself')
              .map((p: Patient) => (
                <CoursesGrid
                  patientId={p.id}
                  forWhoTitle={`${FOR_WHO_RESOURCE.FOR_PATIENT} ${p.firstName}`}
                  lineColor="border-secondaryAccents-lightGreen"
                />
              ))
          )}
        </>
      )}
    </>
  )
}

export default CoursesView
