import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface Customer {
  createdAt: number
  createdBy: string
  id: string
  modifiedAt: number
  modifiedBy: string
  stripeCustomerId: string
  userId: string
  version: number
}

export const useGetCustomer = () =>
  useQuery(['getCustomer'], async () => getCustomer())

const getCustomer = async (): Promise<Customer> => {
  const token: string = await auth.currentUser.getIdToken()
  const customer: Customer = await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL}v1/customers`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return customer
}
