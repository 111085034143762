import type { Therapist } from './Therapist'

export type UserWhoScheduled = {
  name: string
  id: string
}

export type Session = {
  patientId: string
  patientName: string
  type: string
  therapist: Therapist
  zoomLink: string
  startTime: string
  endTime: string
  status: string
  rating: number | null
  id: string
  carePlanId: string
  conditionId: string
  canceledAt: string
  name: string
  scheduledBy?: UserWhoScheduled
  sessionType?: string
}

export const mapSessionStatus = (status: string): string => {
  switch (status) {
    case 'completed':
    case 'completed_makeup_session':
      return 'completed'
    case 'no_show':
      return 'absent'
    case 'pending':
      return 'pending'
    case 'canceled_by_patient':
    case 'canceled_by_therapist':
    case 'tech_trouble_cancellation':
    case 'canceled_no_payment':
      return 'cancelled'
    case 'late_cancel_by_patient':
      return 'late_cancel'
    default:
      return status
  }
}

export const mapSessionName = (
  therapyType: string,
  sessionType: string
): string => {
  return `${therapyType} ${
    sessionType ? sessionType?.replace('_', ' ') : 'Session'
  }`
}
