import { ExclamationIcon } from '@heroicons/react/outline'

import CenteredModal from '../CenteredModal'
import {
  deleteButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useNavigate } from 'react-router-dom'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import type { ServiceLine } from '../../types/ServiceLine'
import { useProviderContext } from '../../contexts/ProviderContext'

const WantDifferentTherapistModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  serviceLine: ServiceLine
  currentProviderName: string
}> = ({ open, setOpen, serviceLine, currentProviderName }) => {
  const navigate = useNavigate()
  const { setShowTherapistModal, setWantDifferentTherapist } =
    useProviderContext()

  const viewOtherProviders = () => {
    setShowTherapistModal(location.pathname.includes('/booking'))
    setWantDifferentTherapist(true)

    navigate(
      `/booking/${
        SERVICE_LINES_ARRAY.find(
          (line: ServiceLine) => line.displayName === serviceLine?.displayName
        ).url
      }/providers`
    )
  }

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        <div className="flex flex-row gap-4">
          <div className="self-start rounded-full bg-status-error bg-opacity-20 p-2.5">
            <ExclamationIcon className="h-5 w-5 text-status-error" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">Want a different therapist?</p>
            <p className="whitespace-pre-wrap">
              Switching your therapist will cancel any future sessions you had
              booked with {currentProviderName} (your original therapist).
              <br />
              <br />
              Don't worry, switching is not official until you book a session
              with the new therapist.
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button className={deleteButtonClass} onClick={viewOtherProviders}>
            I understand, view my options
          </button>
          <button
            className={tertiaryButtonClass}
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default WantDifferentTherapistModal
