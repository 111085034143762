import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TabNavigation: React.FC<{
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  tabs: string[]
  hiddenBottomBar?: boolean
}> = ({ activeTab, setActiveTab, tabs, hiddenBottomBar = false }) => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      setActiveTab(location.hash.replace('#', ''))
    }
  }, [location.hash])

  const onChangeTab = (tab: string) => {
    setActiveTab(tab)
    window.location.hash = tab
  }

  return (
    <div
      className={`flex w-full gap-4 ${
        !hiddenBottomBar ? 'border-b border-b-components-fields' : ''
      } xs:justify-center`}
    >
      {React.Children.toArray(
        tabs.map((tab: string) => (
          <button
            className={`${
              activeTab === tab
                ? 'border-b-2 border-b-cta-disabled text-cta-default'
                : 'text-text-label'
            } -mb-px px-5 pb-1 text-base font-semibold focus:outline-none xs:text-sm`}
            onClick={() => onChangeTab(tab)}
          >
            {tab}
          </button>
        ))
      )}
    </div>
  )
}

export default TabNavigation
