import React from 'react'
import { Outlet } from 'react-router-dom'

import Banner from '../DashboardMenu/Banner'
import Navbar from '../DashboardMenu/Navbar'

const LeftNavScreen: React.FC<{
  bannerClassName?: string
  hideLateralPaddingSmallScreens?: boolean
  hideTopPaddingAllScreens?: boolean
  centerContent?: boolean
  figureRightBanner?: boolean
}> = ({
  bannerClassName = '',
  hideTopPaddingAllScreens = false,
  hideLateralPaddingSmallScreens = false,
  centerContent = true,
  figureRightBanner = false,
}) => {
  return (
    <div className="overflow-y-auto font-grotesque text-text-primary">
      {/* Content */}
      <div className="flex flex-col bg-background-offwhite">
        {/* Banner */}
        {Boolean(bannerClassName?.length) && (
          <Banner
            bannerClassName={bannerClassName}
            bgImageBannerClass={
              figureRightBanner
                ? 'bg-banner-figure-right-mobile sm:bg-banner-figure-right'
                : ''
            }
          />
        )}

        {/* Navbar */}
        <Navbar />

        {/* Children Content */}
        <div
          className={`z-20 flex min-h-screen flex-col items-center pb-16 lg:pl-21 lg:pb-0 
          ${hideLateralPaddingSmallScreens ? 'xs:px-0' : 'xs:px-4'}
          ${centerContent ? 'justify-center' : ''}
          ${hideTopPaddingAllScreens ? '' : 'pt-5'}
          `}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default LeftNavScreen
