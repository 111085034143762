import React from 'react'
import { Link } from 'react-router-dom'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { usePatient } from '../../contexts/PatientProvider'
import useNavigateInsuranceComplete from './hooks/useNavigateInsuranceComplete'
import { RefreshIcon } from '@heroicons/react/solid'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'

const InsuranceInfoPolicyHolderAddressConfirm: React.FC = () => {
  const { patient } = usePatient()
  const [navigateAccordinglyToFlow, isLoadingNavigateAccordinglyToFlow] =
    useNavigateInsuranceComplete()

  return (
    <div className="max-w-lg text-center">
      <h2 className="text-lg font-semibold sm:text-2xl">
        Is this the correct policy holder's address?
      </h2>
      <p className="py-3.5 px-4 text-sm font-normal sm:text-base">
        {[
          patient.insurance.streetAddress,
          patient.insurance.apartmentUnit,
          patient.insurance.city,
          patient.insurance.state,
          patient.insurance.zip,
        ]
          .filter((str: string) => Boolean(str.trim()))
          .join(', ')}
      </p>
      <div className="flex flex-col gap-6 sm:gap-12">
        <div className="grid grid-cols-2 gap-2 sm:gap-4">
          <Link
            to="../edit"
            state={{ insurance: patient.insurance }}
            className={tertiaryButtonClass}
          >
            No, edit
          </Link>
          <button
            className={primaryButtonClass}
            type="button"
            disabled={isLoadingNavigateAccordinglyToFlow}
            onClick={() =>
              navigateAccordinglyToFlow({
                unassociatedInsurance: patient.unassociatedInsurance,
              })
            }
          >
            {isLoadingNavigateAccordinglyToFlow ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                Loading
              </>
            ) : (
              <>Yes, it's correct</>
            )}
          </button>
        </div>
        <SkipAndComeBackLater />
      </div>
    </div>
  )
}

export default InsuranceInfoPolicyHolderAddressConfirm
