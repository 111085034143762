import type { ServiceLine } from '../types/ServiceLine'
import type { Product } from '../types/User'
import { useAuth } from '../contexts/AuthProvider'
import useCurrentServiceLine from './useCurrentServiceLine'

const useProductAdditionalSessionsType = () => {
  const { user } = useAuth()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()

  return user.products.find(
    (p: Product) =>
      p.serviceLine.serviceId === currentServiceLine.serviceId && !p.isIep
  )?.additionalSessions
}

export default useProductAdditionalSessionsType
