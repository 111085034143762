import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

const deleteDocument = async (id: string) => {
  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_STORAGE_URL}/v1/documents/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const useDeleteDocument = () =>
  useMutation((params: { id: string }) => deleteDocument(params.id))
