import { Combobox } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import type { SelectInsuranceModel } from '../../types/Insurance'
import {
  comboBoxClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useGetInsurances } from '../../queries/dashboard/Insurances'
import { useDebounce } from '../../hooks/useDebounce'
import INSURANCE from '../../assets/images/Insurance.svg'
import { formatPossessiveNoun } from '../../helpers/generic'
import { usePatient } from '../../contexts/PatientProvider'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import { ONBOARDING_STEP } from '../../types/Patient'
import { useAuth } from '../../contexts/AuthProvider'
import { useToastContext } from '../../contexts/ToastContext'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'

const InsuranceChoose: React.FC = () => {
  const addToast = useToastContext()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { patient, setPatient } = usePatient()
  const forSelf: boolean = patient?.relationship?.name === 'Myself'
  const [insurance, setInsurance] = useState<SelectInsuranceModel>(null)
  const [query, setQuery] = useState<string>('')
  const [blockSearch, setBlockSearch] = useState<boolean>(false)
  const debouncedSearchQuery = useDebounce(query, 600)
  const { data: insurances, isFetching } = useGetInsurances({
    name: debouncedSearchQuery,
    blockSearch,
  })
  const { mutateAsync: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()

  const submit = () =>
    navigate('/insurance/information', {
      state: {
        insurance,
      },
    })

  const handleDontHaveInsuranceClick = () => {
    const newPatient = cloneDeep(patient)

    callUpdateProfile(
      {
        patient: {
          ...patient,
          conditions: newPatient.conditions.map((c: Condition) => ({
            ...c,
            carePlans: c.carePlans.map((cp: CarePlan) => ({
              ...cp,
              dontHaveInsurance: true,
            })),
          })),
        },
        user,
      },
      {
        onSuccess: () => {
          setPatient(newPatient)
          redirectNoInsurance()
        },
      }
    )
  }

  const redirectNoInsurance = () => {
    // onboarding multiple patients, check if there are patients without insurance
    // const currentPatientIdx = user?.roster.findIndex(
    //   (p: Patient) => p.id === patient.id
    // )
    // const otherPatientWithoutInsurance = user?.roster.find(
    //   (p: Patient) =>
    //     !p.unassociatedInsurance &&
    //     !p.insurance &&
    //     p.id !== patient.id &&
    //     p?.onboardingStep === ONBOARDING_STEP.insurance
    // )

    const newUser = cloneDeep(user)

    // ONLY BECAUSE WE ARE NOT USING INSURANCE else newUser.roster[currentPatientIdx].onboardingStep = ONBOARDING_STEP.booking
    newUser.roster.forEach((_, index) => {
      newUser.roster[index].onboardingStep = ONBOARDING_STEP.booking
    })

    flushSync(() => {
      setUser(newUser)

      // if (otherPatientWithoutInsurance) return setPatient(otherPatientWithoutInsurance)

      const patientsInBooking = newUser.roster.filter(
        (p: Patient) => p?.onboardingStep === ONBOARDING_STEP.booking
      )

      // if only IEP patients OR patients without allowed to book
      if (
        patientsInBooking.every(
          (p: Patient) => p?.isIepOnly || !p?.allowedToBook
        )
      )
        navigate('/dashboard')
      // default behaviour
      else
        navigate(
          `/select-service-line?multipleOnboarding=${
            patientsInBooking?.length > 1
          }`
        )
    })
  }

  useEffect(() => {
    if (!patient) return navigate('/dashboard')

    if (
      !patient?.conditions?.flatMap((c: Condition) => c.carePlans)?.flat()
        ?.length
    ) {
      addToast('warning', 'No care plans found.')
      return navigate('/dashboard')
    }

    // INSURANCE REVERTED
    // Use setTimeout to move the flushSync call to a micro task
    setTimeout(() => {
      redirectNoInsurance()
    }, 0)
  }, [])

  return (
    <div className="max-w-xl items-center">
      <div className="flex flex-col items-center justify-center gap-6">
        <p className="text-center text-2xl font-bold xs:text-lg">
          Do you have health insurance?
        </p>
        <p className="text-center text-base font-semibold xs:text-sm xs:font-normal">
          Search for{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : `${formatPossessiveNoun(patient?.firstName)}`}
          </span>{' '}
          insurance
        </p>
      </div>
      <img src={INSURANCE} alt="insurance" className="xs:h-30 xs:w-30" />

      {/* Search insurance */}
      <div className="flex w-3/4 flex-col justify-center xs:w-full">
        <div className="relative rounded-md shadow-sm">
          <Combobox as="div" value={insurance} onChange={setInsurance}>
            <Combobox.Label className="block text-base font-semibold xs:text-sm">
              Insurance
            </Combobox.Label>
            <div className="relative mt-1">
              <Combobox.Input
                disabled={isLoadingUpdateProfile}
                className={`${comboBoxClass} capitalize`}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(i: SelectInsuranceModel) =>
                  i?.name.toLowerCase()
                }
                placeholder="e.g. Blue Cross Blue Shield"
                autoComplete="off"
                onFocus={() => setBlockSearch(false)}
              />
              <Combobox.Button
                className={`${
                  !insurances?.length ? 'cursor-default' : ''
                } absolute inset-y-0 right-3 flex items-center rounded-r-md px-2 focus:outline-none`}
              >
                {isFetching ? (
                  <RefreshIcon
                    className="loader h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  Boolean(insurances?.length) && (
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )
                )}
              </Combobox.Button>

              {!isFetching && Boolean(insurances) && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {(insurances
                    ? [
                        ...insurances,
                        { name: 'Other', id: 'other', other: true },
                      ]
                    : []
                  ).map((insurance) => (
                    <Combobox.Option
                      key={insurance.id}
                      value={insurance}
                      onClick={() => setBlockSearch(true)}
                      className={({ active }) =>
                        `${
                          active ? 'bg-components-fillBorders' : ''
                        } relative cursor-pointer select-none p-4 py-2 xs:text-sm`
                      }
                    >
                      <span className="block truncate capitalize">
                        {insurance?.name?.toLowerCase()}
                      </span>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        </div>
      </div>

      <div className="mt-8 grid w-full grid-cols-2 items-stretch gap-2 px-0 sm:mt-0 sm:gap-4 sm:px-10">
        <button
          className={tertiaryButtonClass}
          type="button"
          onClick={handleDontHaveInsuranceClick}
          disabled={isFetching || isLoadingUpdateProfile}
        >
          I don't have insurance
        </button>
        <button
          type="button"
          className={primaryButtonClass}
          onClick={submit}
          disabled={!insurance || isFetching || isLoadingUpdateProfile}
        >
          {isLoadingUpdateProfile ? (
            <>
              Loading <RefreshIcon className="loader h-5 w-5 text-white" />
            </>
          ) : (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5 text-white" />
            </>
          )}
        </button>
      </div>

      <button
        className="-mt-2 text-sm font-medium text-text-secondary underline sm:mt-2 sm:text-base"
        type="button"
        disabled={isLoadingUpdateProfile}
        onClick={redirectNoInsurance}
      >
        I don't want to use insurance
      </button>
    </div>
  )
}

export default InsuranceChoose
