import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { RefreshIcon } from '@heroicons/react/solid'

import CenteredModal from '../CenteredModal'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import type { Patient } from '../../types/Patient'
import { PatientAvatarAndName } from '../../screens/account-settings/PermissionSettings'
import { useSendSeparateLoginInvite } from '../../mutations/permissions/SendSeparateLoginInvite'
import { useToastContext } from '../../contexts/ToastContext'

interface ManagePermissionForm {
  ableToBookAndCancel: boolean
}

const ManagePermissionModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  patient: Patient
}> = ({ open, setOpen, patient }) => {
  const addToast = useToastContext()
  const navigate = useNavigate()

  const [resentInvite, setResentInvite] = useState<boolean>(false)

  const { mutate: callSendInvite, isLoading: isLoadingSendingInvite } =
    useSendSeparateLoginInvite()

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    setValue,
  } = useForm<ManagePermissionForm>({
    mode: 'all',
  })

  const onSubmit = (data: ManagePermissionForm) =>
    navigate(
      `/create-permission/${patient.id}/consent?ableToBookAndCancel=${data.ableToBookAndCancel}`
    )

  const resendInvite = () => {
    if (!patient?.email)
      return addToast(
        'error',
        'No email available for this patient. Please contact support.'
      )

    callSendInvite(
      { personId: patient.personId, email: patient?.email },
      { onSettled: () => setResentInvite(true) }
    )
  }

  useEffect(() => {
    if (!open)
      return reset({
        ableToBookAndCancel: patient?.ableToBookAndCancel,
      })

    const within24Hours =
      patient?.separateLoginInvitedAt &&
      new Date().getTime() / 1000 - patient.separateLoginInvitedAt < 86400

    setValue('ableToBookAndCancel', patient?.ableToBookAndCancel)
    setResentInvite(within24Hours)
  }, [open, patient, reset])

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        <p className="text-lg font-semibold">Manage Separate Login</p>
        <PatientAvatarAndName patient={patient} />

        <div className="flex flex-col gap-4">
          <p className="text-base font-semibold">Separate Login</p>
          <div className="flex flex-col gap-8 rounded-lg border border-components-fields p-4">
            <div>
              <p className="text-base font-semibold">Account Permissions</p>
              <p>This account can:</p>
              <div className="mt-4 flex items-center gap-2">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-cta-default focus:ring-cta-disabled"
                  {...register('ableToBookAndCancel')}
                />
                <p className="text-base text-cta-default xs:text-sm">
                  Book and cancel sessions
                </p>
              </div>

              <div className="my-4 w-full border-t border-components-fillBorders" />

              <p className="text-base font-semibold">Email</p>
              <div className="ml-4 flex items-center justify-between gap-2">
                <p className="text-base">{patient?.email || '-'}</p>
                {!patient?.hasSeparateLogin && (
                  <>
                    {isLoadingSendingInvite ? (
                      <div className="flex items-center gap-1 text-text-label">
                        <RefreshIcon className="loader h-5 w-5" />
                        <p className="text-sm">Sending email..</p>
                      </div>
                    ) : resentInvite ? (
                      <p className="text-sm italic text-status-success">
                        Invite sent! Can resend in 24 hours.
                      </p>
                    ) : (
                      patient?.separateLoginInvitedAt && (
                        <button
                          className="text-base text-cta-default underline"
                          onClick={resendInvite}
                        >
                          Resend Invite
                        </button>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-4">
        <button
          className={tertiaryButtonClass}
          onClick={() => setOpen(false)}
          disabled={isLoadingSendingInvite}
        >
          Cancel
        </button>
        <button
          className={primaryButtonClass}
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || !isValid || isLoadingSendingInvite}
        >
          {isLoadingSendingInvite ? (
            <div className="flex items-center gap-1">
              <RefreshIcon className="loader h-5 w-5" />
              <p className="text-sm">Sending..</p>
            </div>
          ) : (
            'Save'
          )}
        </button>
      </div>
    </CenteredModal>
  )
}

export default ManagePermissionModal
