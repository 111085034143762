import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { ResumeCourse, ResumeCourseSection } from '../../types/Courses'
import type { User } from '../../types/User'
import { formatModuleDuration } from '../../helpers/utils'
import { resourceStatusOptions } from '../../constants/values'

type GetResumeCoursesParams = {
  user: User
  pageNumber?: number
  pageSize?: number
}

export const useGetResumeCourses = (params: GetResumeCoursesParams) =>
  useQuery(['getResumeCourses', params.pageNumber], async () =>
    getResumeCourses(params)
  )

const getResumeCourses = async ({
  user,
  pageNumber,
  pageSize,
}: GetResumeCoursesParams): Promise<ResumeCourseSection> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/courses/in-progress?page=${pageNumber}&pageSize=${pageSize}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    const result: ResumeCourse[] = []

    response.courses.forEach((course: any) => {
      course.courseProgresses
        .filter((cp: any) => cp.nextModule)
        .forEach((cp: any) => {
          result.push({
            id: course.id,
            imageSrc: course.coverUrl,
            numberOfVideos: course.modulesCount,
            title: course.title,
            video: {
              id: cp.nextModule.id,
              title: cp.nextModule.title,
              duration: formatModuleDuration(cp.nextModule.duration),
              imageSrc: cp.nextModule.coverUrl,
            },
            courseProgress: {
              status: resourceStatusOptions.find((s) => s.id === 'in_progress'),
              completedAt: null,
              completePercentage: cp.progress,
              patient: cp.patientId
                ? user.roster.find((p) => p.id === cp.patientId)
                : null,
            },
          })
        })
    })

    return {
      courses: result,
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }
  } catch (error) {
    return null
  }
}
