import React from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom'

import { usePatient } from '../../contexts/PatientProvider'
import { useAuth } from '../../contexts/AuthProvider'

const DashboardElement: React.FC<{
  children: React.ReactNode
  title?: string | React.ReactNode
  headerTitle?: string
  filled?: boolean
  hidden?: boolean
  addAnotherPersonButton?: boolean
  noMoreSessionsRemaining?: boolean
  noContentPadding?: boolean
  noGap?: boolean
  noStyling?: boolean
  rightTitleButton?: React.ReactNode
}> = ({
  children,
  title,
  filled,
  hidden,
  headerTitle,
  addAnotherPersonButton,
  noContentPadding,
  noGap,
  noStyling,
  rightTitleButton,
}) => {
  const { user } = useAuth()
  const { setPatient } = usePatient()
  const navigate = useNavigate()

  return (
    <div
      className={`${hidden ? 'hidden' : ''} ${
        noGap ? '' : 'gap-6'
      } flex w-full flex-col sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-4.5xl xs:gap-0`}
    >
      {/* Header */}
      <div
        className={`${
          !headerTitle ? 'hidden' : ''
        } mt-4 flex items-center justify-between border-b-2 border-beige pb-1 xs:mx-4`}
      >
        {/* Title */}
        <p className="flex items-center gap-2 text-left text-2.5xl font-semibold xs:text-lg">
          {headerTitle}
        </p>

        {/* Button */}
        {user?.roster?.length < 10 && addAnotherPersonButton && (
          <button
            onClick={() => {
              navigate('/onboarding/care-for', {
                state: { from: '/dashboard' },
              })
              setPatient(null)
            }}
            className="flex items-center gap-1 rounded-md bg-background-offwhite px-4 py-2.5 text-base font-semibold text-cta-default xs:text-sm"
          >
            <PlusIcon className="h-5 w-5 xs:h-3 xs:w-3" />
            Add another person
          </button>
        )}
      </div>

      {/* Actual content */}
      {noStyling ? (
        <div className="flex flex-col gap-6 xs:gap-4 xs:px-4">{children}</div>
      ) : (
        <div
          className={`${
            filled
              ? 'border-cta-default bg-cta-default text-white'
              : 'border-components-fields bg-white '
          } ${noContentPadding && !noStyling ? '' : 'p-8'} ${
            noStyling ? '' : 'gap-6'
          } flex flex-col rounded-2xl border shadow-lg xs:gap-4 xs:rounded-none xs:border-0 xs:p-4 xs:text-lg`}
        >
          <div
            className={`${
              !title ? 'hidden' : ''
            } flex items-center justify-between gap-2`}
          >
            <p className="text-2xl font-semibold xs:text-lg">{title}</p>
            {rightTitleButton}
          </div>

          {children}
        </div>
      )}
    </div>
  )
}

export default DashboardElement
