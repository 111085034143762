import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { Patient } from '../../types/Patient'

interface PostConsentFormProps {
  name: string
  version: number
  signatureDate: string
  patient: Partial<Patient>
}

const postConsentForm = async ({
  name,
  version,
  signatureDate,
  patient,
}: PostConsentFormProps): Promise<boolean> => {
  const token: string = await auth.currentUser.getIdToken()
  const r = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PATIENT_PACKET_URL
    }/v1/separate-login-consent/sign`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name,
        version,
        signatureDate,
        patientId: patient.id,
        patientName: `${patient.firstName} ${patient.lastName}`,
        patientRelationship: patient?.relationship?.name || '',
      }),
    }
  )

  return r.status === 200
}

export const usePostConsentForm = () =>
  useMutation((params: PostConsentFormProps) => postConsentForm(params))
