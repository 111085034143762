import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { ResourceTopic } from '../../types/Resources'
import { getTopicsForPatient } from '../../helpers/externalCalls'

interface SaveInterestsProps {
  patientId: string
  interests: ResourceTopic[]
}

const saveInterest = async ({
  patientId,
  interests,
}: SaveInterestsProps): Promise<ResourceTopic[]> => {
  const token = await auth.currentUser.getIdToken()
  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
    }v1/preferences/patients/${patientId}/add`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        interests.map((i: ResourceTopic) => ({
          preferenceKey: i.key.includes('OTHER') ? i?.key : 'TOPIC',
          value: i.key.includes('OTHER') ? i?.name : i?.key,
        }))
      ),
    }
  )

  const topics: ResourceTopic[] = await getTopicsForPatient(patientId)

  return topics
}

export const useSaveInterest = () =>
  useMutation((params: SaveInterestsProps) => saveInterest(params))
