import React from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'

import INSURANCE from '../../assets/images/Insurance.svg'
import {
  primaryButtonClass,
  secondaryButtonClass,
} from '../../constants/classConstants'
import { useAuth } from '../../contexts/AuthProvider'
import { usePatient } from '../../contexts/PatientProvider'
import type { Insurance } from '../../types/Insurance'
import type { Patient } from '../../types/Patient'
import { ONBOARDING_STEP } from '../../types/Patient'

const InsuranceUseSame: React.FC = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { patient, setPatient } = usePatient()
  const location = useLocation()
  const insurance: Insurance = location?.state?.insurance

  const useSameInsurance = () => {
    navigate('/insurance/information', {
      state: { defaulValuesForInsuranceForm: insurance },
    })
  }

  const redirectNoInsurance = () => {
    const otherPatientDuringOnboarding = user.roster.find(
      (p: Patient) =>
        !p.unassociatedInsurance &&
        !p.insurance &&
        p.id !== patient.id &&
        p?.onboardingStep === ONBOARDING_STEP.insurance
    )

    if (Boolean(otherPatientDuringOnboarding)) {
      flushSync(() => setPatient(otherPatientDuringOnboarding))
      navigate('/insurance', {
        state: {
          insurance,
        },
      })
      return
    }

    const patientsInBooking = user.roster.filter(
      (p: Patient) => p?.onboardingStep === ONBOARDING_STEP.booking
    )

    // if only IEP patients OR current patient not allowed to book
    if (
      patientsInBooking.every((p: Patient) => p?.isIepOnly) ||
      !patient.allowedToBook
    ) {
      navigate('/dashboard')
    } else {
      // default behaviour
      navigate(
        `/select-service-line?multipleOnboarding=${
          patientsInBooking?.length > 1
        }`
      )
    }
  }

  if (!insurance || !patient) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-4xl items-center">
      <p className="text-center text-2xl font-bold xs:text-lg">
        For{' '}
        {patient?.relationship?.key === 'myself' ? (
          'you'
        ) : (
          <span className="text-cta-default">{patient.firstName}</span>
        )}
        , would you like to use the same insurance information?
      </p>
      <img src={INSURANCE} alt="insurance" className="xs:h-30 xs:w-30" />

      <div className="grid w-full max-w-lg grid-cols-2 gap-2 sm:gap-4">
        <Link to="/insurance" className={secondaryButtonClass} type="button">
          No
        </Link>
        <button
          type="button"
          className={primaryButtonClass}
          onClick={useSameInsurance}
        >
          Yes
        </button>
      </div>

      <button
        className="mt-0 text-sm font-medium text-text-secondary underline sm:mt-2 sm:text-base"
        type="button"
        onClick={redirectNoInsurance}
      >
        I don't want to use insurance
      </button>
      <button
        className="mt-0 text-sm font-medium text-text-secondary underline sm:mt-2 sm:text-base"
        type="button"
        onClick={redirectNoInsurance}
      >
        I don't have insurance
      </button>
    </div>
  )
}

export default InsuranceUseSame
