import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useMediaQuery from '../../hooks/useMediaQuery'

const NavButton: React.FC<{
  icon: any
  iconWhite?: any
  text: string
  to?: string
  handleOnClick?: () => void
  hideMobile?: boolean
  hideDesktop?: boolean
}> = ({
  icon,
  iconWhite,
  text,
  to,
  handleOnClick,
  hideMobile = false,
  hideDesktop = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const navigate = useNavigate()
  const location = useLocation()
  const [currentIcon, setCurrentIcon] = React.useState(iconWhite)
  const isActive = location.pathname === to

  const buttonClass = `
    ${
      isActive ? 'lg:bg-components-fillBorders text-text-primary' : 'text-white'
    } 
    ${hideMobile ? 'xs:hidden' : ''} 
    ${hideDesktop ? 'lg:hidden' : ''} 
    flex lg:flex-row lg:h-13 lg:w-13 lg:group-hover:w-full lg:items-center rounded-md justify-center lg:p-4 lg:hover:bg-components-fillBorders hover:text-text-primary flex-col lg:gap-4`

  const textClass = `${
    isMobile ? 'text-text-primary' : ''
  } lg:hidden lg:font-semibold lg:text-base lg:group-hover:flex lg:mt-0 mt-1 block text-xs font-light lg:w-full`

  const iconClass = 'w-5 h-5 object-cover self-center'

  return (
    <button
      onClick={handleOnClick ? () => handleOnClick() : () => navigate(to)}
      className={buttonClass}
      onMouseEnter={() => setCurrentIcon(icon)}
      onMouseLeave={() => setCurrentIcon(iconWhite)}
    >
      <img
        src={isActive || isMobile ? icon : currentIcon}
        className={iconClass}
        alt="icon"
      />
      <p className={textClass}>{text}</p>
    </button>
  )
}

export default NavButton
