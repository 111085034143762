import { PlusIcon } from '@heroicons/react/solid'
import React from 'react'

const DottedLargeButton: React.FC<{
  onClick: () => void
  title: string
  disabled?: boolean
}> = ({ onClick, title, disabled = false }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`${
        disabled
          ? 'border-components-disabled text-cta-disabled'
          : 'border-components-fields text-cta-default'
      } my-2 w-full rounded-2xl border-2 border-dashed p-8 text-center xs:rounded-md xs:p-4`}
    >
      <div className="flex w-full items-center justify-center border-none text-base font-semibold xs:text-sm">
        <PlusIcon className="mr-4 h-5 w-5" />
        {title}
      </div>
    </button>
  )
}

export default DottedLargeButton
