import type { KeyboardEvent } from 'react'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'

import { useAuth } from '../../contexts/AuthProvider'
import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
} from '../../constants/classConstants'
import { states } from '../../constants/values'
import type { Patient } from '../../types/Patient'
import { formatPossessiveNoun } from '../../helpers/generic'
import { useToastContext } from '../../contexts/ToastContext'
import type { State } from '../../types/State'
import { usePatient } from '../../contexts/PatientProvider'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'

interface PhysicalAddressFormData {
  streetAddress: string
  apartmentUnit: string
  city: string
  state: string
  zip: string
}

const PhysicalAddressFormSchema = Yup.object().shape({
  streetAddress: Yup.string()
    .required('Street address is required.')
    .nullable()
    .trim(),
  apartmentUnit: Yup.string().nullable().trim(),
  city: Yup.string().required('City is required.').nullable().trim(),
  state: Yup.string().required('State is required.').nullable().trim(),
  zip: Yup.string().required('Zip code is required.').nullable().trim(),
})

const PhysicalAddress: React.FC = (): JSX.Element => {
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { mutate: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const addToast = useToastContext()
  const { setPatient, patient } = usePatient()
  const forSelf = patient.relationship.name === 'Myself'

  const {
    setValue,
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<PhysicalAddressFormData>({
    defaultValues: {
      streetAddress: patient.streetAddress || '',
      apartmentUnit: patient.apartmentUnit || '',
      city: patient.city || '',
      state: patient.state || '',
      zip: patient.zip || '',
    },
    mode: 'all',
    resolver: yupResolver(PhysicalAddressFormSchema),
  })

  const onSubmit = (data: PhysicalAddressFormData) => {
    const newPatient: Patient | Partial<Patient> = {
      ...patient,
      streetAddress: data.streetAddress,
      apartmentUnit: data.apartmentUnit,
      city: data.city,
      state: data.state,
      zip: data.zip,
    }

    callUpdateProfile(
      {
        patient: newPatient,
        user,
      },
      {
        onError: () => addToast('error', 'Something went wrong'),
        onSuccess: (newPatient) => {
          const newUser = cloneDeep(user)
          const patientIdx = newUser.roster.findIndex(
            (p: Patient) => p.id === patient.id
          )
          newUser.roster[patientIdx].streetAddress = data.streetAddress
          newUser.roster[patientIdx].apartmentUnit = data.apartmentUnit
          newUser.roster[patientIdx].city = data.city
          newUser.roster[patientIdx].state = data.state
          newUser.roster[patientIdx].zip = data.zip
          setUser(newUser)
          setPatient(
            newUser.roster.find((p: Patient) => p.id === newPatient.id)
          )
          navigate('/dashboard', {
            state: {
              updatedPhysicalAddress: true,
            },
          })
        },
      }
    )
  }

  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  return (
    <div className="mt-16 mb-auto flex max-w-lg flex-col items-center gap-6 text-text-primary sm:mt-28 sm:gap-10 sm:py-10">
      <div className="flex flex-col gap-6 text-center">
        <p className="text-base font-semibold sm:text-2xl">
          What is{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : `${formatPossessiveNoun(patient.firstName)}`}
          </span>{' '}
          physical address?
        </p>
        <p className="text-sm font-normal sm:text-base">
          {'You can find this information later in Roster > Profile'}
        </p>
      </div>
      <div className="my-auto grid w-full max-w-md grid-cols-2 gap-4">
        <Controller
          control={control}
          name="streetAddress"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1">
              <label
                htmlFor="streetAddress"
                className="text-sm font-semibold after:ml-0.5 after:text-status-error after:content-['*'] sm:text-base"
              >
                Street address
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Street address"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="apartmentUnit"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1">
              <label
                htmlFor="apartmentUnit"
                className="text-sm font-semibold sm:text-base"
              >
                Apt/Unit (optional)
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Apt or Unit number"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="city"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-2 flex flex-col gap-1">
              <label
                htmlFor="city"
                className="text-sm font-semibold after:ml-0.5 after:text-status-error after:content-['*'] sm:text-base"
              >
                City
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="City name"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="state"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-1 flex flex-col gap-1 xs:col-span-2">
              <Listbox {...field}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="font-semibold after:ml-0.5 after:text-status-error after:content-['*']">
                      State
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = states.find((s: State) =>
                            s.name.toLowerCase().startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('state', match.abbrev, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={error ? comboBoxErrorClass : comboBoxClass}
                      >
                        <span
                          className={`${
                            Boolean(field?.value) ? '' : 'text-text-placeholder'
                          } block truncate`}
                        >
                          {states?.find((s: State) => s.abbrev === field?.value)
                            ?.name || 'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          {Boolean(error) ? (
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-status-error"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {states.map((s: State) => (
                            <Listbox.Option
                              key={s.abbrev}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={s.abbrev}
                            >
                              <span>{s.name}</span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                    {Boolean(error) && (
                      <p className="text-sm text-status-error">
                        {error.message}
                      </p>
                    )}
                  </>
                )}
              </Listbox>
            </div>
          )}
        />

        <Controller
          control={control}
          name="zip"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <div className="col-span-1 flex flex-col gap-1 xs:col-span-2">
              <label
                htmlFor="zip"
                className="text-sm font-semibold after:ml-0.5 after:text-status-error after:content-['*'] sm:text-base"
              >
                Zip code
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    error ? inputErrorClass : inputValidClass
                  } font-normal`}
                  placeholder="Zip code"
                  {...field}
                />
                {Boolean(error) && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-status-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {Boolean(error) && (
                <p className="text-sm text-status-error">{error.message}</p>
              )}
            </div>
          )}
        />
      </div>
      <div className="flex w-full flex-col gap-4 sm:gap-12">
        <button
          disabled={!isValid || !isDirty || isLoadingUpdateProfile}
          className={`${primaryButtonClass} w-full`}
          onClick={handleSubmit(onSubmit)}
        >
          {isLoadingUpdateProfile ? (
            <>
              <RefreshIcon
                className="loader h-5 w-5 text-white"
                aria-hidden="true"
              />
              Loading
            </>
          ) : (
            'Complete task'
          )}
        </button>
        <button
          disabled={isLoadingUpdateProfile}
          onClick={() => navigate('/dashboard')}
          className="text-sm font-medium text-text-secondary underline sm:text-base"
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default PhysicalAddress
