import type { ServiceLine } from '../../../../../types/ServiceLine'

interface TherapistAvatarMiddleProps {
  currentServiceLine: ServiceLine
  smaller?: boolean
}

const TherapistAvatarMiddle: React.FC<TherapistAvatarMiddleProps> = ({
  currentServiceLine,
  smaller = false,
}) => {
  return (
    <div
      className={`relative z-50 flex h-11 w-11 items-center justify-center rounded-full shadow-md ring-0 ${
        smaller ? 'sm:h-20 sm:w-20' : 'sm:h-24 sm:w-24'
      } ${currentServiceLine.bgColor}`}
    >
      <img
        src={currentServiceLine.avatar}
        alt="Therapist"
        className={`h-8 w-8 ${smaller ? 'sm:h-14 sm:w-14' : 'sm:h-17 sm:w-17'}`}
      />
    </div>
  )
}

export default TherapistAvatarMiddle
