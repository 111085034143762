import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface UpdateUserEmailProps {
  email: string
}

const updateUserEmail = async ({ email }: UpdateUserEmailProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_BFF_URL}user/email`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useUpdateUserEmail = () =>
  useMutation((params: UpdateUserEmailProps) => updateUserEmail(params))
