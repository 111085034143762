import React from 'react'

import useMediaQuery from '../hooks/useMediaQuery'

const MIN_WIDTH_VALUE = '720px'

const Tooltip: React.FC<{
  children: React.ReactNode
  content: React.ReactNode
  isDisabled?: boolean
  position?: 'top' | 'bottom' | 'left' | 'right'
}> = ({ children, content, isDisabled = false, position = 'top' }) => {
  const isLargeEnough = useMediaQuery(`(min-width: ${MIN_WIDTH_VALUE})`)

  if (isDisabled) return <>{children}</>

  let positionClasses = ''
  const topPositionConfig = 'bottom-full left-1/2 -translate-x-1/2 pb-2'

  switch (position) {
    case 'top':
      positionClasses = topPositionConfig
      break
    case 'bottom':
      positionClasses = 'top-full left-1/2 -translate-x-1/2 pt-2'
      break
    case 'left':
      positionClasses = isLargeEnough
        ? 'top-1/2 right-full -translate-y-1/2 pr-2'
        : topPositionConfig
      break
    case 'right':
      positionClasses = isLargeEnough
        ? 'top-1/2 left-full -translate-y-1/2 pl-2'
        : topPositionConfig
      break
    default:
      break
  }

  return (
    <div className="group relative xs:w-full" id="container">
      <div
        className={`absolute z-50 hidden transform text-left text-sm font-normal text-white group-hover:flex ${positionClasses}`}
      >
        <div className="relative">
          <div className="w-[50vw] whitespace-normal rounded-lg bg-text-primary p-2 sm:w-[35vw] xl:w-[25vw] 2xl:w-[20vw]">
            {content}
          </div>
          <Caret position={position} />
        </div>
      </div>
      {children}
    </div>
  )
}

export default Tooltip

const Caret: React.FC<{ position: 'top' | 'bottom' | 'left' | 'right' }> = ({
  position = 'top',
}) => {
  const isLargeEnough = useMediaQuery(`(min-width: ${MIN_WIDTH_VALUE})`)

  const caretTopConfig =
    'rotate-[270deg] -bottom-[14px] left-[50%] -translate-x-1/2'
  let caretClasses = ''

  switch (position) {
    case 'top':
      caretClasses = caretTopConfig

      break
    case 'bottom':
      caretClasses = 'rotate-[90deg] -top-[5px] left-[50%] -translate-x-1/2'
      break
    case 'left':
      caretClasses = isLargeEnough
        ? 'rotate-[180deg] top-1/2 left-full -translate-y-1/2'
        : caretTopConfig
      break
    case 'right':
      caretClasses = isLargeEnough
        ? 'rotate-[0deg] top-1/2 right-full -translate-y-1/2'
        : caretTopConfig
      break
    default:
      break
  }

  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      className={`absolute -translate-y-1/2 ${caretClasses}`}
      fill="#1A344B"
    >
      <polygon points="5,5 10,0 10,10" />
    </svg>
  )
}
