import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface PostPatientPacketProps {
  name: string
  version: number
  patientRelationship: string
  signatureDate: string
  patientId: string
}

const postPatientPacket = async ({
  name,
  version,
  patientRelationship,
  signatureDate,
  patientId,
}: PostPatientPacketProps): Promise<boolean> => {
  const token: string = await auth.currentUser.getIdToken()
  const r = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PATIENT_PACKET_URL
    }/v2/patient-packet/sign`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name,
        version,
        patientRelationship,
        signatureDate,
        patientId,
      }),
    }
  )

  return r.status === 200
}

export const usePostPatientPacket = () =>
  useMutation((params: PostPatientPacketProps) => postPatientPacket(params))
