import React from 'react'

import type { ResourceSection } from '../../../types/Resources'
import CoursesGridDisplay from './CourseGridDisplay'
import { ResourcePaginator } from '../components/ResourcesGrid'

const FilteredCoursesGrid: React.FC<{
  resourceSection: ResourceSection
  isLoadingResourceSection: boolean
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}> = ({
  resourceSection,
  isLoadingResourceSection,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <>
      {/* Resources grid */}
      <CoursesGridDisplay courses={resourceSection?.resources} showBadge />

      {/* Paginator */}
      <ResourcePaginator
        pageMapId="filteredResources"
        currentPage={currentPage}
        resourceSection={resourceSection}
        setCurrentPage={setCurrentPage}
        isLoadingResourceSection={isLoadingResourceSection}
      />
    </>
  )
}

export default FilteredCoursesGrid
