import { useQuery } from '@tanstack/react-query'

import type { Course } from '../../types/Courses'
import { auth } from '../../config/firebase'
import { toCourse } from '../../helpers/transformers'
import type { User } from '../../types/User'

interface GetRecommendationsProps {
  courseId: string
  user: User
  patientId: string
}

export const useGetCourseRecommendations = (
  params: GetRecommendationsProps,
  deps = params
) =>
  useQuery(['getCourseRecommendations', deps], async () =>
    getRecommendations(params)
  )

const getRecommendations = async ({
  courseId,
  user,
  patientId,
}: GetRecommendationsProps): Promise<Course[]> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/courses/${courseId}/recommendations${
        patientId ? `?patientId=${patientId}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return response.map((rawCourse: any) =>
      toCourse({
        ...rawCourse,
        courseProgresses: rawCourse.courseProgresses.map((cp: any) => ({
          ...cp,
          patient: user.roster.find((p) => p.id === cp.patientId),
        })),
      })
    )
  } catch (error) {
    return null
  }
}
