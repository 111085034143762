import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { DASHBOARD_RATING_SURVEY_ID } from '../../constants/values'

interface DashboardSurveySendAnswerProps {
  answerInt: number
  answerText?: string
}

const dashboardSurveySendAnswer = async ({
  answerInt,
  answerText = '',
}: DashboardSurveySendAnswerProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
    }/v1/survey/${DASHBOARD_RATING_SURVEY_ID}/sendAnswers`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([{ questionNo: 1, answerInt, answerText }]),
    }
  )

  if (!result.ok) throw new Error('Something went wrong.')
}

export const useDashboardSurveySendAnswer = () =>
  useMutation((params: DashboardSurveySendAnswerProps) =>
    dashboardSurveySendAnswer(params)
  )
