import { useQuery } from '@tanstack/react-query'

import type { ResourceSection } from '../../types/Resources'
import { auth } from '../../config/firebase'
import { toResource } from '../../helpers/transformers'

type GetResourcesParams = {
  patientId: string
  forCaregiver?: boolean
  paginationToken: number
  pageNumber?: number
  pageSize?: number
}

export const useGetResources = (params: GetResourcesParams, deps = params) =>
  useQuery(['getResources', deps], async () => getResources(params))

const getResources = async ({
  patientId,
  forCaregiver,
  pageNumber = 0,
  pageSize = 6,
  paginationToken,
}: GetResourcesParams): Promise<ResourceSection> => {
  const token: string = await auth.currentUser.getIdToken()

  try {
    if (forCaregiver) {
      const response: any = await fetch(
        `${
          import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
        }v2/display/multimedia/caregivers/${patientId}?page=${pageNumber}&pageSize=${pageSize}&paginationToken=${paginationToken}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => response.json())

      return {
        resources: response.resources.map((rawResource: any) =>
          toResource(rawResource)
        ),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      }
    } else {
      const response: any = await fetch(
        `${
          import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
        }v2/display/multimedia/patients/${patientId}?page=${pageNumber}&pageSize=${pageSize}&paginationToken=${paginationToken}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => response.json())

      return {
        resources: response.resources.map((rawResource: any) =>
          toResource(rawResource)
        ),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      }
    }
  } catch (error) {
    return null
  }
}
