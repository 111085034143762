import { useMutation } from '@tanstack/react-query'

import type { EligibilityInfo } from '../../types/Register'
import { convertFrontendDateToBackend } from '../../helpers/generic'
import { auth } from '../../config/firebase'

type ResponseFromEligibility = {
  externalId: string
  isEligible: boolean
  isIep: boolean
  locationId: string
  personId: string
}

export const getEligibilityForAccountHolder = async (): Promise<boolean> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const { isEligible }: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/patients/get-account-holder-eligibility`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return isEligible
  } catch {
    return false
  }
}

const getEligibilityType = async (
  clientId: string,
  firstName: string,
  lastName: string,
  dob: string,
  identifier: string
): Promise<ResponseFromEligibility> => {
  const data: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
    }v1/patients/get-eligibility-type`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientId,
        first: firstName,
        last: lastName,
        dob,
        identifier,
      }),
    }
  ).then((response) => response.json())

  return {
    externalId: data?.externalId,
    isEligible: data?.isEligible,
    isIep: data?.isIep,
    locationId: data?.locationId,
    personId: data?.personId,
  }
}

interface VerifyProps {
  userInfo: EligibilityInfo
  clientId: string
}

export type VerifyEligibilityResponse = {
  isEligible: boolean
  isIep: boolean
  locationId: string
  personId: string
}

const verifyEligibility = async ({
  userInfo,
  clientId,
}: VerifyProps): Promise<VerifyEligibilityResponse> => {
  const data: ResponseFromEligibility = await getEligibilityType(
    clientId,
    userInfo.firstName,
    userInfo.lastName,
    convertFrontendDateToBackend(userInfo.birthDate),
    userInfo?.email || userInfo?.externalId
  )

  if (!data?.personId || (!data?.isEligible && !data?.isIep))
    throw new Error('Eligibility cannot be verified!')

  return {
    isEligible: data.isEligible,
    isIep: data.isIep,
    locationId: data.locationId,
    personId: data.personId,
  }
}

export const useVerifyEligibility = () =>
  useMutation((params: VerifyProps) => verifyEligibility(params))
