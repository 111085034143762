import type { Insurance } from './Insurance'
import type { GenderIdentity, Race } from './Profile'
import type { ResourceTopic } from './Resources'
import type { Session } from './Session'
import type { CheckInTask } from './Tasks'

export type RelationshipType = {
  name: string
  key: string
}

export type RemainingBulkResult = {
  isBulkModel: boolean
  remainingSessionsOnBulkModel: number
}

export type CarePlan = {
  id: string
  displayName: string
  providerId?: string
  sessions?: Session[]
  remainingSessions?: number
  allowedSessions?: number
  bookingNonSponsoredInformationConfirmed?: boolean
  billingInformationConfirmed?: boolean
  dontHaveInsurance?: boolean
  notes?: string
  productId?: string
  bulkModel?: RemainingBulkResult
}

export type Condition = {
  id: string
  carePlans: CarePlan[]
  isIep?: boolean
}

export type Patient = {
  ableToBookAndCancel?: boolean
  age: number
  allowedToBook?: boolean
  apartmentUnit: string
  birthDate: string
  billingApartmentUnit?: string
  billingCity?: string
  billingState?: string
  billingStreetAddress?: string
  billingZip?: string
  checkInTask?: CheckInTask
  city: string
  conditions: Condition[]
  email?: string
  emergencyContact?: {
    personId?: string
    firstName?: string
    lastName?: string
    phoneNumber?: string
    relationship?: string
  }
  externalId?: string
  firstName: string
  gender: string
  genderIdentity: GenderIdentity
  hasSeparateLogin?: boolean
  id: string
  interests?: ResourceTopic[]
  insurance?: Insurance
  invitedForSeparateLogin?: boolean
  isDisabled?: boolean
  isEligible?: boolean
  isIep: boolean
  isIepOnly: boolean
  isSeparateLogin?: boolean
  lastName: string
  locationId?: string
  onboardingStep?: string
  personId: string
  preferredLanguage: string
  preferredName: string
  preferredPronouns?: string
  race?: Race
  relationship: RelationshipType
  separateLoginInvitedAt?: number
  state: string
  streetAddress: string
  studentId?: string
  timeZone?: string
  unassociatedInsurance?: boolean
  userId?: string
  zip: string
}

export const ONBOARDING_STEP = {
  whatBringsYouHere: 'WHAT_BRINGS_YOU_HERE',
  yourStory: 'YOUR_STORY',
  careFor: 'CARE_FOR',
  eligibility: 'ELIGIBILITY_VERIFICATION',
  buildProfile: 'BUILD_PROFILE',
  emergencyContact: 'EMERGENCY_CONTACT',
  interest: 'INTEREST',
  areaFocus: 'AREA_FOCUS',
  addAnotherPerson: 'ADD_ANOTHER_PERSON',
  insurance: 'INSURANCE',
  booking: 'BOOKING',
  skipped: 'SKIPPED',
  skippedInErrorDob: 'SKIPPED_IN_ERROR_DOB',
} as const
