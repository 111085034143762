import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { pdfjs } from 'react-pdf'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import './index.css'
import ViewResource from './screens/resources/ViewResource'
import InsuranceChooseScreen from './screens/insurance/InsuranceChooseScreen'
import InsuranceInfoScreen from './screens/insurance/InsuranceInfoScreen'
import ViewPatientScreen from './screens/view-patient/ViewPatientScreen'
import OnboardingScreen from './components/ScreenTemplates/OnboardingScreen'
import YourStory from './screens/onboarding/YourStory'
import LeftNavScreen from './components/ScreenTemplates/LeftNavScreen'
import Booking from './screens/booking/Booking'
import Providers from './screens/booking/Providers'
import Provider from './screens/booking/Provider'
import RequireServiceLine from './components/RouteMiddlewares/RequireServiceLine'
import InsuranceVerifyScreen from './screens/insurance/InsuranceVerifyScreen'
import { ContactScreen } from './screens/contact/ContactScreen'
import ResetPasswordScreen from './screens/password/ResetPasswordScreen'
import InitialColorfulScreen from './components/ScreenTemplates/InitialColorfulScreen'
import ForgotPasswordScreen from './screens/password/ForgotPasswordScreen'
import SignIn from './screens/auth/SignIn'
import ConfirmationEmail from './screens/auth/ConfirmationEmail'
import ResendConfirmationEmail from './screens/auth/ResendConfirmationEmail'
import DashboardScreen from './screens/dashboard/DashboardScreen'
import ProviderConfirmation from './screens/booking/ProviderConfirmation'
import ProviderSuccess from './screens/booking/ProviderSuccess'
import AccountSettings from './screens/account-settings/AccountSettings'
import Page404 from './screens/Page404'
import SelectServiceLine from './screens/booking/SelectServiceLine'
import { AuthProvider } from './contexts/AuthProvider'
import RequireAuth from './components/RouteMiddlewares/RequireAuth'
import RequireNotAuth from './components/RouteMiddlewares/RequireNotAuth'
import { ToastContextProvider } from './contexts/ToastContext'
import { PatientProvider } from './contexts/PatientProvider'
import { RegisterProvider } from './contexts/RegisterContext'
import BuildProfileScreen from './screens/onboarding/BuildProfileScreen'
import CreateLogin from './screens/auth/eligibility/CreateLogin'
import CareForScreen from './screens/onboarding/CareForScreen'
import PatientPacketSignature from './screens/booking/PatientPacketSignature'
import RequireNotEmergencyContact from './components/RouteMiddlewares/RequireNotEmergencyContact'
import RequireBookingLogic from './components/RouteMiddlewares/RequireBookingLogic'
import RequireNotPhysicalAddress from './components/RouteMiddlewares/RequireNotPhysicalAddress'
import PhysicalAddress from './screens/tasks/PhysicalAddress'
import UpdatePaymentOptions from './screens/bookingNonSponsored/UpdatePaymentOptions'
import AddAnotherPerson from './screens/onboarding/AddAnotherPerson'
import InsuranceInfoConfirm from './screens/bookingNonSponsored/InsuranceInfoConfirm'
import InsuranceInfoEdit from './screens/bookingNonSponsored/InsuranceInfoEdit'
import InsuranceInfoPolicyHolderAddressConfirm from './screens/bookingNonSponsored/InsuranceInfoPolicyHolderAddressConfirm'
import InsuranceInfoAdd from './screens/bookingNonSponsored/InsuranceInfoAdd'
import InsuranceInfoComplete from './screens/bookingNonSponsored/InsuranceInfoComplete'
import InsuranceInfoPolicyHolderAddressEdit from './screens/bookingNonSponsored/InsuranceInfoPolicyHolderAddressEdit'
import InsuranceInfoPendingVerification from './screens/bookingNonSponsored/InsuranceInfoPendingVerification'
import MaybeChangeTherapist from './screens/bookingNonSponsored/MaybeChangeTherapist'
import InsuranceUseSame from './screens/insurance/InsuranceUseSame'
import TherapistOutOfNetworkInsuranceMedicaid from './screens/bookingNonSponsored/TherapistOutOfNetworkInsuranceMedicaid'
import PaymentHistoryScreen from './screens/account-settings/PaymentHistory'
import MyResources from './screens/resources/MyResources'
import GetStarted from './screens/auth/eligibility/GetStarted'
import VerifyEligibility from './screens/auth/eligibility/VerifyEligibility'
import InvalidVerification from './screens/auth/eligibility/InvalidVerification'
import SubmittedContactInfo from './screens/auth/eligibility/SubmittedContactInfo'
import { EligibilityVerified } from './screens/auth/eligibility/EligibilityVerified'
import ConfirmBillingInformation from './screens/booking/ConfirmBillingInformation'
import InterestScreen from './screens/onboarding/InterestScreen'
import AreaFocus from './screens/onboarding/AreaFocus'
import WhatBringsYouHere from './screens/onboarding/WhatBringsYouHere'
import RequirePatient from './components/RouteMiddlewares/RequirePatient'
import RequirePatientPayCOS from './components/RouteMiddlewares/RequirePatientPayCOS'
import { ResourceGridProvider } from './contexts/ResourceGridContext'
import ConfirmCardInfo from './screens/booking/ConfirmCardInfo'
import Eligibility from './screens/onboarding/Eligibility'
import EligibilityFailed from './screens/onboarding/EligibilityFailed'
import MyBookings from './screens/my-bookings/MyBookings'
import MyBookmarks from './screens/resources/MyBookmarks'
import ViewCourse from './screens/resources/courses/ViewCourse'
import { AlreadyBooked } from './screens/booking/AlreadyBooked'
import { BookingMaxedOut } from './screens/booking/BookingMaxedOut'
import BulkModelOutOfFreeSessions from './screens/onboarding/BulkModelOutOfFreeSessions'
import { PermissionSettings } from './screens/account-settings/PermissionSettings'
import CreatePermission from './screens/account-settings/CreatePermission'
import { PermissionSettingsProvider } from './contexts/PermissionSettingsContext'
import PermissionConsentForm from './screens/account-settings/PermissionConsentForm'
import CheckInScreen from './screens/tasks/outcomes/CheckInScreen'
import TeleAppropriateness from './screens/booking/TeleAppropriateness'
import TherapistNoLongerAtDCT from './screens/booking/TherapistNoLongerAtDCT'
import DuressScreen from './screens/tasks/outcomes/DuressScreen'
import { ProviderContextProvider } from './contexts/ProviderContext'
import EmailConfirmationFailed from './screens/auth/EmailConfirmationFailed'
import SeparateLoginInviteExpired from './screens/auth/SeparateLoginInviteExpired'
import CreateAccountFromEmail from './screens/auth/CreateAccountFromEmail'
import PermissionPatientPacket from './screens/account-settings/PermissionPatientPacket'
import ThankYouSignOut from './screens/onboarding/ThankYouSignOut'
import EmergencyContact from './screens/onboarding/EmergencyContact'

// https://github.com/wojtekmaj/react-pdf/issues/321#issuecomment-451291757
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
)

const requireAuthRoutes = [
  // dashboard
  {
    path: '/dashboard',
    element: (
      <LeftNavScreen
        bannerClassName="banner-dashboard"
        hideLateralPaddingSmallScreens
        centerContent={false}
      />
    ),
    children: [
      {
        index: true,
        element: <DashboardScreen />,
      },
    ],
  },
  // account settings
  {
    path: '/account-settings',
    element: <LeftNavScreen hideLateralPaddingSmallScreens />,
    children: [
      {
        index: true,
        element: <AccountSettings />,
      },
    ],
  },
  // permission settings
  {
    path: '/permission-settings',
    element: <LeftNavScreen hideLateralPaddingSmallScreens />,
    children: [
      {
        index: true,
        element: <PermissionSettings />,
      },
    ],
  },
  // create permission
  {
    path: '/create-permission/:patientId',
    element: (
      <PermissionSettingsProvider>
        <OnboardingScreen />
      </PermissionSettingsProvider>
    ),
    children: [
      { index: true, element: <CreatePermission /> },
      {
        path: 'consent',
        element: <PermissionConsentForm />,
      },
      {
        path: 'patient-packet',
        element: <PermissionPatientPacket />,
      },
    ],
  },
  // payment-history
  {
    path: '/payment-history',
    element: <LeftNavScreen hideLateralPaddingSmallScreens />,
    children: [
      {
        index: true,
        element: <PaymentHistoryScreen />,
      },
    ],
  },
  // contact
  {
    path: '/contact',
    element: <LeftNavScreen hideLateralPaddingSmallScreens />,
    children: [
      {
        index: true,
        element: <ContactScreen />,
      },
    ],
  },
  // view-patient
  {
    path: '/patient/:patientId',
    element: (
      <RequirePatient>
        <LeftNavScreen centerContent={false} />
      </RequirePatient>
    ),
    children: [
      {
        index: true,
        element: <ViewPatientScreen />,
      },
    ],
  },
  // tasks
  {
    path: '/tasks',
    element: (
      <LeftNavScreen hideTopPaddingAllScreens hideLateralPaddingSmallScreens />
    ),
    children: [
      {
        path: 'emergency-contact',
        element: (
          <RequireNotEmergencyContact>
            <EmergencyContact />
          </RequireNotEmergencyContact>
        ),
      },
      {
        path: 'physical-address',
        element: (
          <RequireNotPhysicalAddress>
            <PhysicalAddress />
          </RequireNotPhysicalAddress>
        ),
      },
      {
        path: 'check-in/:patientId',
        element: <CheckInScreen />,
      },
      {
        path: 'duress',
        element: <DuressScreen />,
      },
    ],
  },
  // onboarding
  {
    path: '/onboarding',
    element: <OnboardingScreen />,
    children: [
      // your story
      {
        path: 'your-story',
        element: <YourStory />,
      },
      // care for
      { path: 'care-for', element: <CareForScreen /> },
      // build profile
      {
        path: 'build-profile',
        element: <BuildProfileScreen />,
      },
      // emergency contact
      {
        path: 'emergency-contact',
        element: <EmergencyContact />,
      },
      // interest
      {
        path: 'interest',
        element: (
          <RequirePatient>
            <InterestScreen />
          </RequirePatient>
        ),
      },
      // area-of-focus
      {
        path: 'area-focus',
        element: (
          <RequirePatient>
            <AreaFocus />
          </RequirePatient>
        ),
      },
      // add another patient
      {
        path: 'add-another-person',
        element: <AddAnotherPerson />,
      },
      // thank you for choosing
      {
        path: 'thank-you-sign-out',
        element: <ThankYouSignOut />,
      },
      // eligibility
      {
        path: 'eligibility',
        children: [
          { index: true, element: <Eligibility /> },
          {
            path: 'failed',
            element: <EligibilityFailed />,
          },
        ],
      },
    ],
  },
  // what brings you here
  {
    path: '/what-brings-you-here',
    element: (
      <InitialColorfulScreen
        showWarning
        bannerBg="background-image-onboarding"
      />
    ),
    children: [
      {
        index: true,
        element: <WhatBringsYouHere />,
      },
    ],
  },
  // insurance
  {
    path: '/insurance',
    element: (
      <RequirePatient>
        <OnboardingScreen />
      </RequirePatient>
    ),
    children: [
      {
        index: true,
        element: <InsuranceChooseScreen />,
      },
      {
        path: 'information',
        element: <InsuranceInfoScreen />,
      },
      {
        path: 'verify',
        element: <InsuranceVerifyScreen />,
      },
      {
        path: 'use-same',
        element: <InsuranceUseSame />,
      },
    ],
  },
  // therapist no longer at us
  {
    path: '/therapist-unavailable',
    element: <OnboardingScreen />,
    children: [
      {
        index: true,
        element: <TherapistNoLongerAtDCT />,
      },
    ],
  },
  // booking
  {
    path: '/booking/:serviceLine',
    element: (
      <RequireServiceLine>
        <RequirePatient>
          <RequireBookingLogic>
            <OnboardingScreen />
          </RequireBookingLogic>
        </RequirePatient>
      </RequireServiceLine>
    ),
    children: [
      {
        index: true,
        element: <Booking />,
      },
      {
        path: 'already-booked',
        element: <AlreadyBooked />,
      },
      {
        path: 'maxed-out',
        element: <BookingMaxedOut />,
      },
      {
        path: 'providers',
        children: [
          { index: true, element: <Providers /> },
          {
            path: ':providerId',
            children: [
              { index: true, element: <Provider /> },
              {
                path: 'patient-packet-signature',
                element: <PatientPacketSignature />,
              },
              {
                path: 'tele-appropriateness',
                element: <TeleAppropriateness />,
              },
              {
                path: 'billing-information',
                element: <ConfirmBillingInformation />,
              },
              {
                path: 'card-information',
                element: <ConfirmCardInfo />,
              },
              {
                path: 'confirmation',
                element: <ProviderConfirmation />,
              },
              {
                path: 'success',
                element: <ProviderSuccess />,
              },
            ],
          },
        ],
      },
      // bulk model out of covered sessions notice
      {
        path: 'out-of-bulk-sessions',
        element: <BulkModelOutOfFreeSessions />,
      },
    ],
  },
  // booking-non-sponsored
  {
    path: '/booking-non-sponsored/:serviceLine',
    element: (
      <RequireServiceLine>
        <RequirePatient>
          <RequirePatientPayCOS>
            <OnboardingScreen />
          </RequirePatientPayCOS>
        </RequirePatient>
      </RequireServiceLine>
    ),
    children: [
      { index: true, element: <Navigate to="update" replace /> },
      {
        path: 'update',
        element: <UpdatePaymentOptions />,
      },
      {
        path: 'insurance-info',
        children: [
          {
            path: 'pending-verification',
            element: <InsuranceInfoPendingVerification />,
          },
          {
            path: 'confirm',
            element: <InsuranceInfoConfirm />,
          },
          {
            path: 'policy-holder-address',
            children: [
              {
                path: 'confirm',
                element: <InsuranceInfoPolicyHolderAddressConfirm />,
              },
              {
                path: 'edit',
                element: <InsuranceInfoPolicyHolderAddressEdit />,
              },
            ],
          },
          {
            path: 'edit',
            element: <InsuranceInfoEdit />,
          },
          {
            path: 'edit/complete',
            element: <InsuranceInfoComplete />,
          },
          {
            path: 'add',
            element: <InsuranceInfoAdd />,
          },
          {
            path: 'add/complete',
            element: <InsuranceInfoComplete />,
          },
        ],
      },
      {
        path: 'change-therapist',
        element: <MaybeChangeTherapist />,
      },
      {
        path: 'therapist-no-accept',
        element: <TherapistOutOfNetworkInsuranceMedicaid />,
      },
    ],
  },
  // select service line
  {
    path: '/select-service-line',
    element: <OnboardingScreen />,
    children: [
      {
        index: true,
        element: <SelectServiceLine />,
      },
    ],
  },
  // view resource
  {
    path: '/view-resource/:resourceId',
    element: <LeftNavScreen centerContent={false} />,
    children: [
      {
        index: true,
        element: (
          <ResourceGridProvider>
            <ViewResource />
          </ResourceGridProvider>
        ),
      },
    ],
  },
  // view course
  {
    path: '/view-course/:courseId',
    element: <LeftNavScreen centerContent={false} />,
    children: [
      {
        index: true,
        element: (
          <ResourceGridProvider>
            <ViewCourse />
          </ResourceGridProvider>
        ),
      },
    ],
  },
  // resources
  {
    path: '/resources',
    element: (
      <LeftNavScreen bannerClassName="banner-resources" centerContent={false} />
    ),
    children: [
      {
        index: true,
        element: (
          <ResourceGridProvider>
            <MyResources />
          </ResourceGridProvider>
        ),
      },
    ],
  },
  // bookmarks
  {
    path: '/bookmarks',
    element: <LeftNavScreen centerContent={false} />,
    children: [
      {
        index: true,
        element: <MyBookmarks />,
      },
    ],
  },
  // bookings
  {
    path: '/bookings',
    element: (
      <LeftNavScreen bannerClassName="banner-bookings" centerContent={false} />
    ),
    children: [
      {
        index: true,
        element: <MyBookings />,
      },
    ],
  },
]

const requireNotAuthRoutes = [
  // sign in
  {
    path: '/sign-in',
    element: <InitialColorfulScreen showWarning showAccessToResources />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
    ],
  },
  // sign up
  {
    path: '/sign-up',
    element: (
      <RegisterProvider>
        <Outlet />
      </RegisterProvider>
    ),
    children: [
      {
        path: 'verify',
        element: (
          <InitialColorfulScreen showWarning bannerBg="background-kids-image" />
        ),
        children: [
          {
            index: true,
            element: <GetStarted />,
          },
          {
            path: 'eligibility',
            element: <VerifyEligibility />,
          },
          {
            path: 'invalid',
            element: <InvalidVerification />,
          },
          {
            path: 'submitted',
            element: <SubmittedContactInfo />,
          },
        ],
      },
      {
        path: 'create-login',
        element: <OnboardingScreen />,
        children: [{ index: true, element: <CreateLogin /> }],
      },
      {
        path: 'eligibility-verified',
        element: <OnboardingScreen />,
        children: [{ index: true, element: <EligibilityVerified /> }],
      },
    ],
  },
  // password reset, forgot
  {
    path: '/password',
    element: <InitialColorfulScreen />,
    children: [
      {
        path: 'reset',
        element: <ResetPasswordScreen />,
      },
      {
        path: 'forgot',
        element: <ForgotPasswordScreen />,
      },
    ],
  },
  // confirmation email handling
  {
    path: '/confirmation',
    element: <InitialColorfulScreen />,
    children: [
      {
        path: 'success',
        element: <ConfirmationEmail />,
      },
      {
        path: 'resend',
        element: <ResendConfirmationEmail />,
      },
    ],
  },
  // email confirmation failed
  {
    path: '/email-confirmation-failed',
    element: <OnboardingScreen />,
    children: [
      {
        index: true,
        element: <EmailConfirmationFailed />,
      },
    ],
  },
  // separate login invite expired
  {
    path: '/separate-login-invite-expired',
    element: <OnboardingScreen />,
    children: [
      {
        index: true,
        element: <SeparateLoginInviteExpired />,
      },
    ],
  },
  // create account from email
  {
    path: '/create-account-from-email',
    element: <OnboardingScreen />,
    children: [
      {
        index: true,
        element: <CreateAccountFromEmail />,
      },
    ],
  },
]

const routes = [
  // RequireNotAuth
  ...requireNotAuthRoutes.map((route) => ({
    ...route,
    element: <RequireNotAuth>{route.element}</RequireNotAuth>,
  })),
  // RequireAuth
  ...requireAuthRoutes.map((route) => ({
    ...route,
    element: (
      <PatientProvider>
        <RequireAuth>
          <ProviderContextProvider>{route.element}</ProviderContextProvider>
        </RequireAuth>
      </PatientProvider>
    ),
  })),
  // zesh redirects
  { path: '/app/landing', element: <Navigate to={'/dashboard'} /> },
  { path: '/app/sign-in', element: <Navigate to={'/sign-in'} /> },
  { path: '/app/sign-up', element: <Navigate to={'/sign-up/create-login'} /> },
  // default
  { path: '/', element: <Navigate to={'/dashboard'} /> },
  // 404
  { path: '*', element: <Page404 /> },
]

const router = createBrowserRouter(routes)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY)

root.render(
  <QueryClientProvider client={queryClient}>
    <ToastContextProvider>
      <Elements stripe={stripePromise}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </Elements>
    </ToastContextProvider>
  </QueryClientProvider>
)
