import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { toCamelCase } from '../../helpers/serializers'

interface GetInsuranceProps {
  name?: string
  providerId?: string
  blockSearch?: boolean
}

export const useGetInsurances = (params: GetInsuranceProps, deps = params) =>
  useQuery(['getInsurances', deps], async () => getInsurances(params), {
    enabled:
      (Boolean(params?.name) || Boolean(params.providerId)) &&
      !params.blockSearch,
  })

const getInsurances = async ({ name, providerId }: GetInsuranceProps) => {
  const queryParams = [
    name ? `name=${name}` : '',
    providerId ? `provider_id=${providerId}` : '',
  ]
  const queryParamsFiltered = queryParams.filter((qp) => qp)

  const token: string = await auth.currentUser.getIdToken()
  const insurances: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/payers_for_patient_selection?${queryParamsFiltered.join('&')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return toCamelCase(insurances)
}
