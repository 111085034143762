import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface CheckPatientDOBProps {
  personId: string
  dob: string
}

const checkPatientDOB = async ({
  personId,
  dob,
}: CheckPatientDOBProps): Promise<boolean> => {
  try {
    const token = await auth.currentUser.getIdToken()

    const data: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/patients/check-person-dob`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          personId,
          dob,
        }),
      }
    ).then((response) => response.json())

    return data.status === true
  } catch (error) {
    return false
  }
}

export const useCheckPatientDOB = () =>
  useMutation((params: CheckPatientDOBProps) => checkPatientDOB(params))
