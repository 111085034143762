import { useMutation } from '@tanstack/react-query'

import type { TeleAppropriatenessForm } from '../../screens/booking/TeleAppropriateness'

import { auth } from '../../config/firebase'

interface CompleteTeleProps {
  results: TeleAppropriatenessForm
  personId: string
  firstName: string
  lastName: string
  email: string
}

export const TELE_APPROPRIATENESS_SURVEY_ID =
  'a14b379b-b55b-48b1-b119-bfcbbe7a4a54'

const completeTeleAppropriateness = async ({
  results,
  personId,
  firstName,
  lastName,
  email,
}: CompleteTeleProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const surveyUrl = `${import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL}/v1/survey`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const sendResultsPromise = fetch(
    `${surveyUrl}/${TELE_APPROPRIATENESS_SURVEY_ID}/sendAnswers?personId=${personId}`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify([
        {
          questionNo: 0,
          answerInt: 0,
          answerText: JSON.stringify({
            adultSupervision: results.adultSupervision,
          }),
        },
        {
          questionNo: 1,
          answerInt: 1,
          answerText: JSON.stringify({
            sessionRequirements: results.sessionRequirements,
          }),
        },
      ]),
    }
  )

  const noDevicesHelpEmailPromise =
    !results.sessionRequirements.reliableInternet ||
    !results.sessionRequirements.reliableCamera ||
    results.sessionRequirements.noneOfTheAbove
      ? fetch(`${surveyUrl}/send-tele-appropriateness-no-devices-help-email`, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            firstName,
            lastName,
            emailAddress: email,
            reason1:
              !results.sessionRequirements.reliableInternet &&
              !results.sessionRequirements.noneOfTheAbove
                ? 'They do not have reliable internet access'
                : null,
            reason2:
              !results.sessionRequirements.reliableCamera &&
              !results.sessionRequirements.noneOfTheAbove
                ? 'They do not have a reliable device with camera and microphone'
                : null,
            reason3: results.sessionRequirements.noneOfTheAbove
              ? 'They do not have any of the needed items for virtual therapy'
              : null,
          }),
        })
      : null

  const noAssistanceHelpPromise =
    results.adultSupervision === 'No'
      ? fetch(
          `${surveyUrl}/send-tele-appropriateness-no-assistance-help-email`,
          {
            method: 'POST',
            headers,
            body: JSON.stringify({
              firstName,
              lastName,
              emailAddress: email,
              reason1: null,
              reason2: null,
              reason3: null,
            }),
          }
        )
      : null

  await Promise.all(
    [
      sendResultsPromise,
      noDevicesHelpEmailPromise,
      noAssistanceHelpPromise,
    ].filter(Boolean)
  )
}

export const useCompleteTele = () =>
  useMutation((params: CompleteTeleProps) =>
    completeTeleAppropriateness(params)
  )
