import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { PreviewResource } from '../../types/Resources'
import { toPreviewResource } from '../../helpers/transformers'

interface GetResourcePreviewProps {
  resourceId: string
}

export const useGetResourceForPreview = (
  params: GetResourcePreviewProps,
  deps = params
) =>
  useQuery(
    ['getResourceForPreview', deps],
    async () => getResourceForPreview(params),
    { enabled: Boolean(params.resourceId) }
  )

const getResourceForPreview = async ({
  resourceId,
}: GetResourcePreviewProps): Promise<PreviewResource> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v1/resources/${resourceId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return toPreviewResource(response)
  } catch (error) {
    return null
  }
}
