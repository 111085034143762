import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface RateResourceProps {
  resourceId: string
  value: number
}

const rateResource = async ({ resourceId, value }: RateResourceProps) => {
  const token: string = await auth.currentUser.getIdToken()
  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
    }v1/resources/${resourceId}/rate`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        score: value,
      }),
    }
  )
}

export const useRateResource = () =>
  useMutation((params: RateResourceProps) => rateResource(params))
