import { useMutation } from '@tanstack/react-query'

import { convertFrontendDateToBackend } from '../../helpers/generic'

interface GrantAccessProps {
  bearerToken: string
  dateOfBirth: string
  clientId: string
  externalId: string
}

const grantAccessPerson = async ({
  bearerToken,
  dateOfBirth,
  clientId,
  externalId,
}: GrantAccessProps): Promise<string> => {
  const patient_id: string = await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL}v1/accesses/customer`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        dob: convertFrontendDateToBackend(dateOfBirth),
        clientId,
        externalId,
      }),
    }
  ).then((response) => response.json())

  if (!patient_id) throw new Error('Something went wrong.')

  return patient_id
}

export const useGrantAccessPerson = () =>
  useMutation((params: GrantAccessProps) => grantAccessPerson(params))
