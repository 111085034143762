import React, { useEffect, useState } from 'react'
import { BookmarkIcon } from '@heroicons/react/solid'
import { Link, Navigate } from 'react-router-dom'

import { secondaryButtonClass } from '../../constants/classConstants'
import type {
  ResourceFormat,
  ResourceSection,
  ResourceStatus,
  ResourceTopic,
} from '../../types/Resources'
import { useDebounce } from '../../hooks/useDebounce'
import { usePatient } from '../../contexts/PatientProvider'
import { useGetFilteredResources } from '../../queries/resources/GetFilteredResources'
import ResourceFilters from './components/ResourceFilters'
import ResourcesView from './ResourcesView'
import CoursesView from './CoursesView'
import TabNavigation from '../../components/TabNavigation'
import { useGetFilteredCourses } from '../../queries/courses/GetFilteredCourses'
import { useAuth } from '../../contexts/AuthProvider'

const MyResources: React.FC = () => {
  const { user } = useAuth()
  const { setPatient } = usePatient()
  const [activeTab, setActiveTab] = useState<string>('Multimedia')
  const [currentPageFilteredResources, setCurrentPageFilteredResources] =
    useState<number>(0)
  const [resettingFilters, setResettingFilters] = useState<boolean>(false)
  const [mainSearchValue, setMainSearchValue] = useState<string>('')
  const [selectedTopics, setSelectedTopics] = useState<ResourceTopic[]>([])
  const [selectedFormats, setSelectedFormats] = useState<ResourceFormat[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<ResourceStatus[]>([])
  const [filteredResources, setFilteredResources] =
    useState<ResourceSection>(null)
  const debouncedMainSearchValue = useDebounce(mainSearchValue, 600)
  const { data: multimediaFiltered, isLoading: isLoadingFilteredResources } =
    useGetFilteredResources({
      mainSearchValue: debouncedMainSearchValue,
      selectedFormats,
      selectedTopics,
      pageNumber: currentPageFilteredResources,
      isEnabled: activeTab === 'Multimedia',
    })

  const { data: coursesFiltered, isLoading: isLoadingFilteredCourses } =
    useGetFilteredCourses({
      user,
      mainSearchValue: debouncedMainSearchValue,
      selectedStatuses,
      selectedTopics,
      pageNumber: currentPageFilteredResources,
      isEnabled: activeTab === 'Courses',
    })

  // Mix Panel - Track what end users are inputting in the search bar
  useEffect(() => {
    // if (debouncedMainSearchValue)
    // TODO - Add Mixpanel log
    // setExtraLog(
    //   `Resources / ${activeTab} / Resource page search bar input: ${debouncedMainSearchValue}`
    // )
  }, [debouncedMainSearchValue])

  // start on top
  useEffect(() => {
    setPatient(null)
    window.scrollTo(0, 0)
  }, [])

  // set filtered multimedia after search
  useEffect(() => {
    if (!multimediaFiltered || resettingFilters) return

    setFilteredResources(multimediaFiltered)
  }, [multimediaFiltered, resettingFilters])

  // set filtered courses after search
  useEffect(() => {
    if (!coursesFiltered || resettingFilters) return

    setFilteredResources(coursesFiltered)
  }, [coursesFiltered, resettingFilters])

  if (user.roster.length && user.roster.every((p) => p.isDisabled))
    return <Navigate to="/" />

  return (
    <div className="mb-auto flex w-full max-w-4.5xl flex-col items-start gap-28 pb-4 sm:px-4 lg:pt-10 xl:px-0 2xl:pt-18 xs:gap-6 xs:pt-24">
      {/* Header */}
      <div className="flex w-full items-center justify-between xs:flex-col xs:items-start xs:gap-6">
        <p className="flex flex-row items-center justify-center gap-2 text-lg font-semibold sm:text-2xl">
          My Resources
        </p>
        <Link
          className={`${secondaryButtonClass} !bg-white xs:w-full`}
          to={`/bookmarks#${activeTab}`}
        >
          <BookmarkIcon className="h-5 w-5" />
          My Bookmarks
        </Link>
      </div>

      <div className="flex w-full flex-col gap-8 xs:gap-6">
        <TabNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={['Multimedia', 'Courses']}
        />

        <ResourceFilters
          debouncedMainSearchValue={debouncedMainSearchValue}
          mainSearchValue={mainSearchValue}
          setMainSearchValue={setMainSearchValue}
          selectedFormats={selectedFormats}
          setSelectedFormats={setSelectedFormats}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          resettingFilters={resettingFilters}
          setResettingFilters={setResettingFilters}
          setFilteredResources={setFilteredResources}
          filteredResources={filteredResources}
          isLoadingFilteredResources={
            activeTab === 'Multimedia'
              ? isLoadingFilteredResources
              : isLoadingFilteredCourses
          }
          showFormat={activeTab === 'Multimedia'}
          showStatus={activeTab === 'Courses'}
        />

        {activeTab === 'Multimedia' ? (
          <ResourcesView
            currentPageFilteredResources={currentPageFilteredResources}
            setCurrentPageFilteredResources={setCurrentPageFilteredResources}
            debouncedMainSearchValue={debouncedMainSearchValue}
            filteredResources={filteredResources}
            isLoadingFilteredResources={isLoadingFilteredResources}
            selectedFormats={selectedFormats}
            selectedTopics={selectedTopics}
          />
        ) : (
          activeTab === 'Courses' && (
            <CoursesView
              currentPageFilteredResources={currentPageFilteredResources}
              setCurrentPageFilteredResources={setCurrentPageFilteredResources}
              debouncedMainSearchValue={debouncedMainSearchValue}
              filteredResources={filteredResources}
              isLoadingFilteredResources={isLoadingFilteredCourses}
              selectedStatuses={selectedStatuses}
              selectedTopics={selectedTopics}
            />
          )
        )}
      </div>
    </div>
  )
}

export default MyResources
