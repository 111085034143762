import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { CarePlan } from '../../types/Patient'

interface GetSessionDurationsProps {
  currentCarePlan: CarePlan
  isAssessment: boolean
}

export const useGetSessionDurations = (params: GetSessionDurationsProps) =>
  useQuery(['getSessionDurations', params.currentCarePlan.id], async () =>
    getSessionDurations(params)
  )

const getSessionDurations = async ({
  currentCarePlan,
  isAssessment,
}: GetSessionDurationsProps): Promise<number[]> => {
  const token: string = await auth.currentUser.getIdToken()

  try {
    // booking an assessment
    if (isAssessment) return [60]

    // booking a session with 1 session left on remaining sessions or on bulk model must always be 30 minutes
    if (
      (currentCarePlan?.bulkModel?.isBulkModel &&
        currentCarePlan?.bulkModel?.remainingSessionsOnBulkModel < 2) ||
      currentCarePlan?.remainingSessions < 2
    )
      return [30]

    const appointmentTypes = await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_EOC_URL}v1/eoc/${
        currentCarePlan.id
      }/appointment-types`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return appointmentTypes
      ?.filter((a: any) => a.active)
      ?.map((a: any) => a.sessionMinutes)
  } catch {
    return []
  }
}
