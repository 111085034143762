import React, { Fragment, useState } from 'react'
import type { KeyboardEvent } from 'react'
import { Document, Page } from 'react-pdf'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { format } from 'date-fns'
import { Listbox, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useQueryClient } from '@tanstack/react-query'
import { flushSync } from 'react-dom'

import { useGetPatientPacket } from '../queries/onboarding/GetPatientPacket'
import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../constants/classConstants'
import { DEFAULT_DATE_REGEX } from '../constants/regex'
import { isValidDefaultDate } from '../helpers/generic'
import { useAuth } from '../contexts/AuthProvider'
import type { Patient, RelationshipType } from '../types/Patient'
import DatePicker from './DatePicker'
import { relationshipOptionsProfile } from '../constants/values'
import SkipAndComeBackLater from './SkipAndComeBackLater'
import { usePostPatientPacket } from '../mutations/onboarding/PostPatientPacket'
import { useToastContext } from '../contexts/ToastContext'

interface PatientPacketFormData {
  electronicSignature: string
  signedDate: string
  relationship: string
}

const patientPacketFormSchema = Yup.object().shape({
  electronicSignature: Yup.string()
    .required('Electronic Signature is required.')
    .trim(),
  signedDate: Yup.string()
    .required('Signed Date is required.')
    .matches(
      DEFAULT_DATE_REGEX,
      'Signed Date must be in valid MM/DD/YYYY format.'
    )
    .test('valid-date', 'Signed Date is not valid.', (enteredDate: string) =>
      isValidDefaultDate(enteredDate)
    )
    .trim(),
  relationship: Yup.string()
    .required('Your relationship to this person is required.')
    .trim(),
})

const PatientPacket: React.FC<{
  patient: Partial<Patient>
  onSuccessPostPatientPacket: () => void
  showSkipAndComeBackLater?: boolean
  extraLoading?: boolean
}> = ({
  patient,
  onSuccessPostPatientPacket,
  showSkipAndComeBackLater = false,
  extraLoading = false,
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { user } = useAuth()
  const addToast = useToastContext()
  const [numPages, setNumPages] = useState<number>(0)

  const {
    data: patientPacket,
    isLoading: isLoadingGetPatientPacket,
    isFetching: isFetchingGetPatientPacket,
    isError: isErrorGetPatientPacket,
  } = useGetPatientPacket({
    patientId: patient.id,
  })

  const { isLoading: isLoadingPostPatientPacket, mutate: postPatientPacket } =
    usePostPatientPacket()

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors, isValid },
  } = useForm<PatientPacketFormData>({
    mode: 'all',
    defaultValues: {
      electronicSignature: patientPacket?.signedName || '',
      signedDate: format(new Date(), 'MM/dd/yyyy'),
      relationship: patient?.relationship?.key || null,
    },
    resolver: yupResolver(patientPacketFormSchema),
  })

  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  const signPatientPacket = (data?: PatientPacketFormData) =>
    postPatientPacket(
      {
        name: data.electronicSignature,
        patientRelationship: relationshipOptionsProfile.find(
          (r) => r.key === data.relationship
        )?.name,
        signatureDate: data.signedDate,
        version: patientPacket.version,
        patientId: patient.id,
      },
      {
        onSettled: async (wasPostPatientPacketSuccessful: boolean) => {
          if (wasPostPatientPacketSuccessful)
            flushSync(async () => {
              onSuccessPostPatientPacket()
              queryClient.invalidateQueries(['getPatientPacket', patient.id])
            })
          else addToast('error', 'Something went wrong')
        },
      }
    )

  const isLoading =
    isLoadingPostPatientPacket ||
    isLoadingGetPatientPacket ||
    extraLoading ||
    isFetchingGetPatientPacket

  return (
    <div className="max-w-5xl items-center text-center">
      <div className="flex w-full max-w-lg flex-col items-center gap-4 sm:gap-6">
        <p className="text-base font-medium sm:text-2xl">
          Please review and sign the patient packet
        </p>
        <p className="text-sm font-normal sm:text-base">
          You only need to do this once
        </p>
      </div>
      <div
        className={`mx-auto h-full max-h-[50vh] overflow-x-hidden sm:max-h-[90vh] ${
          numPages > 1
            ? 'overflow-y-auto rounded-lg border border-components-fields'
            : 'overflow-y-hidden'
        }`}
      >
        {isLoadingGetPatientPacket ? (
          <div className="flex flex-row items-center justify-center gap-2 text-text-primary">
            <p>Loading the patient packet</p>
          </div>
        ) : !isErrorGetPatientPacket ? (
          <Document
            file={patientPacket.url}
            className="space-y-0 p-0 sm:space-y-2 sm:p-4 lg:p-6"
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {numPages > 0 &&
              React.Children.toArray(
                [...Array(numPages)].map((_, page) => (
                  <Page
                    pageNumber={page + 1}
                    className="-mx-6 border-b shadow-none last:border-b-0 sm:mx-auto sm:border-b-0 sm:shadow-subtle [&>*]:!h-full [&>*]:!w-full"
                  />
                ))
              )}
          </Document>
        ) : (
          <p className="text-status-error">
            Something went wrong while fetching the patient packet.
          </p>
        )}
      </div>
      <div className="grid w-full max-w-sm grid-cols-2 gap-4">
        <div className="col-span-2 flex flex-col items-start gap-1">
          <label
            htmlFor="electronicSignature"
            className="text-sm font-semibold sm:text-base"
          >
            Electronic Signature
          </label>
          <div className="relative self-stretch">
            <input
              type="text"
              className={`${
                errors.electronicSignature ? inputErrorClass : inputValidClass
              } font-normal`}
              placeholder="Enter"
              {...register('electronicSignature')}
              disabled={
                isLoadingGetPatientPacket ||
                isErrorGetPatientPacket ||
                patientPacket?.signed
              }
            />
            {Boolean(errors.electronicSignature) && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-status-error"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.electronicSignature ? (
            <p className="text-sm text-status-error">
              {errors.electronicSignature?.message}
            </p>
          ) : (
            <p className="text-sm">{`e.g. ${user.data.firstName} ${user.data.lastName}`}</p>
          )}
        </div>
        <div className="col-span-2 flex flex-col items-start gap-1">
          <label
            htmlFor="signedDate"
            className="text-sm font-semibold sm:text-base"
          >
            Signed Date
          </label>
          <div className="relative self-stretch">
            <DatePicker
              setValue={setValue}
              control={control}
              fieldKey="signedDate"
              isDisabled={
                isLoadingGetPatientPacket ||
                isErrorGetPatientPacket ||
                patientPacket?.signed
              }
              disableFutureDays
            />
            {Boolean(errors.signedDate) && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-status-error"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {Boolean(errors.signedDate) && (
            <p className="text-sm text-status-error">
              {errors.signedDate?.message}
            </p>
          )}
        </div>
        <div className="col-span-2 flex flex-col items-start gap-1">
          <Controller
            control={control}
            name="relationship"
            render={({ field }) => (
              <Listbox
                {...field}
                disabled={
                  isLoadingGetPatientPacket ||
                  isErrorGetPatientPacket ||
                  patientPacket?.signed
                }
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="font-semibold">
                      {`Your relationship to ${patient.firstName}`}
                    </Listbox.Label>
                    <div className="relative self-stretch">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = relationshipOptionsProfile.find(
                            (r: RelationshipType) =>
                              r?.name
                                .toLowerCase()
                                .startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('relationship', match.key, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={
                          errors.relationship
                            ? comboBoxErrorClass
                            : comboBoxClass
                        }
                      >
                        <span>
                          {(field.value &&
                            relationshipOptionsProfile.find(
                              (r: RelationshipType) => r.key === field.value
                            )?.name) ||
                            'Select'}
                        </span>
                        {!patientPacket?.signed && (
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                            {errors.relationship ? (
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-status-error"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        )}
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {relationshipOptionsProfile.map(
                            (relationship: RelationshipType) => (
                              <Listbox.Option
                                key={relationship?.key}
                                className={({ active }) =>
                                  classNames(
                                    'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                    active && 'bg-components-fillBorders'
                                  )
                                }
                                value={relationship?.key}
                              >
                                <span>{relationship?.name}</span>
                              </Listbox.Option>
                            )
                          )}
                        </Listbox.Options>
                      </Transition>
                    </div>
                    {Boolean(errors.relationship) && (
                      <p className="text-sm text-status-error">
                        {errors.relationship?.message}
                      </p>
                    )}
                  </>
                )}
              </Listbox>
            )}
          />
        </div>
      </div>
      <div className="flex w-full max-w-lg flex-col items-center gap-4 pt-8 sm:gap-12 sm:pt-0">
        <div className="flex w-full flex-row gap-2 sm:gap-4">
          <button
            disabled={isLoading}
            onClick={() => navigate(-1)}
            className={`${tertiaryButtonClass} w-full`}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            <p>Back</p>
          </button>
          <button
            onClick={() =>
              patientPacket?.signed
                ? onSuccessPostPatientPacket()
                : handleSubmit(signPatientPacket)()
            }
            disabled={!patientPacket?.signed && (isLoading || !isValid)}
            className={`${primaryButtonClass} w-full`}
          >
            {isLoading ? (
              <>
                <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                <p>Loading</p>
              </>
            ) : (
              <>
                <p>Next</p>
                <ChevronRightIcon className="h-5 w-5" />
              </>
            )}
          </button>
        </div>
        {!isLoading && showSkipAndComeBackLater && <SkipAndComeBackLater />}
      </div>
    </div>
  )
}

export default PatientPacket
