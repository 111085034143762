import React from 'react'

import ResourceGridLoader from './ResourceGridLoader'

const MultimediaScreenLoader: React.FC = () => {
  return (
    <div className="flex w-full max-w-5xl flex-col gap-10 sm:items-center sm:py-20 xs:gap-6">
      {/* Back button */}
      <div className="mb-4 flex w-full items-start xs:mb-0">
        <div className="flex h-7 w-20 animate-pulse rounded-md bg-components-fields" />
      </div>

      {/* Content */}
      <div className="flex w-full flex-col gap-10 rounded-2xl bg-white p-8 shadow-subtle xs:gap-6 xs:p-4">
        {/* Header */}
        <div className="flex flex-col gap-2">
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
        </div>

        {/* Categories */}
        <div className="flex flex-row flex-wrap gap-2">
          {React.Children.toArray(
            [...Array(6)].map(() => (
              <div className="flex h-8 w-30 animate-pulse flex-col rounded-md bg-components-fields" />
            ))
          )}
        </div>

        {/* Description */}
        <div className="flex flex-col gap-2">
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
          <div className="flex h-5 w-full animate-pulse flex-col rounded-md bg-components-fields" />
        </div>

        {/* Resource */}
        <div className="h-[50vh] animate-pulse rounded-2xl bg-components-fields px-6 py-8" />
      </div>

      {/* Divider */}
      <div className="w-full border-t border-text-secondary" />

      {/* Recommended */}
      <div className="flex w-full flex-col gap-10 xs:pb-10">
        <p className="text-2xl font-semibold xs:text-center xs:text-xl">
          Recommended
        </p>
        <ResourceGridLoader loadingNumber={3} />
      </div>
    </div>
  )
}

export default MultimediaScreenLoader
