import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import { flushSync } from 'react-dom'
import cloneDeep from 'lodash.clonedeep'

import { useAuth } from '../../../contexts/AuthProvider'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import { formatPossessiveNoun } from '../../../helpers/generic'
import TASKS from '../../../assets/images/Outcomes.svg'
import ProgressBar from './ProgressBar'
import type { Question } from '../../../queries/outcomes/GetQuestions'
import { useGetQuestions } from '../../../queries/outcomes/GetQuestions'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { useCompleteQuiz } from '../../../mutations/outcomes/CompleteQuiz'
import QuitTaskModal from './QuitTaskModal'
import { useGetAdditionalQuestions } from '../../../mutations/outcomes/GetAdditionalQuestions'
import type { ProviderInfo } from '../../../mutations/outcomes/TriggerDuress'
import { useCallDuressEmail } from '../../../mutations/outcomes/TriggerDuress'
import useGetProviders from '../../../queries/booking/UseGetProviders'

const ANSWERS = [
  { id: 0, text: 'Not at all' },
  { id: 1, text: 'Several days' },
  { id: 2, text: 'More than half the days' },
  { id: 3, text: 'Nearly every day' },
]

const DURESS_QUESTION_TEXT =
  'Thoughts that you would be better off dead, or of hurting yourself in some way'

const CheckInScreen: React.FC = () => {
  const { user, setUser } = useAuth()
  const { patientId } = useParams()
  const navigate = useNavigate()

  const patient = user.roster.find((p) => p.id === patientId)
  const quizIds = patient?.checkInTask?.nextSurveyIds
  const forSelf = patient?.relationship?.key === 'myself'

  const { data: resultProviders, isLoading: isLoadingUseGetProviders } =
    useGetProviders({
      patients: user?.roster,
      enabled: Boolean(user?.roster?.length),
      ignoreTakingNewPrivatePatients: true,
    })

  const { data: questionsResult, isLoading: isLoadingQuestions } =
    useGetQuestions({
      quizIds,
    })
  const { mutate: callCompleteQuiz, isLoading: isLoadingCompleteQuiz } =
    useCompleteQuiz()
  const { mutate: callSendDuressEmail, isLoading: isLoadingDuressEmail } =
    useCallDuressEmail()

  const {
    mutate: callGetAdditionalQuestions,
    isLoading: isLoadingAdditionalQuestions,
  } = useGetAdditionalQuestions()

  const [started, setStarted] = useState<boolean>(false)
  const [openQuitTask, setOpenQuitTask] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [results, setResults] = useState<Question[]>([])
  const [questions, setQuestions] = useState<Question[]>([])

  const selectAnswer = (answerId: number) => {
    const currentQuestion = questions[currentIndex]
    const questionIdx = results.findIndex((q) => q.id === currentQuestion.id)
    let tempResults = []

    if (questionIdx > -1) {
      const newResults = [...results]
      newResults[questionIdx].answer = answerId
      tempResults = newResults
    } else {
      tempResults = [
        ...results,
        {
          ...currentQuestion,
          answer: answerId,
        },
      ]
    }

    if (currentIndex < questions.length - 1) setCurrentIndex(currentIndex + 1)

    const nextQuizMapping = {
      'PHQ-2': 'PHQ-9',
      'GAD-2': 'GAD-7',
    }

    const currentQuizName = currentQuestion.quizName
    const followUpQuizName = nextQuizMapping[currentQuizName]

    if (followUpQuizName) {
      const insertNewQuestions = (
        newQuestions: Question[],
        insertAfterQuizName: string
      ) => {
        setQuestions((curr) => {
          // insert new questions after the quiz
          const index =
            curr.lastIndexOf(
              curr.find((q) => q.quizName === insertAfterQuizName)
            ) + 2

          // insert the questions at the correct place
          const result = [
            ...curr.slice(0, index),
            ...newQuestions.slice(2),
            ...curr.slice(index),
          ]

          // modify the quizInfo for the previous quiz questions
          const newArray = result.map((q) => {
            const newQuestion = newQuestions.find(
              (newQ) => newQ.text === q.text
            )
            if (newQuestion) return newQuestion
            else return q
          })

          // same for existing results
          tempResults = tempResults.map((r) => {
            const newQuestion = newQuestions.find(
              (newQ) => newQ.text === r.text
            )
            if (newQuestion) return { ...newQuestion, answer: r.answer }
            else return r
          })

          setResults(tempResults)

          return newArray
        })
      }

      const calculatePoints = (quizName: string) =>
        tempResults
          .filter((r) => r.quizName === quizName)
          .reduce((sum, result) => sum + result.answer, 0)

      const points = calculatePoints(currentQuizName)

      // add follow-up questions if more than 3 points and not already added
      if (
        points >= 3 &&
        !questions.some((q) => q.quizName === followUpQuizName)
      ) {
        callGetAdditionalQuestions(
          { quizId: currentQuestion.followUpSurveyId },
          {
            onSettled: (newQuestions: Question[]) =>
              insertNewQuestions(newQuestions, currentQuizName),
          }
        )
      } else setResults(tempResults)
    } else setResults(tempResults)
  }

  const completeQuiz = () =>
    callCompleteQuiz(
      {
        results,
        personId: patient.personId,
        patientTimeZone: patient.timeZone,
      },
      {
        onSettled: () => {
          flushSync(() => {
            const newUser = cloneDeep(user)
            const patientIdx = newUser.roster.findIndex(
              (p) => p.id === patientId
            )
            newUser.roster[patientIdx].checkInTask = null
            setUser(newUser)
          })
          const duressQuestion = results.find(
            (q) => q.text === DURESS_QUESTION_TEXT
          )
          const triggerDuress = duressQuestion?.answer !== 0
          if (Boolean(duressQuestion) && triggerDuress) {
            const providers: ProviderInfo[] =
              patient?.conditions
                ?.flatMap((c) =>
                  c?.carePlans?.map((cp) => {
                    const therapist = resultProviders
                      ?.find((r) => r.patient.id === patient.id)
                      ?.therapists?.find((t) => t.id === cp?.providerId)

                    if (!therapist) return

                    return {
                      id: therapist.id,
                      email: therapist.email,
                      firstName: therapist?.preferredName || therapist?.name,
                      serviceLine: therapist.serviceLine,
                    }
                  })
                )
                ?.filter(Boolean) || []

            // priority 1 send to MH
            const mentalHealthProvider = providers.find(
              (p) => p.serviceLine === 'Mental Health Therapy'
            )

            // priority 2 if no MH, send to school psych
            const schoolPsychologyProvider = providers.find(
              (p) => p.serviceLine === 'School Psychology'
            )

            // priority 3 if no school psych, send to ST
            const speechTherapyProvider = providers.find(
              (p) => p.serviceLine === 'Speech Therapy'
            )

            // if none, send to any provider
            const anyProvider = providers.find((p) => p)

            callSendDuressEmail(
              {
                answer: ANSWERS.find((a) => a.id === duressQuestion.answer)
                  .text,
                patientFullName: `${patient.firstName} ${patient.lastName}`,
                providers: [
                  mentalHealthProvider ||
                    schoolPsychologyProvider ||
                    speechTherapyProvider ||
                    anyProvider,
                ].filter(Boolean),
                clientName: user.data.clientData.clientName,
              },
              {
                onSettled: () => navigate('/tasks/duress', { replace: true }),
              }
            )
          } else
            navigate('/dashboard', {
              replace: true,
              state: { completedQuiz: true },
            })
        },
      }
    )

  useEffect(() => {
    if (isLoadingQuestions) return

    setQuestions(questionsResult)
  }, [isLoadingQuestions])

  const isLoading =
    isLoadingQuestions ||
    isLoadingAdditionalQuestions ||
    isLoadingCompleteQuiz ||
    isLoadingDuressEmail ||
    isLoadingUseGetProviders

  if ((!isLoading && (!patientId || !quizIds?.length)) || !patient)
    return <Navigate to="/dashboard" />

  return (
    <div className="mt-16 mb-auto flex w-full max-w-xs flex-col items-center gap-6 text-text-primary sm:mt-28 sm:max-w-lg">
      {!started ? (
        <>
          <div className="flex flex-col gap-6 text-center">
            <p className="text-base font-semibold sm:text-2xl">
              Let's do a quick check-in{' '}
              {!forSelf && (
                <>
                  for{' '}
                  <span className="text-cta-default">{patient?.firstName}</span>
                </>
              )}
            </p>
            <p className="text-sm font-normal sm:text-base">
              {forSelf ? (
                <>
                  These questions will help us understand your needs.
                  <br /> Please complete these questions in one sitting.
                </>
              ) : (
                <>
                  These questions will help us understand{' '}
                  {formatPossessiveNoun(patient?.firstName)} needs.
                  <br /> Please complete these questions in one sitting and have{' '}
                  {patient?.firstName} answer them independently or have the
                  parent/guardian complete them on{' '}
                  {formatPossessiveNoun(patient?.firstName)} behalf.
                </>
              )}
            </p>
          </div>

          <img
            src={TASKS}
            alt="task"
            className="h-26 w-26 object-cover sm:h-38 sm:w-38"
          />

          <div className="flex w-full flex-col gap-4 sm:gap-12">
            <button
              className={`${primaryButtonClass} w-full`}
              onClick={() => setStarted(true)}
              disabled={isLoadingQuestions || !questions?.length}
            >
              {isLoadingQuestions ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-white" />
                  Loading questions
                </>
              ) : (
                'Start task'
              )}
            </button>
            <button
              onClick={() => navigate('/dashboard')}
              className="text-sm font-medium text-text-secondary underline sm:text-base"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          {/* Progress */}
          <ProgressBar
            value={Math.floor((results.length * 100) / questions?.length)}
          />

          {/* Separator */}
          <div className="h-0.5 w-full max-w-sm bg-components-fillBorders" />

          {/* Texts */}
          <div className="flex flex-col gap-4">
            {/* Back and Next */}
            <div
              className={`flex w-full items-center ${
                currentIndex > 0 ? 'justify-between' : 'justify-end'
              }`}
            >
              {currentIndex > 0 && (
                <button
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                  className="flex items-center gap-1 font-semibold text-cta-default underline"
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  Back
                </button>
              )}
              {currentIndex < results?.length &&
                currentIndex !== questions?.length - 1 && (
                  <button
                    onClick={() => setCurrentIndex(currentIndex + 1)}
                    className="flex items-center gap-1 font-semibold text-cta-default underline"
                  >
                    Next
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                )}
            </div>

            {/* Intro */}
            <p className="text-center text-base xs:text-sm">
              Over the past 2 weeks, how often{' '}
              {forSelf ? (
                'have you'
              ) : (
                <>
                  has{' '}
                  <span className="font-semibold text-cta-default">
                    {patient.firstName}
                  </span>
                </>
              )}{' '}
              been bothered by the following problems?
            </p>

            {/* Question */}
            <p className="text-center text-base font-semibold">
              {questions[currentIndex]?.text}
            </p>
          </div>

          {/* Answers */}
          <div className="my-4 flex flex-col gap-4 xs:my-0 xs:w-full">
            {React.Children.toArray(
              ANSWERS.map((a) => (
                <button
                  className={`${tertiaryButtonClass} ${
                    results.some(
                      (q) =>
                        questions[currentIndex]?.id === q?.id &&
                        q?.answer === a?.id
                    )
                      ? 'border-cta-active bg-components-paleBlue'
                      : ''
                  }`}
                  onClick={(e) => {
                    selectAnswer(a.id)
                    e.currentTarget.blur()
                  }}
                  disabled={isLoadingAdditionalQuestions}
                >
                  {a.text}
                </button>
              ))
            )}
          </div>

          {/* Complete */}
          {results.length === questions.length &&
            !isLoadingAdditionalQuestions &&
            currentIndex === questions.length - 1 && (
              <button
                className={`${primaryButtonClass} w-full`}
                disabled={isLoadingCompleteQuiz || isLoadingDuressEmail}
                onClick={completeQuiz}
              >
                {isLoadingCompleteQuiz || isLoadingDuressEmail ? (
                  <>
                    <RefreshIcon className="loader h-5 w-5 text-white" />
                    Loading
                  </>
                ) : (
                  'Complete task'
                )}
              </button>
            )}

          {isLoadingAdditionalQuestions ? (
            <div className="flex items-center gap-1 font-semibold text-text-label">
              <RefreshIcon className="loader h-5 w-5 text-text-label" />
              Loading
            </div>
          ) : (
            <button
              className="text-center text-base underline xs:text-sm"
              onClick={() => setOpenQuitTask(true)}
            >
              Quit
            </button>
          )}

          {/* Quit task modal */}
          <QuitTaskModal open={openQuitTask} setOpen={setOpenQuitTask} />
        </>
      )}
    </div>
  )
}

export default CheckInScreen
