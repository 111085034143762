interface BannerProps {
  bannerClassName?: string
  bgImageBannerClass?: string
}

const Banner: React.FC<BannerProps> = ({
  bgImageBannerClass = '',
  bannerClassName = '',
}) => {
  return (
    <div
      className={`${bgImageBannerClass} ${bannerClassName} fixed top-0 left-0 w-screen`}
    />
  )
}

export default Banner
