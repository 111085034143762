import type { PaymentMethod } from '../types/User'
import { convertBackendTopicForFrontend } from '../queries/resources/GetResourceTopics'
import type { ResourceTopic } from '../types/Resources'
import { auth } from '../config/firebase'

export const getTopicsForPatient = async (
  patientId: string
): Promise<ResourceTopic[]> => {
  try {
    const token = await auth.currentUser.getIdToken()
    const backendTopics: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v1/preferences/patients/${patientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return convertBackendTopicForFrontend(backendTopics || [])
  } catch (error) {
    return []
  }
}

export const getPaymentMethod = async (): Promise<PaymentMethod> => {
  try {
    const token = await auth.currentUser.getIdToken()
    const paymentMethods: PaymentMethod[] = await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL}v1/payment-methods`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return paymentMethods?.[0] || null
  } catch (error) {
    return null
  }
}
