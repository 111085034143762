export const timeZones = {
  'Pacific Time (US and Canada)': 'America/Los_Angeles',
  'Eastern Time (US and Canada)': 'America/New_York',
  'Central Time (US and Canada)': 'America/Chicago',
  'Mountain Time (US and Canada)': 'America/Denver',
  Arizona: 'America/Phoenix',
  Hawaii: 'Pacific/Honolulu',
  Alaska: 'America/Juneau',
  'Puerto Rico': 'America/Puerto_Rico',
  'Midway Island, Samoa': 'Pacific/Midway',
  'Pago Pago': 'Pacific/Pago_Pago',
  Tijuana: 'America/Tijuana',
  Mazatlan: 'America/Mazatlan',
  Saskatchewan: 'America/Regina',
  'Mexico City': 'America/Mexico_City',
  Guatemala: 'America/Guatemala',
  Bogota: 'America/Bogota',
  Lima: 'America/Lima',
  Halifax: 'America/Halifax',
  Caracas: 'America/Caracas',
  Santiago: 'America/Santiago',
  'Newfoundland and Labrador': 'America/St_Johns',
  Montevideo: 'America/Montevideo',
  'Buenos Aires, Georgetown': 'America/Argentina/Buenos_Aires',
  Greenland: 'America/Godthab',
  'Sao Paulo': 'America/Sao_Paulo',
  Azores: 'Atlantic/Azores',
  'Cape Verde Islands': 'Atlantic/Cape_Verde',
  'Belgrade, Bratislava, Ljubljana': 'Europe/Belgrade',
  Dublin: 'Europe/Dublin',
  London: 'Europe/London',
  Lisbon: 'Europe/Lisbon',
  Casablanca: 'Africa/Casablanca',
  Copenhagen: 'Europe/Copenhagen',
  Brussels: 'Europe/Brussels',
  'Amsterdam, Berlin, Rome, Stockholm, Vienna': 'Europe/Berlin',
  Helsinki: 'Europe/Helsinki',
  Amsterdam: 'Europe/Amsterdam',
  Rome: 'Europe/Rome',
  Stockholm: 'Europe/Stockholm',
  Vienna: 'Europe/Vienna',
  Paris: 'Europe/Paris',
  Zurich: 'Europe/Zurich',
  Madrid: 'Europe/Madrid',
  Algiers: 'Africa/Algiers',
  'Harare, Pretoria': 'Africa/Harare',
  Nairobi: 'Africa/Nairobi',
  Warsaw: 'Europe/Warsaw',
  'Prague Bratislava': 'Europe/Prague',
  Budapest: 'Europe/Budapest',
  Sofia: 'Europe/Sofia',
  Istanbul: 'Europe/Istanbul',
  Athens: 'Europe/Athens',
  Bucharest: 'Europe/Bucharest',
  Jerusalem: 'Asia/Jerusalem',
  Cairo: 'Africa/Cairo',
  Johannesburg: 'Africa/Johannesburg',
  Moscow: 'Europe/Moscow',
  Baghdad: 'Asia/Baghdad',
  Kuwait: 'Asia/Kuwait',
  Riyadh: 'Asia/Riyadh',
  Tehran: 'Asia/Tehran',
  Muscat: 'Asia/Muscat',
  'Baku, Tbilisi, Yerevan': 'Asia/Baku',
  Kabul: 'Asia/Kabul',
  Yekaterinburg: 'Asia/Yekaterinburg',
  'Islamabad, Karachi, Tashkent': 'Asia/Tashkent',
  Kathmandu: 'Asia/Kathmandu',
  Novosibirsk: 'Asia/Novosibirsk',
  Almaty: 'Asia/Almaty',
  Krasnoyarsk: 'Asia/Krasnoyarsk',
  'Astana, Dhaka': 'Asia/Dhaka',
  Bangkok: 'Asia/Bangkok',
  Jakarta: 'Asia/Jakarta',
  'Irkutsk, Ulaanbaatar': 'Asia/Irkutsk',
  'Beijing, Shanghai': 'Asia/Shanghai',
  'Hong Kong': 'Asia/Hong_Kong',
  Taipei: 'Asia/Taipei',
  'Kuala Lumpur': 'Asia/Kuala_Lumpur',
  Singapore: 'Asia/Singapore',
  Perth: 'Australia/Perth',
  Yakutsk: 'Asia/Yakutsk',
  Seoul: 'Asia/Seoul',
  'Osaka, Sapporo, Tokyo': 'Asia/Tokyo',
  Darwin: 'Australia/Darwin',
  Adelaide: 'Australia/Adelaide',
  Vladivostok: 'Asia/Vladivostok',
  'Guam, Port Moresby': 'Pacific/Port_Moresby',
  Brisbane: 'Australia/Brisbane',
  'Canberra, Melbourne, Sydney': 'Australia/Sydney',
  Hobart: 'Australia/Hobart',
  Magadan: 'Asia/Magadan',
  'New Caledonia': 'Pacific/Noumea',
  Kamchatka: 'Asia/Kamchatka',
  'Fiji Islands, Marshall Islands': 'Pacific/Fiji',
  'Auckland, Wellington': 'Pacific/Auckland',
  'Mumbai, Kolkata, New Delhi': 'Asia/Kolkata',
  Kiev: 'Europe/Kiev',
  'Independent State of Samoa': 'Pacific/Apia',
}
