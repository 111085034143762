import React, { useEffect, useRef, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon, SearchIcon, XIcon } from '@heroicons/react/solid'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getSourceFromLocation } from '../../../helpers/utils'

import TopicLoader from './TopicLoader'
import type {
  ResourceFormat,
  ResourceSection,
  ResourceStatus,
  ResourceTopic,
} from '../../../types/Resources'
import { inputValidClass } from '../../../constants/classConstants'
import { useGetResourceTopics } from '../../../queries/resources/GetResourceTopics'
import {
  resourceFormatOptions,
  resourceStatusOptions,
} from '../../../constants/values'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'

interface ResourceFiltersProps {
  resettingFilters: boolean
  setResettingFilters: React.Dispatch<React.SetStateAction<boolean>>
  mainSearchValue: string
  setMainSearchValue: React.Dispatch<React.SetStateAction<string>>
  selectedTopics: ResourceTopic[]
  setSelectedTopics: React.Dispatch<React.SetStateAction<ResourceTopic[]>>
  selectedFormats?: ResourceFormat[]
  setSelectedFormats?: React.Dispatch<React.SetStateAction<ResourceFormat[]>>
  selectedStatuses?: ResourceStatus[]
  setSelectedStatuses?: React.Dispatch<React.SetStateAction<ResourceStatus[]>>
  debouncedMainSearchValue: string
  filteredResources: ResourceSection
  setFilteredResources: React.Dispatch<React.SetStateAction<ResourceSection>>
  isLoadingFilteredResources: boolean
  showFormat?: boolean
  showStatus?: boolean
}

const ResourceFilters: React.FC<ResourceFiltersProps> = ({
  resettingFilters,
  setResettingFilters,
  mainSearchValue,
  setMainSearchValue,
  selectedTopics,
  setSelectedTopics,
  selectedFormats,
  setSelectedFormats,
  selectedStatuses,
  setSelectedStatuses,
  debouncedMainSearchValue,
  filteredResources,
  setFilteredResources,
  isLoadingFilteredResources,
  showFormat,
  showStatus,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchInputRef = useRef(null)
  const { data: topics, isLoading: isLoadingTopics } = useGetResourceTopics()

  const [loadingFilters, setLoadingFilters] = useState<boolean>(true)
  const [searchTopicValue, setSearchTopicValue] = useState<string>('')

  // load filters from the URL query parameters
  useEffect(() => {
    const mainSearchValueParam = searchParams.get('mainSearchValue')
    const selectedTopicsParam =
      searchParams.get('selectedTopics')?.split(',') || []
    const selectedFormatsParam =
      searchParams.get('selectedFormats')?.split(',') || []
    const selectedStatusesParam =
      searchParams.get('selectedStatuses')?.split(',') || []

    setMainSearchValue(mainSearchValueParam || '')
    setSearchTopicValue('')
    setSelectedTopics(
      selectedTopicsParam
        .map((id) =>
          topics?.find((t: ResourceTopic) => t.key.toString() === id)
        )
        .filter(Boolean)
    )
    setSelectedFormats(
      selectedFormatsParam
        .map((id) =>
          resourceFormatOptions.find(
            (f: ResourceFormat) => f.id.toString() === id
          )
        )
        .filter(Boolean)
    )
    setSelectedStatuses(
      selectedStatusesParam
        .map((id) =>
          resourceStatusOptions.find(
            (s: ResourceStatus) => s.id.toString() === id
          )
        )
        .filter(Boolean)
    )

    setLoadingFilters(false)

    trackMixPanel({
      eventName: MIXPANEL_EVENT.SEARCH_COMPLETED,
      properties: {
        source: getSourceFromLocation(location),
        searchTerm: mainSearchValueParam,
      },
    })
  }, [])

  // filter by topics and formats
  useEffect(() => {
    if (loadingFilters || resettingFilters) return

    if (
      debouncedMainSearchValue?.length < 3 && // don't search if less than 3 characters
      !selectedTopics?.length && // don't search if no topics selected
      !selectedFormats?.length && // don't search if no formats selected
      !selectedStatuses?.length // don't search if no statuses selected
    )
      setFilteredResources(null)

    // update the URL query parameters
    const queryParams = new URLSearchParams()
    if (debouncedMainSearchValue)
      queryParams.set('mainSearchValue', debouncedMainSearchValue)
    if (selectedTopics.length > 0) {
      const selectedTopicsList = selectedTopics
        .map((t: ResourceTopic) => t.key)
        .join(',')
      queryParams.set('selectedTopics', selectedTopicsList)
      trackMixPanel({
        eventName: MIXPANEL_EVENT.LIST_FILTERED,
        properties: {
          list: getSourceFromLocation(location),
          filterType: 'Topics',
          filterValue: selectedTopicsList,
        },
      })
    }
    if (selectedFormats.length > 0) {
      const selectedFormatsList = selectedFormats.map((f) => f.id).join(',')
      queryParams.set('selectedFormats', selectedFormatsList)
      trackMixPanel({
        eventName: MIXPANEL_EVENT.LIST_FILTERED,
        properties: {
          list: getSourceFromLocation(location),
          filterType: 'Formats',
          filterValue: selectedFormatsList,
        },
      })
    }
    if (selectedStatuses.length > 0) {
      const selectedStatusesList = selectedStatuses.map((s) => s.id).join(',')
      queryParams.set('selectedStatuses', selectedStatusesList)
      trackMixPanel({
        eventName: MIXPANEL_EVENT.LIST_FILTERED,
        properties: {
          list: getSourceFromLocation(location),
          filterType: 'Statuses',
          filterValue: selectedStatusesList,
        },
      })
    }
    const hash = window.location.hash
    navigate(`?${queryParams.toString()}${hash}`, { replace: true })
  }, [
    debouncedMainSearchValue,
    selectedTopics,
    selectedFormats,
    selectedStatuses,
    loadingFilters,
    resettingFilters,
  ])

  // clear filters
  const resetFilters = () => {
    setResettingFilters(true)
    setSelectedTopics([])
    setMainSearchValue('')
    setSelectedFormats([])
    setSelectedStatuses([])
    const hash = window.location.hash
    navigate(`?${hash}`, { replace: true })
    setFilteredResources(null)

    trackMixPanel({
      eventName: MIXPANEL_EVENT.LIST_FILTER_RESET,
      properties: {},
    })

    // need to wait here for the debounce value to be set
    setTimeout(() => {
      setResettingFilters(false)
    }, 800)
  }

  return (
    <>
      <div className="flex w-full flex-col gap-1">
        <p className="text-base font-semibold">Search</p>
        <div className="flex w-full items-end gap-4 sm:justify-between xs:flex-wrap">
          {/* Search */}
          <div className="relative w-full">
            <input
              ref={searchInputRef}
              className={`${inputValidClass} whitespace-pre !px-12`}
              placeholder="Search by keywords"
              value={mainSearchValue}
              onChange={(e) => setMainSearchValue(e.target.value)}
            />
            <SearchIcon className="absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 text-components-fields" />
            {Boolean(mainSearchValue?.length) && (
              <XIcon
                className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer text-components-fields"
                onClick={() => {
                  searchInputRef.current.value = ''
                  setMainSearchValue('')
                }}
              />
            )}
          </div>

          {/* Topics */}
          <Listbox>
            <div className="relative my-auto flex-[0.15]">
              <Listbox.Button
                className="my-auto flex items-center gap-2 xs:gap-1"
                disabled={isLoadingTopics}
              >
                <p className="text-sm font-semibold text-text-primary xs:text-xs">
                  Topics
                </p>
                <p className="w-9 rounded-md bg-cta-default py-1 px-2.5 text-sm font-semibold text-white xs:w-8 xs:text-xs">
                  {selectedTopics.length || 0}
                </p>
                <ChevronDownIcon className="h-5 w-5 text-text-label" />
              </Listbox.Button>

              <Listbox.Options className="absolute z-10 mt-2 max-h-96 w-80 overflow-auto rounded-md bg-white p-4 shadow-lg ring-1 ring-cta-default ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="relative mb-4">
                  <SearchIcon className="absolute top-1/2 left-4 h-5 w-5 -translate-y-1/2 text-components-fields" />
                  <input
                    type="text"
                    className={`${inputValidClass} w-full whitespace-pre rounded-md border border-components-fields pl-10 text-sm focus:ring-cta-default`}
                    placeholder="Search by keywords"
                    onChange={(e) => setSearchTopicValue(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                {isLoadingTopics ? (
                  <TopicLoader />
                ) : (
                  React.Children.toArray(
                    topics
                      ?.filter((option: ResourceTopic) => {
                        const searchWords = searchTopicValue
                          .toLowerCase()
                          .split(' ')
                        return (
                          searchWords.some((word) =>
                            option.name.toLowerCase().includes(word)
                          ) && option.key !== 'OTHER'
                        )
                      })
                      .map((option: ResourceTopic) => (
                        <div
                          key={option.key}
                          className="my-2 flex items-center space-x-2"
                        >
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border border-components-fields text-cta-default focus:ring-cta-default"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedTopics([...selectedTopics, option])
                              } else {
                                setSelectedTopics(
                                  selectedTopics.filter(
                                    (selectedOption: ResourceTopic) =>
                                      selectedOption.key !== option.key
                                  )
                                )
                              }
                            }}
                            defaultChecked={selectedTopics.some(
                              (selectedOption: ResourceTopic) =>
                                selectedOption.key === option.key
                            )}
                          />
                          <span className="truncate">{option.name}</span>
                        </div>
                      ))
                  )
                )}
              </Listbox.Options>
            </div>
          </Listbox>

          {/* Format */}
          {showFormat && (
            <Listbox>
              <div className="relative my-auto flex-[0.15]">
                <Listbox.Button className="my-auto flex items-center gap-2 xs:gap-1">
                  <p className="text-sm font-semibold text-text-primary xs:text-xs">
                    Format
                  </p>
                  <p className="w-9 rounded-md bg-cta-default py-1 px-2.5 text-sm font-semibold text-white xs:w-8 xs:text-xs">
                    {selectedFormats.length || 0}
                  </p>
                  <ChevronDownIcon className="h-5 w-5 text-text-label" />
                </Listbox.Button>

                <Listbox.Options className="absolute z-10 mt-2 max-h-96 min-w-min overflow-auto rounded-md bg-white p-4 shadow-lg ring-1 ring-cta-default ring-opacity-5 focus:outline-none sm:text-sm">
                  {React.Children.toArray(
                    resourceFormatOptions.map((option: ResourceFormat) => (
                      <div className="my-2 flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border border-components-fields text-cta-default focus:ring-cta-default"
                          defaultChecked={selectedFormats.some(
                            (selectedOption: ResourceFormat) =>
                              selectedOption.id === option.id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedFormats([...selectedFormats, option])
                            } else {
                              setSelectedFormats(
                                selectedFormats.filter(
                                  (selectedOption: ResourceFormat) =>
                                    selectedOption.id !== option.id
                                )
                              )
                            }
                          }}
                        />
                        <span className="truncate">{option.displayName}</span>
                      </div>
                    ))
                  )}
                </Listbox.Options>
              </div>
            </Listbox>
          )}

          {/* Status */}
          {showStatus && (
            <Listbox>
              <div className="relative my-auto flex-[0.15]">
                <Listbox.Button className="my-auto flex items-center gap-2 xs:gap-1">
                  <p className="text-sm font-semibold text-text-primary xs:text-xs">
                    Status
                  </p>
                  <p className="w-9 rounded-md bg-cta-default py-1 px-2.5 text-sm font-semibold text-white xs:w-8 xs:text-xs">
                    {selectedStatuses.length || 0}
                  </p>
                  <ChevronDownIcon className="h-5 w-5 text-text-label" />
                </Listbox.Button>

                <Listbox.Options className="absolute z-10 mt-2 max-h-96 min-w-min overflow-auto rounded-md bg-white p-4 shadow-lg ring-1 ring-cta-default ring-opacity-5 focus:outline-none sm:text-sm">
                  {React.Children.toArray(
                    resourceStatusOptions.map((option: ResourceStatus) => (
                      <div className="my-2 flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border border-components-fields text-cta-default focus:ring-cta-default"
                          defaultChecked={selectedStatuses.some(
                            (selectedOption: ResourceStatus) =>
                              selectedOption.id === option.id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedStatuses([...selectedStatuses, option])
                            } else {
                              setSelectedStatuses(
                                selectedStatuses.filter(
                                  (selectedOption: ResourceStatus) =>
                                    selectedOption.id !== option.id
                                )
                              )
                            }
                          }}
                        />
                        <span className="truncate">{option.name}</span>
                      </div>
                    ))
                  )}
                </Listbox.Options>
              </div>
            </Listbox>
          )}
        </div>

        {/* Reset filters */}
        <button
          className="mt-3 self-start font-medium text-cta-default underline xs:text-xs"
          onClick={resetFilters}
        >
          Reset search & filters
        </button>
      </div>

      {/* Search message */}
      {!isLoadingFilteredResources && Boolean(filteredResources) ? (
        <div
          className={`flex flex-col gap-4 md:flex-row ${
            mainSearchValue.length > 20 ? 'flex-wrap' : ''
          }`}
        >
          <div
            className={`flex flex-shrink-0 items-center gap-2.5 rounded-lg bg-components-fillBorders p-2.5 ${
              mainSearchValue.length > 20 ? 'flex-col sm:flex-row xs:gap-1' : ''
            } ${mainSearchValue.length > 30 ? 'max-w-full' : 'max-w-fit'}`}
          >
            {filteredResources?.totalElements}{' '}
            {filteredResources?.totalElements === 1 ? 'result' : 'results'}
            {mainSearchValue?.length &&
            mainSearchValue === debouncedMainSearchValue ? (
              <>
                {' '}
                for:
                <span className="truncate font-semibold line-clamp-1">
                  "{mainSearchValue}"
                </span>
              </>
            ) : null}
          </div>
          {!filteredResources?.totalElements && (
            <p className="text-base">
              Sorry we couldn't find anything matching what you are looking for.
              Try using other keywords and filters or check out the recommended
              resources below.
            </p>
          )}
        </div>
      ) : null}
    </>
  )
}

export default ResourceFilters
