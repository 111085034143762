/**
  ^ matches the start of the string
  (0[1-9]|1[0-2]) matches the month part of the date. It matches either 0 followed by a digit from 1 to 9, or 1 followed by a digit from 0 to 2.
  \/ matches the forward slash character '/'
  (0[1-9]|[12]\d|3[01]) matches the day part of the date. It matches either 0 followed by a digit from 1 to 9, or 1 or 2 followed by any digit, or 3 followed by a digit from 0 to 1.
  \/ matches another forward slash character '/'
  ((19\d{2})|([2-9]\d{3})) matches the year part of the date. It matches years starting from 1900 and ending with 9999. It matches either 19 followed by any two digits, or any digit from 2 to 9 followed by any three digits.
  $ matches the end of the string
 */
export const DEFAULT_DATE_REGEX =
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/((19\d{2})|([2-9]\d{3}))$/

export const PASWORD_VALIDATION_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/*
  ^ is an anchor that matches the start of the input string.
  \( matches a literal opening parenthesis.
  \d{3} matches any three digits.
  \) matches a literal closing parenthesis.
  " " matches a literal space character.
  \d{3} matches any three digits.
  - matches a literal hyphen.
  \d{4} matches any four digits.
  $ is an anchor that matches the end of the input string.
*/
export const VALID_PHONE_NUMBER_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/

/*
  ^ Matches the beginning of the string
  (0[1-9]|1[0-2]) Matches the hour, which can be a number from 1 to 12
  : Matches the colon between the hours and minutes
  [0-5][0-9] Matches the minutes, which can be a number from 00 to 59
  ' ' Matches a literal space character.
  (AM|PM) Matches either "AM" or "PM"
  $ Matches the end of the string
*/
export const HOUR12_REGEX = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/
