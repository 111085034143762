import React from 'react'

import QUESTION_MARK from '../assets/icons/Question.svg'
import handleClickSupportEmail from '../helpers/supportClick'
import { useAuth } from '../contexts/AuthProvider'

interface QuestionsButtonProps {
  showOnMobile?: boolean
}

const QuestionsButton: React.FC<QuestionsButtonProps> = ({ showOnMobile }) => {
  const { user } = useAuth()

  return (
    <div
      className={`group fixed right-10 bottom-10 z-20 flex items-center rounded-4xl bg-white p-1 shadow-sm shadow-slate-500 hover:pr-0 lg:gap-2 lg:pr-2 xs:bottom-2 xs:right-2
      ${!showOnMobile ? 'xs:hidden' : ''}`}
    >
      <a
        href="mailto:support@huddleupcare.com"
        className="sm:hidden"
        onClick={() => handleClickSupportEmail(user, location)}
      >
        <img src={QUESTION_MARK} className="h-9" alt="mark" />
      </a>
      <img
        src={QUESTION_MARK}
        className="h-9 group-hover:hidden xs:hidden"
        alt="mark"
      />
      <p className="inline-flex gap-1 text-center font-grotesque text-lg font-extrabold text-text-primary group-hover:p-1 group-hover:text-base group-hover:font-normal xs:hidden">
        <span className="hidden group-hover:flex lg:flex">Questions?</span>
        <span className="hidden gap-1 group-hover:flex">
          Contact us at
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline xs:text-sm"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
        </span>
      </p>
    </div>
  )
}

export default QuestionsButton
