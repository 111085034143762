import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface GetConsentFormProps {
  patientId: string
}

interface ConsentFormResultData {
  url: string
  version: number
  signed: boolean
  patientRelationship: string | null
  signDate: string | null
  signedName: string | null
  error?: string
}

export const useGetConsentForm = (params: GetConsentFormProps, deps = params) =>
  useQuery(['getConsentForm', deps], async () => getConsentForm(params))

export const getConsentForm = async ({
  patientId,
}: GetConsentFormProps): Promise<ConsentFormResultData> => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const result = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PATIENT_PACKET_URL
      }/v1/separate-login-consent/${patientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return result
  } catch (error) {
    return Promise.reject(error)
  }
}
