import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface DeleteInsuranceProps {
  patientId: string
  insuranceId: string
}

const deleteInsurance = async ({
  patientId,
  insuranceId,
}: DeleteInsuranceProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/patients/${patientId}/insurance_memberships/${insuranceId}/inactivate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(null),
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useDeleteInsurance = () =>
  useMutation((params: DeleteInsuranceProps) => deleteInsurance(params))
