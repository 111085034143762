import React, { useEffect, useState } from 'react'

import {
  enumeratedElementsFromArray,
  formatPossessiveNoun,
} from '../../../helpers/generic'
import DashboardElement from '../DashboardElement'
import { useAuth } from '../../../contexts/AuthProvider'
import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import BookNewSessionBtn from '../../view-patient/tabs/BookNewSessionBtn'
import handleClickSupportEmail from '../../../helpers/supportClick'

const SessionsReplenished: React.FC = () => {
  const { user } = useAuth()
  const [showMessaging, setShowMessaging] = useState<boolean>(false)

  const userId = user?.data.id

  const seenCOSPage = JSON.parse(localStorage.getItem('DOTCOM_SEEN_COS_PAGE'))
  const sessionsOutLocalStorage = JSON.parse(
    localStorage.getItem('DOTCOM_NO_MORE_SESSIONS')
  )
  const sessionsAdditionalCareDismissed = JSON.parse(
    localStorage.getItem('DOTCOM_SESSIONS_REPLENISHED_DISMISSED')
  )

  const patientsWithAdditionalCare = user?.roster?.filter((patient) =>
    patient?.conditions
      ?.find((c) => !c.isIep)
      ?.carePlans?.some(
        (cp) =>
          // sessions ran out
          sessionsOutLocalStorage?.[userId]?.includes(cp.productId) &&
          //  they have sessions again
          (cp?.bulkModel?.isBulkModel
            ? cp?.bulkModel?.remainingSessionsOnBulkModel > 0
            : true) &&
          // they have remaining or unlimited sessions
          (cp.remainingSessions > 0 || cp.remainingSessions === null)
      )
  )

  // NOT_ALLOWED or PATIENT_PAY
  const additionalSessionsType = user.products.find((p) =>
    sessionsOutLocalStorage?.[userId]?.includes(p.id)
  )?.additionalSessions

  // determine when to show/hide the component
  useEffect(() => {
    if (!user) return

    const productIdsWithNoMoreSessions = user?.roster?.flatMap((patient) =>
      patient?.conditions
        ?.find((c) => !c.isIep)
        ?.carePlans?.filter(
          (cp) =>
            (cp.bulkModel.isBulkModel &&
              !cp.bulkModel.remainingSessionsOnBulkModel) ||
            (cp?.allowedSessions && cp?.remainingSessions <= 0)
        )
        ?.map((cp) => cp.productId)
    )

    if (productIdsWithNoMoreSessions?.length) {
      // sessions ran out
      localStorage.setItem(
        'DOTCOM_NO_MORE_SESSIONS',
        JSON.stringify({
          ...sessionsOutLocalStorage,
          [userId]: productIdsWithNoMoreSessions,
        })
      )

      // mark the messaging as not dismissed since the sessions have just ran out
      if (sessionsOutLocalStorage?.[userId]?.length)
        localStorage.setItem(
          'DOTCOM_SESSIONS_REPLENISHED_DISMISSED',
          JSON.stringify({
            ...sessionsAdditionalCareDismissed,
            [userId]: false,
          })
        )
    }

    const productsHaveCOSAllowed: boolean =
      user.products.find((p) =>
        sessionsOutLocalStorage?.[userId]?.includes(p.id)
      )?.additionalSessions !== 'NOT_ALLOWED'

    // did not dismiss the message
    // and did see the COS page
    // and there are patients with additional care
    if (
      !sessionsAdditionalCareDismissed?.[userId] &&
      (productsHaveCOSAllowed ? seenCOSPage?.[userId] : true) &&
      patientsWithAdditionalCare?.length
    )
      setShowMessaging(true)
  }, [user])

  const handleDismiss = () => {
    localStorage.setItem(
      'DOTCOM_SESSIONS_REPLENISHED_DISMISSED',
      JSON.stringify({ [userId]: true })
    )
    localStorage.setItem(
      'DOTCOM_NO_MORE_SESSIONS',
      JSON.stringify({
        ...sessionsOutLocalStorage,
        [userId]: [],
      })
    )
    setShowMessaging(false)
  }

  const patientNames = formatPossessiveNoun(
    enumeratedElementsFromArray(
      patientsWithAdditionalCare.map((p) => p.firstName)
    )
  )

  const therapyTypes = enumeratedElementsFromArray(
    patientsWithAdditionalCare.flatMap((p) =>
      p?.conditions
        ?.find((c) => !c.isIep)
        ?.carePlans.filter((cp) =>
          sessionsOutLocalStorage?.[userId]?.includes(cp.productId)
        )
        .map(
          (cp) =>
            SERVICE_LINES_ARRAY.find((sl) => sl.displayName === cp?.displayName)
              ?.type
        )
    )
  )

  if (!showMessaging) return null

  return (
    <DashboardElement
      noGap
      title={
        additionalSessionsType === 'PATIENT_PAY' ? (
          <span>
            <span className="text-cta-default">{patientNames}</span> therapy
            sessions are now at no cost to you!
          </span>
        ) : (
          <span>
            Additional care is now available for{' '}
            <span className="text-cta-default">{patientNames}</span>!
          </span>
        )
      }
    >
      <p className="text-base xs:text-sm">
        {additionalSessionsType === 'PATIENT_PAY' ? (
          <span>
            Great news, {patientNames} school or organization is covering the
            cost of their {therapyTypes} therapy sessions at this time.
          </span>
        ) : (
          <span>
            Great news, {patientNames} school or organization is now offering
            additional {therapyTypes} therapy sessions!
          </span>
        )}
        <br /> Get started today! Questions? Contact{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium text-cta-default underline "
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>
      <BookNewSessionBtn onClickBookNewSession={handleDismiss} withAvatarsBtn />
      <button
        className="self-center font-semibold underline"
        onClick={handleDismiss}
      >
        Dismiss
      </button>
    </DashboardElement>
  )
}

export default SessionsReplenished
