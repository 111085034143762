import React, { useState, useContext, createContext } from 'react'

import type { EligibilityInfo } from '../types/Register'

export interface RegisterContextModel {
  forSelf: boolean
  setForSelf: React.Dispatch<React.SetStateAction<boolean>>
  useWorkEmail: boolean
  setUseWorkEmail: React.Dispatch<React.SetStateAction<boolean>>
  setUserInfo: React.Dispatch<React.SetStateAction<EligibilityInfo>>
  userInfo: EligibilityInfo
}

export const RegisterContext = createContext<RegisterContextModel>(
  {} as RegisterContextModel
)

export const useRegister = (): RegisterContextModel =>
  useContext(RegisterContext)

export const RegisterProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [forSelf, setForSelf] = useState<boolean>(null)
  const [useWorkEmail, setUseWorkEmail] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<EligibilityInfo>(null)

  return (
    <RegisterContext.Provider
      value={{
        userInfo,
        setUserInfo,
        forSelf,
        setForSelf,
        useWorkEmail,
        setUseWorkEmail,
      }}
    >
      {children}
    </RegisterContext.Provider>
  )
}
