import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useFormContext } from 'react-hook-form'
import cloneDeep from 'lodash.clonedeep'

import { useAuth } from '../../contexts/AuthProvider'
import { formatPossessiveNoun } from '../../helpers/generic'
import type { Insurance, SelectInsuranceModel } from '../../types/Insurance'
import InsuranceForm from '../insurance/InsuranceForm'
import {
  useCreateInsuranceMembership,
  useCreateUnassociatedMembership,
} from '../../mutations/onboarding/InsuranceMembership'
import { useToastContext } from '../../contexts/ToastContext'
import type { Patient } from '../../types/Patient'
import { usePatient } from '../../contexts/PatientProvider'
import { useDeleteInsurance } from '../../mutations/dashboard/DeleteInsurance'
import useNavigateInsuranceComplete from './hooks/useNavigateInsuranceComplete'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'

const InsuranceInfoComplete: React.FC = () => {
  const { patient } = usePatient()
  const location = useLocation()

  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const selectedInsurance: SelectInsuranceModel = location?.state?.insurance
  const prevSelectedInsuranceFormData: Insurance =
    location?.state?.insuranceFormData

  // splitting form in only insurance info and then address
  const [split, setSplit] = useState<boolean>(true)
  const [formData, setFormData] = useState<Insurance>(
    prevSelectedInsuranceFormData || null
  )

  return (
    <div className="max-w-xl text-center">
      {split ? (
        <div className="flex flex-col gap-2 sm:gap-6">
          {!patient?.insurance ? (
            <h2 className="text-lg font-semibold sm:text-2xl">
              Good news. We accept health insurance!
            </h2>
          ) : (
            <h1 className="text-2xl font-semibold xs:text-lg">
              Update {forSelf ? 'your' : 'the'} insurance information
              {!forSelf && (
                <>
                  {' '}
                  for{' '}
                  <span className="text-cta-default">{patient.firstName}</span>
                </>
              )}
            </h1>
          )}
          <p className="text-sm font-semibold sm:text-base">
            Please enter{' '}
            <span className={`${forSelf ? '' : 'text-cta-default'}`}>
              {forSelf ? 'your' : formatPossessiveNoun(patient.firstName)}
            </span>{' '}
            insurance information.
          </p>
        </div>
      ) : (
        <h2 className="text-lg font-semibold sm:text-2xl">
          What is the policy holder's physical address?
        </h2>
      )}
      <InsuranceForm
        classes="mx-auto text-left"
        defaultValues={{ insurance: selectedInsurance, ...formData }}
        split={split}
        preSplit={!split}
        editableInsurance={false}
        formButtons={
          <InsuranceFormButtons
            split={split}
            setSplit={setSplit}
            formData={formData}
            setFormData={setFormData}
          />
        }
        buttonsOutsideForm
      />
    </div>
  )
}

export default InsuranceInfoComplete

const InsuranceFormButtons: React.FC<{
  split: boolean
  setSplit: React.Dispatch<React.SetStateAction<boolean>>
  formData: Insurance
  setFormData: React.Dispatch<React.SetStateAction<Insurance>>
}> = ({ split, setSplit, formData, setFormData }) => {
  const [settled, setSettled] = useState({
    value: false,
    unassociatedInsurance: false,
  })

  const { patient, setPatient } = usePatient()
  const { user, setUser } = useAuth()
  const addToast = useToastContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [navigateAccordinglyToFlow, isLoadingNavigateAccordinglyToFlow] =
    useNavigateInsuranceComplete()

  const selectedInsurance: SelectInsuranceModel = location.state?.insurance

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useFormContext()

  const { isLoading: isLoadingMembership, mutate: callCreateMembership } =
    useCreateInsuranceMembership()
  const {
    isLoading: isLoadingUnassociatedMembership,
    mutate: callCreateUnassociatedMembership,
  } = useCreateUnassociatedMembership()
  const {
    mutate: callDeleteInsurance,
    isLoading: isLoadingIntermediaryDeleteInsurance,
  } = useDeleteInsurance()

  // submit function for when entering only insurance info
  const halfSubmit = (data: Insurance) => {
    setFormData(data)
    setSplit(false)
  }

  // submit info when we have both insurance and address info
  const onSubmit = async (insurance: Insurance) => {
    if (patient?.insurance?.id) {
      callDeleteInsurance(
        {
          insuranceId: patient.insurance?.id,
          patientId: patient.id,
        },
        {
          onError: () => addToast('error', 'Something went wrong.'),
          onSuccess: () => {
            createNewInsurance(insurance)
          },
        }
      )
    } else {
      createNewInsurance(insurance)
    }
  }

  const createNewInsurance = (insurance: Insurance) => {
    if (insurance.otherInsuranceName)
      callCreateUnassociatedMembership(
        {
          patientId: patient.id,
          insurance,
        },
        {
          onError: onSettledError,
          onSuccess: (insuranceId) =>
            onSettledMembership({ ...insurance, id: insuranceId }, true),
        }
      )
    else
      callCreateMembership(
        {
          patientId: patient.id,
          insurance,
        },
        {
          onError: onSettledError,
          onSuccess: (insuranceId) =>
            onSettledMembership({ ...insurance, id: insuranceId }),
        }
      )
  }

  const onSettledError = () => addToast('error', 'Something went wrong.')

  const onSettledMembership = (
    insurance: Insurance,
    unassociatedInsurance = false
  ) => {
    const patientIdx = user.roster.findIndex(
      (p: Patient) => p.id === patient.id
    )

    const newUser = cloneDeep(user)
    newUser.roster[patientIdx] = {
      ...patient,
      insurance,
      unassociatedInsurance,
    }
    setUser(newUser)
    setPatient({
      ...patient,
      insurance,
      unassociatedInsurance,
    })

    setSettled({ value: true, unassociatedInsurance })
  }

  useEffect(() => {
    if (settled.value && !isLoadingNavigateAccordinglyToFlow)
      navigateAccordinglyToFlow({
        unassociatedInsurance: settled.unassociatedInsurance,
      })
  }, [settled, isLoadingNavigateAccordinglyToFlow])

  return (
    <div className="mx-auto flex w-full max-w-lg flex-col gap-6 sm:gap-12">
      <div className="flex w-full gap-2 sm:gap-4">
        <button
          type="button"
          className={`w-1/2 ${tertiaryButtonClass}`}
          disabled={
            isSubmitting ||
            isLoadingMembership ||
            isLoadingUnassociatedMembership ||
            isLoadingIntermediaryDeleteInsurance
          }
          onClick={() => {
            if (split)
              navigate(!patient?.insurance ? '../add' : '../edit', {
                state: {
                  insurance: selectedInsurance,
                  insuranceFormData: formData,
                },
              })
            else setSplit(true)
          }}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
        <button
          className={`w-1/2 ${primaryButtonClass}`}
          disabled={
            !isValid ||
            isSubmitting ||
            isLoadingMembership ||
            isLoadingUnassociatedMembership ||
            isLoadingIntermediaryDeleteInsurance ||
            isLoadingNavigateAccordinglyToFlow
          }
          onClick={handleSubmit(split ? halfSubmit : onSubmit)}
        >
          {isLoadingMembership ||
          isLoadingUnassociatedMembership ||
          isLoadingIntermediaryDeleteInsurance ||
          isLoadingNavigateAccordinglyToFlow ? (
            <>
              <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
              Loading
            </>
          ) : (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5" />
            </>
          )}
        </button>
      </div>
      <SkipAndComeBackLater />
    </div>
  )
}
