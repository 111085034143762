import { signOut } from 'firebase/auth'
import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { Product, User, UserInfo } from '../../types/User'
import { toProduct, toRoster, toUserData } from '../../helpers/transformers'
import getVanityUrl from '../../helpers/getVanityUrl'

const getUserData = async (): Promise<User> => {
  const token: string = await auth.currentUser.getIdToken(true)

  const user = await fetch(`${import.meta.env.VITE_DOTCOM_BFF_URL}user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.json())

  const userData: UserInfo = toUserData(user.data)

  // redirect to vanity url if in the wrong place
  const currentVanityUrl = getVanityUrl()
  const userVanityUrl = userData.clientData.vanityUrl

  if (!userVanityUrl || !currentVanityUrl)
    throw new Error('Something went wrong.')

  if (userVanityUrl !== currentVanityUrl) {
    signOut(auth)

    window.location.href = `${window.location.protocol}//${
      window.location.hostname.includes('localhost')
        ? userVanityUrl?.replace('huddleupcare.com', 'localhost:5173')
        : userVanityUrl
    }/sign-in?email=${encodeURIComponent(userData.email)}`
  }

  const userProducts: Product[] = user.products
    .map((p: any) => toProduct(p))
    .filter((p: Product) => {
      if (userData.clientData.clientType === 'EDUCATION' && !p.isIep)
        return p.serviceLine.serviceId === 4
      return true
    })

  return {
    data: userData,
    roster: toRoster(user),
    products: userProducts,
    payments: user.payments,
    paymentMethod: user.paymentMethod,
    allowedToBookHistories: user.allowedToBookHistories,
    dashboardSurveyLatest: user.dashboardSurveyLatest,
  }
}

export const useGetUserData = () => useMutation(() => getUserData())
