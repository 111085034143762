import React from 'react'

import type { Patient } from '../../../types/Patient'
import type { CourseProgress } from '../../../types/Courses'
import getAvatarSrc from '../../../helpers/getAvatarSrc'
import { useAuth } from '../../../contexts/AuthProvider'

const OneBadge: React.FC<{ patient: Partial<Patient>; index: number }> = ({
  patient,
  index,
}) => {
  const { user } = useAuth()
  return (
    <img
      src={getAvatarSrc(user, patient)}
      alt="patient-avatar"
      style={{ zIndex: 100 - index }}
      className="-mr-4 flex h-10 w-10 rounded-full shadow-subtle xs:h-6 xs:w-6"
    />
  )
}

const CoursePatientBadge: React.FC<{ progresses: CourseProgress[] }> = ({
  progresses,
}) => {
  return (
    <div className="absolute top-2 left-2 flex w-full max-w-xxs flex-wrap">
      {React.Children.toArray(
        progresses?.map((cp, index) => (
          <OneBadge patient={cp.patient} index={index} />
        ))
      )}
    </div>
  )
}

export default CoursePatientBadge
