import { useMutation } from '@tanstack/react-query'

import type { Question } from '../../queries/outcomes/GetQuestions'
import { fetchQuestions } from '../../queries/outcomes/GetQuestions'

type GetQuestionsParams = {
  quizId: string
}

export const useGetAdditionalQuestions = () =>
  useMutation((params: GetQuestionsParams) =>
    getAdditionalQuestions(params.quizId)
  )

const getAdditionalQuestions = async (quizId: string): Promise<Question[]> => {
  try {
    const result = await fetchQuestions([quizId])

    return result.flatMap((quiz: any) =>
      quiz.surveyQuestions.map((q: any) => ({
        id: q.id,
        text: q.text,
        quizName: quiz.name,
        quizId: quiz.id,
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
