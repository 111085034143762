import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { ServiceLine } from '../../types/ServiceLine'
import { getProviders } from './Providers'
import type { Patient } from '../../types/Patient'

interface ProvidersLengthProps {
  patient: Partial<Patient>
  serviceLines: ServiceLine[]
  licenseJurisdiction: string
  language?: string
  options?: UseQueryOptions
}

export interface ProvidersLengthReturnValue {
  social_work?: number
  speech_therapy?: number
  occupational_therapy?: number
}

// get number of providers by service line and selected state
const getProvidersLength = async ({
  patient,
  serviceLines,
  licenseJurisdiction,
  language = '',
}: ProvidersLengthProps): Promise<ProvidersLengthReturnValue> => {
  const returnValue: ProvidersLengthReturnValue = {}

  for (let i = 0; i < serviceLines?.length; i++) {
    const serviceType = serviceLines[i].serviceType
    const result = await getProviders({
      serviceType,
      language,
      licenseJurisdiction,
      patient,
    })

    returnValue[serviceType] = result?.length || 0
  }

  return returnValue
}

export const useGetProvidersLength = (
  { options = {}, ...params }: ProvidersLengthProps,
  deps = params
) =>
  useQuery(
    ['getProvidersLength', deps],
    async () => getProvidersLength(params),
    options
  )
