import { useMutation } from '@tanstack/react-query'

type SaveInfoParams = {
  email: string
  clientName: string
  phoneNumber?: string
  birthDate?: string
  firstName?: string
  lastName?: string
  externalId?: string
}

const saveContactInfo = async (data: SaveInfoParams) => {
  await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
    }v1/patients/send-eligibility-help-email`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientName: data.clientName,
        emailAddress: data.email,
        phoneNumber: data.phoneNumber,
        birthDate: data?.birthDate,
        firstName: data?.firstName,
        lastName: data?.lastName,
        externalId: data?.externalId,
      }),
    }
  )
}

export const useSaveContactInfo = () =>
  useMutation((params: SaveInfoParams) => saveContactInfo(params))
